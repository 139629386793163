import { Component, ViewEncapsulation } from "@angular/core";
import { GML_module } from "projects/fitnessforservice/src/services/gml_module";
import { Component_MAWP } from "projects/fitnessforservice/src/services/component_mawp";
import { LTA_module } from "projects/fitnessforservice/src/services/lta_module";
import { Pitting_module } from "projects/fitnessforservice/src/services/pitting_module";
import { Dent_Module } from "projects/fitnessforservice/src/services/dent_module";
import { Laminations_module } from "projects/fitnessforservice/src/services/laminations_module";
import { BlisterHIC_module } from "projects/fitnessforservice/src/services/blisterHIC_module";
import { Crack_Module } from "projects/fitnessforservice/src/services/crack_module";

@Component({
  selector: "report-template",
  templateUrl: "report-template.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ReportTemplateComponent {
  reportHTML: string;

  public component_mawp = Component_MAWP;
  crackLikeImageUrl: string;
  dentGougeImageUrl: string;

  constructor() {
    switch (Component_MAWP.strD_iclass) {
      case 0: //GML
        this.reportHTML = GML_module.GML_report();
        break;
      case 1: // LTA
        this.reportHTML = LTA_module.LTA_report();
        break;
      case 2: // LTA Profile
        this.reportHTML = LTA_module.LTA_report();
        break;
      case 3: //Gouge
        this.reportHTML = LTA_module.LTA_report();
        break;
      case 4: // Pitting
        this.reportHTML = Pitting_module.Pit_report();
        break;
      case 5: //BlisterHIC
        this.reportHTML = BlisterHIC_module.BlisterHIC_report();
        break;
      case 6: //Crack
        this.crackLikeImageUrl = Crack_Module.chartBase64ImageUrl;
        this.reportHTML = Crack_Module.Crack_report();
        break;
      case 7: // Dent
        this.reportHTML = Dent_Module.Dent_report();
        break;
      case 8: // DentGouge
        this.dentGougeImageUrl = Dent_Module.chartBase64ImageUrl;
        this.reportHTML = Dent_Module.Dent_Gouge_report();
        break;
      case 9: //Laminations
        this.reportHTML = Laminations_module.Laminations_report();
        break;
      default:
        break;
    }
  }
}
