import { Component, OnInit } from "@angular/core";
import { CalcTemplate } from "../../services/calctemplate";
import { Component_MAWP } from "../../services/component_mawp";
import { LTA_module } from "../../services/lta_module";
import { GML_module } from "../../services/gml_module";
import { BlisterHIC_module } from "../../services/blisterHIC_module";
import { Crack_Module } from "../../services/crack_module";
import { GougeModule } from "../../services/gouge_module";

@Component({
  selector: "debug-cmp",
  templateUrl: "debug-cmp.component.html",
})
export class DebugCmpComponent implements OnInit {
  public MAWPValues = "";
  public GMLValues = "";
  public CalcTemplateValues = "";
  public LTAValues = "";
  public BlisterHICValues = "";
  public CrackValues = "";
  public GougeValues = "";

  public refreshData(): void {
    this.showMAWPParam();
    this.showGMLParam();
    this.showCalcTemplateParam();
    this.showLTAParam();
    this.showBlisterHICParam();
    this.showCrackParam();
    this.showGougeParam();
  }

  private showMAWPParam(): void {
    this.MAWPValues = Component_MAWP.ReturnMAWPValuesAsHTML();
  }

  private showGMLParam(): void {
    this.GMLValues = GML_module.ReturnGMLValuesAsHTML();
  }

  private showCalcTemplateParam(): void {
    this.CalcTemplateValues = CalcTemplate.ReturnCalcTemplateValuesAsHTML();
  }

  private showLTAParam(): void {
    this.LTAValues = LTA_module.ReturnLTAValuesAsHTML();
  }

  private showBlisterHICParam(): void {
    this.BlisterHICValues = BlisterHIC_module.ReturnBlisterHICValuesAsHTML();
  }

  private showCrackParam(): void {
    this.CrackValues = Crack_Module.ReturnCrackValuesAsHTML();
  }

  private showGougeParam(): void {
    this.GougeValues = GougeModule.ReturnGougeValuesAsHTML();
  }

  ngOnInit(): void {
    this.refreshData();
  }
}
