import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ComponentEventService {

    private cb1_DI_EI_Source = new BehaviorSubject<boolean>(true);
    enabledComboBox1_DI_EquipInfoObservable = this.cb1_DI_EI_Source.asObservable();

    public allowableStressTempS = new BehaviorSubject<number>(0);
    public isHelpDialogModeActive = new BehaviorSubject<boolean>(false);

    public compMAWPintEquip = new BehaviorSubject<number>(-1);

    enableComboBox1_DI_EquipInfo(enable: boolean) {
        this.cb1_DI_EI_Source.next(enable)
    }

    public updateAllowableStressTempS(value: number): void {
        this.allowableStressTempS.next(value);
    }

    public get allowableStressTempSValue() {
        return this.allowableStressTempS.value;
    }

    public updateHelpDialogModeActive(value: boolean): void {
        this.isHelpDialogModeActive.next(value);
    }

    public get isHelpDialogModeActiveValue() {
        return this.isHelpDialogModeActive.value;
    }

    public updateCompMAWPintEquip(value: number): void {
        this.compMAWPintEquip.next(value);
    }

    public get compMAWPintEquipValue() {
        return this.compMAWPintEquip.value;
    }
}