import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    NextPageService,
    NavigationLocation,
    PageLayout
} from '@cvx/nextpage';

@Component({
    selector: 'units',
    templateUrl: 'units.component.html'
})

export class UnitsComponent implements OnInit {
    NavigationLocation = NavigationLocation;
    PageLayout = PageLayout;
    path: string;
    unitSelected: number = 0;

    constructor(private NP: NextPageService, private activatedRoute: ActivatedRoute) {
        this.path = activatedRoute.snapshot['_routerState'].url;
    }

    ngOnInit() {
        this.unitSelected = parseInt(localStorage.getItem('unitType')) || 0;
    }

    onChange(unitType: number) {
        localStorage.setItem('unitType', unitType.toString());
        this.unitSelected = unitType;
        window.location.reload();
    }
}