import { ComponentPortal, DomPortalOutlet } from "@angular/cdk/portal";
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
} from "@angular/core";
import { ReportTemplateComponent } from "../app/print-report/report-template/report-template.component";

@Injectable()
export class PopoutService {
  styleSheetElement;

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef
  ) { }

  openPopoutModal() {
    const windowInstance = this.openOnce(
      "assets/modal/popout.html",
      "MODAL_POPOUT"
    );

    // Wait for window instance to be created
    setTimeout(() => {
      this.createCDKPortal(windowInstance);
    }, 1000);
  }

  openOnce(url, target) {
    // Open a blank "target" window
    // or get the reference to the existing "target" window
    const winRef = window.open("", target, "");
    // If the "target" window was just opened, change its url
    if (winRef.location.href === "about:blank") {
      winRef.location.href = url;
    }
    return winRef;
  }

  createCDKPortal(windowInstance) {
    if (windowInstance) {
      // Create a PortalOutlet with the body of the new window document
      const outlet = new DomPortalOutlet(
        windowInstance.document.body,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector
      );
      // Copy styles from parent window
      document.querySelectorAll("style").forEach((htmlElement) => {
        windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
      });
      // Copy stylesheet link from parent window
      this.styleSheetElement = this.getStyleSheetElement();
      windowInstance.document.head.appendChild(this.styleSheetElement);

      this.styleSheetElement.onload = () => {
        // Clear popout modal content
        windowInstance.document.body.innerText = "";

        // Attach the portal
        windowInstance.document.title = "Report";
        this.attachReportContainer(outlet);
      };
    }
  }

  attachReportContainer(outlet) {
    const containerPortal = new ComponentPortal(ReportTemplateComponent, null);
    const containerRef: ComponentRef<ReportTemplateComponent> =
      outlet.attach(containerPortal);
    return containerRef.instance;
  }

  getStyleSheetElement() {
    const styleSheetElement = document.createElement("link");
    document.querySelectorAll("link").forEach((htmlElement) => {
      if (htmlElement.rel === "stylesheet") {
        const absoluteUrl = new URL(htmlElement.href).href;
        styleSheetElement.rel = "stylesheet";
        styleSheetElement.href = absoluteUrl;
      }
    });
    return styleSheetElement;
  }
}
