// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-container {
  display: flex;
}

.dialog-actions {
  float: right;
}

label {
  color: black;
}

.container {
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./projects/fitnessforservice/src/app/print-report/print-report-modal/print-report-modal.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".dialog-container {\n  display: flex;\n}\n\n.dialog-actions {\n  float: right;\n}\n\nlabel {\n  color: black;\n}\n\n.container {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
