import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  SearchComponent,
  FooterComponent,
  TabbedComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  ValidNextPageRoutePipe,
  AlertComponent,
} from "@cvx/nextpage";


const sharedComponents = [
  SearchComponent,
  FooterComponent,
  TabbedComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  ValidNextPageRoutePipe,
  AlertComponent,
];

@NgModule({
  declarations: [sharedComponents],
  imports: [CommonModule],
  exports: [sharedComponents],
})
export class SharedModule {}
