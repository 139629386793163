import { NgModule, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterModule,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { NextPageService } from "@cvx/nextpage";
import { NextPageRoute } from "@cvx/nextpage/public_api";
import { HomeComponent } from "./home/home.component";
import { GeneralMetalLossComponent } from "./assessments/general-metal-loss/general-metal-loss.component";
import { LocalMetalLossComponent } from "./assessments/local-metal-loss/local-metal-loss.component";
import { LocalMetalLossProfileDataComponent } from "./assessments/local-metal-loss-profile-data/local-metal-loss-profile-data.component";
import { GougesComponent } from "./assessments/gouges/gouges.component";
import { PittingCorrosionComponent } from "./assessments/pitting-corrosion/pitting-corrosion.component";
import { BlistersAndHICDamageComponent } from "./assessments/blisters-and-hic-damage/blisters-and-hic-damage.component";
import { CrackLikeIndicationsComponent } from "./assessments/crack-like-indications/crack-like-indications.component";
import { DentsComponent } from "./assessments/dents/dents.component";
import { DentGougeCombinationsComponent } from "./assessments/dents-gouge-combinations/dents-gouge-combinations.component";
import { LaminationsComponent } from "./assessments/laminations/laminations.component";
import { UnitsComponent } from "./units/units.component";
import { HelpComponent } from "./help/help.component";
import { Utility } from "../services/utility";
import { CalAngularService, CalGuardService } from "@cvx/cal-angular";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { Observable, map, catchError, of, tap } from "rxjs";
import { environment } from "../environments/environment";

//Add this to canActivate in the route to check if the user is in the group
export const userIsInGroup = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const authService = inject(CalAngularService);
  const router = inject(Router);
  return authService.isInGroup(environment.groupName).pipe(
    map((isInGroup) => isInGroup),
    tap((isInGroup) => {
      if (!isInGroup) {
        router.navigate(["/"]); //redirect to home page if user is not in the group
      }
    }),
    catchError(() => {
      return of(router.createUrlTree(["/"])); //redirect to home page if there is an error
    })
  );
};

export const routes: NextPageRoute[] = [
  {
    path: "NewAnalysis",
    title: "New Analysis",
    redirectTo: "/",
    pathMatch: "full",
  },
  {
    path: "Units",
    title: "Units " + Utility.getActualUnitName(),
    component: UnitsComponent,
    canActivate: [CalGuardService, userIsInGroup],
  },
  {
    path: "Help",
    title: "Help",
    canActivate: [CalGuardService],
    children: [
      {
        path: "help",
        title: "Technical Content",
        component: HelpComponent,
        canActivate: [CalGuardService],
      },
      {
        path: "about",
        title: "About Chevron FFS",
        component: AboutComponent,
        canActivate: [CalGuardService],
      },
      {
        path: "contact-us",
        title: "Contact Us",
        component: ContactComponent,
        canActivate: [CalGuardService],
      },
    ],
  },
  {
    path: "",
    title: "Assessments",
    children: [
      {
        path: "",
        component: HomeComponent,
        canActivate: [CalGuardService],
      },
      {
        path: "general-metal-loss",
        title: "General Metal Loss",
        component: GeneralMetalLossComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "local-metal-loss",
        title: "Local Metal Loss",
        component: LocalMetalLossComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "local-metal-loss-profile-data",
        title: "Local Metal Loss (Profile Data)",
        component: LocalMetalLossProfileDataComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "gouges",
        title: "Gouges",
        component: GougesComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "pitting-corrosion",
        title: "Pitting Corrosion",
        component: PittingCorrosionComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "blisters-and-hic-damage",
        title: "Blisters and HIC Damage",
        component: BlistersAndHICDamageComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "crack-like-indications",
        title: "Crack-Like Indications",
        component: CrackLikeIndicationsComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "dents",
        title: "Dents",
        component: DentsComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "dents-gouge-combinations",
        title: "Dent-Gouge Combinations",
        component: DentGougeCombinationsComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
      {
        path: "laminations",
        title: "Laminations",
        component: LaminationsComponent,
        canActivate: [CalGuardService, userIsInGroup],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private NP: NextPageService) {
    this.NP.options.navigationRoutes = routes;
  }
}
