import { Chart } from "chart.js";
import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { GougeModule } from "./gouge_module";
import { Dent_Module } from "./dent_module";
import { formatDate } from "@angular/common";
import { FormControl, FormGroup } from "@angular/forms";
import { Utility } from "./utility";

export class Crack_Module {
  public static sngA_crack: number; // crack depth
  public static sngC_crack: number; // crack length
  public static intcrack_location: number; // crack location, 0 - Weld & HAZ, 1 - base metal
  public static intcrack_orien: number; // crack orientation, 0 - Parallel, 1 - Perpendicular
  public static intsize_accuracy: number; // sizing accuracy, 0 - low, 1- high
  public static intweld_orien: number; // weld orientation, 0 - Longitudinal, 1 - Circumferential
  public static intcarbsteelsulf_cont: number; // added by shameer for crack like module, carbon steel content
  public static sngSMTS: number; // specified minimum tensile strength
  public static sngA_stress: number; // allowable stress
  public static intPWHT: number; // post weld heat treat, 0 - no, 1 - yes, 2 - Don't know
  public static sngTref: number; // reference temperature
  public static intcurve_key: number; // crack curve number
  public static sngC_allow: number; // allowable crack length
  public static sngCET: number; // critical exposure temperature

  public static Tpt: number = 0.0;
  public static TptActual: number = 0.0;
  public static aa: number = 0.0;

  public static cet_temp: number = -1;

  public static chart2: Chart;
  public static chart2LabelResult: string;
  public static chart2LabelResultColorRed: boolean;

  public static validationMessageCrack = [];
  public static validationMessageComponentInformation = [];
  public static crackAlertMessageFix = [];
  public static crackCalcResult = "";

  public static chartBase64ImageUrl: string;

  public static Crack_initialize(): void {
    // initialize crack module
    this.sngA_crack = -1;
    this.sngC_crack = -1;
    this.intcrack_location = -1;
    this.intcrack_orien = -1;
    this.intsize_accuracy = -1;
    this.intweld_orien = -1;
    this.intcarbsteelsulf_cont = -1;
    this.sngSMTS = -1;
    this.sngA_stress = -1;
    this.intPWHT = -1;
    this.sngTref = -1;
    this.intcurve_key = -1;
    this.sngC_allow = -1;
    this.sngCET = -1;

    this.chart2LabelResult = "";
    this.validationMessageCrack = [];
    this.validationMessageComponentInformation = [];
    this.crackAlertMessageFix = [];
    this.crackCalcResult = "";
    this.chartBase64ImageUrl = "";
  }

  public static ReturnCrackValuesAsHTML(): string {
    let result =
      "sngA_crack: " +
      Crack_Module.sngA_crack +
      "</br>" +
      "sngC_crack: " +
      Crack_Module.sngC_crack +
      "</br>" +
      "intcrack_location: " +
      Crack_Module.intcrack_location +
      "</br>" +
      "intcrack_orien: " +
      Crack_Module.intcrack_orien +
      "</br>" +
      "intsize_accuracy: " +
      Crack_Module.intsize_accuracy +
      "</br>" +
      "intweld_orien: " +
      Crack_Module.intweld_orien +
      "</br>" +
      "intcarbsteelsulf_cont: " +
      Crack_Module.intcarbsteelsulf_cont +
      "</br>" +
      "sngSMTS: " +
      Crack_Module.sngSMTS +
      "</br>" +
      "sngA_stress: " +
      Crack_Module.sngA_stress +
      "</br>" +
      "intPWHT: " +
      Crack_Module.intPWHT +
      "</br>" +
      "sngTref: " +
      Crack_Module.sngTref +
      "</br>" +
      "intcurve_key: " +
      Crack_Module.intcurve_key +
      "</br>" +
      "sngC_allow: " +
      Crack_Module.sngC_allow +
      "</br>" +
      "sngCET: " +
      Crack_Module.sngCET +
      "</br>" +
      "Tpt: " +
      Crack_Module.Tpt +
      "</br>" +
      "aa: " +
      Crack_Module.aa;

    return result;
  }

  public static Crack_calc(): string {
    // calculate crack properties
    this.crackCalcResult = "";
    // Function to perform Crack Level 1 calculations
    let key1: number,
      key2: number,
      key3: number,
      key4: number,
      key5: number,
      key6: number,
      key7: number,
      key8: number;

    // First criterion -- trd <= 1.5" (38mm)
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (Component_MAWP.sngtrd <= 1.5) {
        key1 = 0;
        this.crackCalcResult += `Thickness, t = ${Component_MAWP.sngtrd.toFixed(
          3
        )} inch <= 1.5 inch </br>`;
      } else {
        key1 = 1;
        this.crackCalcResult += `Thickness, t = ${Component_MAWP.sngtrd.toFixed(
          3
        )} inch > 1.5 inch <font color="red">*Fail*</font> </br>`;
      }
    } else {
      if (Component_MAWP.sngtrd <= 38.0) {
        key1 = 0;
        this.crackCalcResult += `Thickness, t = ${Component_MAWP.sngtrd.toFixed(
          1
        )} mm <= 38 mm </br>`;
      } else {
        key1 = 1;
        this.crackCalcResult += `Thickness, t = ${Component_MAWP.sngtrd.toFixed(
          1
        )} mm > 38 mm <font color="red">*Fail*</font> </br>`;
      }
    }

    // Second criterion -- R / t >= 5
    if (Component_MAWP.intEquip === 2) {
      // flat plate
      key2 = 0;
      this.crackCalcResult += `Ratio R / t is not applicable to flat plate. </br>`;
    } else {
      const aa = Component_MAWP.sngID / (2.0 * Component_MAWP.sngtrd);
      if (aa >= 5.0) {
        key2 = 0;
        this.crackCalcResult += `Ratio R / t = ${aa.toFixed(3)} >= 5 </br>`;
      } else {
        key2 = 1;
        this.crackCalcResult += `Ratio R / t = ${aa.toFixed(
          3
        )} < 5 <font color="red">*Fail*</font> </br>`;
      }
    }

    // Third criterion -- Lmsd >= 1.8 * sqrt(ID * trd)
    if (Component_MAWP.intEquip === 2) {
      // flat plate
      key3 = 0;
      this.crackCalcResult += `Lmsd is not applicable to flat plate. </br>`;
    } else {
      const aa = 1.8 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtrd);
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        if (CalcTemplate.sngLmsd >= aa) {
          key3 = 0;
          this.crackCalcResult += `Lmsd = ${CalcTemplate.sngLmsd.toFixed(
            3
          )} inch >= 1.8 * sqrt(ID * trd) = ${aa.toFixed(3)} inch </br>`;
        } else {
          key3 = 1;
          this.crackCalcResult += `Lmsd = ${CalcTemplate.sngLmsd.toFixed(
            3
          )} inch < 1.8 * sqrt(ID * trd) = ${aa.toFixed(
            3
          )} inch <font color="red">*Fail*</font> </br>`;
        }
      } else {
        // SI
        if (CalcTemplate.sngLmsd >= aa) {
          key3 = 0;
          this.crackCalcResult += `Lmsd = ${CalcTemplate.sngLmsd.toFixed(
            1
          )} mm >= 1.8 * sqrt(ID * trd) = ${aa.toFixed(1)} mm </br>`;
        } else {
          key3 = 1;
          this.crackCalcResult += `Lmsd = ${CalcTemplate.sngLmsd.toFixed(
            1
          )} mm < 1.8 * sqrt(ID * trd) = ${aa.toFixed(
            1
          )} mm <font color="red">*Fail*</font> </br>`;
        }
      }
    }

    // Fourth criterion -- base metal allowable stress
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (this.sngA_stress <= 25.0) {
        key4 = 0;
        this.crackCalcResult += `Base metal allowable stress = ${this.sngA_stress.toFixed(
          1
        )} ksi <= 25 ksi </br>`;
      } else {
        key4 = 1;
        this.crackCalcResult += `Base metal allowable stress = ${this.sngA_stress.toFixed(
          1
        )} ksi > 25 ksi <font color="red">*Fail*</font> </br>`;
      }
    } else {
      if (this.sngA_stress <= 172.0) {
        key4 = 0;
        this.crackCalcResult += `Base metal allowable stress = ${this.sngA_stress.toFixed(
          2
        )} MPa <= 172 MPa </br>`;
      } else {
        key4 = 1;
        this.crackCalcResult += `Base metal allowable stress = ${this.sngA_stress.toFixed(
          2
        )} MPa > 172 MPa <font color="red">*Fail*</font> </br>`;
      }
    }

    // Fifth criterion -- base metal SMYS <= 276 MPa (40 ksi)
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = GougeModule.sngSMYS.toFixed(1);
      if (GougeModule.sngSMYS <= 40.0) {
        key5 = 0;
        this.crackCalcResult += `Base metal SMYS = ${Component_MAWP.buffer} ksi <= 40 ksi </br>`;
      } else {
        key5 = 1;
        this.crackCalcResult += `Base metal SMYS = ${Component_MAWP.buffer} ksi > 40 ksi <font color="red">*Fail*</font> </br>`;
      }
    } else {
      // SI
      Component_MAWP.buffer = GougeModule.sngSMYS.toFixed(2);
      if (GougeModule.sngSMYS <= 276.0) {
        key5 = 0;
        this.crackCalcResult += `Base metal SMYS = ${Component_MAWP.buffer} MPa <= 276 MPa </br>`;
      } else {
        key5 = 1;
        this.crackCalcResult += `Base metal SMYS = ${Component_MAWP.buffer} MPa > 276 MPa <font color="red">*Fail*</font> </br>`;
      }
    }

    // sixth criterion -- base metal SMTS <= 483 MPa (70 ksi)
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = this.sngSMTS.toFixed(1);
      if (this.sngSMTS <= 70.0) {
        key6 = 0;
        this.crackCalcResult += `Base metal SMTS = ${Component_MAWP.buffer} ksi <= 70 ksi </br>`;
      } else {
        key6 = 1;
        this.crackCalcResult += `Base metal SMTS = ${Component_MAWP.buffer} ksi > 70 ksi <font color="red">*Fail*</font> </br>`;
      }
    } else {
      // SI
      Component_MAWP.buffer = this.sngSMTS.toFixed(2);
      if (this.sngSMTS <= 483.0) {
        key6 = 0;
        this.crackCalcResult += `Base metal SMTS = ${Component_MAWP.buffer} MPa <= 483 MPa </br>`;
      } else {
        key6 = 1;
        this.crackCalcResult += `Base metal SMTS = ${Component_MAWP.buffer} MPa > 483 MPa <font color="red">*Fail*</font> </br>`;
      }
    }

    //- shameer start new req after adding mawp screen for cracklike
    // MAWP >= P_d pass else fail

    if (
      (Component_MAWP.intEquip === 0 || Component_MAWP.intEquip === 1) &&
      Component_MAWP.sngp_H !== -1
    ) {
      // when user selected "B31.3 Piping" in Crack-Like Indications then Liquid headHeight = -1 should be considered
      {
        Component_MAWP.buffer = (
          Component_MAWP.sngMAWP - Component_MAWP.sngp_H
        ).toFixed(1);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);

        if (
          Component_MAWP.intUnit_select === 0 ||
          Component_MAWP.intUnit_select === 1
        ) {
          // US Customary or SI units
          if (
            Component_MAWP.sngMAWP - Component_MAWP.sngp_H >=
            Component_MAWP.sngP_d
          ) {
            key8 = 0;
            this.crackCalcResult += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} >= P_d = ${Component_MAWP.buffer1}</br>`;
          } else {
            key8 = 1;
            this.crackCalcResult += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} < P_d = ${Component_MAWP.buffer1} <font color="red">*Fail*</font></br>`;
          }
        }
      }
    } else {
      Component_MAWP.buffer = Component_MAWP.sngMAWP.toFixed(1);
      Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
      if (
        Component_MAWP.intUnit_select === 0 ||
        Component_MAWP.intUnit_select === 1
      ) {
        // US Customary or SI units
        if (Component_MAWP.sngMAWP >= Component_MAWP.sngP_d) {
          key8 = 0;
          this.crackCalcResult += `Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} >= P_d = ${Component_MAWP.buffer1}</br>`;
        } else {
          key8 = 1;
          this.crackCalcResult += `Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} < P_d = ${Component_MAWP.buffer1} <font color="red">*Fail*</font></br>`;
        }
      }
    }

    //- shameer end

    // seventh criterion -- assign assessment curve and calculate the maximum allowable crack length
    if (
      key1 === 0 &&
      key2 === 0 &&
      key3 === 0 &&
      key4 === 0 &&
      key5 === 0 &&
      key6 === 0
    ) {
      if (Component_MAWP.intEquip === 2)
        // flat plate
        this.intcurve_key = 10;
      if (Component_MAWP.intEquip === 1) {
        // sphere
        if (this.intcrack_orien === 0)
          // Parallel
          this.intcurve_key = 60;
        // Perpendicular
        else this.intcurve_key = 70;
      }
      if (Component_MAWP.intEquip === 0) {
        // cylinder
        if (this.intweld_orien === 0) {
          // long.
          if (this.intcrack_orien === 0)
            // parallel
            this.intcurve_key = 20;
          // crack_orien = 1, perpendicular
          else this.intcurve_key = 30;
        } else {
          // weld_orien = 1, circ.
          if (this.intcrack_orien === 0)
            // parallel
            this.intcurve_key = 40;
          // crack_orien = 1, perpendicular
          else this.intcurve_key = 50;
        }
      }

      this.curve_select();
      this.C_curve_pt();

      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        Component_MAWP.buffer = this.sngC_crack.toFixed(3);
        Component_MAWP.buffer1 = this.sngC_allow.toFixed(3);
        if (this.sngC_crack <= this.sngC_allow) {
          key7 = 0;
          if (Component_MAWP.cet_temp - this.sngTref + 100 < 0) {
            this.crackCalcResult += `<font color="red">T – Tref + 100 is out of range on the Level 1 Assessment Curve</font></br>`;
          } else {
            this.crackCalcResult += `Measured crack length = ${Component_MAWP.buffer} inch <= Allowable crack length = ${Component_MAWP.buffer1} inch</br>`;
          }
        } else {
          key7 = 1;
          if (Component_MAWP.cet_temp - this.sngTref + 100 < 0) {
            this.crackCalcResult += `<font color="red">T – Tref + 100 is out of range on the Level 1 Assessment Curve</font></br>`;
          } else {
            this.crackCalcResult += `Measured crack length = ${Component_MAWP.buffer} inch > Allowable crack length = ${Component_MAWP.buffer1} inch</br>`;
          }
        }
      } else {
        // SI
        Component_MAWP.buffer = this.sngC_crack.toFixed(1);
        Component_MAWP.buffer1 = this.sngC_allow.toFixed(1);
        if (this.sngC_crack <= this.sngC_allow) {
          key7 = 0;
          if (Component_MAWP.cet_temp - this.sngTref + 56 < 0) {
            this.crackCalcResult += `<font color="red">T – Tref + 56 is out of range on the Level 1 Assessment Curve</font></br>`;
          } else {
            this.crackCalcResult += `Measured crack length = ${Component_MAWP.buffer} mm <= Allowable crack length = ${Component_MAWP.buffer1} mm</br>`;
          }
        } else {
          key7 = 1;
          if (Component_MAWP.cet_temp - this.sngTref + 56 < 0) {
            this.crackCalcResult += `<font color="red">T – Tref + 56 is out of range on the Level 1 Assessment Curve</font></br>`;
          } else {
            this.crackCalcResult += `Measured crack length = ${Component_MAWP.buffer} mm > Allowable crack length = ${Component_MAWP.buffer1} mm</br>`;
          }
        }
      }
    } else {
      key7 = 1;
      this.crackCalcResult += `One or more of the above conditions are not met. Crack length is not assessed! </br>`;
    }

    // shameer - start  T-this.sngTref + 100 / T - this.sngTref + 56 // uscustomary // SI added new condition
    if (
      (Component_MAWP.intUnit_select === 0 &&
        Component_MAWP.cet_temp - this.sngTref + 100 < 0) ||
      (Component_MAWP.intUnit_select === 1 &&
        Component_MAWP.cet_temp - this.sngTref + 56 < 0)
    ) {
      Component_MAWP.strD_result = "Fail";
      this.crackCalcResult += ` </br>`;
      //Validate if key 8 has been failed to attach fail message    /* Carlos Acero - 061924 */
      if (key8 === 1) {
        this.crackCalcResult += `<font color="red">Component failed MAWP Check. </font></br>`;
      }
      this.crackCalcResult += `<font color="red">Indication failed Level 1 assessment. </br> Consider performing a Level 2 or Level 3 assessment. </font></br>`;
    } else {
      // shameer - end

      if (key8 === 1) {
        Component_MAWP.strD_result = "Fail";
        this.crackCalcResult += ` </br>`;
        this.crackCalcResult += `<font color="red">Component failed MAWP Check. </br> Indication failed Level 1 assessment. </br> Consider rerating, performing a Level 2, or Level 3 Assessment.</font></br>`;
      } else {
        if (
          key1 === 0 &&
          key2 === 0 &&
          key3 === 0 &&
          key4 === 0 &&
          key5 === 0 &&
          key6 === 0 &&
          key7 === 0 &&
          key8 === 0
        ) {
          Component_MAWP.strD_result = "Pass";
          this.crackCalcResult += ` </br>`;
          this.crackCalcResult += `<font color="blue">Indication passed Level 1 assessment. </font></br>`;
        } else {
          Component_MAWP.strD_result = "Fail";
          this.crackCalcResult += ` </br>`;
          this.crackCalcResult += `<font color="red">Indication failed Level 1 assessment. </br> Consider performing a Level 2 or Level 3 assessment. </font></br>`;
        }
      }
    }

    return this.crackCalcResult;
  }

  public static curve_select(): void {
    // select crack curve
    // set up the crack assessment curve
    let a_limit: number = this.calculateALimit();

    if (this.intcrack_location === 0) {
      // in weld & HAZ
      this.intcurve_key = this.calculateCurveKeyForWeld(a_limit);
    } else {
      // in base metal
      this.intcurve_key = this.calculateCurveKeyForBaseMetal(a_limit);
    }
  }

  private static calculateALimit(): number {
    let a_limit: number;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      a_limit = Component_MAWP.sngtrd > 1.0 ? 0.25 : 0.25 * Component_MAWP.sngtrd;
    } else {
      // SI
      a_limit = Component_MAWP.sngtrd > 25.4 ? 6.35 : 0.25 * Component_MAWP.sngtrd;
    }
    return a_limit;
  }

  private static calculateCurveKeyForWeld(a_limit: number): number {
    let curve_key: number;
    if (this.intPWHT === 0) {
      // no PWHT
      curve_key = this.sngA_crack <= a_limit && this.intsize_accuracy === 1 ? this.intcurve_key + 1 : this.intcurve_key + 2;
    } else {
      // PWHT
      curve_key = this.sngA_crack <= a_limit && this.intsize_accuracy === 1 ? this.intcurve_key + 3 : this.intcurve_key + 4;
    }
    return curve_key;
  }

  private static calculateCurveKeyForBaseMetal(a_limit: number): number {
    return this.sngA_crack <= a_limit && this.intsize_accuracy === 1 ? this.intcurve_key + 5 : this.intcurve_key + 6;
  }

  public static C_curve_pt(): void {
    // calculate C curve point
    // calculate the maximum Allowable crack length based on crack depth and other information from the assessment plots
    let smy: number;
    smy = GougeModule.sngSMYS; //  new requirement

    switch (GougeModule.intASME_curve) {
      case 0:
        if (Component_MAWP.intUnit_select == 0) {
          //us customary
          if (Crack_Module.intcarbsteelsulf_cont == 0) {
            this.sngTref =
              114 +
              ((61 + (smy + 10) / 2.83 - 147.6 / 5.86) / 4) *
              Math.log(
                (15 - (1.84 + 0.738 * Math.pow((smy + 10) / 66.7, 2))) /
                (147.6 - 15)
              ); // for lower
          }
          if (Crack_Module.intcarbsteelsulf_cont == 1) {
            this.sngTref =
              114 +
              ((61 + (smy + 10) / 2.83 - 73.8 / 5.86) / 4) *
              Math.log(
                (15 - (1.84 + 0.738 * Math.pow((smy + 10) / 66.7, 2))) /
                (73.8 - 15)
              ); // for higher
          }
        } else {
          // SI
          if (Crack_Module.intcarbsteelsulf_cont == 0) {
            this.sngTref =
              45.6 +
              ((34 + (smy + 69) / 35.1 - 200 / 14.3) / 4) *
              Math.log(
                (20 - (2.5 + Math.pow((smy + 69) / 460, 2))) / (200 - 20)
              ); // for lower
          }
          if (Crack_Module.intcarbsteelsulf_cont == 1) {
            this.sngTref =
              45.6 +
              ((34 + (smy + 69) / 35.1 - 100 / 14.3) / 4) *
              Math.log(
                (20 - (2.5 + Math.pow((smy + 69) / 460, 2))) / (100 - 20)
              ); // for higher
          }
        }
        break;
      case 1:
        if (Component_MAWP.intUnit_select == 0) {
          //us customary
          if (Crack_Module.intcarbsteelsulf_cont == 0) {
            this.sngTref =
              76 +
              ((61 + (smy + 10) / 2.83 - 147.6 / 5.86) / 4) *
              Math.log(
                (15 - (1.84 + 0.738 * Math.pow((smy + 10) / 66.7, 2))) /
                (147.6 - 15)
              ); // for lower
          }
          if (Crack_Module.intcarbsteelsulf_cont == 1) {
            this.sngTref =
              76 +
              ((61 + (smy + 10) / 2.83 - 73.8 / 5.86) / 4) *
              Math.log(
                (15 - (1.84 + 0.738 * Math.pow((smy + 10) / 66.7, 2))) /
                (73.8 - 15)
              ); // for higher
          }
        } else {
          //SI
          if (Crack_Module.intcarbsteelsulf_cont == 0) {
            this.sngTref =
              24.4 +
              ((34 + (smy + 69) / 35.1 - 200 / 14.3) / 4) *
              Math.log(
                (20 - (2.5 + Math.pow((smy + 69) / 460, 2))) / (200 - 20)
              ); // for lower
          }
          if (Crack_Module.intcarbsteelsulf_cont == 1) {
            this.sngTref =
              24.4 +
              ((34 + (smy + 69) / 35.1 - 100 / 14.3) / 4) *
              Math.log(
                (20 - (2.5 + Math.pow((smy + 69) / 460, 2))) / (100 - 20)
              ); // for higher
          }
        }
        break;
      case 2:
        if (Component_MAWP.intUnit_select == 0) {
          //us customary
          if (Crack_Module.intcarbsteelsulf_cont == 0) {
            this.sngTref =
              38 +
              ((61 + (smy + 10) / 2.83 - 147.6 / 5.86) / 4) *
              Math.log(
                (15 - (1.84 + 0.738 * Math.pow((smy + 10) / 66.7, 2))) /
                (147.6 - 15)
              ); // for lower
          }
          if (Crack_Module.intcarbsteelsulf_cont == 1) {
            this.sngTref =
              38 +
              ((61 + (smy + 10) / 2.83 - 73.8 / 5.86) / 4) *
              Math.log(
                (15 - (1.84 + 0.738 * Math.pow((smy + 10) / 66.7, 2))) /
                (73.8 - 15)
              ); // for heigher
          }
        } else {
          //SI
          if (Crack_Module.intcarbsteelsulf_cont == 0) {
            this.sngTref =
              3.3 +
              ((34 + (smy + 69) / 35.1 - 200 / 14.3) / 4) *
              Math.log(
                (20 - (2.5 + Math.pow((smy + 69) / 460, 2))) / (200 - 20)
              ); // for lower
          }
          if (Crack_Module.intcarbsteelsulf_cont == 1) {
            this.sngTref =
              3.3 +
              ((34 + (smy + 69) / 35.1 - 100 / 14.3) / 4) *
              Math.log(
                (20 - (2.5 + Math.pow((smy + 69) / 460, 2))) / (100 - 20)
              ); // for higher
          }
        }
        break;
      case 3:
        if (Component_MAWP.intUnit_select == 0) {
          //us customary
          if (Crack_Module.intcarbsteelsulf_cont == 0) {
            this.sngTref =
              12 +
              ((61 + (smy + 10) / 2.83 - 147.6 / 5.86) / 4) *
              Math.log(
                (15 - (1.84 + 0.738 * Math.pow((smy + 10) / 66.7, 2))) /
                (147.6 - 15)
              ); // for lower
          }
          if (Crack_Module.intcarbsteelsulf_cont == 1) {
            this.sngTref =
              12 +
              ((61 + (smy + 10) / 2.83 - 73.8 / 5.86) / 4) *
              Math.log(
                (15 - (1.84 + 0.738 * Math.pow((smy + 10) / 66.7, 2))) /
                (73.8 - 15)
              ); // for heigher
          }
        } else {
          //SI
          if (Crack_Module.intcarbsteelsulf_cont == 0) {
            this.sngTref =
              -11.1 +
              ((34 + (smy + 69) / 35.1 - 200 / 14.3) / 4) *
              Math.log(
                (20 - (2.5 + Math.pow((smy + 69) / 460, 2))) / (200 - 20)
              ); // for lower
          }
          if (Crack_Module.intcarbsteelsulf_cont == 1) {
            this.sngTref =
              -11.1 +
              ((34 + (smy + 69) / 35.1 - 100 / 14.3) / 4) *
              Math.log(
                (20 - (2.5 + Math.pow((smy + 69) / 460, 2))) / (100 - 20)
              ); // for higher
          }
        }
        break;
    }

    if (Component_MAWP.intUnit_select == 0) {
      this.TptActual = this.sngCET - this.sngTref + 100.0;
      if (this.sngCET - this.sngTref + 100.0 > 200) {
        this.Tpt = 200;
      } else if (this.sngCET - this.sngTref + 100.0 < 0) {
        this.Tpt = 0;
      } else {
        this.Tpt = this.sngCET - this.sngTref + 100.0;
      }
    } else {
      this.TptActual = this.sngCET - this.sngTref + 56.0;
      if (this.sngCET - this.sngTref + 56 > 110) {
        this.Tpt = 110;
      } else if (this.sngCET - this.sngTref + 56 < 0) {
        this.Tpt = 0;
      } else {
        this.Tpt = this.sngCET - this.sngTref + 56.0;
      }
    }

    switch (this.intcurve_key) {
      case 11:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            0.103 +
            0.000258333333 * this.Tpt +
            0.00000833333333 * Math.pow(this.Tpt, 2) -
            8.33333333e-8 * Math.pow(this.Tpt, 3) +
            1.04166667e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt <= 100.0) {
          this.sngC_allow =
            0.963 -
            0.056525 * this.Tpt +
            0.00136708333 * Math.pow(this.Tpt, 2) -
            0.000014125 * Math.pow(this.Tpt, 3) +
            5.41666667e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 100.0 && this.Tpt <= 140.0) {
          this.sngC_allow =
            -9.717 +
            0.450775 * this.Tpt -
            0.00745875 * Math.pow(this.Tpt, 2) +
            0.000052625 * Math.pow(this.Tpt, 3) -
            0.00000013125 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 140.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            106.937432 -
            3.97931594 * this.Tpt +
            0.05468751 * Math.pow(this.Tpt, 2) -
            0.000329279391 * Math.pow(this.Tpt, 3) +
            0.000000736714525 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 3.9;
        }
        break;
      case 12:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            0.103 +
            0.000258333333 * this.Tpt +
            0.00000833333333 * Math.pow(this.Tpt, 2) -
            8.33333333e-8 * Math.pow(this.Tpt, 3) +
            1.04166667e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            1.09219223 -
            0.0391578955 * this.Tpt +
            0.000596158957 * Math.pow(this.Tpt, 2) -
            0.00000398128409 * Math.pow(this.Tpt, 3) +
            1.07529184e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 0.796;
        }
        break;
      case 13:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            0.772 +
            0.00136666667 * this.Tpt +
            0.000121041667 * Math.pow(this.Tpt, 2) -
            0.00000210416667 * Math.pow(this.Tpt, 3) +
            2.55208333e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt <= 116.405) {
          this.sngC_allow =
            13.274 -
            0.825925 * this.Tpt +
            0.0199720833 * Math.pow(this.Tpt, 2) -
            0.0002079375 * Math.pow(this.Tpt, 3) +
            0.000000807291667 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 116.405) {
          this.sngC_allow = 8.0;
        }
        break;
      case 14:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            0.379 +
            0.00117083333 * this.Tpt +
            0.0000165625 * Math.pow(this.Tpt, 2) -
            5.20833333e-8 * Math.pow(this.Tpt, 3) +
            0.00000000234375 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            3.12029685 -
            0.110609368 * this.Tpt +
            0.00173219386 * Math.pow(this.Tpt, 2) -
            0.0000118628766 * Math.pow(this.Tpt, 3) +
            3.33057801e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 2.889;
        }
        break;
      case 15:
        if (this.Tpt <= 60.0) {
          this.sngC_allow =
            2.973 +
            0.0267416667 * this.Tpt +
            0.00037875 * Math.pow(this.Tpt, 2) -
            4.16666658e-8 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 60.0 && this.Tpt <= 73.635) {
          this.sngC_allow =
            0.98 +
            0.209433333 * this.Tpt -
            0.00460375 * Math.pow(this.Tpt, 2) +
            0.0000414791667 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 73.635) {
          this.sngC_allow = 8.0;
        }
        break;
      case 16:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            1.079 +
            0.00347916667 * this.Tpt +
            0.0000265625 * Math.pow(this.Tpt, 2) +
            0.000000427083333 * Math.pow(this.Tpt, 3) +
            0.00000000234375 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt <= 158.09) {
          this.sngC_allow =
            9.15084273 -
            0.321170099 * this.Tpt +
            0.004954943 * Math.pow(this.Tpt, 2) -
            0.0000334521637 * Math.pow(this.Tpt, 3) +
            9.27890084e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.09) {
          this.sngC_allow = 8.0;
        }
        break;
      case 21:
        if (this.Tpt < 100.0) {
          this.sngC_allow =
            0.100985714 +
            0.000367261905 * this.Tpt +
            0.000000357142859 * Math.pow(this.Tpt, 2) +
            7.29166667e-8 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt >= 100.0 && this.Tpt < 140.0) {
          this.sngC_allow =
            5.17 -
            0.1325 * this.Tpt +
            0.001055 * Math.pow(this.Tpt, 2) -
            0.00000225 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt >= 140.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            -29.4850205 +
            0.750377903 * this.Tpt -
            0.00637107582 * Math.pow(this.Tpt, 2) +
            0.0000183779884 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 2.573;
        }
        break;
      case 22:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.101 +
            0.0003375 * this.Tpt +
            0.00000239583333 * Math.pow(this.Tpt, 2) +
            0.00000003125 * Math.pow(this.Tpt, 3) +
            2.60416667e-10 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            0.261672487 -
            0.00704089564 * this.Tpt +
            0.000130950521 * Math.pow(this.Tpt, 2) -
            0.000000990594452 * Math.pow(this.Tpt, 3) +
            3.43506315e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 0.659;
        break;
      case 23:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            1.091 +
            0.00135833333 * this.Tpt +
            0.000257708333 * Math.pow(this.Tpt, 2) -
            0.00000470833333 * Math.pow(this.Tpt, 3) +
            5.26041667e-8 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 100.0)
          this.sngC_allow =
            -4.019 +
            0.28825 * this.Tpt -
            0.00508 * Math.pow(this.Tpt, 2) +
            0.000031375 * Math.pow(this.Tpt, 3);
        if (this.Tpt > 100.0 && this.Tpt <= 103.744)
          this.sngC_allow =
            132.091 -
            8.445475 * this.Tpt +
            0.196249375 * Math.pow(this.Tpt, 2) -
            0.0019535625 * Math.pow(this.Tpt, 3) +
            0.0000070890625 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 103.744) this.sngC_allow = 8.0;
        break;
      case 24:
        if (this.Tpt <= 140.0)
          this.sngC_allow =
            0.438814394 +
            0.00122106332 * this.Tpt +
            0.00000820785959 * Math.pow(this.Tpt, 2) +
            0.000000129024624 * Math.pow(this.Tpt, 3) +
            5.50426127e-10 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 140.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            -0.187880876 +
            0.0250353571 * this.Tpt -
            0.000327183562 * Math.pow(this.Tpt, 2) +
            0.00000220347906 * Math.pow(this.Tpt, 3) -
            4.20298271e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 1.682;
        break;
      case 25:
        if (this.Tpt <= 60.0) {
          this.sngC_allow =
            2.032 +
            0.00626666666 * this.Tpt +
            0.0001025 * Math.pow(this.Tpt, 2) +
            0.00000158333333 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 60.0 && this.Tpt <= 85.37) {
          this.sngC_allow =
            -3.036 +
            0.502545833 * this.Tpt -
            0.0154744792 * Math.pow(this.Tpt, 2) +
            0.000186447917 * Math.pow(this.Tpt, 3) -
            0.000000660677083 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 85.37) {
          this.sngC_allow = 8.0;
        }
        break;
      case 26:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            0.804825666 +
            0.00196328641 * this.Tpt +
            0.0000070806533 * Math.pow(this.Tpt, 2) +
            0.000000310630818 * Math.pow(this.Tpt, 3) -
            3.8581251e-10 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            0.737905902 +
            0.00702154968 * this.Tpt -
            0.0000994578677 * Math.pow(this.Tpt, 2) +
            0.00000117858187 * Math.pow(this.Tpt, 3) -
            2.82064061e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 2.265;
        }
        break;
      case 31:
        if (this.Tpt < 100.0) {
          this.sngC_allow =
            0.086015873 +
            0.000326256614 * this.Tpt +
            0.000000173611077 * Math.pow(this.Tpt, 2) +
            3.35648153e-8 * Math.pow(this.Tpt, 3) +
            2.60416664e-10 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt >= 100.0 && this.Tpt < 140.0) {
          this.sngC_allow =
            -3.96 +
            0.1720875 * this.Tpt -
            0.0025428125 * Math.pow(this.Tpt, 2) +
            0.00001509375 * Math.pow(this.Tpt, 3) -
            0.00000002734375 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt >= 140.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            59.6169825 -
            2.2423241 * this.Tpt +
            0.0313270681 * Math.pow(this.Tpt, 2) -
            0.000193045181 * Math.pow(this.Tpt, 3) +
            0.000000445699275 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 3.826;
        }
        break;
      case 32:
        if (this.Tpt <= 120.0) {
          this.sngC_allow =
            0.0860411255 +
            0.000294859305 * this.Tpt +
            0.00000200757584 * Math.pow(this.Tpt, 2) +
            1.89393833e-9 * Math.pow(this.Tpt, 3) +
            4.26136368e-10 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 120.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            0.167172774 -
            0.00398013274 * this.Tpt +
            0.0000825563293 * Math.pow(this.Tpt, 2) -
            0.000000646994203 * Math.pow(this.Tpt, 3) +
            2.3227141e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 0.5;
        }
        break;
      case 33:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            0.829 +
            0.000954166667 * this.Tpt +
            0.0003071875 * Math.pow(this.Tpt, 2) -
            0.00000732291667 * Math.pow(this.Tpt, 3) +
            0.00000007265625 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt <= 100.0) {
          this.sngC_allow =
            1.663 -
            0.0859208333 * this.Tpt +
            0.0033478125 * Math.pow(this.Tpt, 2) -
            0.0000507604167 * Math.pow(this.Tpt, 3) +
            0.00000028984375 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 100.0 && this.Tpt <= 104.833) {
          this.sngC_allow =
            113.188 -
            7.24210833 * this.Tpt +
            0.168311875 * Math.pow(this.Tpt, 2) -
            0.00167716667 * Math.pow(this.Tpt, 3) +
            0.0000060984375 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 104.833) {
          this.sngC_allow = 8.0;
        }
        break;
      case 34:
        if (this.Tpt <= 120.0) {
          this.sngC_allow =
            0.272103896 +
            0.000650108216 * this.Tpt +
            0.0000119412882 * Math.pow(this.Tpt, 2) -
            6.15530349e-8 * Math.pow(this.Tpt, 3) +
            1.25473487e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 120.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            0.640350124 -
            0.0147400821 * this.Tpt +
            0.000253981167 * Math.pow(this.Tpt, 2) -
            0.00000175561052 * Math.pow(this.Tpt, 3) +
            5.69456937e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 1.288;
        }
        break;
      case 35:
        if (this.Tpt <= 60.0) {
          this.sngC_allow =
            2.98 -
            0.000175000001 * this.Tpt +
            0.00103625 * Math.pow(this.Tpt, 2) -
            0.000001625 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 60.0 && this.Tpt <= 67.74) {
          this.sngC_allow =
            -0.682 +
            0.335508333 * this.Tpt -
            0.00811875 * Math.pow(this.Tpt, 2) +
            0.0000746666667 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 67.74) {
          this.sngC_allow = 8.0;
        }
        break;
      case 36:
        if (this.Tpt <= 100.0) {
          this.sngC_allow =
            0.627027778 +
            0.00137407406 * this.Tpt +
            0.0000248090286 * Math.pow(this.Tpt, 2) -
            0.000000120949087 * Math.pow(this.Tpt, 3) +
            2.4739584e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 100.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            1.88863939 -
            0.04962321 * this.Tpt +
            0.000795730506 * Math.pow(this.Tpt, 2) -
            0.00000529941466 * Math.pow(this.Tpt, 3) +
            1.55479121e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 2.725;
        }
        break;
      case 41:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            0.086 +
            0.000379166667 * this.Tpt -
            0.00000364583333 * Math.pow(this.Tpt, 2) +
            0.000000114583333 * Math.pow(this.Tpt, 3) -
            2.60416667e-10 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt <= 140.0) {
          this.sngC_allow =
            -2.015 +
            0.103983333 * this.Tpt -
            0.00181458333 * Math.pow(this.Tpt, 2) +
            0.0000129791666 * Math.pow(this.Tpt, 3) -
            2.91666666e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 140.0 && this.Tpt <= 157.62) {
          this.sngC_allow =
            264.723948 -
            10.0257458 * this.Tpt +
            0.140287416 * Math.pow(this.Tpt, 2) -
            0.000860273342 * Math.pow(this.Tpt, 3) +
            0.00000195549813 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 157.62) {
          this.sngC_allow = 8.0;
        }
        break;
      case 42:
        if (this.Tpt <= 120.0) {
          this.sngC_allow =
            0.0860411255 +
            0.000294859305 * this.Tpt +
            0.00000200757584 * Math.pow(this.Tpt, 2) +
            1.89393833e-9 * Math.pow(this.Tpt, 3) +
            4.26136368e-10 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 120.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            0.167172774 -
            0.00398013274 * this.Tpt +
            0.0000825563293 * Math.pow(this.Tpt, 2) -
            0.000000646994203 * Math.pow(this.Tpt, 3) +
            2.3227141e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 0.5;
        }
        break;
      case 43:
        if (this.Tpt <= 77.002) {
          this.sngC_allow =
            1.422 -
            0.00299166667 * this.Tpt +
            0.0011775 * Math.pow(this.Tpt, 2) -
            0.0000304583333 * Math.pow(this.Tpt, 3) +
            0.000000390625 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 77.002) {
          this.sngC_allow = 8.0;
        }
        break;
      case 44:
        if (this.Tpt <= 120.0) {
          this.sngC_allow =
            0.349991342 +
            0.000473502901 * this.Tpt +
            0.0000241003782 * Math.pow(this.Tpt, 2) -
            0.000000174558072 * Math.pow(this.Tpt, 3) +
            1.87026512e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 120.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            0.480250849 -
            0.00706916915 * this.Tpt +
            0.000172249708 * Math.pow(this.Tpt, 2) -
            0.00000138742837 * Math.pow(this.Tpt, 3) +
            5.42597358e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 1.587;
        }
        break;
      case 45:
        if (this.Tpt <= 60.0) {
          this.sngC_allow =
            2.98 -
            0.000175000001 * this.Tpt +
            0.00103625 * Math.pow(this.Tpt, 2) -
            0.000001625 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 60.0 && this.Tpt <= 67.74) {
          this.sngC_allow =
            -0.682 +
            0.335508333 * this.Tpt -
            0.00811875 * Math.pow(this.Tpt, 2) +
            0.0000746666667 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 67.74) {
          this.sngC_allow = 8.0;
        }
        break;

      case 46:
        if (this.Tpt <= 120.0) {
          this.sngC_allow =
            0.627090909 +
            0.00129558082 * this.Tpt +
            0.0000293939389 * Math.pow(this.Tpt, 2) -
            0.000000200126256 * Math.pow(this.Tpt, 3) +
            2.88825755e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 120.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            1.88863939 -
            0.04962321 * this.Tpt +
            0.000795730506 * Math.pow(this.Tpt, 2) -
            0.00000529941466 * Math.pow(this.Tpt, 3) +
            1.55479121e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 2.725;
        }
        break;
      case 51:
        if (this.Tpt <= 60.0) {
          this.sngC_allow =
            0.101 +
            0.000316666667 * this.Tpt -
            0.00000125 * Math.pow(this.Tpt, 2) +
            0.000000145833333 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 60.0 && this.Tpt <= 100.0) {
          this.sngC_allow =
            -0.064 +
            0.00839166667 * this.Tpt -
            0.00012 * Math.pow(this.Tpt, 2) +
            0.000000645833333 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 100.0 && this.Tpt <= 140.0) {
          this.sngC_allow =
            5.946 -
            0.155666667 * this.Tpt +
            0.00128 * Math.pow(this.Tpt, 2) -
            0.00000295833333 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 140.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            -31.4066876 +
            0.795937517 * this.Tpt -
            0.00672414734 * Math.pow(this.Tpt, 2) +
            0.0000192754093 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 2.576;
        }
        break;
      case 52:
        if (this.Tpt <= 60.0) {
          this.sngC_allow =
            0.101 +
            0.000316666667 * this.Tpt -
            0.00000125 * Math.pow(this.Tpt, 2) +
            0.000000145833333 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 60.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            0.408118483 -
            0.0122320935 * this.Tpt +
            0.000198148291 * Math.pow(this.Tpt, 2) -
            0.0000013655372 * Math.pow(this.Tpt, 3) +
            4.19442972e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 0.657;
        }
        break;
      case 53:
        if (this.Tpt <= 80.0) {
          this.sngC_allow =
            0.765 +
            0.0006875 * this.Tpt +
            0.000170520833 * Math.pow(this.Tpt, 2) -
            0.00000290625 * Math.pow(this.Tpt, 3) +
            2.68229167e-8 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 80.0 && this.Tpt < 120.0) {
          this.sngC_allow =
            11.729 -
            0.707595833 * this.Tpt +
            0.0166459375 * Math.pow(this.Tpt, 2) -
            0.000167260417 * Math.pow(this.Tpt, 3) +
            0.00000062265625 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt >= 120.0 && this.Tpt <= 121.444) {
          this.sngC_allow =
            627.659 -
            29.9642708 * this.Tpt +
            0.525643646 * Math.pow(this.Tpt, 2) -
            0.00401682292 * Math.pow(this.Tpt, 3) +
            0.0000113158854 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 121.444) {
          this.sngC_allow = 8.0;
        }
        break;
      case 54:
        if (this.Tpt <= 100.0) {
          this.sngC_allow =
            0.340035714 +
            0.000864285724 * this.Tpt +
            0.0000135937495 * Math.pow(this.Tpt, 2) -
            1.56249923e-8 * Math.pow(this.Tpt, 3) +
            1.17187496e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 100.0 && this.Tpt <= 158.5) {
          this.sngC_allow =
            -0.000626967178 +
            0.014656667 * this.Tpt -
            0.000195113771 * Math.pow(this.Tpt, 2) +
            0.00000138419543 * Math.pow(this.Tpt, 3) -
            2.34097446e-9 * Math.pow(this.Tpt, 4);
        }
        if (this.Tpt > 158.5) {
          this.sngC_allow = 1.455;
        }
        break;
      case 55:
        if (this.Tpt <= 60.0) {
          this.sngC_allow =
            2.032 +
            0.00626666666 * this.Tpt +
            0.0001025 * Math.pow(this.Tpt, 2) +
            0.00000158333333 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 60.0 && this.Tpt <= 85.583) {
          this.sngC_allow =
            9.649 -
            0.311408333 * this.Tpt +
            0.00328875 * Math.pow(this.Tpt, 2) +
            0.00000145833333 * Math.pow(this.Tpt, 3);
        }
        if (this.Tpt > 85.583) {
          this.sngC_allow = 8.0;
        }
        break;
      case 56:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.805 +
            0.00179166667 * this.Tpt +
            0.0000183333333 * Math.pow(this.Tpt, 2) +
            8.33333333e-8 * Math.pow(this.Tpt, 3) +
            1.04166667e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            0.737905902 +
            0.00702154968 * this.Tpt -
            0.0000994578677 * Math.pow(this.Tpt, 2) +
            0.00000117858187 * Math.pow(this.Tpt, 3) -
            2.82064061e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 2.265;
        break;
      case 61:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.103 +
            0.000633333333 * this.Tpt -
            0.0000233333333 * Math.pow(this.Tpt, 2) +
            0.000000666666667 * Math.pow(this.Tpt, 3) -
            4.16666667e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 120.0)
          this.sngC_allow =
            0.689 -
            0.0388083333 * this.Tpt +
            0.000943125 * Math.pow(this.Tpt, 2) -
            0.00000960416667 * Math.pow(this.Tpt, 3) +
            0.0000000359375 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 120.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            20.4305306 -
            0.763087889 * this.Tpt +
            0.0106761428 * Math.pow(this.Tpt, 2) -
            0.0000662877491 * Math.pow(this.Tpt, 3) +
            0.000000156335793 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 2.408;
        break;
      case 62:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.103 +
            0.000633333333 * this.Tpt -
            0.0000233333333 * Math.pow(this.Tpt, 2) +
            0.000000666666667 * Math.pow(this.Tpt, 3) -
            4.16666667e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            0.461369381 -
            0.0149137896 * this.Tpt +
            0.000249565831 * Math.pow(this.Tpt, 2) -
            0.00000179644738 * Math.pow(this.Tpt, 3) +
            5.59798647e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 0.747;
        break;
      case 63:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            1.005 +
            0.004325 * this.Tpt +
            0.0000883333333 * Math.pow(this.Tpt, 2) -
            0.000002 * Math.pow(this.Tpt, 3) +
            3.85416667e-8 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 100.0)
          this.sngC_allow =
            2.509 -
            0.152341667 * this.Tpt +
            0.00557166667 * Math.pow(this.Tpt, 2) -
            0.0000803333333 * Math.pow(this.Tpt, 3) +
            0.000000430208333 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 100.0 && this.Tpt <= 102.718)
          this.sngC_allow =
            179.339 -
            11.4989333 * this.Tpt +
            0.267132708 * Math.pow(this.Tpt, 2) -
            0.00265910417 * Math.pow(this.Tpt, 3) +
            0.00000964010417 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 102.718) this.sngC_allow = 8.0;
        break;
      case 64:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.485 +
            0.0014625 * this.Tpt +
            0.0000109375 * Math.pow(this.Tpt, 2) +
            0.00000015625 * Math.pow(this.Tpt, 3) +
            0.00000000078125 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            -0.287403723 +
            0.0335797366 * this.Tpt -
            0.000480783532 * Math.pow(this.Tpt, 2) +
            0.00000342066696 * Math.pow(this.Tpt, 3) -
            7.06401581e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 2.119;
        break;
      case 65:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            1.961 +
            0.00404166667 * this.Tpt +
            0.000344166667 * Math.pow(this.Tpt, 2) -
            0.00000635416667 * Math.pow(this.Tpt, 3) +
            7.70833333e-8 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 100.0)
          this.sngC_allow =
            1.567 +
            0.0450833333 * this.Tpt -
            0.00109229167 * Math.pow(this.Tpt, 2) +
            0.0000141666667 * Math.pow(this.Tpt, 3) -
            2.55208333e-8 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 100.0 && this.Tpt <= 103.35)
          this.sngC_allow =
            65.367 -
            4.04875 * this.Tpt +
            0.0932785417 * Math.pow(this.Tpt, 2) -
            0.00091625 * Math.pow(this.Tpt, 3) +
            0.00000329739583 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 103.35) this.sngC_allow = 8.0;
        break;
      case 66:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.948 +
            0.00234166667 * this.Tpt +
            0.0000245833333 * Math.pow(this.Tpt, 2) +
            0.000000145833333 * Math.pow(this.Tpt, 3) +
            1.04166667e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            -0.869788541 +
            0.0683624458 * this.Tpt -
            0.000876649253 * Math.pow(this.Tpt, 2) +
            0.00000573383153 * Math.pow(this.Tpt, 3) -
            1.25579505e-8 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 2.848;
        break;
      case 71:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.103 +
            0.000633333333 * this.Tpt -
            0.0000233333333 * Math.pow(this.Tpt, 2) +
            0.000000666666667 * Math.pow(this.Tpt, 3) -
            4.16666667e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 120.0)
          this.sngC_allow =
            0.689 -
            0.0388083333 * this.Tpt +
            0.000943125 * Math.pow(this.Tpt, 2) -
            0.00000960416667 * Math.pow(this.Tpt, 3) +
            0.0000000359375 * Math.pow(this.Tpt, 3);
        if (this.Tpt > 120.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            20.4305306 -
            0.763087889 * this.Tpt +
            0.0106761428 * Math.pow(this.Tpt, 2) -
            0.0000662877491 * Math.pow(this.Tpt, 3) +
            0.000000156335793 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 2.408;
        break;
      case 72:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.103 +
            0.000633333333 * this.Tpt -
            0.0000233333333 * Math.pow(this.Tpt, 2) +
            0.000000666666667 * Math.pow(this.Tpt, 3) -
            4.16666667e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            0.461369381 -
            0.0149137896 * this.Tpt +
            0.000249565831 * Math.pow(this.Tpt, 2) -
            0.00000179644738 * Math.pow(this.Tpt, 3) +
            5.59798647e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 0.747;
        break;
      case 73:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.697 +
            0.00229166667 * this.Tpt +
            0.0000410416667 * Math.pow(this.Tpt, 2) -
            0.000000229166667 * Math.pow(this.Tpt, 3) +
            9.89583333e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 121.162)
          this.sngC_allow =
            15.42 -
            0.953154167 * this.Tpt +
            0.0223994792 * Math.pow(this.Tpt, 2) -
            0.000224989583 * Math.pow(this.Tpt, 3) +
            0.000000832552083 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 121.162) this.sngC_allow = 8.0;
        break;
      case 74:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.37 +
            0.000929166667 * this.Tpt +
            0.0000126041667 * Math.pow(this.Tpt, 2) +
            0.000000177083333 * Math.pow(this.Tpt, 3) -
            2.60416667e-10 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            -0.611985867 +
            0.0391722013 * this.Tpt -
            0.000534519256 * Math.pow(this.Tpt, 2) +
            0.00000346096563 * Math.pow(this.Tpt, 3) -
            6.5400734e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 1.822;
        break;
      case 75:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            1.961 +
            0.00404166667 * this.Tpt +
            0.000344166667 * Math.pow(this.Tpt, 2) -
            0.00000635416667 * Math.pow(this.Tpt, 3) +
            7.70833333e-8 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 100.0)
          this.sngC_allow =
            1.567 +
            0.0450833333 * this.Tpt -
            0.00109229167 * Math.pow(this.Tpt, 2) +
            0.0000141666667 * Math.pow(this.Tpt, 3) -
            2.55208333e-8 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 100.0 && this.Tpt <= 103.35)
          this.sngC_allow =
            65.367 -
            4.04875 * this.Tpt +
            0.0932785417 * Math.pow(this.Tpt, 2) -
            0.00091625 * Math.pow(this.Tpt, 3) +
            0.00000329739583 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 103.35) this.sngC_allow = 8.0;
        break;
      case 76:
        if (this.Tpt <= 80.0)
          this.sngC_allow =
            0.948 +
            0.00234166667 * this.Tpt +
            0.0000245833333 * Math.pow(this.Tpt, 2) +
            0.000000145833333 * Math.pow(this.Tpt, 3) +
            1.04166667e-9 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 80.0 && this.Tpt <= 158.5)
          this.sngC_allow =
            -0.869788541 +
            0.0683624458 * this.Tpt -
            0.000876649253 * Math.pow(this.Tpt, 2) +
            0.00000573383153 * Math.pow(this.Tpt, 3) -
            1.25579505e-8 * Math.pow(this.Tpt, 4);
        if (this.Tpt > 158.5) this.sngC_allow = 2.848;
        break;
    }

    if (Component_MAWP.intUnit_select == 1)
      this.sngC_allow = this.sngC_allow * 25.4;
  }

  public static Crack_Chart_Series0() {
    let result = [];

    if (
      (Component_MAWP.intUnit_select === 0 && this.sngC_crack <= 8) ||
      (Component_MAWP.intUnit_select === 1 && this.sngC_crack <= 203)
    ) {
      if (this.sngC_crack <= this.sngC_allow) {
        this.chart2LabelResultColorRed = false;
        this.chart2LabelResult = `Measured crack length <= Allowable crack length`;
      } else {
        this.chart2LabelResultColorRed = true;
        this.chart2LabelResult = `Measured crack length > Allowable crack length`;
      }
    } else {
      if (this.sngC_crack <= this.sngC_allow) {
        this.chart2LabelResultColorRed = false;
        this.chart2LabelResult = `Assessment point outside the plot. Measured crack length <= Allowable crack length`;
      } else {
        this.chart2LabelResultColorRed = true;
        this.chart2LabelResult = `Assessment point outside the plot. Measured crack length > Allowable crack length`;
      }
    }

    switch (this.intcurve_key) {
      case 11:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.103 +
              0.000258333333 * i +
              0.00000833333333 * Math.pow(i, 2) -
              8.33333333e-8 * Math.pow(i, 3) +
              1.04166667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 100.0)
            yy =
              0.963 -
              0.056525 * i +
              0.00136708333 * Math.pow(i, 2) -
              0.000014125 * Math.pow(i, 3) +
              5.41666667e-8 * Math.pow(i, 4);
          if (i > 100.0 && i <= 140.0)
            yy =
              -9.717 +
              0.450775 * i -
              0.00745875 * Math.pow(i, 2) +
              0.000052625 * Math.pow(i, 3) -
              0.00000013125 * Math.pow(i, 4);
          if (i > 140.0 && i <= 158.5)
            yy =
              106.937432 -
              3.97931594 * i +
              0.05468751 * Math.pow(i, 2) -
              0.000329279391 * Math.pow(i, 3) +
              0.000000736714525 * Math.pow(i, 4);
          if (i > 158.5) yy = 3.9;

          result.push({ x: i, y: yy });
        }
        break;
      case 12:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.103 +
              0.000258333333 * i +
              0.00000833333333 * Math.pow(i, 2) -
              8.33333333e-8 * Math.pow(i, 3) +
              1.04166667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              1.09219223 -
              0.0391578955 * i +
              0.000596158957 * Math.pow(i, 2) -
              0.00000398128409 * Math.pow(i, 3) +
              1.07529184e-8 * Math.pow(i, 4);
          if (i > 158.5) yy = 0.796;
          result.push({ x: i, y: yy });
        }
        break;
      case 13:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.772 +
              0.00136666667 * i +
              0.000121041667 * Math.pow(i, 2) -
              0.00000210416667 * Math.pow(i, 3) +
              2.55208333e-8 * Math.pow(i, 4);
          if (i > 80.0 && i <= 116.405)
            yy =
              13.274 -
              0.825925 * i +
              0.0199720833 * Math.pow(i, 2) -
              0.0002079375 * Math.pow(i, 3) +
              0.000000807291667 * Math.pow(i, 4);
          if (i > 116.405) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 14:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.379 +
              0.00117083333 * i +
              0.0000165625 * Math.pow(i, 2) -
              5.20833333e-8 * Math.pow(i, 3) +
              0.00000000234375 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              3.12029685 -
              0.110609368 * i +
              0.00173219386 * Math.pow(i, 2) -
              0.0000118628766 * Math.pow(i, 3) +
              3.33057801e-8 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.889;
          result.push({ x: i, y: yy });
        }
        break;
      case 15:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 60.0)
            yy =
              2.973 +
              0.0267416667 * i +
              0.00037875 * Math.pow(i, 2) -
              4.16666658e-8 * Math.pow(i, 3);
          if (i > 60.0 && i <= 73.635)
            yy =
              0.98 +
              0.209433333 * i -
              0.00460375 * Math.pow(i, 2) +
              0.0000414791667 * Math.pow(i, 3);
          if (i > 73.635) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 16:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              1.079 +
              0.00347916667 * i +
              0.0000265625 * Math.pow(i, 2) +
              0.000000427083333 * Math.pow(i, 3) +
              0.00000000234375 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.09)
            yy =
              9.15084273 -
              0.321170099 * i +
              0.004954943 * Math.pow(i, 2) -
              0.0000334521637 * Math.pow(i, 3) +
              9.27890084e-8 * Math.pow(i, 4);
          if (i > 158.09) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 21:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i < 100.0)
            yy =
              0.100985714 +
              0.000367261905 * i +
              0.000000357142859 * Math.pow(i, 2) +
              7.29166667e-8 * Math.pow(i, 3);
          if (i >= 100.0 && i < 140.0)
            yy =
              5.17 -
              0.1325 * i +
              0.001055 * Math.pow(i, 2) -
              0.00000225 * Math.pow(i, 3);
          if (i >= 140.0 && i <= 158.5)
            yy =
              -29.4850205 +
              0.750377903 * i -
              0.00637107582 * Math.pow(i, 2) +
              0.0000183779884 * Math.pow(i, 3);
          if (i > 158.5) yy = 2.573;
          result.push({ x: i, y: yy });
        }
        break;
      case 22:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.101 +
              0.0003375 * i +
              0.00000239583333 * Math.pow(i, 2) +
              0.00000003125 * Math.pow(i, 3) +
              2.60416667e-10 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              0.261672487 -
              0.00704089564 * i +
              0.000130950521 * Math.pow(i, 2) -
              0.000000990594452 * Math.pow(i, 3) +
              3.43506315e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 0.659;
          result.push({ x: i, y: yy });
        }
        break;
      case 23:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              1.091 +
              0.00135833333 * i +
              0.000257708333 * Math.pow(i, 2) -
              0.00000470833333 * Math.pow(i, 3) +
              5.26041667e-8 * Math.pow(i, 4);
          if (i > 80.0 && i <= 100.0)
            yy =
              -4.019 +
              0.28825 * i -
              0.00508 * Math.pow(i, 2) +
              0.000031375 * Math.pow(i, 3);
          if (i > 100.0 && i <= 103.744)
            yy =
              132.091 -
              8.445475 * i +
              0.196249375 * Math.pow(i, 2) -
              0.0019535625 * Math.pow(i, 3) +
              0.0000070890625 * Math.pow(i, 4);
          if (i > 103.744) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 24:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 140.0)
            yy =
              0.438814394 +
              0.00122106332 * i +
              0.00000820785959 * Math.pow(i, 2) +
              0.000000129024624 * Math.pow(i, 3) +
              5.50426127e-10 * Math.pow(i, 4);
          if (i > 140.0 && i <= 158.5)
            yy =
              -0.187880876 +
              0.0250353571 * i -
              0.000327183562 * Math.pow(i, 2) +
              0.00000220347906 * Math.pow(i, 3) -
              4.20298271e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 1.682;
          result.push({ x: i, y: yy });
        }
        break;
      case 25:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 60.0)
            yy =
              2.032 +
              0.00626666666 * i +
              0.0001025 * Math.pow(i, 2) +
              0.00000158333333 * Math.pow(i, 3);
          if (i > 60.0 && i <= 85.37)
            yy =
              -3.036 +
              0.502545833 * i -
              0.0154744792 * Math.pow(i, 2) +
              0.000186447917 * Math.pow(i, 3) -
              0.000000660677083 * Math.pow(i, 4);
          if (i > 85.37) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 26:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 80.0)
            yy =
              0.804825666 +
              0.00196328641 * i +
              0.0000070806533 * Math.pow(i, 2) +
              0.000000310630818 * Math.pow(i, 3) -
              3.8581251e-10 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              0.737905902 +
              0.00702154968 * i -
              0.0000994578677 * Math.pow(i, 2) +
              0.00000117858187 * Math.pow(i, 3) -
              2.82064061e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.265;
          result.push({ x: i, y: yy });
        }
        break;
      case 31:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i < 100.0)
            yy =
              0.086015873 +
              0.000326256614 * i +
              0.000000173611077 * Math.pow(i, 2) +
              3.35648153e-8 * Math.pow(i, 3) +
              2.60416664e-10 * Math.pow(i, 4);
          if (i >= 100.0 && i < 140.0)
            yy =
              -3.96 +
              0.1720875 * i -
              0.0025428125 * Math.pow(i, 2) +
              0.00001509375 * Math.pow(i, 3) -
              0.00000002734375 * Math.pow(i, 4);
          if (i >= 140.0 && i <= 158.5)
            yy =
              59.6169825 -
              2.2423241 * i +
              0.0313270681 * Math.pow(i, 2) -
              0.000193045181 * Math.pow(i, 3) +
              0.000000445699275 * Math.pow(i, 4);
          if (i > 158.5) yy = 3.826;
          result.push({ x: i, y: yy });
        }
        break;
      case 32:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 120.0)
            yy =
              0.0860411255 +
              0.000294859305 * i +
              0.00000200757584 * Math.pow(i, 2) +
              1.89393833e-9 * Math.pow(i, 3) +
              4.26136368e-10 * Math.pow(i, 4);
          if (i > 120.0 && i <= 158.5)
            yy =
              0.167172774 -
              0.00398013274 * i +
              0.0000825563293 * Math.pow(i, 2) -
              0.000000646994203 * Math.pow(i, 3) +
              2.3227141e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 0.5;
          result.push({ x: i, y: yy });
        }
        break;
      case 33:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 80.0)
            yy =
              0.829 +
              0.000954166667 * i +
              0.0003071875 * Math.pow(i, 2) -
              0.00000732291667 * Math.pow(i, 3) +
              0.00000007265625 * Math.pow(i, 4);
          if (i > 80.0 && i <= 100.0)
            yy =
              1.663 -
              0.0859208333 * i +
              0.0033478125 * Math.pow(i, 2) -
              0.0000507604167 * Math.pow(i, 3) +
              0.00000028984375 * Math.pow(i, 4);
          if (i > 100.0 && i <= 104.833)
            yy =
              113.188 -
              7.24210833 * i +
              0.168311875 * Math.pow(i, 2) -
              0.00167716667 * Math.pow(i, 3) +
              0.0000060984375 * Math.pow(i, 4);
          if (i > 104.833) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 34:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 120.0)
            yy =
              0.272103896 +
              0.000650108216 * i +
              0.0000119412882 * Math.pow(i, 2) -
              6.15530349e-8 * Math.pow(i, 3) +
              1.25473487e-9 * Math.pow(i, 4);
          if (i > 120.0 && i <= 158.5)
            yy =
              0.640350124 -
              0.0147400821 * i +
              0.000253981167 * Math.pow(i, 2) -
              0.00000175561052 * Math.pow(i, 3) +
              5.69456937e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 1.288;
          result.push({ x: i, y: yy });
        }
        break;
      case 35:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 60.0)
            yy =
              2.98 -
              0.000175000001 * i +
              0.00103625 * Math.pow(i, 2) -
              0.000001625 * Math.pow(i, 3);
          if (i > 60.0 && i <= 67.74)
            yy =
              -0.682 +
              0.335508333 * i -
              0.00811875 * Math.pow(i, 2) +
              0.0000746666667 * Math.pow(i, 3);
          if (i > 67.74) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 36:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 100.0)
            yy =
              0.627027778 +
              0.00137407406 * i +
              0.0000248090286 * Math.pow(i, 2) -
              0.000000120949087 * Math.pow(i, 3) +
              2.4739584e-9 * Math.pow(i, 4);
          if (i > 100.0 && i <= 158.5)
            yy =
              1.88863939 -
              0.04962321 * i +
              0.000795730506 * Math.pow(i, 2) -
              0.00000529941466 * Math.pow(i, 3) +
              1.55479121e-8 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.725;
          result.push({ x: i, y: yy });
        }
        break;
      case 41:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 80.0)
            yy =
              0.086 +
              0.000379166667 * i -
              0.00000364583333 * Math.pow(i, 2) +
              0.000000114583333 * Math.pow(i, 3) -
              2.60416667e-10 * Math.pow(i, 4);
          if (i > 80.0 && i <= 140.0)
            yy =
              -2.015 +
              0.103983333 * i -
              0.00181458333 * Math.pow(i, 2) +
              0.0000129791666 * Math.pow(i, 3) -
              2.91666666e-8 * Math.pow(i, 4);
          if (i > 140.0 && i <= 157.62)
            yy =
              264.723948 -
              10.0257458 * i +
              0.140287416 * Math.pow(i, 2) -
              0.000860273342 * Math.pow(i, 3) +
              0.00000195549813 * Math.pow(i, 4);
          if (i > 157.62) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 42:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 120.0)
            yy =
              0.0860411255 +
              0.000294859305 * i +
              0.00000200757584 * Math.pow(i, 2) +
              1.89393833e-9 * Math.pow(i, 3) +
              4.26136368e-10 * Math.pow(i, 4);
          if (i > 120.0 && i <= 158.5)
            yy =
              0.167172774 -
              0.00398013274 * i +
              0.0000825563293 * Math.pow(i, 2) -
              0.000000646994203 * Math.pow(i, 3) +
              2.3227141e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 0.5;
          result.push({ x: i, y: yy });
        }
        break;
      case 43:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 77.002)
            yy =
              1.422 -
              0.00299166667 * i +
              0.0011775 * Math.pow(i, 2) -
              0.0000304583333 * Math.pow(i, 3) +
              0.000000390625 * Math.pow(i, 4);
          if (i > 77.002) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 44:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 120.0)
            yy =
              0.349991342 +
              0.000473502901 * i +
              0.0000241003782 * Math.pow(i, 2) -
              0.000000174558072 * Math.pow(i, 3) +
              1.87026512e-9 * Math.pow(i, 4);
          if (i > 120.0 && i <= 158.5)
            yy =
              0.480250849 -
              0.00706916915 * i +
              0.000172249708 * Math.pow(i, 2) -
              0.00000138742837 * Math.pow(i, 3) +
              5.42597358e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 1.587;
          result.push({ x: i, y: yy });
        }
        break;
      case 45:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 60.0)
            yy =
              2.98 -
              0.000175000001 * i +
              0.00103625 * Math.pow(i, 2) -
              0.000001625 * Math.pow(i, 3);
          if (i > 60.0 && i <= 67.74)
            yy =
              -0.682 +
              0.335508333 * i -
              0.00811875 * Math.pow(i, 2) +
              0.0000746666667 * Math.pow(i, 3);
          if (i > 67.74) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 46:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 120.0)
            yy =
              0.627090909 +
              0.00129558082 * i +
              0.0000293939389 * Math.pow(i, 2) -
              0.000000200126256 * Math.pow(i, 3) +
              2.88825755e-9 * Math.pow(i, 4);
          if (i > 120.0 && i <= 158.5)
            yy =
              1.88863939 -
              0.04962321 * i +
              0.000795730506 * Math.pow(i, 2) -
              0.00000529941466 * Math.pow(i, 3) +
              1.55479121e-8 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.725;
          result.push({ x: i, y: yy });
        }
        break;
      case 51:
        for (let i = 0; i <= 200; i++) {
          let yy;
          if (i <= 60.0)
            yy =
              0.101 +
              0.000316666667 * i -
              0.00000125 * Math.pow(i, 2) +
              0.000000145833333 * Math.pow(i, 3);
          if (i > 60.0 && i <= 100.0)
            yy =
              -0.064 +
              0.00839166667 * i -
              0.00012 * Math.pow(i, 2) +
              0.000000645833333 * Math.pow(i, 3);
          if (i > 100.0 && i <= 140.0)
            yy =
              5.946 -
              0.155666667 * i +
              0.00128 * Math.pow(i, 2) -
              0.00000295833333 * Math.pow(i, 3);
          if (i > 140.0 && i <= 158.5)
            yy =
              -31.4066876 +
              0.795937517 * i -
              0.00672414734 * Math.pow(i, 2) +
              0.0000192754093 * Math.pow(i, 3);
          if (i > 158.5) yy = 2.576;
          result.push({ x: i, y: yy });
        }
        break;
      case 52:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 60.0)
            yy =
              0.101 +
              0.000316666667 * i -
              0.00000125 * Math.pow(i, 2) +
              0.000000145833333 * Math.pow(i, 3);
          if (i > 60.0 && i <= 158.5)
            yy =
              0.408118483 -
              0.0122320935 * i +
              0.000198148291 * Math.pow(i, 2) -
              0.0000013655372 * Math.pow(i, 3) +
              4.19442972e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 0.657;
          result.push({ x: i, y: yy });
        }
        break;
      case 53:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.765 +
              0.0006875 * i +
              0.000170520833 * Math.pow(i, 2) -
              0.00000290625 * Math.pow(i, 3) +
              2.68229167e-8 * Math.pow(i, 4);
          if (i > 80.0 && i < 120.0)
            yy =
              11.729 -
              0.707595833 * i +
              0.0166459375 * Math.pow(i, 2) -
              0.000167260417 * Math.pow(i, 3) +
              0.00000062265625 * Math.pow(i, 4);
          if (i >= 120.0 && i <= 121.444)
            yy =
              627.659 -
              29.9642708 * i +
              0.525643646 * Math.pow(i, 2) -
              0.00401682292 * Math.pow(i, 3) +
              0.0000113158854 * Math.pow(i, 4);
          if (i > 121.444) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 54:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 100.0)
            yy =
              0.340035714 +
              0.000864285724 * i +
              0.0000135937495 * Math.pow(i, 2) -
              1.56249923e-8 * Math.pow(i, 3) +
              1.17187496e-9 * Math.pow(i, 4);
          if (i > 100.0 && i <= 158.5)
            yy =
              -0.000626967178 +
              0.014656667 * i -
              0.000195113771 * Math.pow(i, 2) +
              0.00000138419543 * Math.pow(i, 3) -
              2.34097446e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 1.455;
          result.push({ x: i, y: yy });
        }
        break;
      case 55:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 60.0)
            yy =
              2.032 +
              0.00626666666 * i +
              0.0001025 * Math.pow(i, 2) +
              0.00000158333333 * Math.pow(i, 3);
          if (i > 60.0 && i <= 85.583)
            yy =
              9.649 -
              0.311408333 * i +
              0.00328875 * Math.pow(i, 2) +
              0.00000145833333 * Math.pow(i, 3);
          if (i > 85.583) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 56:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.805 +
              0.00179166667 * i +
              0.0000183333333 * Math.pow(i, 2) +
              8.33333333e-8 * Math.pow(i, 3) +
              1.04166667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              0.737905902 +
              0.00702154968 * i -
              0.0000994578677 * Math.pow(i, 2) +
              0.00000117858187 * Math.pow(i, 3) -
              2.82064061e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.265;
          result.push({ x: i, y: yy });
        }
        break;
      case 61:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.103 +
              0.000633333333 * i -
              0.0000233333333 * Math.pow(i, 2) +
              0.000000666666667 * Math.pow(i, 3) -
              4.16666667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 120.0)
            yy =
              0.689 -
              0.0388083333 * i +
              0.000943125 * Math.pow(i, 2) -
              0.00000960416667 * Math.pow(i, 3) +
              0.0000000359375 * Math.pow(i, 4);
          if (i > 120.0 && i <= 158.5)
            yy =
              20.4305306 -
              0.763087889 * i +
              0.0106761428 * Math.pow(i, 2) -
              0.0000662877491 * Math.pow(i, 3) +
              0.000000156335793 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.408;
          result.push({ x: i, y: yy });
        }
        break;
      case 62:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.103 +
              0.000633333333 * i -
              0.0000233333333 * Math.pow(i, 2) +
              0.000000666666667 * Math.pow(i, 3) -
              4.16666667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              0.461369381 -
              0.0149137896 * i +
              0.000249565831 * Math.pow(i, 2) -
              0.00000179644738 * Math.pow(i, 3) +
              5.59798647e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 0.747;
          result.push({ x: i, y: yy });
        }
        break;
      case 63:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              1.005 +
              0.004325 * i +
              0.0000883333333 * Math.pow(i, 2) -
              0.000002 * Math.pow(i, 3) +
              3.85416667e-8 * Math.pow(i, 4);
          if (i > 80.0 && i <= 100.0)
            yy =
              2.509 -
              0.152341667 * i +
              0.00557166667 * Math.pow(i, 2) -
              0.0000803333333 * Math.pow(i, 3) +
              0.000000430208333 * Math.pow(i, 4);
          if (i > 100.0 && i <= 102.718)
            yy =
              179.339 -
              11.4989333 * i +
              0.267132708 * Math.pow(i, 2) -
              0.00265910417 * Math.pow(i, 3) +
              0.00000964010417 * Math.pow(i, 4);
          if (i > 102.718) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 64:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.485 +
              0.0014625 * i +
              0.0000109375 * Math.pow(i, 2) +
              0.00000015625 * Math.pow(i, 3) +
              0.00000000078125 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              -0.287403723 +
              0.0335797366 * i -
              0.000480783532 * Math.pow(i, 2) +
              0.00000342066696 * Math.pow(i, 3) -
              7.06401581e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.119;
          result.push({ x: i, y: yy });
        }
        break;
      case 65:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              1.961 +
              0.00404166667 * i +
              0.000344166667 * Math.pow(i, 2) -
              0.00000635416667 * Math.pow(i, 3) +
              7.70833333e-8 * Math.pow(i, 4);
          if (i > 80.0 && i <= 100.0)
            yy =
              1.567 +
              0.0450833333 * i -
              0.00109229167 * Math.pow(i, 2) +
              0.0000141666667 * Math.pow(i, 3) -
              2.55208333e-8 * Math.pow(i, 4);
          if (i > 100.0 && i <= 103.35)
            yy =
              65.367 -
              4.04875 * i +
              0.0932785417 * Math.pow(i, 2) -
              0.00091625 * Math.pow(i, 3) +
              0.00000329739583 * Math.pow(i, 4);
          if (i > 103.35) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 66:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.948 +
              0.00234166667 * i +
              0.0000245833333 * Math.pow(i, 2) +
              0.000000145833333 * Math.pow(i, 3) +
              1.04166667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              -0.869788541 +
              0.0683624458 * i -
              0.000876649253 * Math.pow(i, 2) +
              0.00000573383153 * Math.pow(i, 3) -
              1.25579505e-8 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.848;
          result.push({ x: i, y: yy });
        }
        break;
      case 71:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.103 +
              0.000633333333 * i -
              0.0000233333333 * Math.pow(i, 2) +
              0.000000666666667 * Math.pow(i, 3) -
              4.16666667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 120.0)
            yy =
              0.689 -
              0.0388083333 * i +
              0.000943125 * Math.pow(i, 2) -
              0.00000960416667 * Math.pow(i, 3) +
              0.0000000359375 * Math.pow(i, 4);
          if (i > 120.0 && i <= 158.5)
            yy =
              20.4305306 -
              0.763087889 * i +
              0.0106761428 * Math.pow(i, 2) -
              0.0000662877491 * Math.pow(i, 3) +
              0.000000156335793 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.408;
          result.push({ x: i, y: yy });
        }
        break;
      case 72:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.103 +
              0.000633333333 * i -
              0.0000233333333 * Math.pow(i, 2) +
              0.000000666666667 * Math.pow(i, 3) -
              4.16666667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              0.461369381 -
              0.0149137896 * i +
              0.000249565831 * Math.pow(i, 2) -
              0.00000179644738 * Math.pow(i, 3) +
              5.59798647e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 0.747;
          result.push({ x: i, y: yy });
        }
        break;
      case 73:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.697 +
              0.00229166667 * i +
              0.0000410416667 * Math.pow(i, 2) -
              0.000000229166667 * Math.pow(i, 3) +
              9.89583333e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 121.162)
            yy =
              15.42 -
              0.953154167 * i +
              0.0223994792 * Math.pow(i, 2) -
              0.000224989583 * Math.pow(i, 3) +
              0.000000832552083 * Math.pow(i, 4);
          if (i > 121.162) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 74:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.37 +
              0.000929166667 * i +
              0.0000126041667 * Math.pow(i, 2) +
              0.000000177083333 * Math.pow(i, 3) -
              2.60416667e-10 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              -0.611985867 +
              0.0391722013 * i -
              0.000534519256 * Math.pow(i, 2) +
              0.00000346096563 * Math.pow(i, 3) -
              6.5400734e-9 * Math.pow(i, 4);
          if (i > 158.5) yy = 1.822;
          result.push({ x: i, y: yy });
        }
        break;
      case 75:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              1.961 +
              0.00404166667 * i +
              0.000344166667 * Math.pow(i, 2) -
              0.00000635416667 * Math.pow(i, 3) +
              7.70833333e-8 * Math.pow(i, 4);
          if (i > 80.0 && i <= 100.0)
            yy =
              1.567 +
              0.0450833333 * i -
              0.00109229167 * Math.pow(i, 2) +
              0.0000141666667 * Math.pow(i, 3) -
              2.55208333e-8 * Math.pow(i, 4);
          if (i > 100.0 && i <= 103.35)
            yy =
              65.367 -
              4.04875 * i +
              0.0932785417 * Math.pow(i, 2) -
              0.00091625 * Math.pow(i, 3) +
              0.00000329739583 * Math.pow(i, 4);
          if (i > 103.35) yy = 8.0;
          result.push({ x: i, y: yy });
        }
        break;
      case 76:
        for (let i = 0; i <= 200; i++) {
          let yy: number;
          if (i <= 80.0)
            yy =
              0.948 +
              0.00234166667 * i +
              0.0000245833333 * Math.pow(i, 2) +
              0.000000145833333 * Math.pow(i, 3) +
              1.04166667e-9 * Math.pow(i, 4);
          if (i > 80.0 && i <= 158.5)
            yy =
              -0.869788541 +
              0.0683624458 * i -
              0.000876649253 * Math.pow(i, 2) +
              0.00000573383153 * Math.pow(i, 3) -
              1.25579505e-8 * Math.pow(i, 4);
          if (i > 158.5) yy = 2.848;
          result.push({ x: i, y: yy });
        }
        break;
    }

    if (Component_MAWP.intUnit_select == 1) { //SI
      result.forEach(element => {
        element.x = element.x / Utility.CrackXAxisConvertCToF;
        element.y = element.y * Utility.CrackYAxisConvertInchToMm;
      });
    }

    return result;
  }

  public static Crack_Chart_Series1() {
    let result = [];
    result = [
      {
        x: this.Tpt,
        y: this.sngC_crack,
      },
    ];

    return result;
  }

  public static check_NewCrack_Data(inspectionDataFormGroup): boolean {
    //checkData Crack-Like - ADDITIONAL COMPONENT INFORMATION
    let pass_flag: boolean = true;

    this.validationMessageComponentInformation = [];

    let msgNotSet = "not set.";

    let componentInformationFormGroup =
      inspectionDataFormGroup.controls["childComponentInformationForm"];

    if (
      componentInformationFormGroup.controls["comboBox1_DI_EquipInfo"]?.enabled
    ) {
      if (
        !componentInformationFormGroup.controls["comboBox1_DI_EquipInfo"].valid
      ) {
        this.validationMessageComponentInformation.push(
          "Weld orientation - needs to be selected"
        );
        pass_flag = false;
      }
    }

    if (
      componentInformationFormGroup.controls["comboBox2_DI_EquipInfo"]?.enabled
    ) {
      if (
        !componentInformationFormGroup.controls["comboBox2_DI_EquipInfo"].valid
      ) {
        this.validationMessageComponentInformation.push(
          "Post Weld Heat Treatment, PWHT - needs to be selected"
        );
        pass_flag = false;
      }
    }

    if (
      componentInformationFormGroup.controls["comboBox3_DI_EquipInfo"]?.enabled
    ) {
      if (
        !componentInformationFormGroup.controls["comboBox3_DI_EquipInfo"].valid
      ) {
        this.validationMessageComponentInformation.push(
          "ASME exemption curve - needs to be selected"
        );
        pass_flag = false;
      }
    }

    if (
      componentInformationFormGroup.controls["textBox3_DI_EquipInfo"]?.enabled
    ) {
      if (
        !componentInformationFormGroup.controls["textBox3_DI_EquipInfo"].valid
      ) {
        this.validationMessageComponentInformation.push(
          "Specified minimum yield strength, SMYS - " + msgNotSet
        );
        pass_flag = false;
      } else if (GougeModule.sngSMYS <= 0) {
        this.validationMessageComponentInformation.push(
          "Specified minimum yield strength, SMYS - cannot be less than or equal to 0."
        );
        pass_flag = false;
      } else if (
        (GougeModule.sngSMYS < 30 || GougeModule.sngSMYS > 40) &&
        Component_MAWP.intUnit_select == 0
      ) {
        this.validationMessageComponentInformation.push(
          "SMYS = " +
          GougeModule.sngSMYS +
          " Check your input! Specified minimum yield strength is out of the range on Table 9.2\n- Reference Temperature for Use in a Level 1 assessment!"
        );
        pass_flag = false;
      } else if (
        (GougeModule.sngSMYS < 200 || GougeModule.sngSMYS > 276) &&
        Component_MAWP.intUnit_select == 1
      ) {
        this.validationMessageComponentInformation.push(
          "SMYS = " +
          GougeModule.sngSMYS +
          " Check your input! Specified minimum yield strength is out of the range on Table 9.2\n- Reference Temperature for Use in a Level 1 assessment!"
        );
        pass_flag = false;
      }
    }

    if (
      componentInformationFormGroup.controls["textBox4_DI_EquipInfo"]?.enabled
    ) {
      if (
        !componentInformationFormGroup.controls["textBox4_DI_EquipInfo"].valid
      ) {
        this.validationMessageComponentInformation.push(
          "Specified minimum tensile strength, SMTS - " + msgNotSet
        );
        pass_flag = false;
      } else if (this.sngSMTS <= 0) {
        this.validationMessageComponentInformation.push(
          "Specified minimum tensile strength, SMTS - cannot be less than or equal to 0."
        );
        pass_flag = false;
      } else if (this.sngSMTS > 70 && Component_MAWP.intUnit_select == 0) {
        this.validationMessageComponentInformation.push(
          "SMTS = " +
          this.sngSMTS +
          " > 70 ksi. Assessment aborted and crack failed the Level 1 assessment!"
        );
        pass_flag = false;
      } else if (this.sngSMTS > 483 && Component_MAWP.intUnit_select == 1) {
        this.validationMessageComponentInformation.push(
          "SMTS = ",
          this.sngSMTS,
          " > 483 MPa. Assessment aborted and crack failed the Level 1 assessment!"
        );
        pass_flag = false;
      }
    }

    if (
      componentInformationFormGroup.controls["textBox5_DI_EquipInfo"]?.enabled
    ) {
      if (
        !componentInformationFormGroup.controls["textBox5_DI_EquipInfo"].valid
      ) {
        this.validationMessageComponentInformation.push(
          "Allowable stress for the base material, Sigma_a - " + msgNotSet
        );
        pass_flag = false;
      } else {
        if (this.sngA_stress <= 0) {
          this.validationMessageComponentInformation.push(
            "Allowable stress for the base material, Sigma_a - cannot be less than or equal to 0."
          );
          pass_flag = false;
        } else if (
          this.sngA_stress > 25 &&
          Component_MAWP.intUnit_select == 0
        ) {
          this.validationMessageComponentInformation.push(
            "Sigma_a = " +
            this.sngA_stress +
            " > 25 ksi. Assessment aborted and crack failed the Level 1 assessment!"
          );
          pass_flag = false;
        } else if (
          this.sngA_stress > 172 &&
          Component_MAWP.intUnit_select == 1
        ) {
          this.validationMessageComponentInformation.push(
            "Sigma_a = " +
            this.sngA_stress +
            " > 172 MPa. Assessment aborted and crack failed the Level 1 assessment!"
          );
          pass_flag = false;
        }

        if (
          this.sngSMTS <= GougeModule.sngSMYS ||
          GougeModule.sngSMYS <= this.sngA_stress ||
          this.sngA_stress <= 0
        ) {
          this.validationMessageComponentInformation.push(
            "Allowable stress for the base material, Sigma_a - SMTS > SMSY > Sigma_a > 0."
          );
          pass_flag = false;
        }
      }
    }

    if (
      componentInformationFormGroup.controls["textBox6_DI_EquipInfo"]?.enabled
    ) {
      if (
        !componentInformationFormGroup.controls["textBox6_DI_EquipInfo"].valid
      ) {
        this.validationMessageComponentInformation.push(
          "Critical exposure temperature, CET - " + msgNotSet
        );
        pass_flag = false;
      } else if (this.sngCET == -1000) {
        this.validationMessageComponentInformation.push(
          "Critical exposure temperature, CET - cannot equal -1000."
        );
        pass_flag = false;
      } else {
        Component_MAWP.cet_temp = parseFloat(
          componentInformationFormGroup.controls["textBox6_DI_EquipInfo"].value
        );
      }
    }

    // added by shameer for Carbon Steel Sulfur content

    if (
      componentInformationFormGroup.controls["comboBox1_DI_EquipInfo_new"]
        ?.enabled
    ) {
      if (
        !componentInformationFormGroup.controls["comboBox1_DI_EquipInfo_new"]
          .valid
      ) {
        this.validationMessageComponentInformation.push(
          "Carbon Steel Sulfur Content - needs to be selected"
        );
        pass_flag = false;
      }
    }

    return pass_flag;
  }

  public static check_NewCrack_Data1(inspectionDataFormGroup): boolean {
    let pass_flag: boolean = true;

    this.validationMessageCrack = [];
    this.crackAlertMessageFix = [];

    let msgNotSet = "not set.";

    if (inspectionDataFormGroup.controls["textBox1_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["textBox1_DI"].valid) {
        this.validationMessageCrack.push("Indication depth, a - " + msgNotSet);
        pass_flag = false;
      } else {
        if (this.sngA_crack <= 0) {
          this.validationMessageCrack.push(
            "Indication depth, a - cannot be less than or equal to 0."
          );
          pass_flag = false;
        }
        if (this.sngA_crack > Component_MAWP.sngtc) {
          this.validationMessageCrack.push(
            "Indication depth, a - cannot be greater than Future corroded thickness, tc."
          );
          pass_flag = false;
        }
      }
    }

    if (inspectionDataFormGroup.controls["textBox2_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["textBox2_DI"].valid) {
        this.validationMessageCrack.push(
          "Indication length, 2c - " + msgNotSet
        );
        pass_flag = false;
      } else if (this.sngC_crack <= 0) {
        this.validationMessageCrack.push(
          "Indication length, 2c - cannot be less than or equal to 0."
        );
        pass_flag = false;
      } else if (this.sngC_crack > 8 && Component_MAWP.intUnit_select === 0) {
        this.validationMessageCrack.push(
          "Crack length = " +
          this.sngC_crack +
          " > 8 inches. Assessment aborted and crack failed the Level 1 assessment!"
        );
        pass_flag = false;
      } else if (this.sngC_crack > 200 && Component_MAWP.intUnit_select === 1) {
        this.validationMessageCrack.push(
          "Crack length = " +
          this.sngC_crack +
          " > 200 mm. Assessment aborted and crack failed the Level 1 assessment!"
        );
        pass_flag = false;
      }
    }

    if (inspectionDataFormGroup.controls["textBox3_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["textBox3_DI"].valid) {
        this.validationMessageCrack.push(
          "Spacing between the indication and centerline of the weld, Lw - " +
          msgNotSet
        );
        pass_flag = false;
      } else if (Dent_Module.sngLw < 0) {
        this.validationMessageCrack.push(
          "Spacing between the indication and centerline of the weld, Lw - cannot be less than 0."
        );
        pass_flag = false;
      }
    }

    if (inspectionDataFormGroup.controls["comboBox1_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["comboBox1_DI"].valid) {
        this.validationMessageCrack.push(
          "Indication location - needs to be selected"
        );
        pass_flag = false;
      }
    }

    if (inspectionDataFormGroup.controls["comboBox2_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["comboBox2_DI"].valid) {
        this.validationMessageCrack.push(
          "Indication orientation to weld seam - needs to be selected"
        );
        pass_flag = false;
      }
    }

    if (
      inspectionDataFormGroup.controls["textBox4_DI"]?.enabled &&
      Component_MAWP.intEquip !== 2
    ) {
      if (!inspectionDataFormGroup.controls["textBox4_DI"].valid) {
        this.validationMessageCrack.push(
          "Distance between the indication and the nearest major structural discontinuity, Lmsd - " +
          msgNotSet
        );
        pass_flag = false;
      } else if (CalcTemplate.sngLmsd < 0) {
        this.validationMessageCrack.push(
          "Distance between the indication and the nearest major structural discontinuity, Lmsd - cannot be less than 0."
        );
        pass_flag = false;
      }
    }

    if (inspectionDataFormGroup.controls["comboBox3_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["comboBox3_DI"].valid) {
        this.validationMessageCrack.push(
          "Qualified NDE procedures - needs to be selected"
        );
        pass_flag = false;
      }
    }

    //check the crack location
    let i;
    if (
      inspectionDataFormGroup.controls["comboBox1_DI"].value >= 0 &&
      Component_MAWP.sngtrd > 0 &&
      Dent_Module.sngLw >= 0
    ) {
      if (Dent_Module.sngLw <= 2 * Component_MAWP.sngtrd) {
        i = 0;
      } else {
        i = 1;
      }

      if (i !== this.intcrack_location) {
        this.crackAlertMessageFix.push(
          "The spacing between the indication and centerline of the weld, Lw does not match with the Indication Location; the indication location will be modified accordingly."
        );

        this.intcrack_location = i;
        inspectionDataFormGroup.controls["comboBox1_DI"].setValue(
          this.intcrack_location
        );
      }
    }

    return pass_flag;
  }

  public static Crack_report(): string {
    // generate crack report
    let report: string = "";

    const format = "dd/MM/yyyy";
    const locale = "en-US";
    const dt1 = new Date();
    const reportDate = formatDate(dt1, format, locale);

    report += this.Crack_report_subpart_1(reportDate);
    report += this.Crack_report_subpart_2();
    report += this.Crack_report_subpart_3(reportDate);

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      report += `Indication depth, a (inch) = ${this.sngA_crack.toFixed(
        3
      )}<br>`;
      report += `Indication length, 2c (inch) = ${this.sngC_crack.toFixed(
        3
      )}<br>`;
      report += `Spacing between indication and centerline of the weld, Lw (inch) = ${Dent_Module.sngLw.toFixed(
        3
      )}<br>`;
    } else {
      // SI
      report += `Indication depth, a (mm) = ${this.sngA_crack.toFixed(1)}<br>`;
      report += `Indication length, 2c (mm) = ${this.sngC_crack.toFixed(
        1
      )}<br>`;
      report += `Spacing between indication and centerline of the weld, Lw (mm) = ${Dent_Module.sngLw.toFixed(
        1
      )}<br>`;
    }

    if (this.intcrack_location === 0) {
      report += "Indication location = Weld & HAZ<br>";
    } else {
      report += "Indication location = Base Metal<br>";
    }

    if (this.intcrack_orien === 0) {
      report += "Indication orientation to weld seam = Parallel<br>";
    } else {
      report += "Indication orientation to weld seam = Perpendicular<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      report += `Distance to structural discontinuity, Lmsd (inch) = ${CalcTemplate.sngLmsd.toFixed(
        3
      )}<br>`;
    } else {
      report += `Distance to structural discontinuity, Lmsd (mm) = ${CalcTemplate.sngLmsd.toFixed(
        1
      )}<br>`;
    }

    if (this.intsize_accuracy === 0) {
      report +=
        "Qualified NDE procedures and personnel are used in sizing = No<br><br><br>";
    } else {
      report +=
        "Qualified NDE procedures and personnel are used in sizing = Yes<br><br><br>";
    }

    report += "Assessment Results :<br><br>";
    report +=
      "All the conditions in the Applicability and Limitations section are met.<br><br>";

    report += this.crackCalcResult;

    return report;
  }

  public static Crack_report_subpart_1(reportDate: string): string {
    let report: string = "";

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 9</center>";
    report += "<center>Assessment of Crack-Like Flaws</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 1</br></br></br>";

    const sRefinery: string = "Facility: ";
    const sPlant: string = "Plant: ";
    const sEquipment: string = "Equipment: ";
    const sAnalyst: string = "Analyst: ";

    report += sRefinery + Component_MAWP.strFacility + "</br>";
    report += sPlant + Component_MAWP.strPlant + "</br>";
    report += sEquipment + Component_MAWP.strEquipment + "</br>";
    report += sAnalyst + Component_MAWP.strAnalyst + "</br></br></br>";

    report += `  
      <ol class="appAndLimitList">
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. The design temperature is less than or equal to the temperature limit in Table 4.1.</li>
        <li>Dynamic loading effects are not significant (e.g., earthquake, impact, water hammer, etc.).</li>  
        <li>The crack-like flaw is subject to loading conditions and/or an environment that will 'not' result in crack growth. Consultation with a material/corrosion engineer is recommended.</li>
        <li>For cylindrical and spherical shell components, the flaw is in the axial or circumferential direction.</li>  
        <li>The loading on the component is from pressure that produces only a membrane stress field. Components subject to pressure induced bending stresses and/or supplemental loading are excluded (e.g., head-to-cylinder junction, nozzle intersection).</li>
        <li>The membrane stresses during operation are within the design limits of the original construction code.</li>
        <li>If a component is to be subjected to a pressure test, the component's metal temperature shall be above MAT (Minimum Allowable Temperature) during the test. Re-examination is required after the test to ensure that the flaw has not grown.</li>
        <li>The weld joint geometry is either a Single-V or Double-V configuration.</li>
        <li>The material is carbon steel (P1, Group 1 or 2).</li>
        <li>The weldments are made with an electrode compatible with the base material.</li>
        <li>The fracture toughness has not been degraded due to environment (e.g., fire damage, over-heating, graphitization, etc.)</li>
      </ol>  
      </br></br></br>`;

    report += "Component Information :</br></br>";

    if (Component_MAWP.intEquip === 0) {
      report += "Component type = Cylinder<br>";
    } else if (Component_MAWP.intEquip === 1) {
      report += "Component type = Sphere<br>";
    } else if (Component_MAWP.intEquip === 2) {
      report += "Component type = Flat Plate<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (
        !Number.isNaN(Component_MAWP.sngP_d) &&
        Component_MAWP.sngP_d !== -1
      ) {
        report += `Design pressure or vessel MAWP (psi) = ${Component_MAWP.sngP_d.toFixed(
          1
        )}<br>`;
      } else {
        report += "Design pressure or vessel MAWP (psi) = N/A<br>";
      }

      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-F) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design temperature (deg-F) = N/A<br>";
      }

      report += `Inside diameter corrected for FCA as applicable, D (inch) = ${Component_MAWP.sngID.toFixed(
        3
      )}<br>`;
      report += `Current measured nominal (uniform) thickness, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
        3
      )}<br>`;
      report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = ${Component_MAWP.sngFCA.toFixed(
        4
      )}<br>`;

      report += `Future corroded thickness, tc (inch) = ${Component_MAWP.sngtc.toFixed(
        4
      )}<br>`;
    }

    return report;
  }


  public static Crack_report_subpart_2(): string {
    let report: string = "";

    if (Component_MAWP.intUnit_select !== 0) {
      // SI
      if (
        !Number.isNaN(Component_MAWP.sngP_d) &&
        Component_MAWP.sngP_d !== -1
      ) {
        report += `Design pressure or vessel MAWP (MPa) = ${Component_MAWP.sngP_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design pressure or vessel MAWP (MPa) = N/A<br>";
      }

      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-C) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design temperature (deg-C) = N/A<br>";
      }

      report += `Inside diameter corrected for FCA as applicable, D (mm) = ${Component_MAWP.sngID.toFixed(
        2
      )}<br>`;
      report += `Current measured nominal (uniform) thickness, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
        2
      )}<br>`;
      report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = ${Component_MAWP.sngFCA.toFixed(
        2
      )}<br>`;

      report += `Future corroded thickness, tc (mm) = ${Component_MAWP.sngtc.toFixed(
        2
      )}<br>`;
    }

    report += `Longitudinal weld joint efficiency, E_L = ${Component_MAWP.sngE_L.toFixed(
      2
    )}<br>`;

    if (Component_MAWP.intEquip === 0 || Component_MAWP.intEquip === 5)
      report += `Circumferential weld joint efficiency, E_C = ${Component_MAWP.sngE_c.toFixed(
        2
      )}<br>`;

    report += '<div class="pageBreak"></div>';

    return report;
  }

  public static Crack_report_subpart_3(reportDate: string): string {
    let report: string = "";

    // SECOND PAGE

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 9</center>";
    report += "<center>Assessment of Crack-Like Flaws</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 2</br></br></br>";

    if (this.intweld_orien === 0) {
      report += "Weld orientation = Longitudinal<br>";
    } else {
      report += "Weld orientation = Circumferential<br>";
    }

    if (this.intPWHT === 0) {
      report += "Post weld heat treatment (PWHT) = No<br>";
    } else {
      report += "Post weld heat treatment (PWHT) = Yes<br>";
    }

    if (GougeModule.intASME_curve === 0) {
      report += "ASME exemption curve for the base material = Curve A<br>";
    } else if (GougeModule.intASME_curve === 1) {
      report += "ASME exemption curve for the base material = Curve B<br>";
    } else if (GougeModule.intASME_curve === 2) {
      report += "ASME exemption curve for the base material = Curve C<br>";
    } else {
      report += "ASME exemption curve for the base material = Curve D<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      report += `Base metal specified minimum yield strength, SMYS (ksi) = ${GougeModule.sngSMYS.toFixed(
        3
      )}<br>`;
      report += `Base metal specified minimum tensile strength, SMTS (ksi) = ${this.sngSMTS.toFixed(
        3
      )}<br>`;
      report += `Base metal specified allowable stress, Sa (ksi) = ${this.sngA_stress.toFixed(
        3
      )}<br>`;
      report += `Critical exposure temperature, CET (deg-F) = ${this.sngCET.toFixed(
        4
      )}<br><br><br>`;
    } else {
      // SI
      report += `Base metal specified minimum yield strength, SMYS (MPa) = ${GougeModule.sngSMYS.toFixed(
        2
      )}<br>`;
      report += `Base metal specified minimum tensile strength, SMTS (MPa) = ${this.sngSMTS.toFixed(
        2
      )}<br>`;
      report += `Base metal specified allowable stress, Sa (MPa) = ${this.sngA_stress.toFixed(
        2
      )}<br>`;
      report += `Critical exposure temperature, CET (deg-C) = ${this.sngCET.toFixed(
        2
      )}<br><br><br>`;
    }

    report += "Indication Information :<br><br>";

    return report;
  }

  public static ExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      GougeModule: { ...GougeModule },
      Dent_Module: { ...Dent_Module },
      Crack_Module: { ...Crack_Module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static ImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    GougeModule.Gouge_initialize();
    Dent_Module.Dent_initialize();
    Crack_Module.Crack_initialize();

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(GougeModule, jsonDataResult.GougeModule);
    Object.assign(Dent_Module, jsonDataResult.Dent_Module);
    Object.assign(Crack_Module, jsonDataResult.Crack_Module);
  }

  public static updateInspectionDataFormControls(
    inspectionDataFormGroup: any
  ): void {
    let textBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");
    let textBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");
    let textBox3_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox3_DI");
    let textBox4_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox4_DI");
    let comboBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox1_DI");
    let comboBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox2_DI");
    let comboBox3_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox3_DI");

    if (Crack_Module.intcrack_location !== -1) {
      let comboBoxValues = [0, 1];
      comboBox1_DIformControl.setValue(
        comboBoxValues[Crack_Module.intcrack_location]
      );
    }

    if (Crack_Module.intcrack_orien !== -1) {
      let comboBoxValues = ["Parallel", "Perpendicular"];
      comboBox2_DIformControl.setValue(
        comboBoxValues[Crack_Module.intcrack_orien]
      );
    }

    if (Crack_Module.intsize_accuracy !== -1) {
      let comboBoxValues = ["No", "Yes"];
      comboBox3_DIformControl.setValue(
        comboBoxValues[Crack_Module.intsize_accuracy]
      );
    }

    if (Crack_Module.sngA_crack !== -1) {
      textBox1_DIformControl.setValue(Crack_Module.sngA_crack.toString());
    }

    if (Crack_Module.sngC_crack !== -1) {
      textBox2_DIformControl.setValue(Crack_Module.sngC_crack.toString());
    }

    if (Dent_Module.sngLw !== -1) {
      textBox3_DIformControl.setValue(Dent_Module.sngLw.toString());
    }

    if (CalcTemplate.sngLmsd !== -1) {
      textBox4_DIformControl.setValue(CalcTemplate.sngLmsd.toString());
    }
  }

  public static updateComboBoxes(
    comboBox1_DI_EquipInfo: FormControl,
    comboBox2_DI_EquipInfo: FormControl,
    comboBox3_DI_EquipInfo: FormControl,
    ComboBox1_DI_EquipInfo_new
  ) {
    let comboBoxValues = ["Longitudinal", "Circumferential"];

    if (Crack_Module.intweld_orien !== -1)
      comboBox1_DI_EquipInfo.setValue(
        comboBoxValues[Crack_Module.intweld_orien]
      );

    comboBoxValues = ["No", "Yes"];
    if (Crack_Module.intPWHT !== -1)
      comboBox2_DI_EquipInfo.setValue(comboBoxValues[Crack_Module.intPWHT]);

    comboBoxValues = ["Curve A", "Curve B", "Curve C", "Curve D"];
    if (GougeModule.intASME_curve !== -1)
      comboBox3_DI_EquipInfo.setValue(
        comboBoxValues[GougeModule.intASME_curve]
      );

    comboBoxValues = ["<= 0.01% (Low)", "> 0.01% (High)"];
    if (Crack_Module.intcarbsteelsulf_cont !== -1)
      ComboBox1_DI_EquipInfo_new.setValue(
        comboBoxValues[Crack_Module.intcarbsteelsulf_cont]
      );
  }

  public static updateFormControls(formGroup): boolean {
    let form = formGroup.controls.childComponentInformationForm;

    let textBox3_DI_EquipInfo: FormControl = form.get(
      "textBox3_DI_EquipInfo"
    ) as FormControl;
    let textBox4_DI_EquipInfo: FormControl = form.get(
      "textBox4_DI_EquipInfo"
    ) as FormControl;
    let textBox5_DI_EquipInfo: FormControl = form.get(
      "textBox5_DI_EquipInfo"
    ) as FormControl;
    let textBox6_DI_EquipInfo: FormControl = form.get(
      "textBox6_DI_EquipInfo"
    ) as FormControl;
    let comboBox1_DI_EquipInfo: FormControl = form.get(
      "comboBox1_DI_EquipInfo"
    );
    let comboBox2_DI_EquipInfo: FormControl = form.get(
      "comboBox2_DI_EquipInfo"
    );
    let comboBox3_DI_EquipInfo: FormControl = form.get(
      "comboBox3_DI_EquipInfo"
    );
    let ComboBox1_DI_EquipInfo_new: FormControl = form.get(
      "comboBox1_DI_EquipInfo_new"
    );

    this.updateComboBoxes(
      comboBox1_DI_EquipInfo,
      comboBox2_DI_EquipInfo,
      comboBox3_DI_EquipInfo,
      ComboBox1_DI_EquipInfo_new
    );

    if (GougeModule.sngSMYS !== -1)
      textBox3_DI_EquipInfo.setValue(GougeModule.sngSMYS);

    if (Crack_Module.sngSMTS !== -1)
      textBox4_DI_EquipInfo.setValue(Crack_Module.sngSMTS);

    if (Crack_Module.sngA_stress !== -1)
      textBox5_DI_EquipInfo.setValue(Crack_Module.sngA_stress);

    if (Crack_Module.sngCET !== -1)
      textBox6_DI_EquipInfo.setValue(Crack_Module.sngCET);

    let formControlsError: boolean = false;
    if (
      !Utility.isTextADouble(textBox3_DI_EquipInfo.value) &&
      textBox3_DI_EquipInfo.enabled
    )
      formControlsError = true;
    else if (
      !Utility.isTextADouble(textBox4_DI_EquipInfo.value) &&
      textBox4_DI_EquipInfo.enabled
    )
      formControlsError = true;
    else if (
      !Utility.isTextADouble(textBox5_DI_EquipInfo.value) &&
      textBox5_DI_EquipInfo.enabled
    )
      formControlsError = true;
    else if (
      !Utility.isTextADouble(textBox6_DI_EquipInfo.value) &&
      textBox6_DI_EquipInfo.enabled
    )
      formControlsError = true;

    return formControlsError;
  }
}
