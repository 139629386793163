import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Data } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { Component_MAWP } from "../../../services/component_mawp";
import { GougeModule } from "projects/fitnessforservice/src/services/gouge_module";
import { Dent_Module } from "projects/fitnessforservice/src/services/dent_module";
import { Crack_Module } from "projects/fitnessforservice/src/services/crack_module";
import { Subject, Subscription } from "rxjs";
import { FormControlsEventProxyService } from "projects/fitnessforservice/src/services/formcontrols_eventproxy_service";
import { Utility } from "projects/fitnessforservice/src/services/utility";
import { MAWPComponent } from "../../mawp/mawp.component";
import { ComponentEventService } from "projects/fitnessforservice/src/services/component_event.service";
import { MatDialog } from "@angular/material/dialog";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";
import { ComponentTypeData } from "../../models/component-type";

@Component({
  selector: "component-information",
  templateUrl: "component-information.component.html",
})
export class ComponentInformationComponent implements OnInit, OnDestroy {
  @Input() checkValidation: Subject<boolean>;
  @Input() componentData: Subject<boolean>;
  @Input() comboBox1_DI_Disable: Subject<boolean>;
  @Output() comboBox2_DI_EnabledEvent = new EventEmitter<boolean>();
  @Output() textBox4_DI_EnabledEvent = new EventEmitter<boolean>();

  public componentMAWP = Component_MAWP;
  public mawpComponent = MAWPComponent;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;
  intUnit: number;
  ComboBox0Data: Data[];
  ComboBox1Data: Data[];
  ComboBox2Data: Data[];
  ComboBox3Data: Data[];
  ComboBox1NewData: Data[];
  cet_temp_value: number = -1;

  selectedComponentType: string;

  comboBox1_DI_EquipInfo_Enabled: boolean = true;
  textBox1_DI_EquipInfo_Enabled: boolean = true;

  componentInformationFormGroup: FormGroup;

  sigma_a: number;
  sigma_a_subscription: Subscription;

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private formControlsEventProxyService: FormControlsEventProxyService,
    private componentEventService: ComponentEventService,
    private dialog: MatDialog
  ) {
    this.path = activatedRoute.snapshot["_routerState"].url;
    this.setSigmaaValue();
    if (Component_MAWP.strD_iclass === 5) {
      this.ComboBox0Data = [
        { id: 1, text: ComponentTypeData.Cylinder },
        { id: 2, text: ComponentTypeData.Sphere },
        { id: 3, text: ComponentTypeData.EllipticalHead },
        { id: 4, text: ComponentTypeData.FDHead },
      ];
      this.componentInformationFormGroup = this._formBuilder.group({
        comboBox0_DI_EquipInfo: ["", Validators.required],
        textBox1_DI_EquipInfo: [
          { value: "", disabled: !this.textBox1_DI_EquipInfo_Enabled },
          Validators.required,
        ],
        textBox2_DI_EquipInfo: ["", Validators.required],
        textBox3_DI_EquipInfo: ["", Validators.required],
      });
    } else {
      if (Component_MAWP.strD_iclass === 6) {
        this.ComboBox1Data = [
          { id: 1, text: "Longitudinal" },
          { id: 2, text: "Circumferential" },
        ];
        this.ComboBox2Data = [
          { id: 1, text: "No" },
          { id: 2, text: "Yes" },
          { id: 3, text: "Don't Know" },
        ];
        this.ComboBox3Data = [
          { id: 1, text: "Curve A" },
          { id: 2, text: "Curve B" },
          { id: 3, text: "Curve C" },
          { id: 4, text: "Curve D" },
        ];
        this.ComboBox1NewData = [
          { id: 1, text: "<= 0.01% (Low)" },
          { id: 2, text: "> 0.01% (High)" },
        ];
        this.componentInformationFormGroup = this._formBuilder.group({
          comboBox0_DI_EquipInfo: ["", Validators.required],
          textBox1_DI_EquipInfo: [
            { value: "", disabled: !this.textBox1_DI_EquipInfo_Enabled },
            Validators.required,
          ],
          textBox2_DI_EquipInfo: ["", Validators.required],
          comboBox1_DI_EquipInfo: [
            { value: "", disabled: !this.comboBox1_DI_EquipInfo_Enabled },
            Validators.required,
          ],
          comboBox2_DI_EquipInfo: ["", Validators.required],
          comboBox3_DI_EquipInfo: ["", Validators.required],
          textBox3_DI_EquipInfo: ["", Validators.required],
          textBox4_DI_EquipInfo: ["", Validators.required],
          textBox5_DI_EquipInfo: [this.sigma_a, Validators.required],
          textBox6_DI_EquipInfo: ["", Validators.required],
          comboBox1_DI_EquipInfo_new: ["", Validators.required],
        });
      }

      if (Component_MAWP.strD_iclass === 8) {
        this.componentInformationFormGroup = this._formBuilder.group({
          textBox2_DI_EquipInfo: ["", Validators.required],
          textBox1_DI_EquipInfo: [
            { value: "", disabled: true },
            Validators.required,
          ],
          comboBox1_DI_EquipInfo: [
            { value: "", disabled: !this.comboBox1_DI_EquipInfo_Enabled },
            Validators.required,
          ],
          comboBox2_DI_EquipInfo: ["", Validators.required],
          comboBox3_DI_EquipInfo: ["", Validators.required],
          textBox3_DI_EquipInfo: ["", Validators.required],
          textBox4_DI_EquipInfo: ["", Validators.required],
          textBox5_DI_EquipInfo: ["", Validators.required],
          textBox6_DI_EquipInfo: ["", Validators.required],
          comboBox1_DI_EquipInfo_new: ["", Validators.required],
        });
      }
    }
  }

  ngOnInit(): void {
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0; // 0 - US, 1 - SI
    Component_MAWP.intUnit_select = this.intUnit;
    this.setDefaults();

    this.checkValidation.subscribe(() => {
      this.validation();
    });

    if (Component_MAWP.strD_iclass == 8) {
      this.mawpComponent.emitCalCirStressData.subscribe(data => {
        let value = Utility.roundDouble(data, 3);
        Dent_Module.sngSigma = value;
        if (this.intUnit === 0) {
          this.componentInformationFormGroup
            .get("textBox3_DI_EquipInfo")
            .setValue(value.toFixed(1));
        } else {
          this.componentInformationFormGroup
            .get("textBox3_DI_EquipInfo")
            .setValue(value.toFixed(2));
        }
      });

      this.componentData.subscribe((value) => {
        if (value) {
          this.componentInformationFormGroup
            .get("textBox1_DI_EquipInfo")
            .disable();
        } else {
          this.componentInformationFormGroup
            .get("textBox1_DI_EquipInfo")
            .enable();
        }
      });

      this.formControlsEventProxyService.getDentGouge_ComponentInformationEvent().subscribe((v) => {
        this.updateFormControlsAfterImportForDentGouge();
      });

      this.componentInformationFormGroup //for the init of component
        .get("textBox1_DI_EquipInfo")
        .disable();
    }

    if (Component_MAWP.strD_iclass == 6) {
      this.componentEventService.enabledComboBox1_DI_EquipInfoObservable.subscribe(enable => {
        if (enable) {
          this.componentInformationFormGroup.get("comboBox1_DI_EquipInfo").enable();
        } else {
          this.componentInformationFormGroup.get("comboBox1_DI_EquipInfo").disable();
        }
      });
    }
  }

  setDefaults() {
    this.onChangeComboBox0_DI_EquipInfo("Cylinder");
    this.onChangeComboBox1_DI_EquipInfo("Longitudinal");
    this.onChangeComboBox2_DI_EquipInfo("No");
    this.onChangeComboBox3_DI_EquipInfo("Curve A");
    this.onChangeComboBox1_DI_EquipInfo_new("<= 0.01% (Low)");
  }

  onChangeComboBox0_DI_EquipInfo(value): void {
    if (Component_MAWP.strD_iclass == 5) {
      //blister & HIC
      if (value == ComponentTypeData.Cylinder) Component_MAWP.intEquip = 0;
      else if (value == ComponentTypeData.Sphere) Component_MAWP.intEquip = 1;
      else if (value == ComponentTypeData.EllipticalHead) Component_MAWP.intEquip = 2;
      else if (value == ComponentTypeData.FDHead) Component_MAWP.intEquip = 3;
    }
  }

  onChangeTextBox1_DI_EquipInfo(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 5: //BlisterHIC
          Component_MAWP.sngID = parseFloat(e.target.value);
          break;
        case 8: // DentGouge
          Dent_Module.sngP_op = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeTextBox2_DI_EquipInfo(e) {
    if (Utility.isTextADouble(e.target.value)) {
      if (Component_MAWP.strD_iclass === 5) {
        Component_MAWP.sngtrd = parseFloat(e.target.value);
      } else {
        GougeModule.sngSMYS = parseFloat(e.target.value);
      }
    }
  }

  onChangeTextBox3_DI_EquipInfo(e) {
    //textBox111_DI_TextChanged
    if (Utility.isTextADouble(e.target.value)) {
      if (Component_MAWP.strD_iclass === 5) {
        Component_MAWP.sngFCA = parseFloat(e.target.value);
      } else if (Component_MAWP.strD_iclass === 6) {
        //Crack
        GougeModule.sngSMYS = parseFloat(e.target.value);
      } else {
        Dent_Module.sngSigma = parseFloat(e.target.value);
      }
    }
  }

  onChangeComboBox1_DI_EquipInfo(comboBox1Value) {
    //comboBox111_DI_SelectedIndexChanged
    if (comboBox1Value == "Longitudinal") {
      Crack_Module.intweld_orien = 0;
    } else if (comboBox1Value == "Circumferential") {
      Crack_Module.intweld_orien = 1;
    }
  }

  onChangeComboBox2_DI_EquipInfo(comboBox2Value) {
    //comboBox222_DI_SelectedIndexChanged
    if (comboBox2Value === "No") {
      Crack_Module.intPWHT = 0;
    } else if (comboBox2Value === "Yes") {
      Crack_Module.intPWHT = 1;
    } else if (comboBox2Value === "Don't Know") {
      Crack_Module.intPWHT = 0;
      this.openCrackDontKnowModal();
      this.componentInformationFormGroup.get("comboBox2_DI_EquipInfo").setValue("No");
    }
  }

  onChangeComboBox3_DI_EquipInfo(comboBox3Value) {
    //comboBox333_DI_SelectedIndexChanged
    if (comboBox3Value === "Curve A") {
      GougeModule.intASME_curve = 0;
    } else if (comboBox3Value === "Curve B") {
      GougeModule.intASME_curve = 1;
    } else if (comboBox3Value === "Curve C") {
      GougeModule.intASME_curve = 2;
    } else if (comboBox3Value === "Curve D") {
      GougeModule.intASME_curve = 3;
    }
  }

  onChangeTextBox4_DI_EquipInfo(e) {
    //textBox222_DI_TextChanged
    if (Utility.isTextADouble(e.target.value)) {
      Crack_Module.sngSMTS = parseFloat(e.target.value);
    }
  }

  onChangeTextBox5_DI_EquipInfo(e) {
    //textBox444_DI_TextChanged
    if (Utility.isTextADouble(e.target.value)) {
      Crack_Module.sngA_stress = parseFloat(e.target.value);
    }
  }

  onChangeTextBox6_DI_EquipInfo(e) {
    //textBox333_DI_TextChanged
    if (Utility.isTextADouble(e.target.value)) {
      Crack_Module.sngCET = parseFloat(e.target.value);
      Crack_Module.cet_temp = parseFloat(e.target.value);
    }
  }

  onChangeComboBox1_DI_EquipInfo_new(comboBox1newValue) {
    if (comboBox1newValue === "<= 0.01% (Low)") {
      Crack_Module.intcarbsteelsulf_cont = 0;
    } else if (comboBox1newValue === "> 0.01% (High)") {
      Crack_Module.intcarbsteelsulf_cont = 1;
    } else if (Component_MAWP.strD_iclass === 6) {
      //Crack
      GougeModule.sngSMYS = parseFloat(comboBox1newValue.target.value);
    } else {
      Dent_Module.sngSigma = parseFloat(comboBox1newValue.target.value);
    }
  }

  validation() {
    Object.keys(this.componentInformationFormGroup.controls).forEach((key) => {
      this.componentInformationFormGroup.get(key).markAsDirty();
    });
  }

  updateFormControlsAfterImportForDentGouge() {
    let textBox1_DI_EquipInfoformControl: FormControl =
      this.componentInformationFormGroup.get("textBox1_DI_EquipInfo") as FormControl;
    let textBox2_DI_EquipInfoformControl: FormControl =
      this.componentInformationFormGroup.get("textBox2_DI_EquipInfo") as FormControl;
    let textBox3_DI_EquipInfoformControl: FormControl =
      this.componentInformationFormGroup.get("textBox3_DI_EquipInfo") as FormControl;

    if (Dent_Module.intd_key == 0)		// measurement not under pressure
      textBox1_DI_EquipInfoformControl.setValue(Dent_Module.sngP_op.toString());

    if (GougeModule.sngSMYS != -1)
      textBox2_DI_EquipInfoformControl.setValue(GougeModule.sngSMYS.toString());

    if (Dent_Module.sngSigma != -1)
      textBox3_DI_EquipInfoformControl.setValue(Dent_Module.sngSigma.toString());
  }

  private openCrackDontKnowModal(): void {
    const crackWarningMessage = ["Because you don't know, the default 'no PWHT' will be used."];
    this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: {
        warningMessage: crackWarningMessage,
        caption: "Error",
        captionCenter: true,
        cancelButtonShow: false,
      },
    });
  }

  private setSigmaaValue(): void {
    this.sigma_a_subscription = this.componentEventService.allowableStressTempS.subscribe({
      next: (s: number) => {
        let unit = localStorage.getItem('unitType');
        let unitValue = unit ? Number(unit) : 0; 
        let divider = unitValue === 0 ? 1000 : 1;
        this.sigma_a = Number(s/divider);
        this.componentInformationFormGroup.controls['textBox5_DI_EquipInfo'].setValue(this.sigma_a);
        this.componentInformationFormGroup.controls['textBox5_DI_EquipInfo'].disable();
      }, error(err) {
        console.log(err);
      },
    })
  }

  ngOnDestroy(): void {
    if (this.sigma_a_subscription) {
      this.sigma_a_subscription.unsubscribe();
    }
  }
}
