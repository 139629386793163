import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CalcTemplate } from "../../../services/calctemplate";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { Subject } from "rxjs";
import { Component_MAWP } from "../../../services/component_mawp";
import { GougeModule } from "../../../services/gouge_module";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Form1 } from "../../../services/form1";
import { MatStepper } from "@angular/material/stepper";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { Utility } from "../../../services/utility";
import { FormControlsEventProxyService } from "../../../services/formcontrols_eventproxy_service";

@Component({
  selector: "gouges",
  templateUrl: "gouges.component.html",
})
export class GougesComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;
  totalStepsCount: number;

  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;
  inspectionDataCalculate: Subject<boolean> = new Subject();
  applicability: string;

  public component_mawp = Component_MAWP;
  public gauges = GougeModule;

  summary: string;
  inspectionDataResult: string;
  result: string;

  intUnit: number;

  material_toughness_data_enabled: boolean;
  material_toughness_determination_enabled: boolean;
  materialToughness: string = "yes";
  gougeDressed: string = "no";

  gougeValidationList = [];
  gougeValidationListLabel = "Check your input! \n\n";

  gougeWarningMessage = [];

  firstFormGroup = this._formBuilder.group({
    applicabilityCheckbox: ["", Validators.requiredTrue],
  });
  secondFormGroup = this._formBuilder.group({});
  isLinear = true;

  curveMapping = {
    "Curve A": 0,
    "Curve B": 1,
    "Curve C": 2,
    "Curve D": 3,
  };

  gougesDataFormGroup = this._formBuilder.group({
    radioButton2_Gouge: ["", Validators.required],
    radioButton4_Gouge: [{ value: "", disabled: true }, Validators.required],
    textBox1_Gouge: [{ value: "", disabled: true }, Validators.required],
    textBox2_Gouge: [{ value: "", disabled: true }, Validators.required],
    textBox3_Gouge: [{ value: "", disabled: true }, Validators.required],
    comboBox1_Gouge: [{ value: "", disabled: true }, Validators.required],
  });

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private formControlsEventProxyService: FormControlsEventProxyService
  ) {
    this.path = activatedRoute.snapshot["_routerState"].url;
    this.Gouges_Assesment_Setup();
  }

  goToComponent() {
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsDirty({
      onlySelf: true,
    });

    this.goNextStep();
  }

  getApplicabilityCheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("applicabilityCheckbox") as FormControl;
  }

  ngOnInit(): void {
    CalcTemplate.reset_data();
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0;

    this.applicability = `  
          <ol>  
            <li>The original design criteria were in accordance with a recognized code or standard.</li>  
            <li>The component is not operating in the creep range. See the Help Menu for temperature limit used to define the creep range.</li>
            <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment. Note that cold work may be produced by the deformation. This cold worked area may be more susceptible to some forms of mechanical and environmental cracking.</li>  
            <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
            <li>  
              Type A components subject to internal pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness. Examples are:    
              <ol type="a">  
                <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
                <li>Spherical pressure vessels and storage spheres</li>  
                <li>Spherical, elliptical and torispherical formed heads</li>  
                <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
                <li>Cylindrical atmospheric storage tank shell courses</li>  
              </ol>  
            </li>  
          </ol>  
        `;
    localStorage.setItem('calculationStep', '0'); //this is zero step (Applicability)

    this.formControlsEventProxyService.getGouges_ApplicabilityEventSubject().subscribe((v) => {
      this.updateApplicabilityCheckboxToChecked();
    });

    this.formControlsEventProxyService.getGougesDataEventSubject().subscribe((v) => {
      this.updateFormControlsAfterImport();
    });
  }

  updateApplicabilityCheckboxToChecked() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    applicabilityCheckbox.setValue(true);
  }

  goNextStep() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    if (applicabilityCheckbox.value === true)
      localStorage.setItem('calculationStep', (this.myStepper.selectedIndex + 1).toString());

    this.myStepper.next();
  }

  goPreviousStep() {
    localStorage.setItem('calculationStep', (this.myStepper.selectedIndex - 1).toString());
    this.myStepper.previous();
  }

  setTextBox1Value(textBox1_GougeformControl: FormControl) {
    if (GougeModule.sngCVN_value !== -1) {
      textBox1_GougeformControl.setValue(GougeModule.sngCVN_value.toString());
    }
  }

  setComboBox1Value(comboBox1_GougeformControl: FormControl) {
    if (GougeModule.intASME_curve !== -1) {
      const curveMapping = ["Curve A", "Curve B", "Curve C", "Curve D"];
      const curveValue = curveMapping[GougeModule.intASME_curve];
      if (curveValue) {
        comboBox1_GougeformControl.setValue(this.curveMapping[curveValue]);
      }
    }
  }

  setTextBox2Value(textBox2_GougeformControl: FormControl) {
    if (GougeModule.sngSMYS !== -1) {
      textBox2_GougeformControl.setValue(GougeModule.sngSMYS.toString());
    }
  }

  setTextBox3Value(textBox3_GougeformControl: FormControl) {
    if (GougeModule.sngT_op !== -1) {
      textBox3_GougeformControl.setValue(GougeModule.sngT_op.toString());
    }
  }

  updateFormControlsAfterImport() {
    let textBox1_GougeformControl: FormControl = this.gougesDataFormGroup.get("textBox1_Gouge") as FormControl;
    let textBox2_GougeformControl: FormControl = this.gougesDataFormGroup.get("textBox2_Gouge") as FormControl;
    let textBox3_GougeformControl: FormControl = this.gougesDataFormGroup.get("textBox3_Gouge") as FormControl;
    let comboBox1_GougeformControl: FormControl = this.gougesDataFormGroup.get("comboBox1_Gouge") as FormControl;
    let radioButton2_GougeformControl: FormControl = this.gougesDataFormGroup.get("radioButton2_Gouge") as FormControl;
    let radioButton4_GougeformControl: FormControl = this.gougesDataFormGroup.get("radioButton4_Gouge") as FormControl;

    this.setTextBox1Value(textBox1_GougeformControl);
    this.setComboBox1Value(comboBox1_GougeformControl);
    this.setTextBox2Value(textBox2_GougeformControl);
    this.setTextBox3Value(textBox3_GougeformControl);

    switch (GougeModule.intDressed) {
      case 1:
        radioButton2_GougeformControl.setValue("yes");
        this.doStepsAfterRadioButton2SetToYes();
        break;
      case 2:
        radioButton2_GougeformControl.setValue("no");
        this.doStepsAfterRadioButton2SetToNo();
        if (GougeModule.intCVN_data !== -1) {
          this.material_toughness_determination_enabled = true;
          switch (GougeModule.intCVN_data) {
            case 1:
              radioButton4_GougeformControl.setValue("yes");
              this.doStepsAfterRadioButton4SetToYes();
              break;
            case 2:
              radioButton4_GougeformControl.setValue("no");
              this.doStepsAfterRadioButton4SetToNo();
              break;
          }
        }
        break;
    }

    this.gougeValidationList = [];
  }

  Gouges_Assesment_Setup() {
    Component_MAWP.MAWP_initialize();

    Component_MAWP.strD_class = "Gouge";
    Component_MAWP.strD_iclass = 3;
    Component_MAWP.intUnit_select =
      parseInt(localStorage.getItem("unitType")) || 0;
    Form1.prev_strD_class = "Gouge";

    GougeModule.Gouge_initialize();

    this.material_toughness_data_enabled = false;
    this.material_toughness_determination_enabled = false;
  }

  handleSummaryData(data: any) {
    this.summary = data;
  }

  handleInspectionData(data: any) {
    this.inspectionDataResult = data;
  }

  calculateGaouge(): void {
    Object.keys(this.gougesDataFormGroup.controls).forEach((key) => {
      if (this.gougesDataFormGroup.get(key).enabled) {
        this.gougesDataFormGroup.get(key).markAsDirty();
      }
    });

    let gougeValidationResultValid = GougeModule.check_Gouge_Data(
      this.gougesDataFormGroup
    );
    this.gougeValidationList = GougeModule.validationMessage;
    this.gougeWarningMessage = GougeModule.warningMessage;

    if (GougeModule.validationModalMessage.length != 0) {
      this.openErrorModal();
    }
    else if (this.gougesDataFormGroup.valid && gougeValidationResultValid) {
      if (this.gougeWarningMessage.length != 0) {
        this.openWarningModal();
      } else {
        GougeModule.Get_temp();
        this.goNextStep();
      }
    }
  }

  openWarningModal(): void {
    const dialogRef = this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: {
        warningMessage: this.gougeWarningMessage,
        caption: "Proceed",
        captionCenter: true,
        cancelButtonShow: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // "OK"
        GougeModule.Get_temp();
        this.goNextStep();
      } // back
    });
  }

  openErrorModal(): void {
    const dialogRef = this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: {
        warningMessage: GougeModule.validationModalMessage,
        caption: "Assessment Failed",
        captionCenter: true,
        cancelButtonShow: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // "OK"
        return;
      } // back
    });
  }

  calculateResult(): void {
    this.inspectionDataCalculate.next(true);
  }

  doStepsAfterRadioButton2SetToYes() {
    this.material_toughness_data_enabled = false;
    this.gougesDataFormGroup.controls.radioButton4_Gouge.disable();

    Object.keys(this.gougesDataFormGroup.controls).forEach((key) => {
      if (
        this.gougesDataFormGroup.get(key) !=
        this.gougesDataFormGroup.controls.radioButton2_Gouge
      ) {
        this.gougesDataFormGroup.get(key).disable();
        this.gougesDataFormGroup.get(key).setValue("");
        this.gougesDataFormGroup.get(key).markAsPristine();
        this.gougesDataFormGroup.get(key).markAsUntouched();
      }
    });
    this.gougesDataFormGroup.controls.comboBox1_Gouge.setValue("");
    this.material_toughness_determination_enabled = false;

  }

  doStepsAfterRadioButton2SetToNo() {
    this.material_toughness_data_enabled = true;
    this.gougesDataFormGroup.controls.radioButton4_Gouge.enable();
  }

  doStepsAfterRadioButton4SetToYes() {
    this.gougesDataFormGroup.controls.textBox1_Gouge.enable();

    this.gougesDataFormGroup.controls.textBox2_Gouge.disable();
    this.gougesDataFormGroup.controls.textBox3_Gouge.disable();
    this.gougesDataFormGroup.controls.comboBox1_Gouge.disable();
  }

  doStepsAfterRadioButton4SetToNo() {
    this.gougesDataFormGroup.controls.textBox1_Gouge.disable();

    this.gougesDataFormGroup.controls.textBox2_Gouge.enable();
    this.gougesDataFormGroup.controls.textBox3_Gouge.enable();
    this.gougesDataFormGroup.controls.comboBox1_Gouge.enable();
  }

  onChangeRadioButton2_Gouge(value: string) {
    this.gougeDressed = value;
    if (value === "yes") {
      GougeModule.intDressed = 1;

      this.onUpdateCurveValue(-1);
      GougeModule.sngCVN_value = -1;
      GougeModule.sngSMYS = -1;
      GougeModule.sngT_op = -1;

      this.doStepsAfterRadioButton2SetToYes()

      this.gougeValidationList = [];
      GougeModule.validationMessage = [];
    } else {
      this.gauges.intDressed = 2;
      this.doStepsAfterRadioButton2SetToNo();
    }
  }

  onChangeRadioButton4_Gouge(value: string) {
    this.material_toughness_determination_enabled = true;
    this.materialToughness = value;

    this.gougesDataFormGroup.controls.textBox1_Gouge.markAsPristine();
    this.gougesDataFormGroup.controls.textBox2_Gouge.markAsPristine();
    this.gougesDataFormGroup.controls.textBox3_Gouge.markAsPristine();
    this.gougesDataFormGroup.controls.comboBox1_Gouge.markAsPristine();

    this.gougeValidationList = [];
    GougeModule.validationMessage = [];

    if (value === "yes") {
      GougeModule.intCVN_data = 1;
      this.doStepsAfterRadioButton4SetToYes();
    } else {
      GougeModule.intCVN_data = 2;
      this.doStepsAfterRadioButton4SetToNo();
    }
  }

  onUpdateCurveValue(curveName: number): void {
    GougeModule.intASME_curve = curveName;
  }

  onChangeTextBox1_Gouge(e) {
    if (Utility.isTextADouble(e.target.value))
      GougeModule.sngCVN_value = parseFloat(e.target.value);
  }

  onChangeTextBox2_Gouge(e) {
    if (Utility.isTextADouble(e.target.value))
      GougeModule.sngSMYS = parseFloat(e.target.value);
  }

  onChangeTextBox3_Gouge(e) {
    if (Utility.isTextADouble(e.target.value))
      GougeModule.sngT_op = parseFloat(e.target.value);
  }
}
