// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smaller-table th,  
.smaller-table td {  
    width: 5px;
    padding: 8px;  
  }  

     
.smaller-table tr   {  
    height: 20px;  
    width: 5px;
  }  

.smaller-table td  input{
    height: 25px;
    width: 65px;
}
    

.smaller-table td  mat-checkbox{
    height: 10px;
    width: 10px;
}`, "",{"version":3,"sources":["webpack://./projects/fitnessforservice/src/app/assessments/local-metal-loss-profile-data/local-metal-loss-profile-data.component.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,YAAY;EACd;;;AAGF;IACI,YAAY;IACZ,UAAU;EACZ;;AAEF;IACI,YAAY;IACZ,WAAW;AACf;;;AAGA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".smaller-table th,  \n.smaller-table td {  \n    width: 5px;\n    padding: 8px;  \n  }  \n\n     \n.smaller-table tr   {  \n    height: 20px;  \n    width: 5px;\n  }  \n\n.smaller-table td  input{\n    height: 25px;\n    width: 65px;\n}\n    \n\n.smaller-table td  mat-checkbox{\n    height: 10px;\n    width: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
