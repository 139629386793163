import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Component_MAWP } from "../../services/component_mawp";
import { Laminations_module } from "../../services/laminations_module";
import { saveAs } from 'file-saver';
import { FormControlsEventProxyService } from "../../services/formcontrols_eventproxy_service";
import { LTA_module } from "../../services/lta_module";
import { Dent_Module } from "../../services/dent_module";
import { MatDialog } from "@angular/material/dialog";
import { WarningModalComponent } from "../warning-modal/warning-modal.component";
import { GML_module } from "../../services/gml_module";
import { LTAP_module } from "../../services/ltap_module";
import { Pitting_module } from "../../services/pitting_module";
import { GougeModule } from "../../services/gouge_module";
import { BlisterHIC_module } from "../../services/blisterHIC_module";
import { Crack_Module } from "../../services/crack_module";
import { ComponentEventService } from "../../services/component_event.service";

@Component({
  selector: "import-export",
  templateUrl: "import-export.component.html",
  styleUrls: ["import-export.component.css"]
})
export class ImportExportComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private formControlsEventProxyService: FormControlsEventProxyService,
    private componentEventService: ComponentEventService,
    private dialog: MatDialog) { }
  public isLoading: boolean = false;

  triggerGMLDataUpdateFormControlsEvent() {
    this.formControlsEventProxyService.triggerGMLDataUpdateFormControlsEvent();
  }

  triggerInspectionDataUpdateFormControlsEvent() {
    this.formControlsEventProxyService.triggerInspectionDataEvent();
  }

  triggerMAWPUpdateFormControlsEvent() {
    this.formControlsEventProxyService.triggerMAWPEvent();
  }

  triggerLaminationsApplicabilityCheckboxFormControlUpdateEvent() {
    this.formControlsEventProxyService.triggerLaminationsApplicabilityCheckboxFormControlUpdateEvent();
  }

  triggerLTA_ApplicabilityCheckboxFormControlUpdateEvent() {
    this.formControlsEventProxyService.triggerLTA_ApplicabilityCheckboxFormControlUpdateEvent();
  }

  triggerLTAP_ApplicabilityCheckboxFormControlUpdateEvent() {
    this.formControlsEventProxyService.triggerLTAP_ApplicabilityCheckboxFormControlUpdateEvent();
  }

  triggerDents_ApplicabilityCheckboxFormControlUpdateEvent() {
    this.formControlsEventProxyService.triggerDents_ApplicabilityCheckboxFormControlUpdateEvent();
  }

  triggerPitting_ApplicabilityCheckboxFormControlUpdateEvent() {
    this.formControlsEventProxyService.triggerPitting_ApplicabilityCheckboxFormControlUpdateEvent();
  }

  triggerPittingInformationUpdateFormControlsEvent() {
    this.formControlsEventProxyService.triggerPittingInformationEvent();
  }

  triggerGouges_ApplicabilityCheckboxFormControlUpdateEvent() {
    this.formControlsEventProxyService.triggerGouges_ApplicabilityCheckboxFormControlUpdateEvent();
  }

  triggerGougesDataUpdateFormControlsEvent() {
    this.formControlsEventProxyService.triggerGougesDataEvent();
  }

  triggerDentGouge_ApplicabilityCheckboxFormControlUpdateEvent() {
    this.formControlsEventProxyService.triggerDentGouge_ApplicabilityCheckboxFormControlUpdateEvent();
  }

  triggerDentGouge_ComponentInformationUpdateFormControlsEvent() {
    this.formControlsEventProxyService.triggerDentGouge_ComponentInformationEvent();
  }

  triggerBlisterAndHIC_ApplicabilityCheckboxAndFormUpdateEvent() {
    this.formControlsEventProxyService.triggerBlisterAndHIC_ApplicabilityCheckboxAndFormUpdateEvent();
  }

  triggerCrackLikeIndications_ApplicabilityCheckboxAndFormUpdateEvent() {
    this.formControlsEventProxyService.triggerCrackLikeIndications_ApplicabilityCheckboxAndFormUpdateEvent();
  }

  triggerCrackLikeIndicationse_ComponentInformationUpdateFormControlsEvent() {
    this.formControlsEventProxyService.triggerCrackLikeIndications_ComponentInformationEvent();
  }

  exportToJson() {
    let jsonData: string;

    switch (Component_MAWP.strD_iclass) {
      case 0: // GML
        jsonData = GML_module.ExportToJson();
        break;
      case 1: // LML
        jsonData = LTA_module.ExportToJson();
        break;
      case 2: // LMLPD
        jsonData = LTAP_module.ExportToJson();
        break;
      case 3: // Gouges
        jsonData = GougeModule.ExportToJson();
        break;
      case 4: // Pitting
        jsonData = Pitting_module.ExportToJson();
        break;
      case 5: // Blister and HIC
        jsonData = BlisterHIC_module.ExportToJson();
        break;
      case 6: // Crack-Like Indications
        jsonData = Crack_Module.ExportToJson();
        break;
      case 7: // Dent
        jsonData = Dent_Module.DentExportToJson();
        break;
      case 8: // DentGouge
        jsonData = Dent_Module.DentGougeExportToJson();
        break;
      case 9: // Laminations
        jsonData = Laminations_module.ExportToJson();
        break;
      default:
        break;
    }

    const jsonBlob = new Blob([jsonData], { type: 'application/json' });
    let date = new Date();
    let formattedDate = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    const fileName = prompt('Please enter a file name:', Component_MAWP.strD_class + "_" + formattedDate + '.json');

    if (fileName !== null) {
      saveAs(jsonBlob, fileName);
    }
  }

  openFileDialog() {
    this.isLoading = true;
    const fileInput = this.fileInput.nativeElement;
    fileInput.click();
  }

  importFromJson(files: FileList) {
    const file = files.item(0);
    const reader = new FileReader();

    reader.onload = () => {
      const jsonData = reader.result.toString();
      const jsonDataResult = JSON.parse(jsonData);

      if (!this.importedDataWithErrors(jsonDataResult)) {
        switch (Component_MAWP.strD_iclass) {
          case 0: // GML
            GML_module.ImportFromJson(jsonDataResult);
            this.triggerGMLDataUpdateFormControlsEvent();
            break;
          case 1: // LML
            LTA_module.ImportFromJson(jsonDataResult);
            this.triggerLTA_ApplicabilityCheckboxFormControlUpdateEvent();
            break;
          case 2: // LMLPD
            LTAP_module.ImportFromJson(jsonDataResult);
            this.triggerLTAP_ApplicabilityCheckboxFormControlUpdateEvent();
            break;
          case 3: // Gouges
            GougeModule.ImportFromJson(jsonDataResult);
            this.triggerGouges_ApplicabilityCheckboxFormControlUpdateEvent();
            break;
          case 4: // Pitting
            Pitting_module.ImportFromJson(jsonDataResult);
            this.triggerPitting_ApplicabilityCheckboxFormControlUpdateEvent();
            break;
          case 5: // Blister and HIC
            BlisterHIC_module.ImportFromJson(jsonDataResult);
            this.triggerBlisterAndHIC_ApplicabilityCheckboxAndFormUpdateEvent();
            break;
          case 6: // Crack-Like Indications
            Crack_Module.ImportFromJson(jsonDataResult);
            this.triggerCrackLikeIndications_ApplicabilityCheckboxAndFormUpdateEvent();
            //Updating Allowable stress for the base material, Sigma_a  /* Carlos Acero - 061924 */
            let unit = localStorage.getItem('unitType');
            let unitValue = unit ? Number(unit) : 0; 
            let divider = unitValue === 0 ? 1000 : 1;
            Crack_Module.sngA_stress = Number(Component_MAWP.sngS_stress/divider);

            /*
            Carlos Acero - 071824
            Fix 7717292
            https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7717292
            Keep Component_MAWP.intEquip original value in compMAWPintEquip BehaviorSubject
            */
            this.componentEventService.updateCompMAWPintEquip(jsonDataResult.Component_MAWP.intEquip);
            break;
          case 7:
            Dent_Module.DentImportFromJson(jsonDataResult);
            this.triggerDents_ApplicabilityCheckboxFormControlUpdateEvent();
            break;
          case 8: // DentGouge
            Dent_Module.DentGougeImportFromJson(jsonDataResult);
            this.triggerDentGouge_ApplicabilityCheckboxFormControlUpdateEvent();
            break;
          case 9: // Laminations
            Laminations_module.ImportFromJson(jsonDataResult);
            this.triggerLaminationsApplicabilityCheckboxFormControlUpdateEvent();
            break;
          default:
            break;
        }

        this.triggerMAWPUpdateFormControlsEvent();

        switch (Component_MAWP.strD_iclass) {
          case 4:
            this.triggerPittingInformationUpdateFormControlsEvent();
            break;
          case 3:
            this.triggerGougesDataUpdateFormControlsEvent();
            this.triggerInspectionDataUpdateFormControlsEvent();
            break;
          case 6:
            this.triggerCrackLikeIndicationse_ComponentInformationUpdateFormControlsEvent();
            this.triggerInspectionDataUpdateFormControlsEvent();
            break;
          case 8:
            this.triggerDentGouge_ComponentInformationUpdateFormControlsEvent();
            this.triggerInspectionDataUpdateFormControlsEvent();
            break;
          default:
            this.triggerInspectionDataUpdateFormControlsEvent();
            break;
        }


      }
    };

    reader.readAsText(file);

    this.fileInput.nativeElement.value = null; // reset the input after import
    this.isLoading = false;
  }

  exportButtonGrayedOutBasedOnMatSteps() {
    return !(parseInt(localStorage.getItem('calculationStep')) === 0);
  }

  importButtonGrayedOutBasedOnMatSteps() {
    let calculationStep: number = parseInt(localStorage.getItem('calculationStep'));
    return !(calculationStep >= 1 && calculationStep <= 4);
  }

  importedDataWithErrors(jsonDataResult): boolean {
    if (jsonDataResult.Component_MAWP.strD_iclass.toString() != Component_MAWP.strD_iclass.toString()) {
      this.openErrorModal("Incorrect data file for the assessment!")
      return true;
    }

    if (jsonDataResult.Component_MAWP.intUnit_select != Component_MAWP.intUnit_select) {
      if (Component_MAWP.intUnit_select === 0)
        this.openErrorModal("Software is in US Customary system while the data file is in SI system!")
      else
        this.openErrorModal("Software is in SI system while the data file is in US Customary system!")
      return true;
    }

    return false;
  }

  private openErrorModal(warningMessage: string): void {
    this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: {
        warningMessage: [warningMessage],
        caption: "File Read Error",
        captionCenter: true,
        cancelButtonShow: false,
      },
    });
  }
}