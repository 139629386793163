import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "warning-modal",
  templateUrl: "warning-modal.component.html",
  styleUrls: ["./warning-modal.component.css"],
})
export class WarningModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      warningMessage: [];
      caption: string;
      captionCenter: boolean;
      cancelButtonShow: boolean;
    }
  ) { }
}
