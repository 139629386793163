import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PrintReportModalComponent } from "./print-report-modal/print-report-modal.component";

@Component({
  selector: "print-report",
  templateUrl: "print-report.component.html",
})
export class PrintReportComponent {
  constructor(public dialog: MatDialog) { }

  openWarningModal(): void {
    this.dialog.open(PrintReportModalComponent, {
      width: "600px",
      data: {},
    });
  }
}
