import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Data } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";

import { Component_MAWP } from "../../services/component_mawp";
import { CalcTemplate } from "../../services/calctemplate";
import { FormControlsEventProxyService } from "../../services/formcontrols_eventproxy_service";
import { Utility } from "../../services/utility";
import { MatDialog } from "@angular/material/dialog";
import { WarningModalComponent } from "../warning-modal/warning-modal.component";
import { ComponentEventService } from "../../services/component_event.service";
import { ComponentTypeData } from "../models/component-type";

@Component({
  selector: "mawp",
  templateUrl: "mawp.component.html",
})
export class MAWPComponent implements OnInit {
  public componentMAWP = Component_MAWP;
  public calcTemplate = CalcTemplate;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;
  mawpForm: FormGroup;

  validationList = [];

  // Define the output event
  @Output() summaryCalculated: EventEmitter<any> = new EventEmitter();
  @Output("nextStep") nextStep: EventEmitter<any> = new EventEmitter();

  summary: string;
  result: string;

  storageTank_enabled: boolean;

  mawp_apex_enabled: boolean;
  mawp_axialt_sl_enabled: boolean;
  mawp_snge_c_enabled: boolean;
  mawp_specific_gravity_enabled: boolean;
  mawp_liquid_head_hight_enabled: boolean;
  mawp_allowable_rem_strength_enabled: boolean;
  selectionMade = false;

  sngrk_MAWP_Enabled: boolean;
  b313_y_factor_MAWP_Enabled: boolean;
  intUnit: number;

  public static emitCalCirStressData: EventEmitter<any> = new EventEmitter();
  ComponentTypeData: Data[];

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private formControlsEventProxyService: FormControlsEventProxyService,
    private componentEventService: ComponentEventService,
  ) {
    this.path = activatedRoute.snapshot["_routerState"].url;

    if ([7, 8].indexOf(Component_MAWP.strD_iclass) !== -1) {
      this.ComponentTypeData = [
        { value: "0", text: ComponentTypeData.Cylinder },
        { value: "5", text: ComponentTypeData.B313Piping }
      ];
    } else if ([5].indexOf(Component_MAWP.strD_iclass) !== -1) {
      this.ComponentTypeData = [
        { value: "0", text: ComponentTypeData.Cylinder },
        { value: "2", text: ComponentTypeData.Sphere },
        { value: "3", text: ComponentTypeData.EllipticalHead },
        { value: "4", text: ComponentTypeData.FDHead },
        { value: "5", text: ComponentTypeData.B313Piping }
      ];
    } else if ([6].indexOf(Component_MAWP.strD_iclass) !== -1) {
      this.ComponentTypeData = [
        { value: "0", text: ComponentTypeData.Cylinder },
        { value: "2", text: ComponentTypeData.Sphere },
        { value: "5", text: ComponentTypeData.B313Piping }
      ];
    } else {
      this.ComponentTypeData = [
        { value: "0", text: ComponentTypeData.Cylinder },
        { value: "1", text: ComponentTypeData.ConicalShell },
        { value: "2", text: ComponentTypeData.Sphere },
        { value: "3", text: ComponentTypeData.EllipticalHead },
        { value: "4", text: ComponentTypeData.FDHead },
        { value: "5", text: ComponentTypeData.B313Piping }
      ];
    }

  }

  ngOnInit(): void {
    this.mawpForm = this._formBuilder.group({
      selectedComponentType: [
        {},
        [Validators.required, Validators.min(0), Validators.max(6)],
      ],
      // Generate textboxes
      ...Array.from({ length: 10 }, (_, i) => `textBox${i + 2}_MAWP`).reduce(
        (acc, cur) => {
          acc[cur] = [null, Validators.required];
          return acc;
        },
        {}
      ),
      textBox12_MAWP: [
        { value: null, disabled: !this.mawp_apex_enabled },
        Validators.required,
      ],
      textBox13_MAWP: [
        { value: null, disabled: !this.mawp_axialt_sl_enabled },
        Validators.required,
      ],
      textBox14_MAWP: [
        { value: null, disabled: !this.mawp_liquid_head_hight_enabled },
        Validators.required,
      ],
      textBox15_MAWP: [
        { value: null, disabled: !this.mawp_specific_gravity_enabled },
        Validators.required,
      ],
      textBox16_MAWP: ["0.9", Validators.required],
    });

    Component_MAWP.intEquip = -1;
    this.summary = "";
    this.result = "";
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0;
    Component_MAWP.intUnit_select = this.intUnit;
    this.mawpForm.controls["selectedComponentType"].setValue("-1");

    this.onComponentTypeChange("-1");
    this.setDefaults();

    this.formControlsEventProxyService.getMAWPEventSubject().subscribe((v) => {
      this.updateFormControlsAfterImport();
    });
  }

  selectedComponentType: string;

  setDefaults() {
    this.mawpForm.controls["textBox16_MAWP"].setValue(0.9);
    this.textBox16_MAWP_TextChanged(0.9);
  }

  onComponentTypeChange(numValue): void {
    const componentTypeNumber = parseInt(numValue);
    this.mawpForm.enable();
    this.mawpForm.markAsPristine();
    this.mawpForm.markAsUntouched();

    if (
      this.componentMAWP.strD_iclass == 7 ||
      this.componentMAWP.strD_iclass == 8
    ) {
      // Dents modules
      this.mawp_allowable_rem_strength_enabled = false;
      this.mawpForm.controls.textBox16_MAWP.disable();
    }

    if (this.componentMAWP.strD_iclass == 6) { //Crack-Like
      if (componentTypeNumber == 2) //Sphere 
      {
        this.componentEventService.enableComboBox1_DI_EquipInfo(false);
      } else {
        this.componentEventService.enableComboBox1_DI_EquipInfo(true);
      }
    }
    Component_MAWP.intEquip = componentTypeNumber;
    /*
    Carlos Acero - 071824
    Fix 7717292
    https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7717292
    Keep Component_MAWP.intEquip original value in compMAWPintEquip BehaviorSubject
    */
    this.componentEventService.updateCompMAWPintEquip(componentTypeNumber);

    Component_MAWP.changeComponentType(this.mawpForm);
  }

  calculateSummary(): void {
    Object.keys(this.mawpForm.controls).forEach((key) => {
      this.mawpForm.get(key).markAsDirty();
    });

    let valid = Component_MAWP.calc_Check(this.mawpForm);
    this.validationList = Component_MAWP.validationMessage;
    if (!valid) {
      return;
    }

    if (Component_MAWP.alertMessage.length > 0) {
      this.openModal(Component_MAWP.alertMessage);
    }

    let results = Component_MAWP.MAWP_calc();

    if (Component_MAWP.strD_class == "DentGouge") {
      MAWPComponent.emitCalCirStressData.emit(Component_MAWP.cal_cir_stress);
    }
    this.summaryCalculated.emit(results);
    this.nextStep.emit();
  }

  private openModal(warningMessage: string[]): void {
    this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: {
        warningMessage: warningMessage,
        caption: "Alert",
        captionCenter: false,
        cancelButtonShow: false,
      },
    });
  }

  updateFormControlsAfterImport() {

    Component_MAWP.updateMAWPFormControls(this.mawpForm);
    this.validationList = [];
  }

  textBox2_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) {
      if (Component_MAWP.intEquip == 6) Component_MAWP.sngID = Number(e);
      else Component_MAWP.sngIDo = Number(e);
    }
  }

  textBox3_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) {
      if (Component_MAWP.intEquip == 6) Component_MAWP.sngtc = Number(e);
      else Component_MAWP.sngtnom = Number(e);
    }
  }

  textBox4_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) {
      if (Component_MAWP.intEquip == 6) CalcTemplate.sngMFH = Number(e);
      else Component_MAWP.sngLOSSi = Number(e);
    }
  }

  textBox5_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) {
      if (Component_MAWP.intEquip == 6) CalcTemplate.sngMFH_d = Number(e);
      else Component_MAWP.sngLOSSe = Number(e);
    }
  }

  textBox6_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) {
      if (Component_MAWP.intEquip == 6) Component_MAWP.sngE_L = Number(e);
      else Component_MAWP.sngFCAi = Number(e);
    }
  }

  textBox7_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) {
      if (Component_MAWP.intEquip == 6) Component_MAWP.sngE_c = Number(e);
      else Component_MAWP.sngFCAe = Number(e);
    }
  }

  textBox8_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) {
      if (Component_MAWP.intEquip == 6) Component_MAWP.sngtmin = Number(e);
      else Component_MAWP.sngP_d = Number(e);
    }
  }

  textBox9_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e))
      if (Component_MAWP.intEquip == 6) {
        CalcTemplate.sngRSF_a = Number(e);
      } else {
        Component_MAWP.sngS_stress = Number(e);
      }
      //Updating allowableStressTempS value in Crack Module  /* Carlos Acero - 061424 */
      this.componentEventService.updateAllowableStressTempS(Number(e));
  }

  textBox10_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) Component_MAWP.sngE_L = Number(e);
  }

  textBox11_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) Component_MAWP.sngE_c = Number(e);
  }

  textBox12_MAWP_TextChanged(e) {
    if (!Utility.isTextADouble(e)) return;

    const formValue: number = Number(e);
    const isDent: boolean = Component_MAWP.strD_class == "Dent" || Component_MAWP.strD_class == "DentGouge";
    const comboBox1_MAWP_value = this.mawpForm.controls["selectedComponentType"].value;

    if (isDent) {
      this.handleDentCalculations(formValue);
    } else {
      this.handleOtherCalculations(formValue);
    }

    if (Component_MAWP.strD_class == "Crack" && comboBox1_MAWP_value == 5) {
      Component_MAWP.sngY_b = formValue;
    }
  }

  handleDentCalculations(value) {
    if (Component_MAWP.intEquip === 0) {
      Component_MAWP.sngalpha = value;
    } else if (Component_MAWP.intEquip === 5) {
      Component_MAWP.sngY_b = value;
    }
  }

  handleOtherCalculations(value) {
    if (Component_MAWP.intEquip === 1) {
      Component_MAWP.sngalpha = value;
    } else if (Component_MAWP.intEquip === 4) {
      Component_MAWP.sngrk = value;
    } else if (Component_MAWP.intEquip === 5) {
      Component_MAWP.sngY_b = value;
    }
  }


  textBox13_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) Component_MAWP.sngt_sl = Number(e);
  }

  textBox14_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) {
      Component_MAWP.sngH_L = Number(e);
      // added by shameer
      Component_MAWP.disableFiled(this.mawpForm);
    }
  }

  textBox15_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) Component_MAWP.sngs_g = Number(e);
  }

  textBox16_MAWP_TextChanged(e) {
    if (Utility.isTextADouble(e)) CalcTemplate.sngRSF_a = Number(e);
    else CalcTemplate.sngRSF_a = 0.9;
  }
}
