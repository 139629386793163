import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { Subject } from "rxjs";
import { Component_MAWP } from "../../../services/component_mawp";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CalcTemplate } from "../../../services/calctemplate";
import { Form1 } from "../../../services/form1";
import { LTA_module } from "../../../services/lta_module";
import { BlisterHIC_module } from "../../../services/blisterHIC_module";
import { MatStepper } from "@angular/material/stepper";
import { FormControlsEventProxyService } from "../../../services/formcontrols_eventproxy_service";

@Component({
  selector: "blisters-and-hic-damage",
  templateUrl: "blisters-and-hic-damage.component.html",
})
export class BlistersAndHICDamageComponent implements OnInit {
  blistersHIC_formGroup: FormGroup;
  @ViewChild("stepper") private myStepper: MatStepper;
  inspectionDataCalculate: Subject<boolean> = new Subject();
  applicability: string;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;

  public component_mawp = Component_MAWP;

  summary: string;
  inspectionDataResult: string;
  result: string;
  validationList = [];
  basicValidationLabel: string =
    "Following inputs have invalid information.";

  firstFormGroup = this._formBuilder.group({
    applicabilityCheckbox: ["", Validators.requiredTrue],
  });
  secondFormGroup = this._formBuilder.group({});
  isLinear = true;

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private formControlsEventProxyService: FormControlsEventProxyService
  ) {
    this.BlistersAndHICDamage_Assesment_Setup();
    this.path = activatedRoute.snapshot["_routerState"].url;

    this.blistersHIC_formGroup = this._formBuilder.group({
      radioButton_DI: ["", Validators.required]
    });

  }

  goToComponent() {
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsDirty({
      onlySelf: true,
    });
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsTouched({
      onlySelf: true,
    });

    this.goNextStep();
  }

  getApplicabilityCheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("applicabilityCheckbox") as FormControl;
  }

  getRadioButton_DI_formControl(): FormControl {
    return this.blistersHIC_formGroup.get("radioButton_DI") as FormControl;
  }

  ngOnInit(): void {
    CalcTemplate.reset_data();
    this.applicability = `  
      <ol>  
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The operating temperature is less than 400°F (204°C) for carbon or low alloy steels, or is below the applicable design curve in API 941, whichever is greater. Damage associated with high temperature hydrogen attack is specifically excluded.</li>  
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
        <li>Type A components subject to internal pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness.  Examples are:  
          <ol type="a">  
            <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
            <li>Spherical pressure vessels and storage spheres</li>  
            <li>Spherical, elliptical and torispherical formed heads</li>  
            <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
            <li>Cylindrical atmospheric storage tank shell courses</li>  
          </ol>  
        </li>  
      </ol>  
    `;

    localStorage.setItem('calculationStep', '0'); //this is zero step (Applicability)

    this.formControlsEventProxyService.getBlisterAndHIC_ApplicabilityAndFormEventSubject().subscribe((v) => {
      this.updateFormControls();
    });
  }

  updateFormControls() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    applicabilityCheckbox.setValue(true);

    let radioButton_DI_formControl = this.getRadioButton_DI_formControl();
    if (Component_MAWP.strD_class === "Blister")
      radioButton_DI_formControl.setValue("radioButton1_DI_enabled");
    else if (Component_MAWP.strD_class === "HIC")
      radioButton_DI_formControl.setValue("radioButton2_DI_enabled");
  }

  BlistersAndHICDamage_Assesment_Setup() {
    //Form1.cpp line: 3124
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    Component_MAWP.MAWP_initialize();
    BlisterHIC_module.BlisterHIC_initialize();

    Component_MAWP.strD_class = "BlisterHIC";
    Component_MAWP.strD_iclass = 5;
    Component_MAWP.intUnit_select =
      parseInt(localStorage.getItem("unitType")) || 0;
    Form1.prev_strD_class = "Blister";
  }

  handleSummaryData(data: any) {
    this.summary = data;
  }

  handleInspectionData(data: any) {
    this.inspectionDataResult = data;
  }

  calculateResult(): void {
    this.inspectionDataCalculate.next(true);
  }

  goNextStep() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    if (applicabilityCheckbox.value === true)
      localStorage.setItem('calculationStep', (this.myStepper.selectedIndex + 1).toString());

    this.myStepper.next();
  }

  goPreviousStep() {
    localStorage.setItem('calculationStep', (this.myStepper.selectedIndex - 1).toString());
    this.myStepper.previous();
  }

  goToMAWPComponent() {
    let resultFromValidation: boolean = BlisterHIC_module.check_BlisterHIC_TypeOfDamage(
      this.blistersHIC_formGroup
    );
    this.validationList = BlisterHIC_module.validationMessageTypeOfDamage;
    if (!resultFromValidation) return;
    this.goNextStep();
  }

  onChangeRadioButton1_DI(typeOfMetalLoss: string) {
    if (typeOfMetalLoss == "radioButton1_DI_enabled") {
      LTA_module.intLTA_type = 0;
      Component_MAWP.strD_class = "Blister";
    } else {
      LTA_module.intLTA_type = 1;
      Component_MAWP.strD_class = "HIC";
    }
  }
}
