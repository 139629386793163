import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
    providedIn:'root'
})
export class FormControlsEventProxyService {

    private gmlEventSubject = new EventEmitter();
    private inspectionDataEventSubject = new EventEmitter();   
    private MAWPEventSubject = new EventEmitter();   
    private laminationsApplicabilityEventSubject = new EventEmitter();
    private ltaApplicabilityEventSubject = new EventEmitter();
    private ltapApplicabilityEventSubject = new EventEmitter();
    private dentsApplicabilityEventSubject = new EventEmitter();
    private pittingApplicabilityEventSubject = new EventEmitter();
    private pittingInformationEventSubject = new EventEmitter(); 
    private gougesApplicabilityEventSubject = new EventEmitter();
    private gougesDataEventSubject = new EventEmitter(); 
    private dentGougeApplicabilityEventSubject = new EventEmitter();
    private dentGougeComponentInformationEventSubject = new EventEmitter();
    private blisterAndHIC_ApplicabilityAndFormEventSubject = new EventEmitter();
    private crackLikeIndications_ApplicabilityAndFormEventSubject = new EventEmitter();
    private crackLikeIndicationsComponentInformationEventSubject = new EventEmitter();

    triggerGMLDataUpdateFormControlsEvent(){
        this.gmlEventSubject.emit();
    }

    getGMLApplicabilityEventSubject(): EventEmitter<any> {
        return this.gmlEventSubject;
    }

    triggerInspectionDataEvent() {
        this.inspectionDataEventSubject.emit();   
    }

    getInspectionDataEventSubject(): EventEmitter<any> {
        return this.inspectionDataEventSubject;
    }

    triggerMAWPEvent() {
        this.MAWPEventSubject.emit();
    }

    getMAWPEventSubject(): EventEmitter<any> {
        return this.MAWPEventSubject;
    }

    triggerLaminationsApplicabilityCheckboxFormControlUpdateEvent() {
        this.laminationsApplicabilityEventSubject.emit();
    }

    getLaminationsApplicabilityEventSubject(): EventEmitter<any> {
        return this.laminationsApplicabilityEventSubject;
    }

    triggerLTA_ApplicabilityCheckboxFormControlUpdateEvent() {
        this.ltaApplicabilityEventSubject.emit();
    }

    getLTA_ApplicabilityEventSubject(): EventEmitter<any> {
        return this.ltaApplicabilityEventSubject;
    }

    triggerLTAP_ApplicabilityCheckboxFormControlUpdateEvent() {
        this.ltapApplicabilityEventSubject.emit();
    }

    getLTAP_ApplicabilityEventSubject(): EventEmitter<any> {
        return this.ltapApplicabilityEventSubject;
    }

    triggerDents_ApplicabilityCheckboxFormControlUpdateEvent() {
        this.dentsApplicabilityEventSubject.emit();
    }

    getDents_ApplicabilityEventSubject(): EventEmitter<any> {
        return this.dentsApplicabilityEventSubject;
    }

    triggerPitting_ApplicabilityCheckboxFormControlUpdateEvent() {
        this.pittingApplicabilityEventSubject.emit();
    }

    getPitting_ApplicabilityEventSubject(): EventEmitter<any> {
        return this.pittingApplicabilityEventSubject;
    }

    triggerPittingInformationEvent() {
        this.pittingInformationEventSubject.emit();   
    }

    getPittingInformationEventSubject(): EventEmitter<any> {
        return this.pittingInformationEventSubject;
    }

    triggerGouges_ApplicabilityCheckboxFormControlUpdateEvent() {
        this.gougesApplicabilityEventSubject.emit();
    }

    getGouges_ApplicabilityEventSubject(): EventEmitter<any> {
        return this.gougesApplicabilityEventSubject;
    }

    triggerGougesDataEvent() {
        this.gougesDataEventSubject.emit();   
    }

    getGougesDataEventSubject(): EventEmitter<any> {
        return this.gougesDataEventSubject;
    }

    triggerDentGouge_ApplicabilityCheckboxFormControlUpdateEvent() {
        this.dentGougeApplicabilityEventSubject.emit();
    }

    getDentGouge_ApplicabilityEventSubject(): EventEmitter<any> {
        return this.dentGougeApplicabilityEventSubject;
    }

    triggerDentGouge_ComponentInformationEvent() {
        this.dentGougeComponentInformationEventSubject.emit();
    }

    getDentGouge_ComponentInformationEvent(): EventEmitter<any> {
        return this.dentGougeComponentInformationEventSubject;
    }

    triggerBlisterAndHIC_ApplicabilityCheckboxAndFormUpdateEvent() {
        this.blisterAndHIC_ApplicabilityAndFormEventSubject.emit();
    }

    getBlisterAndHIC_ApplicabilityAndFormEventSubject(): EventEmitter<any> {
        return this.blisterAndHIC_ApplicabilityAndFormEventSubject;
    }

    triggerCrackLikeIndications_ApplicabilityCheckboxAndFormUpdateEvent() {
        this.crackLikeIndications_ApplicabilityAndFormEventSubject.emit();
    }

    getCrackLikeIndications_ApplicabilityAndFormEventSubject(): EventEmitter<any> {
        return this.crackLikeIndications_ApplicabilityAndFormEventSubject;
    }

    triggerCrackLikeIndications_ComponentInformationEvent() {
        this.crackLikeIndicationsComponentInformationEventSubject.emit();
    }

    getCrackLikeIndications_ComponentInformationEvent(): EventEmitter<any> {
        return this.crackLikeIndicationsComponentInformationEventSubject;
    }

}