export class CalcTemplate {
  public static intSCbox: number; // 2s x 2c box
  public static sngFCAml: number; // future corrosion allowance inside the damage area
  public static sngLmsd: number; // distance to major structural discontinuity
  public static sngRSF: number; // remaining strength factor
  public static sngRSF_a: number; // allowable remaining strength factor
  public static sngMAWP_r: number; // reduced MAWP in the circumferential (hoop) direction
  public static sngMAWP_rL: number; // reduced MAWP in the longitudinal direction
  public static sngMFH: number; // maximum fill height
  public static sngMFH_d: number; // design maximum fill height
  public static sngMFH_r: number; // reduced MFH
  public static sngH_f: number; // height of the defect location in tank courses

  //by shameer
  public static src: number;

  public static reset_data(): void {
    this.intSCbox = -1;
    this.sngFCAml = -1;
    this.sngFCAml = -1;
    this.sngLmsd = -1;
    this.sngRSF = -1;
    this.sngRSF_a = 0.9;
    this.sngMAWP_r = -1;
    this.sngMAWP_rL = -1;
    this.sngMFH = -1;
    this.sngMFH_d = -1;
    this.sngMFH_r = -1;
    this.sngH_f = -1;
  }

  public static ReturnCalcTemplateValuesAsHTML(): string {
    let result =
      "intSCbox: " +
      this.intSCbox +
      "</br>" +
      "sngFCAml: " +
      this.sngFCAml +
      "</br>" +
      "sngLmsd: " +
      this.sngLmsd +
      "</br>" +
      "sngRSF: " +
      this.sngRSF +
      "</br>" +
      "sngRSF_a: " +
      this.sngRSF_a +
      "</br>" +
      "sngMAWP_r: " +
      this.sngMAWP_r +
      "</br>" +
      "sngMAWP_rL: " +
      this.sngMAWP_rL +
      "</br>" +
      "sngMFH: " +
      this.sngMFH +
      "</br>" +
      "sngMFH_d: " +
      this.sngMFH_d +
      "</br>" +
      "sngMFH_r: " +
      this.sngMFH_r +
      "</br>" +
      "sngH_f: " +
      this.sngH_f;

    return result;
  }
}
