import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { Component_MAWP } from "../../../services/component_mawp";
import { Subject } from "rxjs";
import { LTA_module } from "../../../services/lta_module";
import { Form1 } from "../../../services/form1";
import { Level1Data } from "../../../services/level1Data";
import { Dent_Module } from "../../../services/dent_module";
import { MatStepper } from "@angular/material/stepper";
import { FormControlsEventProxyService } from "../../../services/formcontrols_eventproxy_service";

@Component({
  selector: "dents",
  templateUrl: "dents.component.html",
})
export class DentsComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;
  inspectionDataCalculate: Subject<boolean> = new Subject();
  public level1Data = Level1Data;

  applicability: string;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;

  summary: string;
  inspectionDataResult: string;
  result: string;

  firstFormGroup = this._formBuilder.group({
    applicabilityCheckbox: ["", Validators.requiredTrue],
  });
  secondFormGroup = this._formBuilder.group({});
  isLinear = true;

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private formControlsEventProxyService: FormControlsEventProxyService
  ) {
    this.path = activatedRoute.snapshot["_routerState"].url;

    this.Dent_Assesment_Setup();
  }

  goToComponent() {
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsDirty({
      onlySelf: true,
    });
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsTouched({
      onlySelf: true,
    });

    this.goNextStep();
  }

  getApplicabilityCheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("applicabilityCheckbox") as FormControl;
  }

  ngOnInit(): void {
    this.summary = "";
    this.result = "";
    this.applicability = `  
      <ol>  
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. See the Help Menu for temperature limit used to define the creep range.</li>
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment. Note that cold work may be produced by the deformation. This cold worked area may be more susceptible to some forms of mechanical and environmental cracking.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>
        <li>The component is a carbon steel cylindrical shell subject only to internal pressure (i.e supplemental loads are assumed to be negligible).</li>
        <li>The dent is an inward deviation of the shell cross section.</li>
        <li>The dent is isolated from other dents and dent-gouge combinations.</li>
      </ol>  
    `;

    localStorage.setItem('calculationStep', '0'); //this is zero step (Applicability)

    this.formControlsEventProxyService.getDents_ApplicabilityEventSubject().subscribe((v) => {
      this.updateApplicabilityCheckboxToChecked();
    });
  }

  updateApplicabilityCheckboxToChecked() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    applicabilityCheckbox.setValue(true);
  }

  goNextStep() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    if (applicabilityCheckbox.value === true)
      localStorage.setItem('calculationStep', (this.myStepper.selectedIndex + 1).toString());

    this.myStepper.next();
  }

  goPreviousStep() {
    localStorage.setItem('calculationStep', (this.myStepper.selectedIndex - 1).toString());
    this.myStepper.previous();
  }

  Dent_Assesment_Setup() {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    Dent_Module.Dent_initialize();

    Component_MAWP.strD_class = "Dent";
    Component_MAWP.strD_iclass = 7;
    Component_MAWP.intUnit_select = parseInt(localStorage.getItem("unitType")) || 0;
    Form1.prev_strD_class = "Dent";
  }

  handleSummaryData(data: any) {
    this.summary = data;
  }

  handleInspectionData(data: any) {
    this.inspectionDataResult = data;
  }

  calculateResult(): void {
    this.inspectionDataCalculate.next(true);
  }
}
