import { formatDate } from "@angular/common";
import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { LTA_module } from "./lta_module";
import { Utility } from "./utility";
import { FormControl } from "@angular/forms";

export class Pitting_module {
  public static sngw_max: number;
  public static sngdia_max: number;
  public static intSide: number;
  public static intChart_no: number;

  public static pittingCalcResult: string;

  public static Pitting_initialize(): void {
    //initialize all the variables
    this.sngw_max = -1;
    this.sngdia_max = -1;
    this.intSide = -1;
    this.intChart_no = -1;

    this.pittingCalcResult = "";
  }

  public static Pit_calc(): string {
    let Rt: number, QQ: number, dia: number, aa1: number;
    let key1, key2, key3, key4, key5, key6, key7, key8: number;

    this.pittingCalcResult = "";

    // calculate minimum measured thickness based on max pit depth & thickness ratio, Rt
    if (Component_MAWP.intEquip !== 6) {
      // non-tanks
      LTA_module.sngtmm = Component_MAWP.sngtrd - this.sngw_max;
      if (this.intSide === 0) {
        // pitting & corrosion are on the same side
        LTA_module.sngRt = LTA_module.sngtmm / Component_MAWP.sngtc;
      } else {
        // pitting & corrosion are on the opposite side
        LTA_module.sngRt =
          (LTA_module.sngtmm - Component_MAWP.sngFCA) / Component_MAWP.sngtc;
      }
    } else {
      // tanks
      LTA_module.sngtmm = Component_MAWP.sngtc - this.sngw_max;
      LTA_module.sngRt = LTA_module.sngtmm / Component_MAWP.sngtc;
    }

    if (LTA_module.sngRt >= 0.2) {
      this.Pit_RSF_calc(); // calculate RSF based on Rt and pit chart selected
      if (CalcTemplate.sngRSF - CalcTemplate.sngRSF_a < -0.00005) {
        // check the condition RSF >= RSFa
        if (Component_MAWP.intEquip !== 6)
          // static head is accounted for in MAWP_r
          CalcTemplate.sngMAWP_r =
            Component_MAWP.sngMAWP *
            (CalcTemplate.sngRSF / CalcTemplate.sngRSF_a) -
            Component_MAWP.sngp_H;
        else
          CalcTemplate.sngMFH_r =
            CalcTemplate.sngH_f +
            (CalcTemplate.sngMFH - CalcTemplate.sngH_f) *
            (CalcTemplate.sngRSF / CalcTemplate.sngRSF_a);
      } else {
        // RSF >= RSFa
        if (Component_MAWP.intEquip !== 6)
          // static head is accounted for in MAWP_r
          CalcTemplate.sngMAWP_r =
            Component_MAWP.sngMAWP - Component_MAWP.sngp_H;
        else CalcTemplate.sngMFH_r = CalcTemplate.sngMFH;
      }
    }

    // First criterion -- Rwt >= 0.2
    this.pittingCalcResult += `Thickness ratio, R_wt = ${LTA_module.sngRt.toFixed(
      2
    )}`;
    if (LTA_module.sngRt >= 0.2) {
      key1 = 0;
      this.pittingCalcResult += "  >=  0.2<br />";
    } else {
      key1 = 1;
      this.pittingCalcResult +=
        ' <  0.2  <font color="red">*Fail*</font><br />';
    }

    // second criterion -- tmm criterion
    if (Component_MAWP.intEquip !== 6) {
      // non-tanks
      if (this.intSide === 0) {
        // pitting & corrosion are on the same side
        aa1 = LTA_module.sngtmm;
      } else {
        // pitting & corrosion are on the opposite side
        aa1 = LTA_module.sngtmm - Component_MAWP.sngFCA;
      }
    } else {
      // tanks
      aa1 = LTA_module.sngtmm;
    }

    if (Component_MAWP.intEquip !== 5) {
      // vessels and tanks
      if (Component_MAWP.intUnit_select === 0) {
        // US customary
        aa1 = Utility.roundDouble(aa1, 1);
        Component_MAWP.buffer = aa1.toFixed(3);
        if (aa1 >= 0.1) {
          key2 = 0;
          this.pittingCalcResult +=
            "Min. measured thickness, tmm = " +
            Component_MAWP.buffer +
            " inch  >=  0.1 inch<br />";
        } else {
          key2 = 1;
          this.pittingCalcResult +=
            "Min. measured thickness, tmm = " +
            Component_MAWP.buffer +
            ' inch  <  0.1 inch  <font color="red">*Fail*</font><br />';
        }
      } else {
        // SI
        Component_MAWP.buffer = aa1.toFixed(1);
        if (aa1 >= 2.5) {
          key2 = 0;
          this.pittingCalcResult +=
            "Min. measured thickness, tmm = " +
            Component_MAWP.buffer +
            " mm  >=  2.5 mm<br />";
        } else {
          key2 = 1;
          this.pittingCalcResult +=
            "Min. measured thickness, tmm = " +
            Component_MAWP.buffer +
            ' mm  <  2.5 mm  <font color="red">*Fail*</font><br />';
        }
      }
    } else {
      // piping
      if (Component_MAWP.intUnit_select === 0) {
        // US customary
        Component_MAWP.buffer = aa1.toFixed(3);
        if (aa1 >= 0.05) {
          key2 = 0;
          this.pittingCalcResult +=
            "Min. measured thickness, tmm = " +
            Component_MAWP.buffer +
            " inch >= 0.05 inch<br />";
        } else {
          key2 = 1;
          this.pittingCalcResult +=
            "Min. measured thickness, tmm = " +
            Component_MAWP.buffer +
            ' inch < 0.05 inch <font color="red">*Fail*</font><br />';
        }
      } else {
        // SI
        Component_MAWP.buffer = aa1.toFixed(1);
        if (aa1 >= 1.3) {
          key2 = 0;
          this.pittingCalcResult +=
            "Min. measured thickness, tmm = " +
            Component_MAWP.buffer +
            " mm >= 1.3 mm<br />";
        } else {
          key2 = 1;
          this.pittingCalcResult +=
            "Min. measured thickness, tmm = " +
            Component_MAWP.buffer +
            ' mm < 1.3 mm <font color="red">*Fail*</font><br />';
        }
      }
    }

    // third criterion -- diameter criterion
    if (LTA_module.sngRt >= CalcTemplate.sngRSF_a) QQ = 50.0;
    else
      QQ =
        1.123 *
        Math.sqrt(
          Math.pow(
            (1.0 - LTA_module.sngRt) /
            (1.0 - LTA_module.sngRt / CalcTemplate.sngRSF_a),
            2
          ) - 1.0
        );
    dia = QQ * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = this.sngdia_max.toFixed(3);
      Component_MAWP.buffer1 = dia.toFixed(3);
    } else {
      // SI
      Component_MAWP.buffer = this.sngdia_max.toFixed(1);
      Component_MAWP.buffer1 = dia.toFixed(1);
    }

    if (this.sngdia_max <= dia) {
      key3 = 0;
      if (Component_MAWP.intUnit_select === 0)
        // US Customary
        this.pittingCalcResult +=
          "Max. pit diameter = " +
          Component_MAWP.buffer +
          " inch <= Max. allowable pit diameter = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      else
        this.pittingCalcResult +=
          "Max. pit diameter = " +
          Component_MAWP.buffer +
          " mm <= Max. allowable pit diameter = " +
          Component_MAWP.buffer1 +
          " mm<br />";
    } else {
      key3 = 1;
      if (Component_MAWP.intUnit_select === 0)
        // US Customary
        this.pittingCalcResult +=
          "Max. pit diameter = " +
          Component_MAWP.buffer +
          " inch > Max. allowable pit diameter = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      else
        this.pittingCalcResult +=
          "Max. pit diameter = " +
          Component_MAWP.buffer +
          " mm > Max. allowable pit diameter = " +
          Component_MAWP.buffer1 +
          " mm<br />";
    }


    // fourth criterion -- RSF >= RSF_a
    Component_MAWP.buffer = CalcTemplate.sngRSF.toFixed(2);
    Component_MAWP.buffer1 = CalcTemplate.sngRSF_a.toFixed(4);
    if (LTA_module.sngRt >= 0.2) {
      key4 = 0;
      if (CalcTemplate.sngRSF - CalcTemplate.sngRSF_a >= -0.00005)
        this.pittingCalcResult +=
          "RSF = " +
          Component_MAWP.buffer +
          " >= RSF_a = " +
          Component_MAWP.buffer1 +
          " ==> No pressure reduction.<br />";
      else
        this.pittingCalcResult +=
          "RSF = " +
          Component_MAWP.buffer +
          " < RSF_a = " +
          Component_MAWP.buffer1 +
          " ==> Pressure reduction may be required.<br />";
    } else {
      key4 = 1;
      this.pittingCalcResult +=
        "Remaining strength factor, RSF, is not calculated because R_wt < 0.2.<br />";
    }

    // fifth criterion -- compare MAWP_r and P_d
    if (LTA_module.sngRt >= 0.2) {
      if (Component_MAWP.intEquip !== 6) {
        // cylinders, cones, spheres, heads, piping
        if (Component_MAWP.intUnit_select === 0) {
          // US Customary
          Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(1);
          Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
        } else {
          // SI
          Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(2);
          Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
        }

        if (CalcTemplate.sngMAWP_r >= Component_MAWP.sngP_d) {
          key5 = 0;
          if (Component_MAWP.intUnit_select === 0)
            // US customary
            this.pittingCalcResult +=
              "MAWP_r = " +
              Component_MAWP.buffer +
              " psi >= P_d = " +
              Component_MAWP.buffer1 +
              " psi ==> Equipment can be operated at P_d.<br />";
          // SI
          else
            this.pittingCalcResult +=
              "MAWP_r = " +
              Component_MAWP.buffer +
              " MPa >= P_d = " +
              Component_MAWP.buffer1 +
              " MPa ==> Equipment can be operated at P_d.<br />";
        } else {
          key5 = 1;
          if (Component_MAWP.intUnit_select === 0)
            // US customary
            this.pittingCalcResult +=
              "MAWP_r = " +
              Component_MAWP.buffer +
              " psi < P_d = " +
              Component_MAWP.buffer1 +
              " psi ==> Equipment can only be operated at MAWP_r.<br />";
          // SI
          else
            this.pittingCalcResult +=
              "MAWP_r = " +
              Component_MAWP.buffer +
              " MPa < P_d = " +
              Component_MAWP.buffer1 +
              " MPa ==> Equipment can only be operated at MAWP_r.<br />";
        }
      } else {
        // tanks
        if (Component_MAWP.intUnit_select === 0) {
          // US Customary
          Component_MAWP.buffer = CalcTemplate.sngMFH_r.toFixed(1);
        } else {
          // SI
          Component_MAWP.buffer = CalcTemplate.sngMFH_r.toFixed(2);
        }
        Component_MAWP.buffer1 = CalcTemplate.sngMFH_d.toFixed(2);
        if (CalcTemplate.sngMFH_r >= CalcTemplate.sngMFH_d) {
          key5 = 0;
          if (Component_MAWP.intUnit_select == 0) {
            // US Customary unit
            this.pittingCalcResult +=
              "MFH_r = " +
              Component_MAWP.buffer +
              " ft  >=  MFH_d = " +
              Component_MAWP.buffer1 +
              " ft ==> Equipment can be operated at MFH_d.<br />";
          } else {
            // SI unit
            this.pittingCalcResult +=
              "MFH_r = " +
              Component_MAWP.buffer +
              " m  >=  MFH_d = " +
              Component_MAWP.buffer1 +
              " m ==> Equipment can be operated at MFH_d.<br />";
          }
        } else {
          key5 = 1;
          if (Component_MAWP.intUnit_select == 0) {
            // US Customary unit
            this.pittingCalcResult +=
              "MFH_r = " +
              Component_MAWP.buffer +
              " ft  <  MFH_d = " +
              Component_MAWP.buffer1 +
              " ft ==> Equipment can be operated at MFH_r.<br />";
          } else {
            // SI unit
            this.pittingCalcResult +=
              "MFH_r = " +
              Component_MAWP.buffer +
              " m  <  MFH_d = " +
              Component_MAWP.buffer1 +
              " m ==> Equipment can be operated at MFH_r.<br />";
          }
        }
      }
    } else {
      key5 = 1;
      this.pittingCalcResult +=
        "Reduced MAWP is not calculated because R_wt < 0.2.<br />";
    }

    this.pittingCalcResult += "<br />";
    if (key1 == 0 && key2 == 0 && key3 == 0 && key4 == 0 && key5 == 0) {
      Component_MAWP.strD_result = "Pass";
      this.pittingCalcResult += `<font color="blue">Pitting passed the Level 1 Assessment.</font> <br /><br />`;
    } else if (key1 == 0 && key2 == 0 && key3 == 0 && key4 == 0 && key5 == 1) {
      Component_MAWP.strD_result = "Pass";
      if (Component_MAWP.intEquip === 6) {
        //tanks
        Component_MAWP.buffer = CalcTemplate.sngMFH_r.toFixed(2);
        if (Component_MAWP.intUnit_select === 0) {
          // US customary
          this.pittingCalcResult +=
            '<font color="orange">Equipment can only be operated at MFH_r = ' +
            Component_MAWP.buffer +
            ' ft. (MFH_r < MFH_d).</font> <br />';
        } // SI
        else {
          this.pittingCalcResult +=
            '<font color="orange">Equipment can only be operated at MFH_r = ' +
            Component_MAWP.buffer +
            ' m. (MFH_r < MFH_d).</font> <br />';
        }
      } else {
        Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(1);
        if (Component_MAWP.intUnit_select === 0) {
          // US customary
          this.pittingCalcResult +=
            '<font color="orange">Equipment can only be operated at MAWP_r = ' +
            Component_MAWP.buffer +
            ' psi. (MAWP_r < P_d).</font> <br />';
        } // SI
        else {
          this.pittingCalcResult +=
            '<font color="orange">Equipment can only be operated at MAWP_r = ' +
            Component_MAWP.buffer +
            ' MPa. (MAWP_r < P_d).</font> <br />';
        }
      }
    } else if (
      key1 == 0 &&
      key2 == 0 &&
      key3 == 1 &&
      key4 == 0 &&
      (key5 == 0 || key5 == 1)
    ) {
      Component_MAWP.strD_result = "Pass";
      if (key5 == 0) {
        this.pittingCalcResult += '<font color="orange">Pitting passed the Level 1 Assessment if all pit diameters are less than</font> <br />';
        this.pittingCalcResult += '<font color="orange">the maximum allowable values in the table below.</font> <br /><br />';
      } else {
        if (Component_MAWP.intEquip === 6) {
          //tanks
          Component_MAWP.buffer = CalcTemplate.sngMFH_r.toFixed(2);
          if (Component_MAWP.intUnit_select === 0) {
            // US customary
            this.pittingCalcResult +=
              "Equipment can only be operated at MFH_r = " +
              Component_MAWP.buffer +
              " ft. if all pit diameters are less than<br />";
          } // SI
          else {
            this.pittingCalcResult +=
              "Equipment can only be operated at MFH_r = " +
              Component_MAWP.buffer +
              " m. if all pit diameters are less than<br />";
          }
        } else {
          Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(1);
          if (Component_MAWP.intUnit_select === 0) {
            // US customary
            this.pittingCalcResult +=
              "Equipment can only be operated at MAWP_r = " +
              Component_MAWP.buffer +
              " psi. if all pit diameters are less than<br />";
          } // SI
          else {
            this.pittingCalcResult +=
              "Equipment can only be operated at MAWP_r = " +
              Component_MAWP.buffer +
              " MPa. if all pit diameters are less than<br />";
          }
        }
        this.pittingCalcResult +=
          "the maximum allowable values in the table below.<br /><br />";
      }

      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        this.pittingCalcResult += "Pit Depth (%wt) &nbsp;&nbsp;&nbsp Maximum Pit Diameter (Inch)<br />";
      }
      else {
        // SI
        this.pittingCalcResult += "Pit Depth (%wt) &nbsp;&nbsp;&nbsp Maximum Pit Diameter (mm)<br />";
      }
      for (let i = 18; i >= 4; i--) {
        // generate max allowable diameter at various pit depth levels
        let Rt = 0.05 * i;
        if (Rt >= LTA_module.sngRt - 0.075) {
          let QQ;
          if (Rt >= CalcTemplate.sngRSF_a) QQ = 50.0;
          else
            QQ =
              1.123 *
              Math.sqrt(
                Math.pow((1.0 - Rt) / (1.0 - Rt / CalcTemplate.sngRSF_a), 2) -
                1.0
              );
          let dia = QQ * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
          if (Component_MAWP.intUnit_select === 0) {
            // US Customary
            Component_MAWP.buffer = ((1.0 - Rt) * 100.0).toFixed(2);
            Component_MAWP.buffer1 = dia.toFixed(3);
          }
          else {
            // SI
            Component_MAWP.buffer = ((1.0 - Rt) * 100.0).toFixed(2);
            Component_MAWP.buffer1 = dia.toFixed(1);
          }
          this.pittingCalcResult +=
            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
            Component_MAWP.buffer +
            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
            Component_MAWP.buffer1 +
            "<br />";
        }
      }
    } else {
      Component_MAWP.strD_result = "Fail";
      this.pittingCalcResult += `<font color="red">Pitting failed Level 1 assessment. <br />Consider performing a Level 2 or Level 3 assessment.</font> <br />`; // modified by shameer
    }

    return this.pittingCalcResult;
  }

  public static Pit_RSF_calc(): void {
    if (this.intChart_no === 0 || this.intChart_no === 1) {
      if (Component_MAWP.intEquip < 2 || Component_MAWP.intEquip > 4) {
        CalcTemplate.sngRSF =
          0.87 +
          0.058333 * LTA_module.sngRt +
          0.25 * Math.pow(LTA_module.sngRt, 2) -
          0.208333 * Math.pow(LTA_module.sngRt, 3);
      } else {
        CalcTemplate.sngRSF =
          0.78 +
          0.291667 * LTA_module.sngRt -
          0.25 * Math.pow(LTA_module.sngRt, 2) +
          0.208333 * Math.pow(LTA_module.sngRt, 3);
      }
    }
    if (this.intChart_no === 2) {
      if (Component_MAWP.intEquip < 2 || Component_MAWP.intEquip > 4) {
        CalcTemplate.sngRSF =
          0.86 -
          0.108333 * LTA_module.sngRt +
          0.625 * Math.pow(LTA_module.sngRt, 2) -
          0.416667 * Math.pow(LTA_module.sngRt, 3);
      } else {
        CalcTemplate.sngRSF =
          0.73 +
          0.341667 * LTA_module.sngRt -
          0.25 * Math.pow(LTA_module.sngRt, 2) +
          0.208333 * Math.pow(LTA_module.sngRt, 3);
      }
    }
    if (this.intChart_no === 3) {
      if (Component_MAWP.intEquip < 2 || Component_MAWP.intEquip > 4) {
        CalcTemplate.sngRSF =
          0.71 +
          0.466667 * LTA_module.sngRt -
          0.375 * Math.pow(LTA_module.sngRt, 2) +
          0.208333 * Math.pow(LTA_module.sngRt, 3);
      } else {
        CalcTemplate.sngRSF = 0.65 + 0.35 * LTA_module.sngRt;
      }
    }
    if (this.intChart_no === 4) {
      if (Component_MAWP.intEquip < 2 || Component_MAWP.intEquip > 4) {
        CalcTemplate.sngRSF =
          0.59 +
          0.658333 * LTA_module.sngRt -
          0.625 * Math.pow(LTA_module.sngRt, 2) +
          0.416667 * Math.pow(LTA_module.sngRt, 3);
      } else {
        CalcTemplate.sngRSF =
          0.49 +
          0.758333 * LTA_module.sngRt -
          0.625 * Math.pow(LTA_module.sngRt, 2) +
          0.416667 * Math.pow(LTA_module.sngRt, 3);
      }
    }
    if (this.intChart_no === 5) {
      if ((Component_MAWP.intEquip < 2) || (Component_MAWP.intEquip > 4)) {
        // cylinder
        CalcTemplate.sngRSF = 0.55 + 0.45 * LTA_module.sngRt;
      } else {
        // sphere
        CalcTemplate.sngRSF = 0.45 + 0.55 * LTA_module.sngRt;
      }
    }
    if (this.intChart_no === 6) {
      if ((Component_MAWP.intEquip < 2) || (Component_MAWP.intEquip > 4)) {
        // cylinder
        CalcTemplate.sngRSF = 0.51 + 0.241667 * LTA_module.sngRt + 0.625 * Math.pow(LTA_module.sngRt, 2) - 0.416667 * Math.pow(LTA_module.sngRt, 3);
      } else {
        // sphere
        CalcTemplate.sngRSF = 0.42 + 0.341667 * LTA_module.sngRt + 0.625 * Math.pow(LTA_module.sngRt, 2) - 0.416667 * Math.pow(LTA_module.sngRt, 3);
      }
    }

    if (this.intChart_no === 7) {
      if ((Component_MAWP.intEquip < 2) || (Component_MAWP.intEquip > 4)) {
        // cylinder
        CalcTemplate.sngRSF = 0.42 + 0.508333 * LTA_module.sngRt + 0.25 * Math.pow(LTA_module.sngRt, 2) - 0.208333 * Math.pow(LTA_module.sngRt, 3);
      } else {
        // sphere
        CalcTemplate.sngRSF = 0.37 + 0.391667 * LTA_module.sngRt + 0.625 * Math.pow(LTA_module.sngRt, 2) - 0.416667 * Math.pow(LTA_module.sngRt, 3);
      }
    }
    if (this.intChart_no === 8) {
      CalcTemplate.sngRSF = LTA_module.sngRt;
    }
  }

  public static Pit_report(): string {
    let report: string = "";

    const format = "dd/MM/yyyy";
    const locale = "en-US";
    const dt1 = new Date();
    const reportDate = formatDate(dt1, format, locale);

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 6</center>";
    report += "<center>Assessment of Pitting Corrosion</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 1</br></br></br>";

    const sRefinery: string = "Facility: ";
    const sPlant: string = "Plant: ";
    const sEquipment: string = "Equipment: ";
    const sAnalyst: string = "Analyst: ";

    report += sRefinery + Component_MAWP.strFacility + "</br>";
    report += sPlant + Component_MAWP.strPlant + "</br>";
    report += sEquipment + Component_MAWP.strEquipment + "</br>";
    report += sAnalyst + Component_MAWP.strAnalyst + "</br></br></br>";

    report += "Applicability and Limitations :</br></br>";

    report += `<ol class="appAndLimitList">
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. The design temperature is less than or equal to the temperature limit in Table 4.1.</li>  
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
        <li>Type A components subject to internal pressure or external pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness. Examples are:  
        <ol type="a" class="appAndLimitList"> 
            <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
            <li>Spherical pressure vessels and storage spheres</li>  
            <li>Spherical, elliptical and torispherical formed heads</li>  
            <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
            <li>Cylindrical atmospheric storage tank shell courses</li>  
          </ol>  
        </li>
        <li>The pitting damage is arrested.</li>  
        <li>The pitting damage is located on only one surface (ID or OD) of the component.</li>  
        <li>The pitting damage is composed of many pits; individual pits or isolated pairs of pits should be evaluated using the LTA assessment procedure.</li>    
      </ol>  
    </br></br>`;

    report += "Component Information :</br></br>";

    const result: string[] = [];

    if (Component_MAWP.intEquip === 0) {
      report += "Component type = Cylinder</br>";
    } else if (Component_MAWP.intEquip === 1) {
      report += "Component type = Cone</br>";
    } else if (Component_MAWP.intEquip === 2) {
      report += "Component type = Sphere</br>";
    } else if (Component_MAWP.intEquip === 3) {
      report += "Component type = Elliptical Head</br>";
    } else if (Component_MAWP.intEquip === 4) {
      report += "Component type = F&D Head</br>";
    } else if (Component_MAWP.intEquip === 5) {
      report += "Component type = B31.3 Piping</br>";
    } else {
      report += "Component type = Tank</br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (Component_MAWP.intEquip !== 6) {
        report += `Design pressure or vessel MAWP (psi) = ${Component_MAWP.sngP_d.toFixed(
          1
        )}</br>`;
      } else {
        report += `Design Maximum Fill Height (ft) = ${CalcTemplate.sngMFH_d.toFixed(
          2
        )}</br>`;
      }

      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-F) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}</br>`;
      } else {
        report += "Design temperature (deg-F) = N/A</br>";
      }

      report += `Inside diameter corrected for FCA as applicable, D (inch) = ${Component_MAWP.sngID.toFixed(
        3
      )}</br>`;

      if (Component_MAWP.intEquip !== 6) {
        // non-tanks
        report += `Current measured nominal (uniform) thickness, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
          3
        )}</br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = ${Component_MAWP.sngFCA.toFixed(
          3
        )}</br>`;
      } else {
        report +=
          "Current measured nominal (uniform) thickness, trd (inch) = N/A";
        report += "Future corrosion allowance, FCA = FCAi + FCAe (inch) = N/A";
      }

      report += `Future corroded thickness, tc (inch) = ${Component_MAWP.sngtc.toFixed(
        3
      )}</br>`;
    } else {
      // SI
      if (Component_MAWP.intEquip !== 6) {
        report += `Design pressure or vessel MAWP (MPa) = ${Component_MAWP.sngP_d.toFixed(
          1
        )}</br>`;
      } else {
        report += `Design Maximum Fill Height (m) = ${CalcTemplate.sngMFH_d.toFixed(
          2
        )}</br>`;
      }

      if (Component_MAWP.sngTemp_d !== -1) {
        report += `Design temperature (deg-C) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}</br>`;
      } else {
        report += "Design temperature (deg-C) = N/A</br>";
      }

      report += `Inside diameter corrected for FCA as applicable, D (mm) = ${Component_MAWP.sngID.toFixed(
        1
      )}</br>`;

      if (Component_MAWP.intEquip !== 6) {
        // non-tanks
        report += `Current measured nominal (uniform) thickness, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
          1
        )}</br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = ${Component_MAWP.sngFCA.toFixed(
          1
        )}</br>`;
      } else {
        report +=
          "Current measured nominal (uniform) thickness, trd (mm) = N/A";
        report +=
          "Future corrosion allowance, FCA = FCAi + FCAe (mm) = N/A</br>";
      }

      report += `Future corroded thickness, tc (mm) = ${Component_MAWP.sngtc.toFixed(
        1
      )}</br>`;
    }

    report += '<div class="pageBreak"></div>';

    // SECOND PAGE

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 6</center>";
    report += "<center>Assessment of Pitting Corrosion</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 2</br></br></br>";

    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      if (Component_MAWP.intEquip != 6) {
        report += `Minimum required thickness, tmin (inch) = ${Component_MAWP.sngtmin.toFixed(
          3
        )}<br>`;
        report += `MAWP of the component based on tc, MAWP (psi) = ${Component_MAWP.sngMAWP.toFixed(
          2
        )}<br>`;
      } else {
        report += `Maximum Fill Height based on tc, MFH (ft) = ${CalcTemplate.sngMFH.toFixed(
          2
        )}<br>`;
      }
    } else {
      // SI
      if (Component_MAWP.intEquip != 6) {
        report += `Minimum required thickness, tmin (mm) = ${Component_MAWP.sngtmin.toFixed(
          1
        )}<br>`;
        report += `MAWP of the component based on tc, MAWP (MPa) = ${Component_MAWP.sngMAWP.toFixed(
          1
        )}<br>`;
      } else {
        report += `Maximum Fill Height based on tc, MFH (m) = ${CalcTemplate.sngMFH.toFixed(
          2
        )}<br>`;
      }
    }
    report += `Longitudinal weld joint efficiency, E_L = ${Component_MAWP.sngE_L.toFixed(
      2
    )}<br>`;
    if (
      Component_MAWP.intEquip == 0 ||
      Component_MAWP.intEquip == 1 ||
      Component_MAWP.intEquip == 5
    ) {
      report += `Circumferential weld joint efficiency, E_C = ${Component_MAWP.sngE_c.toFixed(
        2
      )}<br>`; // modified by shameer c to C
    }

    report += `Allowable remaining strength factor, RSFa = ${CalcTemplate.sngRSF_a.toFixed(
      2
    )}<br><br><br>`;

    report += "Pitting Corrosion Information :<br><br>";
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      report += `Maximum pit depth, w_max (inch) = ${Pitting_module.sngw_max.toFixed(
        3
      )}<br>`;
      report += `Maximum pit diameter, dia_max (inch) = ${Pitting_module.sngdia_max.toFixed(
        3
      )}<br>`;
    } else {
      // SI
      report += `Maximum pit depth, w_max (mm) = ${Pitting_module.sngw_max.toFixed(
        1
      )}<br>`;
      report += `Maximum pit diameter, dia_max (mm) = ${Pitting_module.sngdia_max.toFixed(
        1
      )}<br>`;
    }
    if (Pitting_module.intSide == 0)
      report += "Pitting and corrosion damage location = Same Side<br>";
    else report += "Pitting and corrosion damage location = Opposite Sides<br>";
    if (Component_MAWP.intEquip == 6) {
      if (Component_MAWP.intUnit_select == 0)
        // US Customary
        report += `Distance between the flaw and the tank bottom, H_f (ft) = ${CalcTemplate.sngH_f.toFixed(
          3
        )}<br>`;
      // SI
      else
        report += `Distance between the flaw and the tank bottom, H_f (m) = ${CalcTemplate.sngH_f.toFixed(
          2
        )}<br>`;
    }
    if (Pitting_module.intChart_no < 8)
      report += `Pit chart selected =    Grade ${Pitting_module.intChart_no + 1
        }<br><br><br>`;
    else report += "Pit chart selected =    More than Grade 8<br><br><br>";

    report += "Assessment reports :<br><br>";
    report +=
      "All the conditions in the Applicability and Limitations section are met.<br><br>";

    report += this.pittingCalcResult;

    return report;
  }

  public static ExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      Pitting_module: { ...Pitting_module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static ImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    Pitting_module.Pitting_initialize();

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(Pitting_module, jsonDataResult.Pitting_module);
  }

  public static updatePittingInformationFormControls(
    pittingInformationForm
  ): void {
    let maximumPitDepthFormControl: FormControl =
      pittingInformationForm.get('maximumPitDepth');
    let maximumPitDiameterFormControl: FormControl =
      pittingInformationForm.get('maximumPitDiameter');
    let damageLocationFormControl: FormControl =
      pittingInformationForm.get('damageLocation');
    let pittingChartFormControl: FormControl =
      pittingInformationForm.get('pittingChart');


    if (Pitting_module.sngw_max !== -1)
      maximumPitDepthFormControl.setValue(Pitting_module.sngw_max.toString());

    if (Pitting_module.sngdia_max !== -1)
      maximumPitDiameterFormControl.setValue(Pitting_module.sngdia_max.toString());

    if (Pitting_module.intSide !== -1)
      damageLocationFormControl.setValue(Pitting_module.intSide.toString());

    if (Pitting_module.intChart_no !== -1)
      pittingChartFormControl.setValue(Pitting_module.intChart_no.toString());
  }
}
