import { formatDate } from "@angular/common";
import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { LTA_module } from "./lta_module";
import { Utility } from "./utility";
import { FormControl } from "@angular/forms";

export class GML_module {
  public static validationMessage = [];
  public static intanalysis_type: number = -1; // Analysis type, 0 - average measured thickness, 1 - MAWP and MFH
  public static sngtam: number = -1; // average measured thickness
  public static gmlCalcResult = "";

  GML_module() {
    GML_module.GML_initialize();
  }

  public static GML_initialize(): void {
    //initialize all the variables
    this.intanalysis_type = -1;
    this.sngtam = -1;
    LTA_module.sngtmm = -1;
    CalcTemplate.sngFCAml = -1;
    this.gmlCalcResult = "";
  }

  public static ReturnGMLValuesAsHTML(): string {
    let result =
      "intanalysis_type: " +
      this.intanalysis_type +
      "</br>" +
      "sngtam: " +
      this.sngtam;

    return result;
  }

  public static ExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      LTA_module: { ...LTA_module },
      CalcTemplate: { ...CalcTemplate },
      GML_module: { ...GML_module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static ImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    GML_module.GML_initialize();

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(LTA_module, jsonDataResult.LTA_module);
    Object.assign(GML_module, jsonDataResult.GML_module);
  }

  public static updateInspectionDataFormControls(
    inspectionDataFormGroup
  ): void {
    let radioButton1_DIformControl: FormControl =
      inspectionDataFormGroup.get("radioButton_DI");
    let textBox1_DI_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");
    let textBox2_DI_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");
    let textBox3_DI_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox3_DI");

    if (GML_module.intanalysis_type == 0) {
      radioButton1_DIformControl.setValue("radioButton1_DI_enabled");
      radioButton1_DIformControl.enable();
    }

    if (GML_module.intanalysis_type == 1) {
      radioButton1_DIformControl.setValue("radioButton2_DI_enabled");
      radioButton1_DIformControl.enable();
    }

    if (GML_module.sngtam > 0) {
      textBox1_DI_DIformControl.setValue(GML_module.sngtam);
    }

    if (LTA_module.sngtmm > 0) {
      textBox2_DI_DIformControl.setValue(LTA_module.sngtmm);
    }

    if (CalcTemplate.sngFCAml > 0) {
      textBox3_DI_DIformControl.setValue(CalcTemplate.sngFCAml);
    }
  }

  public static check_GML_Data(inspectionDataFormGroup): boolean {
    let messagesValidationsList: string[] = [];
    let msg: string;

    // Check Analysis Type
    const analysisTypeValid = this.checkAnalysisType(
      inspectionDataFormGroup,
      messagesValidationsList
    );

    // Check TAM
    const tamValid = this.checkTam(
      inspectionDataFormGroup,
      messagesValidationsList,
      msg
    );

    // Check TMM
    const tmmValid = this.checkTmm(
      inspectionDataFormGroup,
      messagesValidationsList,
      msg
    );

    // Check FCAml
    const fcAmlValid = this.checkFCAml(
      inspectionDataFormGroup,
      messagesValidationsList,
      msg
    );

    // Combine all validation results
    const overallValidation =
      analysisTypeValid && tamValid && tmmValid && fcAmlValid;
    this.validationMessage = !overallValidation ? messagesValidationsList : [];
    return overallValidation;
  }

  private static checkAnalysisType(
    inspectionDataFormGroup,
    messagesValidationsList: string[]
  ): boolean {
    let pass_flag = true;
    let radioButton_DIformControl: FormControl =
      inspectionDataFormGroup.get("radioButton_DI");

    if (radioButton_DIformControl.enabled) {
      if (
        GML_module.intanalysis_type != 0 &&
        GML_module.intanalysis_type != 1
      ) {
        messagesValidationsList.push("Type of Analysis must be selected.\n");
        pass_flag = false;
      }
    }
    return pass_flag;
  }

  private static checkTam(
    inspectionDataFormGroup,
    messagesValidationsList,
    msg: string
  ): boolean {
    let pass_flag = true;
    let textBox1_DI_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");

    //textBox1_DI GML_module.sngtam
    if (textBox1_DI_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox1_DI_DIformControl.value)) {
        messagesValidationsList.push(
          "Average measured thickness, tam -" + msg + "\n"
        );
        pass_flag = false;
      } else if (GML_module.sngtam <= 0) {
        messagesValidationsList.push(
          "Average measured thickness, tam - cannot be less than or equal to zero.\n"
        );
        pass_flag = false;
      }
    }

    return pass_flag;
  }

  private static checkTmm(
    inspectionDataFormGroup,
    messagesValidationsList,
    msg: string
  ): boolean {
    let pass_flag = true;
    let textBox2_DI_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");

    if (textBox2_DI_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox2_DI_DIformControl.value)) {
        messagesValidationsList.push(
          "Minimum measured thickness, tmm -" + msg + "\n"
        );
        pass_flag = false;
      } else if (LTA_module.sngtmm <= 0) {
        messagesValidationsList.push(
          "Minimum measured thickness, tmm - cannot be less than or equal to zero.\n"
        );
        pass_flag = false;
      } else if (LTA_module.sngtmm > Component_MAWP.sngtnom) {
        messagesValidationsList.push(
          "Minimum measured thickness, tmm - cannot be greater than tnom\n"
        );
        pass_flag = false;
      } else if (LTA_module.sngtmm > GML_module.sngtam) {
        messagesValidationsList.push(
          "Minimum measured thickness, tmm - cannot be greater than Average measured thickness tam\n"
        );
        pass_flag = false;
      }
    }
    return pass_flag;
  }

  private static checkFCAml(
    inspectionDataFormGroup,
    messagesValidationsList,
    msg: string
  ): boolean {
    let pass_flag = true;
    let textBox3_DI_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox3_DI");

    if (textBox3_DI_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox3_DI_DIformControl.value)) {
        messagesValidationsList.push(
          "Future corrosion allowance inside the damage area, FCAml -" +
            msg +
            "\n"
        );
        pass_flag = false;
      } else if (CalcTemplate.sngFCAml < 0) {
        messagesValidationsList.push(
          "Future corrosion allowance inside the damage area, FCAml - cannot be less than zero.\n"
        );
        pass_flag = false;
      }
    }
    return pass_flag;
  }

  public static GML_calc(): string {
    let aa: number, bb: number, cc: number, t_lim: number;
    let sngMWAP_localnew_r: number; // added by shameer
    let key1: number, key2: number, key3: number;

    let result = "";

    // First criterion -- tam - FCAml >= tmin or MAWPr >= P_d / MFH_r >= MFH_d
    if (GML_module.intanalysis_type === 0) {
      // average thickness approach
      aa = GML_module.sngtam - CalcTemplate.sngFCAml;
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        Component_MAWP.buffer = aa.toFixed(3);
        Component_MAWP.buffer1 = Component_MAWP.sngtmin.toFixed(3);

        //added by shameer
        let pp: number;
        pp = Component_MAWP.sngtmin; // sngtmin value is not reflecting when we take directly, but it is reflecting when assigned to a new variable
        pp = Utility.roundDouble(pp, 3);
        aa = Utility.roundDouble(aa, 3);

        if (aa >= pp) {
          key1 = 0;
          result += `tam - FCAml = ${Component_MAWP.buffer} inch  >=  tmin = ${Component_MAWP.buffer1} inch </br>`;
        } else {
          key1 = 1;
          result += `tam - FCAml = ${Component_MAWP.buffer} inch  <  tmin = ${Component_MAWP.buffer1} inch  <font color="red">*Fail*</font> </br>`;
        }
      } else {
        // SI
        Component_MAWP.buffer = aa.toFixed(1);
        Component_MAWP.buffer1 = Component_MAWP.sngtmin.toFixed(1);

        // added by shameer
        let qq: number;
        qq = Component_MAWP.sngtmin; // sngtmin value is not reflecting when we take directly, but it is reflecting when assigned to a new variable
        qq = Utility.roundDouble(qq, 3);
        aa = Utility.roundDouble(aa, 3);

        if (aa >= qq) {
          key1 = 0;
          result += `tam - FCAml = ${Component_MAWP.buffer} mm  >=  tmin = ${Component_MAWP.buffer1} mm </br>`;
        } else {
          key1 = 1;
          result += `tam - FCAml = ${Component_MAWP.buffer} mm  <  tmin = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font> </br>`;
        }
      }
    } else {
      // MAWP approach - intanalysis_type = 1
      if (Component_MAWP.intEquip !== 6) {
        // not tanks

        // adding by shameer
        switch (Component_MAWP.intEquip) {
          case 0: // for cylinder
            sngMWAP_localnew_r =
              (Component_MAWP.sngS_stress *
                Component_MAWP.sngE_L *
                (GML_module.sngtam - CalcTemplate.sngFCAml)) /
                (Component_MAWP.sngID / 2.0 +
                  0.6 * (GML_module.sngtam - CalcTemplate.sngFCAml)) -
              Component_MAWP.sngp_H;
            break;

          case 1: // for cone
            aa = (Component_MAWP.sngalpha / 360.0) * Math.PI;
            sngMWAP_localnew_r =
              (2.0 *
                Component_MAWP.sngS_stress *
                Component_MAWP.sngE_L *
                (GML_module.sngtam - CalcTemplate.sngFCAml) *
                Math.cos(aa)) /
                (Component_MAWP.sngID +
                  1.2 *
                    (GML_module.sngtam - CalcTemplate.sngFCAml) *
                    Math.cos(aa)) -
              Component_MAWP.sngp_H;
            break;

          case 2: // for sphere
            sngMWAP_localnew_r =
              (2.0 *
                Component_MAWP.sngS_stress *
                Component_MAWP.sngE_L *
                (GML_module.sngtam - CalcTemplate.sngFCAml)) /
                (Component_MAWP.sngID / 2.0 +
                  0.2 * (GML_module.sngtam - CalcTemplate.sngFCAml)) -
              Component_MAWP.sngp_H;
            break;
          case 3: // ASME 2-to-1 head
            sngMWAP_localnew_r =
              (2.0 *
                Component_MAWP.sngS_stress *
                Component_MAWP.sngE_L *
                (GML_module.sngtam - CalcTemplate.sngFCAml)) /
                (Component_MAWP.sngID +
                  0.2 * (GML_module.sngtam - CalcTemplate.sngFCAml)) -
              Component_MAWP.sngp_H;
            break;

          case 4: // F&D head
            Component_MAWP.sngID =
              2.0 *
              (Component_MAWP.sngIDo +
                Component_MAWP.sngLOSSi +
                Component_MAWP.sngFCAi);
            aa =
              0.25 *
              (3.0 +
                Math.sqrt(Component_MAWP.sngID / 2.0 / Component_MAWP.sngrk));
            sngMWAP_localnew_r =
              (2.0 *
                Component_MAWP.sngS_stress *
                Component_MAWP.sngE_L *
                (GML_module.sngtam - CalcTemplate.sngFCAml)) /
                (aa * (Component_MAWP.sngID / 2) +
                  0.2 * (GML_module.sngtam - CalcTemplate.sngFCAml)) -
              Component_MAWP.sngp_H;
            break;

          case 5: // B31 Piping
            sngMWAP_localnew_r =
              (2.0 *
                Component_MAWP.sngS_stress *
                Component_MAWP.sngE_L *
                (GML_module.sngtam - CalcTemplate.sngFCAml)) /
                (Component_MAWP.sngOD -
                  2.0 *
                    Component_MAWP.sngY_b *
                    (GML_module.sngtam - CalcTemplate.sngFCAml)) -
              Component_MAWP.sngp_H;
            break;

          case 6:
            break;
        }

        if (Component_MAWP.intUnit_select === 0) {
          // US customary
          Component_MAWP.buffer = sngMWAP_localnew_r.toFixed(1); // modified by shameer
          Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
        } else {
          // SI
          Component_MAWP.buffer = sngMWAP_localnew_r.toFixed(2); // modified by shameer
          Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
        }

        if (Component_MAWP.intUnit_select === 0) {
          // US Customary
          if (sngMWAP_localnew_r >= Component_MAWP.sngP_d) {
            key1 = 0;
            result += `MAWP_r based on tam - FCAml = ${Component_MAWP.buffer} psi  >=  Equipment Design Pressure, P_d = ${Component_MAWP.buffer1} psi </br>`;
          } else {
            key1 = 1;
            result += `MAWP_r based on tam - FCAml = ${Component_MAWP.buffer} psi  <  Equipment Design Pressure, P_d = ${Component_MAWP.buffer1} psi  <font color="red">*Fail*</font> </br>`;
          }
        } else {
          // SI
          if (sngMWAP_localnew_r >= Component_MAWP.sngP_d) {
            key1 = 0;
            result += `MAWP_r based on tam - FCAml = ${Component_MAWP.buffer} MPa  >=  Equipment Design Pressure, P_d = ${Component_MAWP.buffer1} MPa </br>`;
          } else {
            key1 = 1;
            result += `MAWP_r based on tam - FCAml = ${Component_MAWP.buffer} MPa  <  Equipment Design Pressure, P_d = ${Component_MAWP.buffer1} MPa  <font color="red">*Fail*</font> </br>`;
          }
        }
      } else {
        // tanks
        CalcTemplate.sngMFH_r = CalcTemplate.sngMFH;
        Component_MAWP.buffer = CalcTemplate.sngMFH_r.toFixed(2);
        Component_MAWP.buffer1 = CalcTemplate.sngMFH_d.toFixed(2);
        if (Component_MAWP.intUnit_select === 0) {
          // US Customary
          if (CalcTemplate.sngMFH_r >= CalcTemplate.sngMFH_d) {
            key1 = 0;
            result += `MFH_r based on tam - FCAml = ${Component_MAWP.buffer} ft  >=  Design MFH = ${Component_MAWP.buffer1} ft </br>`;
          } else {
            key1 = 1;
            result += `MFH_r based on tam - FCAml = ${Component_MAWP.buffer} ft  <  Design MFH = ${Component_MAWP.buffer1} ft  <font color="red">*Fail*</font> </br>`;
          }
        } else {
          // SI
          if (CalcTemplate.sngMFH_r >= CalcTemplate.sngMFH_d) {
            key1 = 0;
            result += `MFH_r based on tam - FCAml = ${Component_MAWP.buffer} m  >=  Design MFH = ${Component_MAWP.buffer1} m </br>`;
          } else {
            key1 = 1;
            result += `MFH_r based on tam - FCAml = ${Component_MAWP.buffer} m  <  Design MFH = ${Component_MAWP.buffer1} m  <font color="red">*Fail*</font> </br>`;
          }
        }
      }
    }
    // Second criterion -- tmm - FCAml >= limiting t based on equipment type
    if (Component_MAWP.intEquip !== 6) {
      // non-tank
      t_lim = 0.2 * Component_MAWP.sngtnom;
    } else {
      // tank
      t_lim = 0.2 * Component_MAWP.sngtc;
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (Component_MAWP.intEquip !== 5) {
        // non-piping
        if (t_lim < 0.1) t_lim = 0.1;
        Component_MAWP.buffer = t_lim.toFixed(3);
        result += `Limiting t, t_lim = max[0.2 * tnom, 0.1 inch] = ${Component_MAWP.buffer} inch </br>`;
      } else {
        // piping
        if (t_lim < 0.05) t_lim = 0.05;
        Component_MAWP.buffer = t_lim.toFixed(3);
        result += `Limiting t, t_lim = max[0.2 * tnom, 0.05 inch] = ${Component_MAWP.buffer} inch </br>`;
      }
    } else {
      // SI
      if (Component_MAWP.intEquip !== 5) {
        // non-piping
        if (t_lim < 2.5) t_lim = 2.5;
        Component_MAWP.buffer = t_lim.toFixed(1);
        result += `Limiting t, t_lim = max[0.2 * tnom, 2.5 mm] = ${Component_MAWP.buffer} mm </br>`;
      } else {
        // piping
        if (t_lim < 1.3) t_lim = 1.3;
        Component_MAWP.buffer = t_lim.toFixed(1);
        result += `Limiting t, t_lim = max[0.2 * tnom, 1.3 mm] = ${Component_MAWP.buffer} mm </br>`;
      }
    }
    // added by shameer - start
    if (Component_MAWP.intEquip !== 6) {
      // non tank
      let aa = 0.9 * GML_module.sngtam;
      let bb = LTA_module.sngtmm - CalcTemplate.sngFCAml;
      let cc = GML_module.sngtam - CalcTemplate.sngFCAml;

      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        aa = Utility.roundDouble(aa, 3);
        bb = Utility.roundDouble(bb, 3);
        cc = Utility.roundDouble(cc, 3);
        LTA_module.sngtmm = Utility.roundDouble(LTA_module.sngtmm, 3);
        Component_MAWP.sngtmin = Utility.roundDouble(Component_MAWP.sngtmin, 3);
        GML_module.sngtam = Utility.roundDouble(GML_module.sngtam, 3);

        let kk;
        kk = Component_MAWP.sngtmin; // sngtmin value is not reflecting when we take directly, but it is reflecting when assigned to new variable
        kk = Utility.roundDouble(kk, 3);

        Component_MAWP.buffer = cc.toFixed(3);
        Component_MAWP.buffer1 = kk.toFixed(3);

        if (cc >= kk) {
          if (LTA_module.sngtmm >= aa) {
            Component_MAWP.buffer1 = aa.toFixed(3);
            result +=
              "tam - FCAml = " +
              Component_MAWP.buffer +
              " inch >=  tmin and tmm >= 0.9 * tam = " +
              Component_MAWP.buffer1 +
              " inch </br>";
            key3 = 0;
          } else {
            if (bb >= kk) {
              Component_MAWP.buffer1 = bb.toFixed(3);
              result +=
                "tam - FCAml = " +
                Component_MAWP.buffer +
                " inch >=  tmin and tmm - FCAml = " +
                Component_MAWP.buffer1 +
                " inch >= tmin  </br>";
              key3 = 0;
            } else {
              Component_MAWP.buffer = bb.toFixed(3);
              Component_MAWP.buffer1 = aa.toFixed(3);
              result +=
                "tmm - FCAml = " +
                Component_MAWP.buffer +
                " inch <  tmin and tmm < 0.9 * tam = " +
                Component_MAWP.buffer1 +
                ` inch <font color="red">*Fail*</font> </br>`;
              key3 = 1;
            }
          }
         } else {  //commented asper new requirement as getting duplicated (1st line and 3rd line in results screen)
        //   result +=
        //     "tam - FCAml = " +
        //     Component_MAWP.buffer +
        //     " inch <  tmin = " +
        //     Component_MAWP.buffer1 +
        //     ` inch <font color="red">*Fail*</font> </br>`;
        //   key3 = 1;
         }
      } else {
        // SI

        aa = Utility.roundDouble(aa, 3);
        bb = Utility.roundDouble(bb, 3);
        cc = Utility.roundDouble(cc, 3);
        LTA_module.sngtmm = Utility.roundDouble(LTA_module.sngtmm, 3);
        Component_MAWP.sngtmin = Utility.roundDouble(Component_MAWP.sngtmin, 3);
        GML_module.sngtam = Utility.roundDouble(GML_module.sngtam, 3);

        let kk: number;
        kk = Component_MAWP.sngtmin;
        kk = Utility.roundDouble(kk, 3);

        Component_MAWP.buffer = cc.toFixed(1);
        Component_MAWP.buffer1 = kk.toFixed(1);

        if (cc >= kk) {
          if (LTA_module.sngtmm >= aa) {
            Component_MAWP.buffer1 = aa.toFixed(1);
            result += `tam - FCAml = ${Component_MAWP.buffer} mm >=  tmin and tmm >= 0.9 * tam = ${Component_MAWP.buffer1} mm </br>`;
            key3 = 0;
          } else {
            if (bb >= kk) {
              Component_MAWP.buffer1 = bb.toFixed(1);
              result += `tam - FCAml = ${Component_MAWP.buffer} mm >=  tmin and tmm - FCAml = ${Component_MAWP.buffer1} mm >= tmin  </br>`;
              key3 = 0;
            } else {
              Component_MAWP.buffer = bb.toFixed(1);
              Component_MAWP.buffer1 = aa.toFixed(1);
              result += `tmm - FCAml = ${Component_MAWP.buffer} mm <  tmin and tmm < 0.9 * tam = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font> </br>`;
              key3 = 1;
            }
          }
         } else {  //commented asper new requirement as getting duplicated (1st line and 3rd line in results screen)
        //   result += `tam - FCAml = ${Component_MAWP.buffer} mm <  tmin = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font> </br>`;
        //   key3 = 1;
         }
      }
    }
    //added by shameer - end

    if (Component_MAWP.intEquip !== 6) {
      // non tank
      let aa = 0.5 * Component_MAWP.sngtmin;
      if (aa < t_lim) aa = t_lim;
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        Component_MAWP.buffer = (
          LTA_module.sngtmm - CalcTemplate.sngFCAml
        ).toFixed(3);
        Component_MAWP.buffer1 = aa.toFixed(3);
        if (LTA_module.sngtmm - CalcTemplate.sngFCAml >= aa) {
          key2 = 0;
          result += `tmm - FCAml = ${Component_MAWP.buffer} inch  >=  max[0.5 * tmin, t_lim] = ${Component_MAWP.buffer1} inch </br>`;
        } else {
          key2 = 1;
          result += `tmm - FCAml = ${Component_MAWP.buffer} inch  <  max[0.5 * tmin, t_lim] = ${Component_MAWP.buffer1} inch  <font color="red">*Fail*</font> </br>`;
        }
      } else {
        // SI
        Component_MAWP.buffer = (
          LTA_module.sngtmm - CalcTemplate.sngFCAml
        ).toFixed(1);
        Component_MAWP.buffer1 = aa.toFixed(1);
        if (LTA_module.sngtmm - CalcTemplate.sngFCAml >= aa) {
          key2 = 0;
          result += `tmm - FCAml = ${Component_MAWP.buffer} mm  >=  max[0.5 * tmin, t_lim] = ${Component_MAWP.buffer1} mm </br>`;
        } else {
          key2 = 1;
          result += `tmm - FCAml = ${Component_MAWP.buffer} mm  <  max[0.5 * tmin, t_lim] = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font> </br>`;
        }
      }
    } else {
      // tank
      let aa = 0.6 * Component_MAWP.sngtmin;
      if (aa < t_lim) aa = t_lim;
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        Component_MAWP.buffer = (
          LTA_module.sngtmm - CalcTemplate.sngFCAml
        ).toFixed(3);
        Component_MAWP.buffer1 = aa.toFixed(3);
        if (LTA_module.sngtmm - CalcTemplate.sngFCAml >= aa) {
          key2 = 0;
          result += `tmm - FCAml = ${Component_MAWP.buffer} inch  >=  max[0.6 * tmin, t_lim] = ${Component_MAWP.buffer1} inch </br>`;
        } else {
          key2 = 1;
          result += `tmm - FCAml = ${Component_MAWP.buffer} inch  <  max[0.6 * tmin, t_lim] = ${Component_MAWP.buffer1} inch  <font color="red">*Fail*</font> </br>`;
        }
      } else {
        // SI
        Component_MAWP.buffer = (
          LTA_module.sngtmm - CalcTemplate.sngFCAml
        ).toFixed(1);
        Component_MAWP.buffer1 = aa.toFixed(1);
        if (LTA_module.sngtmm - CalcTemplate.sngFCAml >= aa) {
          key2 = 0;
          result += `tmm - FCAml = ${Component_MAWP.buffer} mm  >=  max[0.6 * tmin, t_lim] = ${Component_MAWP.buffer1} mm </br>`;
        } else {
          key2 = 1;
          result += `tmm - FCAml = ${Component_MAWP.buffer} mm  <  max[0.6 * tmin, t_lim] = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font> </br>`;
        }
      }
    }
    if (key1 === 0 && key2 === 0) {
      if (key3 === 1) {
        Component_MAWP.strD_result = "Fail";
        result += `</br> <font color="red">General Metal Loss failed the Level 1 assessment.  </br>Consider performing a General Metal Loss Level 2 or Level 3, or Local Metal Loss assessment.</font> </br>`;
      } else {
        Component_MAWP.strD_result = "Pass";
        result +=
          '</br><font color="blue">General Metal Loss passed Level 1 assessment.</font>  </br>';
      }
    } else {
      Component_MAWP.strD_result = "Fail";
      result +=
        '</br> <font color="red">General Metal Loss failed the Level 1 assessment.  </br>Consider performing a General Metal Loss Level 2 or Level 3, or Local Metal Loss assessment.</font> </br>';
    }

    this.gmlCalcResult = result;

    return result;
  }

  public static GML_report(): string {
    let report: string = "";

    const format = "dd/MM/yyyy";
    const locale = "en-US";
    const dt1 = new Date();
    const reportDate = formatDate(dt1, format, locale);

    report += this.getReportHeader(reportDate);
    report += this.getReportBody(reportDate);

    return report;
  }

  private static getReportHeader(reportDate: string): string {
    let header: string = "";

    header +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 4</center>";
    header += "<center>Assessment of General Metal Loss</center></br></br>";

    header += reportDate + "</br>";
    header += "Page: 1</br></br></br>";

    const sRefinery: string = "Facility: ";
    const sPlant: string = "Plant: ";
    const sEquipment: string = "Equipment: ";
    const sAnalyst: string = "Analyst: ";

    header += sRefinery + Component_MAWP.strFacility + "</br>";
    header += sPlant + Component_MAWP.strPlant + "</br>";
    header += sEquipment + Component_MAWP.strEquipment + "</br>";
    header += sAnalyst + Component_MAWP.strAnalyst + "</br></br></br>";

    header += this.getReportList();

    return header;
  }

  private static getReportList(): string {
    return `  
        <ol class="appAndLimitList">  
          <li>The original design criteria were in accordance with a recognized code or standard.</li>  
          <li>The component is not operating in the creep range. The design temperature is less than or equal to the temperature limit in Table 4.1.</li>  
          <li>The region of metal loss has relatively smooth contours without notches, i.e. negligible local stress concentrations.</li>  
          <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
          <li>Type A components subject to internal pressure or external pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness. Examples are:  
            <ol type="a" class="appAndLimitList">  
              <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
              <li>Spherical pressure vessels and storage spheres</li>  
              <li>Spherical, elliptical and torispherical formed heads</li>  
              <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
              <li>Cylindrical atmospheric storage tank shell courses</li>  
            </ol>  
          </li>  
        </ol>  
        </br></br></br>`;
  }

  private static getReportBody(reportDate: string): string {
    let body: string = "";

    body += this.getComponentInformation();

    body += '<div class="pageBreak"></div>';

    // SECOND PAGE

    body += this.getSecondPageInformation(reportDate);

    body += "Assessment Results :</br></br>";
    body +=
      "All the conditions in the Applicability and Limitations section are met.</br></br>";

    body += this.gmlCalcResult;

    return body;
  }

  private static getComponentInformationUs(): string {
    let report: string = "";
    // US Customary
    if (Component_MAWP.intEquip != 6)
      report += `Design pressure or vessel MAWP (psi) = ${Component_MAWP.sngP_d.toFixed(
        1
      )}</br>`;
    else
      report += `Design Maximum Fill Height (ft) = ${CalcTemplate.sngMFH_d.toFixed(
        2
      )}</br>`;
    if (
      !Number.isNaN(Component_MAWP.sngTemp_d) &&
      Component_MAWP.sngTemp_d != -1
    )
      report += `Design temperature (deg-F) = ${Component_MAWP.sngTemp_d.toFixed(
        2
      )}</br>`;
    else report += "Design temperature (deg-F) = N/A</br>";
    report += `Inside diameter corrected for FCA as applicable, D (inch) = ${Component_MAWP.sngID.toFixed(
      3
    )}</br>`;
    if (Component_MAWP.intEquip != 6) {
      // non-tanks
      report += `Current measured nominal (uniform) thickness, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
        3
      )}</br>`;
      report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = ${Component_MAWP.sngFCA.toFixed(
        3
      )}</br>`;
    } else {
      report +=
        "Current measured nominal (uniform) thickness, trd (inch) = N/A</br>";
      report +=
        "Future corrosion allowance, FCA = FCAi + FCAe (inch) = N/A</br>";
    }
    report += `Future corroded thickness, tc (inch) = ${Component_MAWP.sngtc.toFixed(
      3
    )}</br>`;
    report += `Minimum required thickness, tmin (inch) = ${Component_MAWP.sngtmin.toFixed(
      3
    )}</br>`;
    if (Component_MAWP.intEquip != 6) {
      report += `MAWP of the component based on tc, MAWP (psi) = ${Component_MAWP.sngMAWP.toFixed(
        1
      )}</br>`;
    } else {
      report += `Maximum Fill Height based on tc, MFH (ft) = ${CalcTemplate.sngMFH.toFixed(
        2
      )}</br></br>`;
    }
    return report;
  }

  private static getComponentInformation(): string {
    let report: string = "";
    report += "Component Information :</br></br>";
    if (Component_MAWP.intUnit_select == 0) {
      report += this.getComponentInformationUs();
    } else {
      report += this.getComponentInformationSi();
    }
    return report;
  }

  private static getComponentInformationSi() {
    let report: string = "";
    // SI

    if (Component_MAWP.intEquip != 6)
      report += `Design pressure or vessel MAWP (MPa) = ${Component_MAWP.sngP_d.toFixed(
        2
      )}</br>`;
    else
      report += `Design Maximum Fill Height (m) = ${CalcTemplate.sngMFH_d.toFixed(
        2
      )}</br>`;
    if (
      !Number.isNaN(Component_MAWP.sngTemp_d) &&
      Component_MAWP.sngTemp_d != -1
    )
      report += `Design temperature (deg-C) = ${Component_MAWP.sngTemp_d.toFixed(
        2
      )}</br>`;
    else report += "Design temperature (deg-C) = N/A</br>";
    report += `Inside diameter corrected for FCA as applicable, D (mm) = ${Component_MAWP.sngID.toFixed(
      1
    )}</br>`;
    if (Component_MAWP.intEquip != 6) {
      // non-tanks
      report += `Current measured nominal (uniform) thickness, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
        1
      )}</br>`;
      report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = ${Component_MAWP.sngFCA.toFixed(
        1
      )}</br>`;
    } else {
      report +=
        "Current measured nominal (uniform) thickness, trd (mm) = N/A</br>";
      report += "Future corrosion allowance, FCA = FCAi + FCAe (mm) = N/A</br>";
    }
    report += `Future corroded thickness, tc (mm) = ${Component_MAWP.sngtc.toFixed(
      1
    )}</br>`;
    report += `Minimum required thickness, tmin (mm) = ${Component_MAWP.sngtmin.toFixed(
      1
    )}</br>`;
    if (Component_MAWP.intEquip != 6) {
      report += `MAWP of the component based on tc, MAWP (MPa) = ${Component_MAWP.sngMAWP.toFixed(
        2
      )}</br>`;
    } else {
      report += `Maximum Fill Height based on tc, MFH (m) = ${CalcTemplate.sngMFH.toFixed(
        2
      )}</br></br>`;
    }
    return report;
  }

  private static getSecondPageInformation(reportDate: string): string {
    let report: string = "";
    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 4</center>";
    report += "<center>Assessment of General Metal Loss</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 2</br></br></br>";

    report += `Longitudinal weld joint efficiency, E_L = ${Component_MAWP.sngE_L.toFixed(
      2
    )}</br>`;
    if (Component_MAWP.intEquip <= 1 || Component_MAWP.intEquip >= 5) {
      report += `Circumferential weld joint efficiency, E_C = ${Component_MAWP.sngE_c.toFixed(
        2
      )}</br>`;
    }
    report += `Allowable remaining strength factor, RSFa = ${CalcTemplate.sngRSF_a.toFixed(
      2
    )}</br></br></br>`;

    report += "Metal Loss Information :</br></br>";
    if (GML_module.intanalysis_type == 0)
      report += "Type of analysis = Average measured thickness</br>";
    else report += "Type of analysis = MAWP and MFH</br>";
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      report += `Average measured thickness, tam (inch) = ${GML_module.sngtam.toFixed(
        3
      )}</br>`;
      report += `Minimum measured thickness, tmm (inch) = ${LTA_module.sngtmm.toFixed(
        3
      )}</br>`;
      report += `Future corrosion allowance inside the damage area, FCAml (inch) = ${CalcTemplate.sngFCAml.toFixed(
        3
      )}</br></br></br>`;
    } else {
      // SI
      report += `Average measured thickness, tam (mm) = ${GML_module.sngtam.toFixed(
        1
      )}</br>`;
      report += `Minimum measured thickness, tmm (mm) = ${LTA_module.sngtmm.toFixed(
        1
      )}</br>`;
      report += `Future corrosion allowance inside the damage area, FCAml (mm) = ${CalcTemplate.sngFCAml.toFixed(
        1
      )}</br></br></br>`;
    }
    return report;
  }
}
