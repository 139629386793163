import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { Level1Data } from "./level1Data";
import { LTA_module } from "./lta_module";
import { Location } from "./locationInterface";

export class LTAP_module {
  public static intnum_t_pt: number; // number of thickness measurement points
  public static sngGrid_spacing: number; // grid spacing for thickness profile
  public static grid_yes: number; // switch for grid, -1 = not exist, 0 - exist
  public static grid_ok: number; // checking for positive numbers, -1 = not ok, 0 - ok
  public static sngLamda: number;
  public static P_max5: number;
  public static sngD_factor: number;
  public static dataSource: Location[]; // the data of thickness profile data table kept here for import/export

  LTAP_module() {
    LTAP_module.LTAP_initialize();
  }

  public static LTAP_initialize(): void {
    //initialize all the variables
    this.intnum_t_pt = -1;
    this.sngGrid_spacing = -1;
    this.grid_yes = -1;
    this.grid_ok = 0;
    this.dataSource = [];
  }

  public static Quicksort_indx(Indx: Array<number>, ARR: Array<number>): void {
    let N: number,
      II: number,
      JJ: number,
      L: number,
      IR: number,
      Indxt: number,
      i: number;
    let Q: number;
    N = LTAP_module.intnum_t_pt;
    L = Math.floor(N / 2) + 1;
    IR = N;
    for (i = 0; i <= IR; i++) {
      Indx.push(i);
      Indx[i] = i;
    }
    while (IR > 0) {
      if (L > 1) {
        [L, Indxt, Q] = this.updateL(L, Indx, ARR);
      } else {
        [Indxt, Q, IR] = this.updateIR(Indx, ARR, IR);
        if (IR == 1) {
          Indx[1] = Indxt;
          return;
        }
      }
      [II, JJ] = this.updateIndices(L, IR, Indx, ARR, Q);
      Indx[II] = Indxt;
    }
  }

  private static updateL(
    L: number,
    Indx: Array<number>,
    ARR: Array<number>
  ): [number, number, number] {
    L--;
    let Indxt = Indx[L];
    let Q = ARR[Indxt];
    return [L, Indxt, Q];
  }

  private static updateIR(
    Indx: Array<number>,
    ARR: Array<number>,
    IR: number
  ): [number, number, number] {
    let Indxt = Indx[IR];
    let Q = ARR[Indxt];
    Indx[IR] = Indx[1];
    IR--;
    return [Indxt, Q, IR];
  }

  private static updateIndices(
    L: number,
    IR: number,
    Indx: Array<number>,
    ARR: Array<number>,
    Q: number
  ): [number, number] {
    let II = L;
    let JJ = L * 2;
    while (JJ <= IR) {
      if (JJ < IR && ARR[Indx[JJ]] < ARR[Indx[JJ + 1]]) {
        JJ++;
      }
      if (Q < ARR[Indx[JJ]]) {
        Indx[II] = Indx[JJ];
        II = JJ;
        JJ *= 2;
      } else {
        JJ = IR + 1;
      }
    }
    return [II, JJ];
  }

  public static LTA2_RSF(intEquip: number, dataSource: Location[]): void {
    let gdata: number[] = [],
      x_pos: number[] = [],
      ARR: number[] = [],
      Indx: number[] = [];

    this.initializeArrays(gdata, ARR, x_pos, dataSource);
    LTAP_module.Quicksort_indx(Indx, ARR);
    this.updateSortOrder(Indx);
    this.calculateRSF(gdata, x_pos, Indx, intEquip);
  }

  private static initializeArrays(
    gdata: number[],
    ARR: number[],
    x_pos: number[],
    dataSource: Location[]
  ): void {
    for (let i = 0; i <= LTAP_module.intnum_t_pt; i++) {
      gdata.push(i);
      ARR.push(i);
      x_pos.push(i);
      if (i === 0) continue;
      const item = dataSource[i - 1];
      gdata[i] = parseFloat(item.y.toString()) - CalcTemplate.sngFCAml;
      ARR[i] = gdata[i];
      x_pos[i] = parseFloat(item.x.toString());
    }
  }

  private static updateSortOrder(Indx: number[]): void {
    for (let i = 1; i <= LTAP_module.intnum_t_pt; i++) {
      Level1Data.Sort_order += Indx[i].toString() + "<br />";
    }
  }

  private static calculateRSF(
    gdata: number[],
    x_pos: number[],
    Indx: number[],
    intEquip: number
  ): void {
    LTA_module.sngtmm = gdata[Indx[1]] + CalcTemplate.sngFCAml;
    let min_RSF = 1.0;
    for (let i: number = 1; i <= LTAP_module.intnum_t_pt; i++) {
      min_RSF = this.calculateMinRSF(min_RSF, gdata, x_pos, Indx, i, intEquip);
    }
    CalcTemplate.sngRSF = min_RSF;
    LTA_module.sngss = x_pos[LTAP_module.intnum_t_pt] - x_pos[1];
  }

  private static calculateMinRSF(
    min_RSF: number,
    gdata: number[],
    x_pos: number[],
    Indx: number[],
    i: number,
    intEquip: number
  ): number {
    let area1 = 0.0;
    let area2 = 0.0;
    let high_pt = Indx[i];
    let low_pt = Indx[i];
    do {
      high_pt = high_pt + 1;
      if (high_pt <= LTAP_module.intnum_t_pt) {
        area1 = this.calculateArea1(area1, gdata, x_pos, high_pt);
      }
      low_pt = low_pt - 1;
      if (low_pt >= 1) {
        area2 = this.calculateArea2(area2, gdata, x_pos, low_pt);
      }
      if (low_pt < 1 && high_pt > LTAP_module.intnum_t_pt) {
        break;
      }
      low_pt = Math.max(low_pt, 1);
      high_pt = Math.min(high_pt, LTAP_module.intnum_t_pt);
      let area0 =
        Math.abs(x_pos[high_pt] - x_pos[low_pt]) * Component_MAWP.sngtc;
      let Mt = this.calculateMt(x_pos, high_pt, low_pt, intEquip);
      let area_ratio = (area0 - area1 - area2) / area0;
      let sngRSF: number = (1.0 - area_ratio) / (1.0 - area_ratio / Mt);
      min_RSF = Math.min(min_RSF, sngRSF);
    } while (low_pt >= 1 && high_pt <= LTAP_module.intnum_t_pt);
    return min_RSF;
  }

  private static calculateArea1(
    area1: number,
    gdata: number[],
    x_pos: number[],
    high_pt: number
  ): number {
    return (
      area1 +
      0.5 *
        Math.abs(gdata[high_pt - 1] + gdata[high_pt]) *
        Math.abs(x_pos[high_pt - 1] - x_pos[high_pt])
    );
  }

  private static calculateArea2(
    area2: number,
    gdata: number[],
    x_pos: number[],
    low_pt: number
  ): number {
    return (
      area2 +
      0.5 *
        Math.abs(gdata[low_pt + 1] + gdata[low_pt]) *
        Math.abs(x_pos[low_pt + 1] - x_pos[low_pt])
    );
  }

  private static calculateMt(
    x_pos: number[],
    high_pt: number,
    low_pt: number,
    intEquip: number
  ): number {
    let Mt: number;
    if (intEquip !== -1) {
      LTAP_module.sngLamda =
        (1.285 * (x_pos[high_pt] - x_pos[low_pt])) /
        Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
      if (intEquip <= 1 || intEquip >= 5) {
        if (LTAP_module.sngLamda > 20.0) {
          LTAP_module.sngLamda = 20.0;
        }
        Mt = this.calculateMtForEquip1Or5();
      } else {
        if (LTAP_module.sngLamda > Math.PI * Component_MAWP.sngID) {
          LTAP_module.sngLamda = Math.PI * Component_MAWP.sngID;
        }
        Mt = this.calculateMtForOtherEquip();
      }
    }
    return Mt;
  }

  private static calculateMtForEquip1Or5(): number {
    return (
      1.001 -
      0.014195 * LTAP_module.sngLamda +
      0.2909 * Math.pow(LTAP_module.sngLamda, 2) -
      0.09642 * Math.pow(LTAP_module.sngLamda, 3) +
      0.02089 * Math.pow(LTAP_module.sngLamda, 4) -
      0.003054 * Math.pow(LTAP_module.sngLamda, 5) +
      2.957e-4 * Math.pow(LTAP_module.sngLamda, 6) -
      1.8462e-5 * Math.pow(LTAP_module.sngLamda, 7) +
      7.1553e-7 * Math.pow(LTAP_module.sngLamda, 8) -
      1.5631e-8 * Math.pow(LTAP_module.sngLamda, 9) +
      1.4656e-10 * Math.pow(LTAP_module.sngLamda, 10)
    );
  }

  private static calculateMtForOtherEquip(): number {
    return (
      (1.0005 +
        0.49001 * LTAP_module.sngLamda +
        0.32409 * Math.pow(LTAP_module.sngLamda, 2)) /
      (1.0 +
        0.50144 * LTAP_module.sngLamda -
        0.011067 * Math.pow(LTAP_module.sngLamda, 2))
    );
  }

  public static ExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      LTA_module: { ...LTA_module },
      LTAP_module: { ...LTAP_module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static ImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    LTAP_module.LTAP_initialize();

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(LTA_module, jsonDataResult.LTA_module);
    Object.assign(LTAP_module, jsonDataResult.LTAP_module);
  }
}
