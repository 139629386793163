import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { CalcTemplate } from "../../../services/calctemplate";
import { Subject } from "rxjs";
import { Component_MAWP } from "../../../services/component_mawp";
import { GML_module } from "../../../services/gml_module";
import { Form1 } from "../../../services/form1";
import { MatStepper } from "@angular/material/stepper";
import { FormControlsEventProxyService } from "../../../services/formcontrols_eventproxy_service";

@Component({
  selector: "general-metal-loss",
  templateUrl: "general-metal-loss.component.html",
})
export class GeneralMetalLossComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;
  totalStepsCount: number;
  inspectionDataCalculate: Subject<boolean> = new Subject();
  applicability: string;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;

  public component_mawp = Component_MAWP;

  summary: string;
  inspectionDataResult: string;
  result: string;

  firstFormGroup = this._formBuilder.group({
    applicabilityCheckbox: ["", Validators.requiredTrue],
  });
  secondFormGroup = this._formBuilder.group({});
  isLinear = true;

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private formControlsEventProxyService: FormControlsEventProxyService
  ) {
    this.GML_Assesment_Setup();
    this.path = activatedRoute.snapshot["_routerState"].url;
  }

  goToComponent() {
    let applicabilityCheckbox = this.firstFormGroup.controls["applicabilityCheckbox"];
    applicabilityCheckbox.markAsDirty({
      onlySelf: true,
    });
    applicabilityCheckbox.markAsTouched({
      onlySelf: true,
    });

    this.goNextStep();
  }

  getApplicabilityCheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("applicabilityCheckbox") as FormControl;
  }

  goNextStep() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    if (applicabilityCheckbox.value === true)
      localStorage.setItem(
        "calculationStep",
        (this.myStepper.selectedIndex + 1).toString()
      );

    this.myStepper.next();
  }

  goPreviousStep() {
    localStorage.setItem(
      "calculationStep",
      (this.myStepper.selectedIndex - 1).toString()
    );
    this.myStepper.previous();
  }

  ngOnInit(): void {
    CalcTemplate.reset_data();
    this.applicability = `  
      <ol>  
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. See the Help Menu for temperature limit used to define the creep range.</li>
        <li>The region of metal loss has relatively smooth contours without notches, i.e. negligible local stress concentrations.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
        <li>Type A components subject to internal pressure or external pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness. Examples are:  
          <ol type="a">  
            <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
            <li>Spherical pressure vessels and storage spheres</li>  
            <li>Spherical, elliptical and torispherical formed heads</li>  
            <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
            <li>Cylindrical atmospheric storage tank shell courses</li>  
          </ol>  
        </li>  
      </ol>  
    `;
    localStorage.setItem("calculationStep", "0"); //this is zero step (Applicability)

    this.formControlsEventProxyService
      .getGMLApplicabilityEventSubject()
      .subscribe((v) => {
        this.updateApplicabilityCheckboxToChecked();
      });
  }

  updateApplicabilityCheckboxToChecked() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    applicabilityCheckbox.setValue(true);
  }

  GML_Assesment_Setup() {
    Component_MAWP.MAWP_initialize();
    GML_module.GML_initialize();

    Component_MAWP.strD_class = "GML";
    Component_MAWP.strD_iclass = 0;
    Component_MAWP.intUnit_select = parseInt(localStorage.getItem("unitType")) || 0;
    Form1.prev_strD_class = "GML";
  }

  handleSummaryData(data: any) {
    this.summary = data;
  }

  handleInspectionData(data: any) {
    this.inspectionDataResult = data;
  }

  calculateResult(): void {
    this.inspectionDataCalculate.next(true);
  }
}
