import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { CalcTemplate } from "../../../services/calctemplate";
import { Component_MAWP } from "../../../services/component_mawp";
import { LTA_module } from "../../../services/lta_module";
import { Form1 } from "../../../services/form1";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { Crack_Module } from "../../../services/crack_module";
import { MatStepper } from "@angular/material/stepper";
import { FormControlsEventProxyService } from "../../../services/formcontrols_eventproxy_service";
import { ComponentEventService } from "projects/fitnessforservice/src/services/component_event.service";

@Component({
  selector: "crack-like-indications",
  templateUrl: "crack-like-indications.component.html",
})
export class CrackLikeIndicationsComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;
  inspectionDataCalculate: Subject<boolean> = new Subject();
  additionalInformationCalculate: Subject<boolean> = new Subject();
  updateChart: Subject<boolean> = new Subject();

  applicability: string;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;

  public component_mawp = Component_MAWP;

  summary: string;
  inspectionDataResult: string;
  result: string;

  firstFormGroup = this._formBuilder.group({
    applicabilityCheckbox: ["", Validators.requiredTrue],
  });
  secondFormGroup = this._formBuilder.group({});
  isLinear = true;

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private componentEventService: ComponentEventService,
    private formControlsEventProxyService: FormControlsEventProxyService
  ) {
    this.CrackLikeIndication_Assesment_Setup();
    this.path = activatedRoute.snapshot["_routerState"].url;
  }

  goToComponent() {
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsDirty({
      onlySelf: true,
    });
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsTouched({
      onlySelf: true,
    });

    this.goNextStep();
  }

  getApplicabilityCheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("applicabilityCheckbox") as FormControl;
  }

  goNextStep() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    if (applicabilityCheckbox.value === true)
      localStorage.setItem(
        "calculationStep",
        (this.myStepper.selectedIndex + 1).toString()
      );

    this.myStepper.next();
  }

  goPreviousStep() {
    localStorage.setItem(
      "calculationStep",
      (this.myStepper.selectedIndex - 1).toString()
    );
    this.myStepper.previous();
  }

  ngOnInit(): void {
    CalcTemplate.reset_data();
    this.applicability = `  
      <ol>  
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. See the Help Menu for temperature limit used to define the creep range.</li>  
        <li>Dynamic loading effects are not significant (e.g., earthquake, impact, water hammer, etc.).</li>  
        <li>The crack-like flaw is subject to loading conditions and/or an environment that will 'not' result in crack growth. Consultation with a material/corrosion engineer is recommended.</li>
        <li>For cylindrical and spherical shell components, the flaw is in the axial or circumferential direction.</li>  
        <li>The loading on the component is from pressure that produces only a membrane stress field. Components subject to pressure induced bending stresses and/or supplemental loading are excluded (e.g., head-to-cylinder junction, nozzle intersection).</li>
        <li>The membrane stresses during operation are within the design limits of the original construction code.</li>
        <li>If a component is to be subjected to a pressure test, the component's metal temperature shall be above MAT (Minimum Allowable Temperature) during the test. Re-examination is required after the test to ensure that the flaw has not grown.</li>
        <li>The weld joint geometry is either a Single-V or Double-V configuration.</li>
        <li>The material is carbon steel (P1, Group 1 or 2).</li>
        <li>The weldments are made with an electrode compatible with the base material.</li>
        <li>The fracture toughness has not been degraded due to environment (e.g., fire damage, over-heating, graphitization, etc.)</li>
      </ol>  
    `;
    localStorage.setItem("calculationStep", "0"); //this is zero step (Applicability)

    this.formControlsEventProxyService
      .getCrackLikeIndications_ApplicabilityAndFormEventSubject()
      .subscribe((v) => {
        this.updateApplicabilityCheckboxToChecked();
      });
  }

  updateApplicabilityCheckboxToChecked() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    applicabilityCheckbox.setValue(true);
  }

  CrackLikeIndication_Assesment_Setup() {
    Crack_Module.Crack_initialize();
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();

    Component_MAWP.strD_class = "Crack";
    Component_MAWP.strD_iclass = 6;
    Component_MAWP.intUnit_select = parseInt(localStorage.getItem("unitType")) || 0;
    Form1.prev_strD_class = "Crack";
  }

  handleSummaryData(data: any) {
    this.summary = data;
  }

  handleInspectionData(data: any) {
    this.inspectionDataResult = data;
  }

  calculateAdditionalInformation(): void {
    this.additionalInformationCalculate.next(true);
  }

  calculateResult(): void {
    this.inspectionDataCalculate.next(true);
    this.updateChart.next(Component_MAWP.strD_result == "Pass");
  }

  /*
  Carlos Acero - 071824
  Fix 7717292 
  https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7717292
  Set Component_MAWP.intEquip to its original value when user back from Inspection Data step
  */
  setComponent_MAWPintEquip(): void {
    Component_MAWP.intEquip = this.componentEventService.compMAWPintEquipValue;
  }
}
