// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top_menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #0B2D71 !important;
    color: #FFFFFF !important;
    height: 2.8rem !important;
}

a {
    text-decoration: none !important;
}

.active, a:not(.active):hover {
    background-color: #0066B2 !important;
}

.top_menu__button {
    width: 33%;
    font-family: 'GothamBold', Arial, sans-serif;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./projects/fitnessforservice/src/app/main-top-menu/main-top-menu.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,8BAA8B;IAC9B,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,UAAU;IACV,4CAA4C;IAC5C,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".top_menu {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    background: #0B2D71 !important;\n    color: #FFFFFF !important;\n    height: 2.8rem !important;\n}\n\na {\n    text-decoration: none !important;\n}\n\n.active, a:not(.active):hover {\n    background-color: #0066B2 !important;\n}\n\n.top_menu__button {\n    width: 33%;\n    font-family: 'GothamBold', Arial, sans-serif;\n    font-size: 14px;\n    text-align: center;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
