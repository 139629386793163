import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpComponent } from '../help/help.component';
import { Router } from '@angular/router';
import { ComponentEventService } from '../../services/component_event.service';

@Component({
  selector: 'app-main-top-menu',
  templateUrl: './main-top-menu.component.html',
  styleUrls: [ './main-top-menu.component.css' ]
})
export class MainTopMenuComponent {

  navigationItems!: any[];
  @Input() set navItems (navItems: any[]) {
    if (navItems) {
      this.navigationItems = [...navItems.filter(x => x.title !== 'Assessments')];
      this.helpNavigation = this.navigationItems.find(x => x.path === 'Help');
    }
  }

  helpNavigation: any;

  constructor(public dialog: MatDialog,
              private router: Router,
              private compService: ComponentEventService
  ) { }

  navigate(itm: any): void {
    if (itm?.path !== 'Help') {
      this.router.navigate([itm.path]);
    }
  }

  openSubNavigation(sub: any): void {
    if (sub?.path === 'help') {
      this.openTechnical();
    } else {
      this.router.navigate([`/Help/${sub?.path}`]);
    }
  }

  private openTechnical(): void {
    this.compService.updateHelpDialogModeActive(true);
    this.dialog.open(HelpComponent);
  }
}
