import { Directive, OnDestroy, ElementRef } from "@angular/core";
import { FormControlName } from "@angular/forms";
import { Subscription } from "rxjs";

@Directive({
  selector: "[labelDisable]",
})
export class LabelDisableDirective implements OnDestroy {
  valueSub: Subscription;

  constructor(
    private el: ElementRef,
    private formControlName: FormControlName // Inject FormControlName
  ) {}

  ngOnInit() {
    this.valueSub = this.formControlName.statusChanges.subscribe((value) => {
      this.makeLabelStatusDisabledAsInput(value);
    });
    this.makeLabelStatusDisabledAsInput(this.formControlName.status);
  }

  private makeLabelStatusDisabledAsInput(value) {
    // Get label
    const inputId = this.el.nativeElement.getAttribute("id"),
      label = document.querySelector(`label[for="${inputId}"]`);

    // Toggle `disabled` class
    if (label) {
      if (value == "DISABLED") {
        label.classList.toggle("disabled", true);
      } else {
        label.classList.toggle("disabled", false);
      }
    }
  }

  ngOnDestroy() {
    // Unlisten value changes
    this.valueSub.unsubscribe();
  }
}
