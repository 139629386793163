import { FormControl } from "@angular/forms";
import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { GougeModule } from "./gouge_module";
import { LTA_module } from "./lta_module";
import { Utility } from "./utility";
import { formatDate } from "@angular/common";

export class Dent_Module {
  public static intd_key: number;
  public static sngd_d0: number;
  public static sngd_dp: number;
  public static sngP_op: number;
  public static sngLd: number;
  public static sngLw: number;
  public static sngrd: number;
  public static sngd_g: number;
  public static sngSigma: number;
  public static sngrd1: number;

  public static validationMessage = [];
  public static validationMessageComponentInformation = [];
  public static dentCalcResult = "";
  public static chartBase64ImageUrl = "";
  public static showOutOfRangeAlert: boolean = false;

  public static Dent_Gouge_initialize(): void {
    //initialize all the variables
    Dent_Module.intd_key = -1; // 0-not under pressure, 1-under pressure
    this.sngd_d0 = -1; // dent depth measured not under pressure
    this.sngd_dp = -1; // dent depth measured under pressure
    this.sngP_op = -1; // operating pressure for springback calcs
    this.sngLw = -1; // distance to weld seam
    this.sngLd = -1; // distance to adjacent dent or dent-gouge combination
    GougeModule.sngSMYS = -1; // specified minimum yield strength in ksi
    this.sngd_g = -1; // depth of the gouge in a dent
    this.sngSigma = -1; // circumferential stress
    this.sngrd1 = -1; // Radius at the base of the gouge, added by shameer for dentgouge
    Component_MAWP.sngP_d = -1; // design pressure / vessel MAWP

    this.dentCalcResult = "";
    this.chartBase64ImageUrl = "";
    this.showOutOfRangeAlert = false;

    CalcTemplate.reset_data();
  }

  public static Dent_Gouge_calc(): string {
    // PART1
    // Function to perform dent-gouge Level 1 calculations
    let aa1: number, aa2: number, aa3: number, aa4: number; //added aa4 and key7,key8 by shameer
    let key1: number,
      key2: number,
      key3: number,
      key4: number,
      key5: number,
      key6: number,
      key7: number,
      key8: number;

    //spring-back calcs for depth measurement not under pressure
    CalcTemplate.sngMAWP_r = Component_MAWP.sngMAWP - Component_MAWP.sngp_H;
    if (Dent_Module.intd_key === 0) {
      // not under pressure
      if (Dent_Module.sngP_op >= 0.7 * CalcTemplate.sngMAWP_r) {
        Dent_Module.sngd_dp = 0.7 * Dent_Module.sngd_d0;
      } else {
        Dent_Module.sngd_dp = Dent_Module.sngd_d0;
      }
    }

    this.dentCalcResult = ""; // set up output text file
    // Assuming a method to handle file operations, as TypeScript does not have direct file writing capabilities

    // First criterion (statement) -- adjust dent depth based on spring back calcs as appropriate
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = Dent_Module.sngd_dp.toFixed(3);
      if (Dent_Module.intd_key === 0) {
        this.dentCalcResult += `Maximum depth of the dent in the pressurized condition, d_dp, is calculated  = ${Component_MAWP.buffer} inch<br />`;
      } else {
        this.dentCalcResult += `Maximum depth of the dent in the pressurized condition, d_dp = ${Component_MAWP.buffer} inch<br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer = Dent_Module.sngd_dp.toFixed(1);
      if (Dent_Module.intd_key === 0) {
        this.dentCalcResult += `Maximum depth of the dent in the pressurized condition, d_dp, is calculated  = ${Component_MAWP.buffer} mm<br />`;
      } else {
        this.dentCalcResult += `Maximum depth of the dent in the pressurized condition, d_dp = ${Component_MAWP.buffer} mm<br />`;
      }
    }

    // The commented-out block in the original code is not included in the conversion
    // as it's commented and seems like an alternative implementation
    // Second criterion (statement) -- minimum measured thickness criterion
    LTA_module.sngtmm = Component_MAWP.sngtrd - Dent_Module.sngd_g;
    aa1 = LTA_module.sngtmm - CalcTemplate.sngFCAml;

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (Component_MAWP.intEquip === 5) {
        // if B31.3 Piping is selected
        Component_MAWP.buffer = aa1.toFixed(3);
        Component_MAWP.buffer1 = Math.max(
          0.2 * Component_MAWP.sngtnom,
          0.05
        ).toFixed(3);
        if (aa1 >= Math.max(0.2 * Component_MAWP.sngtnom, 0.05)) {
          key1 = 0;
          this.dentCalcResult += `tmm - FCAml = ${Component_MAWP.buffer} inch >= max(0.2*tnom,0.05 inch) = ${Component_MAWP.buffer1} inch<br />`;
        } else {
          key1 = 1;
          this.dentCalcResult += `tmm - FCAml = ${Component_MAWP.buffer} inch < max(0.2*tnom,0.05 inch) = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font><br />`;
        }
      } else {
        Component_MAWP.buffer = aa1.toFixed(3);
        Component_MAWP.buffer1 = Math.max(
          0.2 * Component_MAWP.sngtnom,
          0.1
        ).toFixed(3);
        if (aa1 >= Math.max(0.2 * Component_MAWP.sngtnom, 0.1)) {
          key1 = 0;
          this.dentCalcResult += `tmm - FCAml = ${Component_MAWP.buffer} inch >= max(0.2*tnom,0.10 inch) = ${Component_MAWP.buffer1} inch<br />`;
        } else {
          key1 = 1;
          this.dentCalcResult += `tmm - FCAml = ${Component_MAWP.buffer} inch < max(0.2*tnom,0.10 inch) = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font><br />`;
        }
      }
    } else {
      // SI
      if (Component_MAWP.intEquip === 5) {
        // if B31.3 Piping is selected
        Component_MAWP.buffer = aa1.toFixed(1);
        Component_MAWP.buffer1 = Math.max(
          0.2 * Component_MAWP.sngtnom,
          1.3
        ).toFixed(1);
        if (aa1 >= Math.max(0.2 * Component_MAWP.sngtnom, 1.3)) {
          key1 = 0;
          this.dentCalcResult += `tmm - FCAml = ${Component_MAWP.buffer} mm >= max(0.2*tnom,1.3 mm) = ${Component_MAWP.buffer1} mm<br />`;
        } else {
          key1 = 1;
          this.dentCalcResult += `tmm - FCAml = ${Component_MAWP.buffer} mm < max(0.2*tnom,1.3 mm) = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font><br />`;
        }
      } else {
        Component_MAWP.buffer = aa1.toFixed(1);
        Component_MAWP.buffer1 = Math.max(
          0.2 * Component_MAWP.sngtnom,
          0.1
        ).toFixed(1);
        if (aa1 >= Math.max(0.2 * Component_MAWP.sngtnom, 2.5)) {
          key1 = 0;
          this.dentCalcResult += `tmm - FCAml = ${Component_MAWP.buffer} mm >= max(0.2*tnom,2.5 mm) = ${Component_MAWP.buffer1} mm<br />`;
        } else {
          key1 = 1;
          this.dentCalcResult += `tmm - FCAml = ${Component_MAWP.buffer} mm < max(0.2*tnom,2.5 mm) = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font><br />`;
        }
      }
    }

    // Third criterion -- distance to structural discontinuity, Lmsd
    aa1 = 1.8 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(3);
      Component_MAWP.buffer1 = aa1.toFixed(3);
      if (CalcTemplate.sngLmsd >= aa1) {
        key2 = 0;
        this.dentCalcResult += `Lmsd = ${Component_MAWP.buffer} inch >= 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch<br />`;
      } else {
        key2 = 1;
        this.dentCalcResult += `Lmsd = ${Component_MAWP.buffer} inch < 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font><br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(1);
      Component_MAWP.buffer1 = aa1.toFixed(1);
      if (CalcTemplate.sngLmsd >= aa1) {
        key2 = 0;
        this.dentCalcResult += `Lmsd = ${Component_MAWP.buffer} mm >= 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm<br />`;
      } else {
        key2 = 1;
        this.dentCalcResult += `Lmsd = ${Component_MAWP.buffer} mm < 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font><br />`;
      }
    }

    // Fourth criterion -- distance to weld, Lw
    aa1 = 2.0 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      aa1 = Math.max(aa1, 1.0);
      Component_MAWP.buffer = Dent_Module.sngLw.toFixed(3);
      Component_MAWP.buffer1 = aa1.toFixed(3);
      if (Dent_Module.sngLw >= aa1) {
        key3 = 0;
        this.dentCalcResult += `Dent-gouge spacing to weld, Lw = ${Component_MAWP.buffer} inch >= max[2 * tc, 1.0 inch] = ${Component_MAWP.buffer1} inch<br />`;
      } else {
        key3 = 1;
        this.dentCalcResult += `Dent-gouge spacing to weld, Lw = ${Component_MAWP.buffer} inch < max[2 * tc, 1.0 inch] = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font><br />`;
      }
    } else {
      // SI
      aa1 = Math.max(aa1, 25.0);
      Component_MAWP.buffer = Dent_Module.sngLw.toFixed(1);
      Component_MAWP.buffer1 = aa1.toFixed(1);
      if (Dent_Module.sngLw >= aa1) {
        key3 = 0;
        this.dentCalcResult += `Dent-gouge spacing to weld, Lw = ${Component_MAWP.buffer} mm >= max[2 * tc, 25.0 mm] = ${Component_MAWP.buffer1} mm<br />`;
      } else {
        key3 = 1;
        this.dentCalcResult += `Dent-gouge spacing to weld, Lw = ${Component_MAWP.buffer} mm < max[2 * tc, 25.0 mm] = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font><br />`;
      }
    }

    // Fifth criterion -- distance to other dent or dent-gouge combination, Ld
    aa1 = 3.6 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = Dent_Module.sngLd.toFixed(3);
      Component_MAWP.buffer1 = aa1.toFixed(3);
      if (Dent_Module.sngLd >= aa1) {
        key4 = 0;
        this.dentCalcResult += `Dent-gouge spacing to the nearest dent-gouge, Ld = ${Component_MAWP.buffer} inch >= 3.6 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch<br />`;
      } else {
        key4 = 1;
        this.dentCalcResult += `Dent-gouge spacing to the nearest dent-gouge, Ld = ${Component_MAWP.buffer} inch < 3.6 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font><br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer = Dent_Module.sngLd.toFixed(1);
      Component_MAWP.buffer1 = aa1.toFixed(1);
      if (Dent_Module.sngLd >= aa1) {
        key4 = 0;
        this.dentCalcResult += `Dent-gouge spacing to the nearest dent-gouge, Ld = ${Component_MAWP.buffer} mm >= 3.6 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm<br />`;
      } else {
        key4 = 1;
        this.dentCalcResult += `Dent-gouge spacing to the nearest dent-gouge, Ld = ${Component_MAWP.buffer} mm < 3.6 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font><br />`;
      }
    }

    // Sixth criterion -- dent-gouge interaction diagram
    aa1 = Dent_Module.sngd_dp / Component_MAWP.sngOD;
    aa2 = Dent_Module.sngd_g / Component_MAWP.sngtc;
    // The specific logic for calculating aa3 based on Dent_Module.sngSigma and GougeModule.sngSMYS
    // needs to be implemented here, as it involves several conditional branches.
    // The following is a simplified placeholder for the actual logic.
    aa3 = this.calculateAA3(Dent_Module.sngSigma, GougeModule.sngSMYS, aa1); // This is a placeholder function

    if (aa2 <= aa3) {
      key5 = 0;
      this.dentCalcResult += `Dent-gouge combination is acceptable per the interaction diagram.<br />`;
    } else {
      let x = Dent_Module.sngd_dp / Component_MAWP.sngOD;
      let y = Dent_Module.sngd_g / Component_MAWP.sngtc;

      key5 = 1;
      this.dentCalcResult += `Dent-gouge combination is un-acceptable per the interaction diagram. <font color="red">*Fail*</font><br />`;

      if (x > 0.05 || y > 0.5) {
        this.dentCalcResult += `<font color="red">Assessment point is out of range!</font><br />`;
      }
    }

    // Additional criterion involving LTA_module and calc_template
    aa1 = LTA_module.sngtmm - CalcTemplate.sngFCAml;
    if (aa1 >= Component_MAWP.sngtc) {
      aa4 = 0.5 * (1 - 0) * Component_MAWP.sngtc; // If tmm - FCAml >= tc then Rt = 0
    } else {
      aa1 = (LTA_module.sngtmm - CalcTemplate.sngFCAml) / Component_MAWP.sngtc;
      aa4 = 0.5 * (1 - aa1) * Component_MAWP.sngtc;
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary unit
      Component_MAWP.buffer = aa4.toFixed(3);
      Component_MAWP.buffer1 = Component_MAWP.sngr.toFixed(3);
      if (Component_MAWP.sngr >= aa4) {
        key7 = 0;
        this.dentCalcResult += `gr = ${Component_MAWP.buffer1} inch >= 0.5 * (1 - Rt) * tc = ${Component_MAWP.buffer} inch<br />`;
      } else {
        key7 = 1;
        this.dentCalcResult += `gr = ${Component_MAWP.buffer1} inch < 0.5 * (1 - Rt) * tc = ${Component_MAWP.buffer} inch <font color="red">*Fail*</font><br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer = aa4.toFixed(1);
      Component_MAWP.buffer1 = Component_MAWP.sngr.toFixed(1);
      if (Component_MAWP.sngr >= aa4) {
        key7 = 0;
        this.dentCalcResult += `gr = ${Component_MAWP.buffer1} mm >= 0.5 * (1 - Rt) * tc = ${Component_MAWP.buffer} mm<br />`;
      } else {
        key7 = 1;
        this.dentCalcResult += `gr = ${Component_MAWP.buffer1} mm < 0.5 * (1 - Rt) * tc = ${Component_MAWP.buffer} mm <font color="red">*Fail*</font><br />`;
      }
    }

    // New criterion involving Component_MAWP and Dent_Module
    aa1 = 5 * Component_MAWP.sngtrd;
    aa2 = Dent_Module.sngrd1;

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary unit
      Component_MAWP.buffer1 = aa1.toFixed(3);
      Component_MAWP.buffer = aa2.toFixed(3);
      if (Dent_Module.sngrd1 < aa1) {
        key8 = 1;
        this.dentCalcResult += `Dent sharpest radius of curvature, rd = ${Component_MAWP.buffer} inch < 5 * trd = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font><br />`;
      } else {
        key8 = 0;
        this.dentCalcResult += `Dent sharpest radius of curvature, rd = ${Component_MAWP.buffer} inch >= 5 * trd = ${Component_MAWP.buffer1} inch<br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer1 = aa1.toFixed(1);
      Component_MAWP.buffer = aa2.toFixed(1);
      if (Dent_Module.sngrd1 < aa1) {
        key8 = 1;
        this.dentCalcResult += `Dent sharpest radius of curvature, rd = ${Component_MAWP.buffer} mm < 5 * trd = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font><br />`;
      } else {
        key8 = 0;
        this.dentCalcResult += `Dent sharpest radius of curvature, rd = ${Component_MAWP.buffer} mm >= 5 * trd = ${Component_MAWP.buffer1} mm<br />`;
      }
    }

    // Seventh criterion -- MAWPr >= equipment MAWP
    let MAWP_string: string = "MAWP";
    if (Component_MAWP.intEquip === 0) {
      MAWP_string = "Adjusted MAWP";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(1);
      Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
      if (CalcTemplate.sngMAWP_r >= Component_MAWP.sngP_d) {
        key6 = 0;
        this.dentCalcResult +=
          MAWP_string +
          ` =${Component_MAWP.buffer} psi >= Equipment design pressure = ${Component_MAWP.buffer1} psi<br />`;
      } else {
        key6 = 1;
        this.dentCalcResult +=
          MAWP_string +
          ` =${Component_MAWP.buffer} psi < Equipment design pressure = ${Component_MAWP.buffer1} psi <font color="red">*Fail*</font><br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(2);
      Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
      if (CalcTemplate.sngMAWP_r >= Component_MAWP.sngP_d) {
        key6 = 0;
        this.dentCalcResult +=
          MAWP_string +
          ` =${Component_MAWP.buffer} MPa >= Equipment design pressure = ${Component_MAWP.buffer1} MPa<br />`;
      } else {
        key6 = 1;
        this.dentCalcResult +=
          MAWP_string +
          ` =${Component_MAWP.buffer} MPa < Equipment design pressure = ${Component_MAWP.buffer1} MPa <font color="red">*Fail*</font><br />`;
      }
    }

    // Final assessment
    if (
      key1 === 0 &&
      key2 === 0 &&
      key3 === 0 &&
      key4 === 0 &&
      key5 === 0 &&
      key6 === 0 &&
      key8 === 0
    ) {
      if (key7 === 1) {
        // Special condition added by shameer
        Component_MAWP.strD_result = `Fail`;
        this.dentCalcResult +=
          '<br /><font color="red">Dent-Gouge failed Level 1 assessment. <br />Consider performing a higher Level assessment.</font><br />';
      } else {
        Component_MAWP.strD_result = `Pass`;
        this.dentCalcResult +=
          '<br /><font color="blue">Dent-Gouge passed the Level 1 assessment.</font><br />';
      }
    } else {
      Component_MAWP.strD_result = `Fail`;
      this.dentCalcResult +=
        '<br /><font color="red">Dent-Gouge failed Level 1 assessment. <br />Consider performing a Level 2 or Level 3 assessment.</font><br />';
    }

    return this.dentCalcResult;
  }

  public static dentGougeUpdateChart(chart) {
    chart.data.datasets[0].data = [];
    chart.data.datasets[1].data = [];

    let x = Dent_Module.sngd_dp / Component_MAWP.sngOD;
    let y = Dent_Module.sngd_g / Component_MAWP.sngtc;

    chart.data.datasets[1].data.push({ x: x, y: y });

    if (
      Dent_Module.sngSigma >= 0 &&
      Dent_Module.sngSigma < 0.3 * GougeModule.sngSMYS
    ) {
      chart.options.plugins.title.text =
        "Interaction Diagram (0.0 SMYS =< SIGMA < 0.3 SMYS)";
      chart.data.datasets[0].data = [
        { x: 0.0, y: 0.4 },
        { x: 0.005, y: 0.4 },
        { x: 0.005, y: 0.18 },
        { x: 0.023, y: 0.1387 },
        { x: 0.04, y: 0.0 },
      ];
    } else if (
      Dent_Module.sngSigma >= 0.3 * GougeModule.sngSMYS &&
      Dent_Module.sngSigma < 0.5 * GougeModule.sngSMYS
    ) {
      chart.options.plugins.title.text =
        "Interaction Diagram (0.3 SMYS  =< SIGMA < 0.5 SMYS)";
      chart.data.datasets[0].data = [
        { x: 0.0, y: 0.3 },
        { x: 0.005, y: 0.3 },
        { x: 0.005, y: 0.15 },
        { x: 0.018, y: 0.1219 },
        { x: 0.032, y: 0.0 },
      ];
    } else if (
      Dent_Module.sngSigma >= 0.5 * GougeModule.sngSMYS &&
      Dent_Module.sngSigma <= 0.72 * GougeModule.sngSMYS
    ) {
      chart.options.plugins.title.text =
        "Interaction Diagram (0.5 SMYS =< SIGMA <= 0.72 SMYS)";
      chart.data.datasets[0].data = [
        { x: 0.0, y: 0.2 },
        { x: 0.005, y: 0.2 },
        { x: 0.005, y: 0.1 },
        { x: 0.015, y: 0.08 },
        { x: 0.025, y: 0.0 },
      ];
    } else {
      chart.options.plugins.title.text =
        "Interaction Diagram (Sigma is out of range of 0 and .72)";
    }

    chart.update();
  }

  static calculateAA3(sngSigma: number, sngSMYS: number, aa1: number): number {
    let aa3: number = 0;

    if (sngSigma >= 0.0 && sngSigma < 0.3 * sngSMYS) {
      aa3 = this.calculateAA3ForRange1(aa1);
    }

    if (sngSigma >= 0.3 * sngSMYS && sngSigma < 0.5 * sngSMYS) {
      aa3 = this.calculateAA3ForRange2(aa1);
    }

    if (sngSigma >= 0.5 * sngSMYS && sngSigma < 0.72 * sngSMYS) {
      aa3 = this.calculateAA3ForRange3(aa1);
    }

    return aa3;
  }

  static calculateAA3ForRange1(aa1: number): number {
    if (aa1 >= 0.0 && aa1 <= 0.005) {
      return 0.4;
    } else if (aa1 > 0.005 && aa1 <= 0.023) {
      return 0.18 - (413.0 / 180.0) * (aa1 - 0.005);
    } else if (aa1 > 0.023 && aa1 <= 0.04) {
      return 0.1387 - (1387.0 / 170.0) * (aa1 - 0.023);
    } else if (aa1 > 0.04) {
      return 0.0;
    }
  }

  static calculateAA3ForRange2(aa1: number): number {
    if (aa1 >= 0.0 && aa1 <= 0.005) {
      return 0.3;
    } else if (aa1 > 0.005 && aa1 <= 0.018) {
      return 0.15 - (281.0 / 130.0) * (aa1 - 0.005);
    } else if (aa1 > 0.018 && aa1 <= 0.032) {
      return 0.1219 - (1219.0 / 140.0) * (aa1 - 0.018);
    } else if (aa1 > 0.032) {
      return 0.0;
    }
  }

  static calculateAA3ForRange3(aa1: number): number {
    if (aa1 >= 0.0 && aa1 <= 0.005) {
      return 0.2;
    } else if (aa1 > 0.005 && aa1 <= 0.015) {
      return 0.1 - 2.0 * (aa1 - 0.005);
    } else if (aa1 > 0.015 && aa1 <= 0.025) {
      return 0.08 - 8.0 * (aa1 - 0.015);
    } else if (aa1 > 0.025) {
      return 0.0;
    }
  }

  public static Dent_initialize(): void {
    // Initialize all the variables
    Dent_Module.intd_key = -1; // 0 - not under pressure, 1 - under pressure
    this.sngd_d0 = -1; // Dent depth measured not under pressure
    this.sngd_dp = -1; // Dent depth measured under pressure
    this.sngP_op = -1; // Operating pressure for spring-back calculations
    this.sngLw = -1; // Distance to weld seam
    this.sngLd = -1; // Distance to adjacent dent or dent-gouge combination
    Component_MAWP.sngP_d = -1; // Design pressure / vessel MAWP
    this.sngrd = -1; // Dent sharpest radius of the curvature

    CalcTemplate.reset_data(); // Reset data in calc_template
  }

  public static ReturnDentValuesAsHTML(): string {
    let result =
      "intd_key: " +
      Dent_Module.intd_key +
      "</br>" +
      "sngd_d0: " +
      Dent_Module.sngd_d0 +
      "</br>" +
      "sngd_dp: " +
      Dent_Module.sngd_dp +
      "</br>" +
      "sngP_op: " +
      Dent_Module.sngP_op +
      "</br>" +
      "sngLd: " +
      Dent_Module.sngLd +
      "</br>" +
      "sngLw: " +
      Dent_Module.sngLw +
      "</br>" +
      "sngrd: " +
      Dent_Module.sngrd +
      "</br>" +
      "sngd_g: " +
      Dent_Module.sngd_g +
      "</br>" +
      "sngSigma: " +
      Dent_Module.sngSigma +
      "</br>" +
      "sngrd1: " +
      Dent_Module.sngrd1;

    return result;
  }

  public static Dent_calc(): string {
    // Dent assessment
    // Function to perform dent Level 1 calculations

    let aa: number, bb: number, cc: number;
    let key1: number, key2: number, key3: number, key4: number, key5: number;

    let key6: number;

    // spring-back calcs for depth measurement not under pressure
    CalcTemplate.sngMAWP_r = Component_MAWP.sngMAWP - Component_MAWP.sngp_H;
    if (Dent_Module.intd_key === 0) {
      // not under pressure
      if (this.sngP_op >= 0.7 * CalcTemplate.sngMAWP_r) {
        this.sngd_dp = 0.7 * this.sngd_d0;
      } else {
        this.sngd_dp = this.sngd_d0;
      }
    }

    this.dentCalcResult = "";

    // First criterion (statement) -- adjust dent depth based on spring-back calcs as appropriate
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = this.sngd_dp.toFixed(3);
      if (Dent_Module.intd_key === 0) {
        this.dentCalcResult += `Maximum depth of the dent in the pressurized condition, d_dp, is calculated = ${Component_MAWP.buffer} inch<br />`;
      } else {
        this.dentCalcResult += `Maximum depth of the dent in the pressurized condition, d_dp = ${Component_MAWP.buffer} inch<br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer = this.sngd_dp.toFixed(1);
      if (Dent_Module.intd_key === 0) {
        this.dentCalcResult += `Maximum depth of the dent in the pressurized condition, d_dp, is calculated = ${Component_MAWP.buffer} mm<br />`;
      } else {
        this.dentCalcResult += `Maximum depth of the dent in the pressurized condition, d_dp = ${Component_MAWP.buffer} mm<br />`;
      }
    }

    // second criterion -- distance to structural discontinuity, Lmsd
    aa = 1.8 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (CalcTemplate.sngLmsd >= aa) {
        key1 = 0;
        this.dentCalcResult += `Lmsd = ${Component_MAWP.buffer} inch >= 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch<br />`;
      } else {
        key1 = 1;
        this.dentCalcResult += `Lmsd = ${Component_MAWP.buffer} inch < 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font><br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (CalcTemplate.sngLmsd >= aa) {
        key1 = 0;
        this.dentCalcResult += `Lmsd = ${Component_MAWP.buffer} mm >= 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm<br />`;
      } else {
        key1 = 1;
        this.dentCalcResult += `Lmsd = ${Component_MAWP.buffer} mm < 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font><br />`;
      }
    }

    // third criterion -- distance to weld, Lw
    aa = 2.0 * Component_MAWP.sngtc;

    if (Component_MAWP.intUnit_select === 0) {
      //Fix for SQ
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        if (aa < 1.0) aa = 1.0;
        Component_MAWP.buffer = this.sngLw.toFixed(3);
        Component_MAWP.buffer1 = aa.toFixed(3);
        if (this.sngLw >= aa) {
          key2 = 0;
        } else {
          key2 = 1;
        }
        this.dentCalcResult += `Dent spacing to weld, Lw = ${Component_MAWP.buffer} inch<br />`;
      } else {
        // SI
        if (aa < 25.0) aa = 25.0;
        Component_MAWP.buffer = this.sngLw.toFixed(1);
        Component_MAWP.buffer1 = aa.toFixed(1);
        if (this.sngLw >= aa) {
          key2 = 0;
        } else {
          key2 = 1;
        }
        this.dentCalcResult += `Dent spacing to weld, Lw = ${Component_MAWP.buffer} mm<br />`;
      }
    }

    // fourth criterion -- distance to other dent or dent-gouge combination, Ld
    aa = 3.6 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      if (this.sngLd >= aa) {
        key3 = 0;
        this.dentCalcResult += `Dent spacing to the nearest dent, Ld = ${this.sngLd.toFixed(
          3
        )} inch >= 3.6 * sqrt(ID * tc) = ${aa.toFixed(3)} inch<br />`;
      } else {
        key3 = 1;
        this.dentCalcResult += `Dent spacing to the nearest dent, Ld = ${this.sngLd.toFixed(
          3
        )} inch < 3.6 * sqrt(ID * tc) = ${aa.toFixed(
          3
        )} inch <font color="red">*Fail*</font><br />`;
      }
    } // SI
    else {
      if (this.sngLd >= aa) {
        key3 = 0;
        this.dentCalcResult += `Dent spacing to the nearest dent, Ld = ${this.sngLd.toFixed(
          1
        )} mm >= 3.6 * sqrt(ID * tc) = ${aa.toFixed(1)} mm<br />`;
      } else {
        key3 = 1;
        this.dentCalcResult += `Dent spacing to the nearest dent, Ld = ${this.sngLd.toFixed(
          1
        )} mm < 3.6 * sqrt(ID * tc) = ${aa.toFixed(
          1
        )} mm <font color="red">*Fail*</font><br />`;
      }
    }

    // fifth criterion -- dent depth <= 7% OD
    aa = 0.07 * Component_MAWP.sngOD;
    bb = 0.04 * Component_MAWP.sngOD;
    cc = 2.0 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      if (this.sngLw <= Math.max(cc, 1.0)) {
        if (this.sngd_dp <= bb) {
          key4 = 0;
          this.dentCalcResult += `Dent depth, d_dp = ${this.sngd_dp.toFixed(
            3
          )} inch <= 0.04 * D = ${bb.toFixed(3)} inch<br />`;
        } else {
          key4 = 1;
          this.dentCalcResult += `Dent depth, d_dp = ${this.sngd_dp.toFixed(
            3
          )} inch > 0.04 * D = ${bb.toFixed(
            3
          )} inch <font color="red">*Fail*</font><br />`;
        }
      } else {
        if (this.sngd_dp <= aa) {
          key4 = 0;
          this.dentCalcResult += `Dent depth, d_dp = ${this.sngd_dp.toFixed(
            3
          )} inch <= 0.07 * D = ${aa.toFixed(4)} inch<br />`;
        } else {
          key4 = 1;
          this.dentCalcResult += `Dent depth, d_dp = ${this.sngd_dp.toFixed(
            3
          )} inch > 0.07 * D = ${aa.toFixed(
            3
          )} inch <font color="red">*Fail*</font><br />`;
        }
      }
    } else {
      // SI
      if (this.sngLw <= Math.max(cc, 25.0)) {
        if (this.sngd_dp <= bb) {
          key4 = 0;
          this.dentCalcResult += `Dent depth, d_dp = ${this.sngd_dp.toFixed(
            1
          )} mm <= 0.04 * D = ${bb.toFixed(1)} mm<br />`;
        } else {
          key4 = 1;
          this.dentCalcResult += `Dent depth, d_dp = ${this.sngd_dp.toFixed(
            1
          )} mm > 0.04 * D = ${bb.toFixed(
            1
          )} mm <font color="red">*Fail*</font><br />`;
        }
      } else {
        if (this.sngd_dp <= aa) {
          key4 = 0;
          this.dentCalcResult += `Dent depth, d_dp = ${this.sngd_dp.toFixed(
            1
          )} mm <= 0.07 * D = ${aa.toFixed(4)} mm<br />`;
        } else {
          key4 = 1;
          this.dentCalcResult += `Dent depth, d_dp = ${this.sngd_dp.toFixed(
            1
          )} mm > 0.07 * D = ${aa.toFixed(
            1
          )} mm <font color="red">*Fail*</font><br />`;
        }
      }
    }

    // sixth criterion -- MAWPr >= equipment MAWP
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(1);
      Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
    } else {
      // SI
      Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(2);
      Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
    }

    let MAWP_string: string = "MAWP";
    if (Component_MAWP.intEquip === 0) {
      MAWP_string = "Adjusted MAWP";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (CalcTemplate.sngMAWP_r >= Component_MAWP.sngP_d) {
        key5 = 0;
        this.dentCalcResult +=
          MAWP_string +
          " = " +
          Component_MAWP.buffer +
          " psi >= Equipment design pressure = " +
          Component_MAWP.buffer1 +
          " psi<br />";
      } else {
        key5 = 1;
        this.dentCalcResult +=
          MAWP_string +
          " = " +
          Component_MAWP.buffer +
          " psi < Equipment design pressure = " +
          Component_MAWP.buffer1 +
          ' psi <font color="red">*Fail*</font><br />';
      }
    } else {
      // SI
      if (CalcTemplate.sngMAWP_r >= Component_MAWP.sngP_d) {
        key5 = 0;
        this.dentCalcResult +=
          MAWP_string +
          " = " +
          Component_MAWP.buffer +
          " MPa >= Equipment design pressure = " +
          Component_MAWP.buffer1 +
          " MPa<br />";
      } else {
        key5 = 1;
        this.dentCalcResult +=
          MAWP_string +
          " = " +
          Component_MAWP.buffer +
          " MPa < Equipment design pressure = " +
          Component_MAWP.buffer1 +
          ' MPa <font color="red">*Fail*</font><br />';
      }
    }

    // shameer -start

    // by shameer for check and display of rd <= 5 *trd fail, Note : here we have used the snglw varibale for rd
    aa = 5 * Component_MAWP.sngtrd;
    bb = Dent_Module.sngrd;

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary unit
      Component_MAWP.buffer1 = aa.toFixed(3);
      Component_MAWP.buffer = bb.toFixed(3);
    } else {
      // SI
      Component_MAWP.buffer1 = aa.toFixed(1);
      Component_MAWP.buffer = bb.toFixed(1);
    }

    //if(Dent_Module.sngLw < aa) // commented by shameer for testing
    if (Dent_Module.sngrd < aa) {
      key6 = 1;
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary unit
        this.dentCalcResult +=
          "Dent sharpest radius of curvature, rd = " +
          Component_MAWP.buffer +
          " inch <  5 * trd = " +
          Component_MAWP.buffer1 +
          ' inch <font color="red">*Fail*</font><br />';
      } // SI
      else {
        this.dentCalcResult +=
          "Dent sharpest radius of curvature, rd = " +
          Component_MAWP.buffer +
          " mm <  5 * trd = " +
          Component_MAWP.buffer1 +
          ' mm <font color="red">*Fail*</font><br />';
      }
    } else {
      key6 = 0;
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary unit
        this.dentCalcResult +=
          "Dent sharpest radius of curvature, rd = " +
          Component_MAWP.buffer +
          " inch >=  5 * trd = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      } // SI
      else {
        this.dentCalcResult +=
          "Dent sharpest radius of curvature, rd = " +
          Component_MAWP.buffer +
          " mm >=  5 * trd = " +
          Component_MAWP.buffer1 +
          " mm<br />";
      }
    }

    //shameer - end

    if (
      key1 === 0 &&
      key2 === 0 &&
      key3 === 0 &&
      key4 === 0 &&
      key5 === 0 &&
      key6 === 0
    ) {
      Component_MAWP.strD_result = "Pass";
      this.dentCalcResult += "<br />";
      this.dentCalcResult +=
        '<font color="blue">Dent passed Level 1 assessment.</font><br />';
    } else {
      Component_MAWP.strD_result = "Fail";
      this.dentCalcResult += "<br />";
      this.dentCalcResult +=
        '<font color="red">Dent failed Level 1 assessment. <br /> Consider performing a Level 2 or Level 3 assessment.</font><br />'; // modified by shameer
    }

    return this.dentCalcResult;
  }

  public static check_Dent_Data(inspectionDataFormGroup): boolean {
    let passFlag: boolean = true;
    let messagesValidationsList: string[] = [];

    let comboBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox1_DI");
    let textBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");
    let textBox1_DI_EquipInfo_formControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI_EquipInfo");
    let textBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");
    let textBox3_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox3_DI");
    let textBox4_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox4_DI");
    let textBox5_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox5_DI");

    // comboBox1_DI error handling
    if (comboBox1_DIformControl?.enabled) {
      if (
        comboBox1_DIformControl.value !== "Yes" &&
        comboBox1_DIformControl.value !== "No"
      ) {
        passFlag = false;
        messagesValidationsList.push(
          "Depth measurement condition needs to be selected."
        );
      }
    }

    // textBox1_DI_EquipInfo error handling
    if (textBox1_DI_EquipInfo_formControl?.enabled) {
      if (textBox1_DI_EquipInfo_formControl.invalid) {
        if (
          textBox1_DI_EquipInfo_formControl.value === null ||
          textBox1_DI_EquipInfo_formControl.value === ""
        ) {
          messagesValidationsList.push(
            "Maximum operating pressure, P_op - not set."
          );
          passFlag = false;
        }
      } else if (Dent_Module.intd_key === 0 && Dent_Module.sngP_op <= 0.0) {
        messagesValidationsList.push(
          "Maximum operating pressure, P_op - cannot be equal to or less than 0."
        );
        passFlag = false;
      }
    }

    // textBox1_DI error handling
    if (textBox1_DIformControl?.enabled) {
      let equipmentString: string = "";
      if (Dent_Module.intd_key === 0) {
        equipmentString = "Dent depth measured under pressure, d_dp - ";
      } else if (Dent_Module.intd_key === 1) {
        equipmentString = "Dent depth measured not under pressure, d_d0 - ";
      }

      if (
        (Dent_Module.intd_key === 0 || Dent_Module.intd_key === 1) &&
        (textBox1_DIformControl.value === null ||
          textBox1_DIformControl.value === "")
      ) {
        messagesValidationsList.push(equipmentString + "not set.");
        passFlag = false;
      } else if (Dent_Module.intd_key == 0 && Dent_Module.sngd_d0 <= 0.0) {
        messagesValidationsList.push(
          "Dent depth measured under pressure, d_dp - cannot be less than or equal to 0."
        );
        passFlag = false;
      } else if (Dent_Module.intd_key == 1 && Dent_Module.sngd_dp <= 0.0) {
        messagesValidationsList.push(
          "Dent depth measured not under pressure, d_d0 - cannot be less than or equal to 0."
        );
        passFlag = false;
      } else if (
        Component_MAWP.strD_class == "Dent" &&
        Component_MAWP.intEquip == 0 &&
        Dent_Module.intd_key == 1 &&
        Dent_Module.sngd_dp >=
        Component_MAWP.sngIDo - 2 * Component_MAWP.sngLOSSi
      ) {
        // added by shameer 5/5/2023
        messagesValidationsList.push(
          "Maximum depth of the dent in the pressurized condition, cannot be greater than or equal to the current inside diameter."
        );
        passFlag = false;
      } else if (
        Component_MAWP.strD_class == "Dent" &&
        Component_MAWP.intEquip == 0 &&
        Dent_Module.intd_key == 0 &&
        Dent_Module.sngd_d0 >=
        Component_MAWP.sngIDo - 2 * Component_MAWP.sngLOSSi
      ) {
        // added by shameer 5/5/2023
        messagesValidationsList.push(
          "Maximum depth of the dent in the unpressurized condition, cannot be greater than or equal to the current inside diameter."
        );
        passFlag = false;
      } else if (
        Component_MAWP.strD_class == "Dent" &&
        Component_MAWP.intEquip == 5 &&
        Dent_Module.intd_key == 1 &&
        Dent_Module.sngd_dp >=
        Component_MAWP.sngIDo -
        2 * (Component_MAWP.sngtmin - Component_MAWP.sngLOSSi)
      ) {
        // added by shameer 5/8/2023
        messagesValidationsList.push(
          "Maximum depth of the dent in the pressurized condition, cannot be greater than or equal to the current inside diameter."
        );
        passFlag = false;
      } else if (
        Component_MAWP.strD_class == "Dent" &&
        Component_MAWP.intEquip == 5 &&
        Dent_Module.intd_key == 0 &&
        Dent_Module.sngd_d0 >=
        Component_MAWP.sngIDo -
        2 * (Component_MAWP.sngtmin - Component_MAWP.sngLOSSi)
      ) {
        // added by shameer 5/8/2023
        messagesValidationsList.push(
          "Maximum depth of the dent in the unpressurized condition, cannot be greater than or equal to the current inside diameter."
        );
        passFlag = false;
      }
    }

    // textBox2_DI error handling
    if (textBox2_DIformControl?.enabled) {
      if (textBox2_DIformControl.invalid) {
        if (
          textBox2_DIformControl.value === null ||
          textBox2_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Dent spacing to the nearest weld joint, Lw - not set"
          );
          passFlag = false;
        }
      } else if (Dent_Module.sngLw < 0.0) {
        messagesValidationsList.push(
          "Dent spacing to the nearest weld joint, Lw - cannot be less than 0."
        );
        passFlag = false;
      }
    }

    // textBox3_DI error handling
    if (textBox3_DIformControl?.enabled) {
      if (textBox3_DIformControl.invalid) {
        if (
          textBox3_DIformControl.value === null ||
          textBox3_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Dent spacing to the nearest structural discontinuity, Lmsd - not set"
          );
          passFlag = false;
        }
      } else if (CalcTemplate.sngLmsd < 0.0) {
        messagesValidationsList.push(
          "Dent spacing to the nearest structural discontinuity, Lmsd - cannot be less than 0."
        );
        passFlag = false;
      }
    }

    // textBox4_DI error handling
    if (textBox4_DIformControl?.enabled) {
      if (textBox4_DIformControl.invalid) {
        if (
          textBox4_DIformControl.value === null ||
          textBox4_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Dent spacing to the nearest dent or dent-gouge combination, Ld - not set"
          );
          passFlag = false;
        }
      } else if (Dent_Module.sngLd < 0.0) {
        messagesValidationsList.push(
          "Dent spacing to the nearest dent or dent-gouge combination, Ld - cannot be less than 0."
        );
        passFlag = false;
      }
    }

    // textBox5_DI error handling
    if (textBox5_DIformControl?.enabled) {
      if (textBox5_DIformControl.invalid) {
        if (
          textBox5_DIformControl.value === null ||
          textBox5_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Dent sharpest radius of curvature, rd - not set"
          );
          passFlag = false;
        }
      } else if (textBox5_DIformControl.value <= 0.0) {
        messagesValidationsList.push(
          "Dent sharpest radius of curvature, rd - cannot be less than or equal to 0"
        );
        passFlag = false;
      }
    }

    this.validationMessage = !passFlag ? messagesValidationsList : [];

    return passFlag;
  }

  public static check_Dent_Gouge_Data(inspectionDataFormGroup): boolean {
    let comboBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox1_DI");
    let textBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");
    let textBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");
    let textBox4_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox4_DI");
    let textBox5_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox5_DI");
    let textBox6_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox6_DI_snggr");
    let textBox7_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox7_DI_sngHf");
    let textBox8_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox8_DI");
    let textBox9_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox9_DI");

    let componentInformationFormGroup =
      inspectionDataFormGroup.controls["childComponentInformationForm"];

    let textBox1_DI_EquipInfo: FormControl = componentInformationFormGroup.get(
      "textBox1_DI_EquipInfo"
    );
    let textBox2_DI_EquipInfo: FormControl = componentInformationFormGroup.get(
      "textBox2_DI_EquipInfo"
    );
    let textBox3_DI_EquipInfo: FormControl = componentInformationFormGroup.get(
      "textBox3_DI_EquipInfo"
    );
    this.validationMessageComponentInformation = [];

    let pass_flag = true;
    let messagesValidationsList: string[] = [];
    let msg: string;

    if (
      comboBox1_DIformControl.enabled &&
      comboBox1_DIformControl.value != "No" &&
      comboBox1_DIformControl.value != "Yes"
    ) {
      messagesValidationsList.push(
        "Depth measurement condition needs to be selected.\n"
      );
      pass_flag = false;
    }

    if (textBox1_DI_EquipInfo.enabled) {
      if (!Utility.isTextADouble(textBox1_DI_EquipInfo.value)) {
        this.validationMessageComponentInformation.push(
          "Maximum operating pressure, P_op - " + msg + "\n"
        );
        pass_flag = false;
      } else if (Dent_Module.intd_key == 0 && Dent_Module.sngP_op <= 0.0) {
        this.validationMessageComponentInformation.push(
          "Maximum operating pressure, P_op - cannot be equal to or less than 0.\n"
        );
        pass_flag = false;
      } else if (
        Dent_Module.sngP_op >
        Component_MAWP.sngMAWP - Component_MAWP.sngp_H
      ) {
        if (Component_MAWP.intEquip == 0) {
          // Cylinder
          if (Component_MAWP.sngH_L > 0) {
            // liquid > 0
            this.validationMessageComponentInformation.push(
              "Maximum operating pressure, P_op (MPa) cannot be greater than adjusted MAWP."
            );
          } else {
            // liquid = 0
            this.validationMessageComponentInformation.push(
              "Maximum operating pressure, P_op (MPa) cannot be greater than MAWP."
            );
          }
        } else {
          // B31,3 Piping
          this.validationMessageComponentInformation.push(
            "Maximum operating pressure, P_op (MPa) cannot be greater than MAWP."
          );
        }
        pass_flag = false;
      }
    }

    if (textBox2_DI_EquipInfo.enabled) {
      if (!Utility.isTextADouble(textBox2_DI_EquipInfo.value)) {
        this.validationMessageComponentInformation.push(
          "Specified minimum yield strength, SMYS - " + msg + "\n"
        );
        pass_flag = false;
      } else if (GougeModule.sngSMYS <= 0.0) {
        this.validationMessageComponentInformation.push(
          "Specified minimum yield strength, SMYS - cannot be less than or equal to 0.\n"
        );
        pass_flag = false;
      } else if (
        Component_MAWP.intUnit_select == 0 &&
        GougeModule.sngSMYS > 70
      ) {
        this.validationMessageComponentInformation.push(
          "Specified minimum yield strength, SMYS - cannot be greater than 70 (Ksi).\n"
        );
        pass_flag = false;
      } else if (
        Component_MAWP.intUnit_select == 1 &&
        GougeModule.sngSMYS > 482
      ) {
        this.validationMessageComponentInformation.push(
          "Specified minimum yield strength, SMYS - cannot be greater than 482 (Mpa).\n"
        );
        pass_flag = false;
      }
    }

    if (textBox3_DI_EquipInfo.enabled) {
      if (!Utility.isTextADouble(textBox3_DI_EquipInfo.value)) {
        this.validationMessageComponentInformation.push(
          "Circumferential stress for the component based on trd-FCA, Sigma - " +
          msg +
          "\n"
        );
        pass_flag = false;
      } else if (Dent_Module.sngSigma < 0.0) {
        this.validationMessageComponentInformation.push(
          "Circumferential stress for the component based on trd-FCA, Sigma - cannot be less than 0.\n"
        );
        pass_flag = false;
      }
    }

    if (textBox1_DIformControl.enabled) {
      let equipmentString: string;
      if (Dent_Module.intd_key === 0) {
        equipmentString =
          "Maximum depth of the dent in the un-pressurized condition, d_d0 - ";
      } else if (Dent_Module.intd_key === 1) {
        equipmentString =
          "Maximum depth of the dent in the pressurized condition, d_dp - ";
      }
      if (
        (Dent_Module.intd_key === 0 || Dent_Module.intd_key === 1) &&
        !Utility.isTextADouble(textBox1_DIformControl.value)
      ) {
        messagesValidationsList.push(equipmentString + msg + "\n");
        pass_flag = false;
      } else if (Dent_Module.intd_key === 0 && Dent_Module.sngd_d0 <= 0.0) {
        messagesValidationsList.push(
          "Dent depth measured not under pressure, d_d0 - cannot be less than or equal to 0.\n"
        );
        pass_flag = false;
      } else if (Dent_Module.intd_key === 1 && Dent_Module.sngd_dp <= 0.0) {
        messagesValidationsList.push(
          "Dent depth measured under pressure, d_dp - cannot be less than or equal to 0.\n"
        );
        pass_flag = false;
      } else if (
        Dent_Module.intd_key === 0 &&
        Dent_Module.sngd_d0 > Component_MAWP.sngID
      ) {
        messagesValidationsList.push(
          "Maximum depth of the dent in the un-pressurized condition, d_d0 - cannot be greater than the inside diameter, D corrected for LOSSi and FCAi.\n"
        );
        pass_flag = false;
      } else if (
        Dent_Module.intd_key === 1 &&
        Dent_Module.sngd_dp > Component_MAWP.sngID
      ) {
        messagesValidationsList.push(
          "Maximum depth of the dent in the pressurized condition, d_dp - cannot be greater than the inside diameter, D corrected for LOSSi and FCAi.\n"
        );
        pass_flag = false;
      }
    }

    if (textBox2_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox2_DIformControl.value)) {
        messagesValidationsList.push(
          "Depth of the gouge in the dent, d-g - " + msg + "\n"
        );
        pass_flag = false;
      } else if (Dent_Module.sngd_g === 0) {
        messagesValidationsList.push(
          "Gouge depth is 0. Use the Dents assessment procedure.\n"
        );
        pass_flag = false;
      } else if (Dent_Module.sngd_g < 0.0) {
        messagesValidationsList.push(
          "Depth of the gouge in the dent, d-g - cannot be less than 0.\n"
        );
        pass_flag = false;
      } else if (Dent_Module.sngd_g >= Component_MAWP.sngtrd) {
        messagesValidationsList.push(
          "Maximum depth of the gouge, d_g - cannot be greater than or equal to the current measured thickness, trd\n"
        );
        pass_flag = false;
      } else {
        LTA_module.sngtmm = Component_MAWP.sngtrd - Dent_Module.sngd_g;
      }
    }

    if (textBox4_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox4_DIformControl.value)) {
        messagesValidationsList.push(
          "Future Corrosion Allowance inside the damage, FCAml - " + msg + "\n"
        );
        pass_flag = false;
      } else if (CalcTemplate.sngFCAml < 0.0) {
        messagesValidationsList.push(
          "Future Corrosion Allowance inside the dent, FCAml - cannot be less than 0.\n"
        );
        pass_flag = false;
      } else if (
        CalcTemplate.sngFCAml > 0 &&
        CalcTemplate.sngFCAml >= Component_MAWP.sngtrd - Dent_Module.sngd_g &&
        Dent_Module.sngd_g <= Component_MAWP.sngtrd
      ) {
        messagesValidationsList.push(
          "The Future Corrosion Allowance inside the dent, FCAml cannot be greater than or equal to the Minimum thickness at the dent, tmm\n"
        );
        pass_flag = false;
      }
    }

    if (textBox5_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox5_DIformControl.value)) {
        messagesValidationsList.push(
          "Dent-Gouge spacing to the nearest weld joint, Lw - " + msg + "\n"
        );
        pass_flag = false;
      } else if (Dent_Module.sngLw < 0.0) {
        messagesValidationsList.push(
          "Dent-Gouge spacing to the nearest weld joint, Lw - cannot be less than 0.\n"
        );
        pass_flag = false;
      }
    }

    if (textBox6_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox6_DIformControl.value)) {
        messagesValidationsList.push(
          "Dent-Gouge spacing to the nearest major structural discontinuity, Lmsd - " +
          msg +
          "\n"
        );
        pass_flag = false;
      } else if (CalcTemplate.sngLmsd < 0.0) {
        messagesValidationsList.push(
          "Dent spacing to the nearest major structural discontinuity, Lmsd - cannot be less than 0.\n"
        );
        pass_flag = false;
      }
    }

    if (textBox7_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox7_DIformControl.value)) {
        messagesValidationsList.push(
          "Dent-Gouge spacing to the nearest dent or dent-gouge combination, Ld - " +
          msg +
          "\n"
        );
        pass_flag = false;
      } else if (Dent_Module.sngLd < 0.0) {
        messagesValidationsList.push(
          "Dent-Gouge spacing to the nearest dent or dent-gouge combination, Ld - cannot be less than 0.\n"
        );
        pass_flag = false;
      }
    }

    if (textBox8_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox8_DIformControl.value)) {
        messagesValidationsList.push(
          "Radius at the base of the Dent-Gouge Combination, gr (inch) - " +
          msg +
          "\n"
        );
        pass_flag = false;
      } else if (Number(textBox8_DIformControl.value) < 0.0) {
        messagesValidationsList.push(
          "Radius at the base of the Dent-Gouge Combination, gr (inch) - cannot be less than 0.\n"
        );
        pass_flag = false;
      } else {
        Component_MAWP.sngr = Number(textBox8_DIformControl.value);
      }
    }

    if (textBox9_DIformControl.enabled) {
      if (!Utility.isTextADouble(textBox9_DIformControl.value)) {
        messagesValidationsList.push(
          "Dent sharpest radius of curvatue, rd (inch) - " + msg + "\n"
        );
        pass_flag = false;
      } else if (Number(textBox9_DIformControl.value) <= 0.0) {
        messagesValidationsList.push(
          "Dent sharpest radius of curvatue, rd (inch) - cannot be less than or equal to 0.\n"
        );
        pass_flag = false;
      } else {
        Dent_Module.sngrd1 = Number(textBox9_DIformControl.value);
      }
    }

    this.validationMessage = !pass_flag ? messagesValidationsList : [];

    return pass_flag;
  }

  public static DentExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      Dent_Module: { ...Dent_Module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static DentImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    Dent_Module.Dent_initialize();

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(Dent_Module, jsonDataResult.Dent_Module);
  }

  public static updateInspectionDataFormControlsForDent(
    inspectionDataFormGroup
  ): void {
    let textBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");
    let textBox1_DI_EquipInfo_formControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI_EquipInfo");
    let textBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");
    let textBox3_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox3_DI");
    let textBox4_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox4_DI");
    let textBox5_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox5_DI");
    let comboBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox1_DI");

    if (Dent_Module.intd_key !== -1) {
      let comboBoxValues = ["No", "Yes"];
      comboBox1_DIformControl.setValue(comboBoxValues[Dent_Module.intd_key]);
    }

    if (Dent_Module.intd_key === 0) {
      // measurement not under pressure
      textBox1_DIformControl.setValue(Dent_Module.sngd_d0.toString());
      textBox1_DI_EquipInfo_formControl.setValue(
        Dent_Module.sngP_op.toString()
      );
    } else {
      textBox1_DI_EquipInfo_formControl.disable();
      textBox1_DIformControl.setValue(Dent_Module.sngd_dp.toString());
    }

    if (Dent_Module.sngLw !== -1)
      textBox2_DIformControl.setValue(Dent_Module.sngLw.toString());

    if (CalcTemplate.sngLmsd !== -1)
      textBox3_DIformControl.setValue(CalcTemplate.sngLmsd.toString());

    if (Dent_Module.sngLd !== -1)
      textBox4_DIformControl.setValue(Dent_Module.sngLd.toString());

    if (Dent_Module.sngrd !== -1) {
      textBox5_DIformControl.setValue(Dent_Module.sngrd.toString());
    }
  }

  public static DentGougeExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      LTA_module: { ...LTA_module },
      GougeModule: { ...GougeModule },
      Dent_Module: { ...Dent_Module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static DentGougeImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    Dent_Module.Dent_initialize();
    GougeModule.Gouge_initialize();

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(LTA_module, jsonDataResult.LTA_module);
    Object.assign(GougeModule, jsonDataResult.GougeModule);
    Object.assign(Dent_Module, jsonDataResult.Dent_Module);
  }

  public static updateInspectionDataFormControlsForDentGouge(
    inspectionDataFormGroup
  ): void {
    let textBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");
    let textBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");
    let textBox4_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox4_DI");
    let textBox5_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox5_DI");
    let textBox6_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox6_DI_snggr");
    let textBox7_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox7_DI_sngHf");
    let textBox8_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox8_DI");
    let textBox9_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox9_DI");
    let comboBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox1_DI");

    let componentInformationFormGroup =
      inspectionDataFormGroup.controls["childComponentInformationForm"];

    let textBox1_DI_EquipInfo: FormControl = componentInformationFormGroup.get(
      "textBox1_DI_EquipInfo"
    );

    if (Dent_Module.intd_key !== -1) {
      let comboBoxValues = ["No", "Yes"];
      comboBox1_DIformControl.setValue(comboBoxValues[Dent_Module.intd_key]);
    }

    if (Dent_Module.intd_key === 0) {
      // measurement not under pressure
      textBox1_DI_EquipInfo.enable();
      textBox1_DIformControl.setValue(Dent_Module.sngd_d0.toString());
    } else {
      textBox1_DIformControl.setValue(Dent_Module.sngd_dp.toString());
      textBox1_DI_EquipInfo.disable();
    }

    if (Dent_Module.sngd_g !== -1)
      textBox2_DIformControl.setValue(Dent_Module.sngd_g.toString());

    if (CalcTemplate.sngFCAml != -1)
      textBox4_DIformControl.setValue(CalcTemplate.sngFCAml.toString());

    if (Dent_Module.sngLw !== -1)
      textBox5_DIformControl.setValue(Dent_Module.sngLw.toString());

    if (CalcTemplate.sngLmsd !== -1) {
      textBox6_DIformControl.enable();
      textBox6_DIformControl.setValue(CalcTemplate.sngLmsd.toString());
    }

    if (Dent_Module.sngLd !== -1)
      textBox7_DIformControl.setValue(Dent_Module.sngLd.toString());

    if (Dent_Module.sngrd1 !== -1)
      textBox8_DIformControl.setValue(Dent_Module.sngrd1.toString());

    if (Dent_Module.sngrd !== -1)
      textBox9_DIformControl.setValue(Dent_Module.sngrd.toString());
  }

  public static Dent_report(): string {
    let report: string = "";

    const format = "dd/MM/yyyy";
    const locale = "en-US";
    const dt1 = new Date();
    const reportDate = formatDate(dt1, format, locale);

    report += this.Dent_report_subpart_1(reportDate);
    report += this.Dent_report_subpart_2(reportDate);

    // SECOND PAGE

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 12</center>";
    report += "<center>Assessment of Dents</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 2</br></br></br>";

    report += "Dent Information :<br><br>";

    if (Dent_Module.intd_key === 0) {
      report +=
        "The maximum depth of the dent is measured in the pressurized condition = No<br>";
    } else {
      report +=
        "The maximum depth of the dent is measured in the pressurized condition = Yes<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (Dent_Module.intd_key === 0) {
        report += `Maximum dent depth in the un-pressurized condition, d_d0 (inch) = ${Dent_Module.sngd_d0.toFixed(
          3
        )}<br>`;
      } else {
        report += `Maximum dent depth in the pressurized condition, d_dp (inch) = ${Dent_Module.sngd_dp.toFixed(
          3
        )}<br>`;
      }
      report += `Dent spacing to the nearest weld joint, Lw (inch) = ${Dent_Module.sngLw.toFixed(
        3
      )}<br>`;
      report += `Distance to structural discontinuity, Lmsd (inch) = ${CalcTemplate.sngLmsd.toFixed(
        3
      )}<br>`;
      report += `Dent spacing to the nearest dent or dent-gouge combination, Ld (inch) = ${Dent_Module.sngLd.toFixed(
        3
      )}<br><br><br>`;
    } else {
      // SI
      if (Dent_Module.intd_key === 0) {
        report += `Maximum dent depth in the un-pressurized condition, d_d0 (mm) = ${Dent_Module.sngd_d0.toFixed(
          1
        )}<br>`;
      } else {
        report += `Maximum dent depth in the pressurized condition, d_dp (mm) = ${Dent_Module.sngd_dp.toFixed(
          1
        )}<br>`;
      }
      report += `Dent spacing to the nearest weld joint, Lw (mm) = ${Dent_Module.sngLw.toFixed(
        1
      )}<br>`;
      report += `Distance to structural discontinuity, Lmsd (mm) = ${CalcTemplate.sngLmsd.toFixed(
        1
      )}<br>`;
      report += `Dent spacing to the nearest dent or dent-gouge combination, Ld (mm) = ${Dent_Module.sngLd.toFixed(
        1
      )}<br><br><br>`;
    }

    report += "Assessment Results :<br><br>";
    report +=
      "All the conditions in the Applicability and Limitations section are met.<br><br>";

    report += this.dentCalcResult;

    return report;
  }

  public static Dent_report_subpart_1(reportDate: string): string {
    let report: string = "";

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 12</center>";
    report += "<center>Assessment of Dents</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 1</br></br></br>";

    const sRefinery: string = "Facility: ";
    const sPlant: string = "Plant: ";
    const sEquipment: string = "Equipment: ";
    const sAnalyst: string = "Analyst: ";

    report += sRefinery + Component_MAWP.strFacility + "</br>";
    report += sPlant + Component_MAWP.strPlant + "</br>";
    report += sEquipment + Component_MAWP.strEquipment + "</br>";
    report += sAnalyst + Component_MAWP.strAnalyst + "</br></br></br>";

    report += "Applicability and Limitations :</br></br>";

    report += `<ol class="appAndLimitList">  
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. The design temperature is less than or equal to the temperature limit in Table 4.1.</li>  
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment. Note that cold work may be produced by the deformation. This cold worked area may be more susceptible to some forms of mechanical and environmental cracking.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>
        <li>The component is a carbon steel cylindrical shell subject only to internal pressure (i.e supplemental loads are assumed to be negligible).</li>
        <li>The dent is an inward deviation of the shell cross section.</li>
        <li>The dent is isolated from other dents and dent-gouge combinations.</li>
      </ol>  
    </br></br>`;

    report += "Component Information :</br></br>";

    if (Component_MAWP.intEquip === 0) {
      report += "Component type = Cylinder<br>";
    } else if (Component_MAWP.intEquip === 5) {
      report += "Component type = B31.3 Piping<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      report += `Design pressure or vessel MAWP (psi) = ${Component_MAWP.sngP_d.toFixed(
        1
      )}<br>`;
      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-F) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design temperature (deg-F) = N/A<br>";
      }
      report += `Inside diameter corrected for FCA as applicable, D (inch) = ${Component_MAWP.sngID.toFixed(
        3
      )}<br>`;
      report += `Current measured nominal (uniform) thickness, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
        3
      )}<br>`;
      report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = ${Component_MAWP.sngFCA.toFixed(
        3
      )}<br>`;
      report += `Future corroded thickness, tc (inch) = ${Component_MAWP.sngtc.toFixed(
        3
      )}<br>`;
      report += `Minimum required thickness, tmin (inch) = ${Component_MAWP.sngtmin.toFixed(
        3
      )}<br>`;
      report += `MAWP of the component based on tc, MAWP (psi) = ${Component_MAWP.sngMAWP.toFixed(
        1
      )}<br>`;
    } else {
      // SI
      report += `Design pressure or vessel MAWP (MPa) = ${Component_MAWP.sngP_d.toFixed(
        2
      )}<br>`;
      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-C) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design temperature (deg-C) = N/A<br>";
      }
      report += `Inside diameter corrected for FCA as applicable, D (mm) = ${Component_MAWP.sngID.toFixed(
        1
      )}<br>`;
      report += `Current measured nominal (uniform) thickness, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
        1
      )}<br>`;
      report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = ${Component_MAWP.sngFCA.toFixed(
        1
      )}<br>`;
      report += `Future corroded thickness, tc (mm) = ${Component_MAWP.sngtc.toFixed(
        1
      )}<br>`;
      report += `Minimum required thickness, tmin (mm) = ${Component_MAWP.sngtmin.toFixed(
        1
      )}<br>`;
      report += `MAWP of the component based on tc, MAWP (MPa) = ${Component_MAWP.sngMAWP.toFixed(
        2
      )}<br>`;
    }

    return report;
  }

  public static Dent_report_subpart_2(reportDate: string): string {
    let report: string = "";

    report += `Longitudinal weld joint efficiency, E_L = ${Component_MAWP.sngE_L.toFixed(
      2
    )}<br>`;
    report += `Circumferential weld joint efficiency, E_C = ${Component_MAWP.sngE_c.toFixed(
      2
    )}<br>`; // modified by shameer c to C

    if (Dent_Module.intd_key === 0) {
      // not under pressure
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        report += `Maximum operating pressure (psi) = ${Dent_Module.sngP_op.toFixed(
          1
        )}<br>`;
      } else {
        // SI
        report += `Maximum operating pressure (MPa) = ${Dent_Module.sngP_op.toFixed(
          2
        )}<br>`;
      }
    } else {
      report += "Maximum operating pressure =    Not Required<br>";
    }

    report += '<div class="pageBreak"></div>';

    return report;
  }

  public static Dent_Gouge_report(): string {
    let report: string = "";

    const format = "dd/MM/yyyy";
    const locale = "en-US";
    const dt1 = new Date();
    const reportDate = formatDate(dt1, format, locale);

    report += this.Dent_Gouge_report_subpart_1(reportDate);
    report += this.Dent_Gouge_report_subpart_2(reportDate);

    // SECOND PAGE

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 12</center>";
    report +=
      "<center>Assessment of Dent-Gouge Combinations</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 2</br></br></br>";

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      report += `Specified minimum yield strength, SMYS (ksi) = ${GougeModule.sngSMYS.toFixed(
        3
      )}<br>`;
      report += `Circumferential stress for the component based on trd-FCA, Sigma (ksi) = ${Dent_Module.sngSigma.toFixed(
        3
      )}<br><br><br>`;
    } else {
      // SI
      report += `Specified minimum yield strength, SMYS (MPa) = ${GougeModule.sngSMYS.toFixed(
        2
      )}<br>`;
      report += `Circumferential stress for the component based on trd-FCA, Sigma (MPa) = ${Dent_Module.sngSigma.toFixed(
        2
      )}<br><br><br>`;
    }

    report += "Dent Gouge Information :<br><br>";
    if (Dent_Module.intd_key === 0) {
      report +=
        "The maximum depth of the dent is measured in the pressurized condition = No<br>";
    } else {
      report +=
        "The maximum depth of the dent is measured in the pressurized condition = Yes<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (Dent_Module.intd_key === 0) {
        report += `Maximum dent depth in the un-pressurized condition, d_d0 (inch) = ${Dent_Module.sngd_d0.toFixed(
          3
        )}<br>`;
      } else {
        report += `Maximum dent depth in the pressurized condition, d_dp (inch) = ${Dent_Module.sngd_dp.toFixed(
          3
        )}<br>`;
      }
      report += `Maximum depth of the gouge, d_g (inch) = ${Dent_Module.sngd_g.toFixed(
        3
      )}<br>`;
      report += `Minimum measured thickness, tmm (inch) = ${LTA_module.sngtmm.toFixed(
        3
      )}<br>`;
      report += `Future Corrosion Allowance inside the damage, FCAml (inch) = ${CalcTemplate.sngFCAml.toFixed(
        3
      )}<br>`;
      report += `Dent-Gouge combination spacing to the nearest weld joint, Lw (inch) = ${Dent_Module.sngLw.toFixed(
        3
      )}<br>`;
      report += `Distance to structural discontinuity, Lmsd (inch) = ${CalcTemplate.sngLmsd.toFixed(
        3
      )}<br>`;
      report += `Dent-Gouge combination spacing to the nearest dent or dent-gouge combination,<br>`;
      report += `Ld (inch) = ${Dent_Module.sngLd.toFixed(4)}<br><br><br>`;
    } else {
      // SI
      if (Dent_Module.intd_key === 0) {
        report += `Maximum dent depth in the un-pressurized condition, d_d0 (mm) = ${Dent_Module.sngd_d0.toFixed(
          1
        )}<br>`;
      } else {
        report += `Maximum dent depth in the pressurized condition, d_dp (mm) = ${Dent_Module.sngd_dp.toFixed(
          1
        )}<br>`;
      }
      report += `Maximum depth of the gouge, d_g (mm) = ${Dent_Module.sngd_g.toFixed(
        1
      )}<br>`;
      report += `Minimum measured thickness, tmm (mm) = ${LTA_module.sngtmm.toFixed(
        1
      )}<br>`;
      report += `Future Corrosion Allowance inside the damage, FCAml (mm) = ${CalcTemplate.sngFCAml.toFixed(
        1
      )}<br>`;
      report += `Dent-Gouge combination spacing to the nearest weld joint, Lw (mm) = ${Dent_Module.sngLw.toFixed(
        1
      )}<br>`;
      report += `Distance to structural discontinuity, Lmsd (mm) = ${CalcTemplate.sngLmsd.toFixed(
        1
      )}<br>`;
      report += `Dent-Gouge combination spacing to the nearest dent or dent-gouge combination,<br>`;
      report += `Ld (mm) = ${Dent_Module.sngLd.toFixed(2)}<br><br><br>`;
    }

    report += "Assessment Results :<br><br>";
    report +=
      "All the conditions in the Applicability and Limitations section are met.<br><br>";

    report += this.dentCalcResult;

    return report;
  }


  public static Dent_Gouge_report_subpart_1(reportDate: string): string {
    let report: string = "";

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 12</center>";
    report +=
      "<center>Assessment of Dent-Gouge Combinations</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 1</br></br></br>";

    const sRefinery: string = "Facility: ";
    const sPlant: string = "Plant: ";
    const sEquipment: string = "Equipment: ";
    const sAnalyst: string = "Analyst: ";

    report += sRefinery + Component_MAWP.strFacility + "</br>";
    report += sPlant + Component_MAWP.strPlant + "</br>";
    report += sEquipment + Component_MAWP.strEquipment + "</br>";
    report += sAnalyst + Component_MAWP.strAnalyst + "</br></br></br>";

    report += "Applicability and Limitations :</br></br>";

    report += `<ol class="appAndLimitList">
        <li>The original design criteria were in accordance with a recognized code or standard.</li>
        <li>The component is not operating in the creep range. The design temperature is less than or equal to the temperature limit in Table 4.1.</li>
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment. Note that cold work may be produced by the deformation. This cold worked area may be more susceptible to some forms of mechanical and environmental cracking.</li>
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>
        <li>The component is a carbon steel cylindrical shell subject only to internal pressure (i.e supplemental loads are assumed to be negligible).</li>
        <li>The component material is carbon steel with a specified minimum yield strength, SMYS, less than or equal to 70 ksi (482 MPa).</li>
        <li>The dent-gouge combination is an inward deviation of the shell cross section.</li>
        <li>The dent-gouge combination is isolated from other dents and dent-gouge combinations.</li>
      </ol>
    </br></br>`;

    report += "Component Information :</br></br>";

    if (Component_MAWP.intEquip === 0) {
      report += "Component type = Cylinder<br>";
    } else if (Component_MAWP.intEquip === 5) {
      report += "Component type = B31.3 Piping<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      report += `Design pressure or vessel MAWP (psi) = ${Component_MAWP.sngP_d.toFixed(
        2
      )}<br>`;
      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-F) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design temperature (deg-F) = N/A<br>";
      }
      report += `Inside diameter corrected for FCA as applicable, D (inch) = ${Component_MAWP.sngID.toFixed(
        3
      )}<br>`;
      report += `Current measured nominal (uniform) thickness, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
        3
      )}<br>`;
      report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = ${Component_MAWP.sngFCA.toFixed(
        3
      )}<br>`;
      report += `Future corroded thickness, tc (inch) = ${Component_MAWP.sngtc.toFixed(
        3
      )}<br>`;
      report += `Minimum required thickness, tmin (inch) = ${Component_MAWP.sngtmin.toFixed(
        3
      )}<br>`;
      report += `MAWP of the component based on tc, MAWP (psi) = ${Component_MAWP.sngMAWP.toFixed(
        1
      )}<br>`;
    } else {
      // SI
      report += `Design pressure or vessel MAWP (MPa) = ${Component_MAWP.sngP_d.toFixed(
        2
      )}<br>`;
      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-C) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design temperature (deg-C) = N/A<br>";
      }
      report += `Inside diameter corrected for FCA as applicable, D (mm) = ${Component_MAWP.sngID.toFixed(
        1
      )}<br>`;
      report += `Current measured nominal (uniform) thickness, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
        1
      )}<br>`;
      report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = ${Component_MAWP.sngFCA.toFixed(
        1
      )}<br>`;
      report += `Future corroded thickness, tc (mm) = ${Component_MAWP.sngtc.toFixed(
        1
      )}<br>`;
      report += `Minimum required thickness, tmin (mm) = ${Component_MAWP.sngtmin.toFixed(
        1
      )}<br>`;
      report += `MAWP of the component based on tc, MAWP (MPa) = ${Component_MAWP.sngMAWP.toFixed(
        2
      )}<br>`;
    }

    return report;
  }


  public static Dent_Gouge_report_subpart_2(reportDate: string): string {
    let report: string = "";

    report += `Longitudinal weld joint efficiency, E_L = ${Component_MAWP.sngE_L.toFixed(
      2
    )}<br>`;
    report += `Circumferential weld joint efficiency, E_C = ${Component_MAWP.sngE_c.toFixed(
      2
    )}<br>`;
    if (this.intd_key === 0) {
      // not under pressure
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        report += `Maximum operating pressure (psi) = ${Dent_Module.sngP_op.toFixed(
          1
        )}<br><br>`;
      } else {
        // SI
        report += `Maximum operating pressure (MPa) = ${Dent_Module.sngP_op.toFixed(
          2
        )}<br><br>`;
      }
    } else {
      report += "Maximum operating pressure = Not Required<br><br>";
    }

    report += '<div class="pageBreak"></div>';

    return report;
  }
}
