import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[appRestrictInput]",
})
export class RestrictInputDirective {
  constructor(private elementRef: ElementRef, private control: NgControl) {}

  @HostListener("keydown", ["$event"]) onKeyDown(event: any) {
    const restrictedCharacters = ["-", "&"]; // Add any characters you want to restrict

    for (const character of restrictedCharacters) {
      if (event.key == character) {
        event.preventDefault();
      }
    }
  }
}
