import { FormControl, FormGroup } from "@angular/forms";
import { CalcTemplate } from "./calctemplate";
import { Utility } from "./utility";

export class Component_MAWP {
  public static intUnit_select: number; // 0 - US Customary, 1 - SI
  public static PI: number = 3.1415926535; // move to some general settings?

  public static strD_class: string; // Defect type; GML, LTA, Pit, etc.
  public static strD_iclass: number; // Defect type; GML = 0, LTA = 1, Pit = 2, Gouge = 3
  public static strD_result: string; // assessment result; pass or fail
  public static strFacility: string; // facility name
  public static strPlant: string; // plant name
  public static strEquipment: string; // equipment name
  public static strAnalyst: string; // analyst name
  public static strTitle1: string; // for printing graphs
  public static buffer: string;
  public static buffer1: string;
  public static buffer2: string; // for sprintf operations
  public static intEquip_mawp: number; // component type; 0-cylinder, 1-cone, 2-sphere, 3-ellip head, 4-F&D head 5-B31 piping
  public static intEquip: number; // component type; 0-cylinder, 1-cone, 2-sphere, 3-ellip head, 4-F&D head 5-B31 piping
  public static sngIDo: number; // original inside diameter
  public static sngID: number; // corroded inside diameter
  public static sngtnom: number; // nominal thickness
  public static sngtrd: number; // current thickness
  public static sngtc: number; // future corroded thickness
  public static cet_temp: number; // added by shameer, to get cet temp and to display crackline result
  public static sngLOSSi: number; // internal metal loss
  public static sngLOSSe: number; // external metal loss
  public static sngFCAi: number; // internal future corrosion allowance
  public static sngFCAe: number; // external future corrosion allowance
  public static sngalpha: number; // cone apex angle
  public static sngrk: number; // F&D head radius
  public static sngY_b: number; // B31.3 factor
  public static sngS_stress: number; // allowable stress
  public static sngE_L: number; // longitudinal joint efficiency
  public static sngE_c: number; // circumferential joint efficiency
  public static sngH_L: number; // liquid head height
  public static sngs_g: number; // specific gravity
  public static sngp_H: number; // head pressure
  public static sngOD: number; // outside diameter
  public static sngMAWP: number; // MAWP of the component
  public static sngTemp_d: number; // design temperature, input from report input screen only
  public static sngt_sl: number; // required thickness for supplemental loads (axial direction)
  public static strDesign_T: string; // added variable
  public static sngP_d: number; // added variable
  public static sngFCA: number; // added variable
  public static sngtmin: number; // added variable
  public static sngtmin_L: number; // added variable
  public static sngr: number; // added variable for Radius at the base of the gouge for dent
  public static cal_cir_stress: number; // added variable for circum stress calculation to display in next screen for dent-gouge module 5/23/2023

  public static validationMessage = [];
  public static alertMessage = [];

  public static MAWP_initialize(): void {
    this.intUnit_select = parseInt(localStorage.getItem("unitType")) || 0;
    this.strD_class = "";
    this.strD_result = "";
    this.strFacility = "";
    this.strPlant = "";
    this.strEquipment = "";
    this.strAnalyst = "";
    this.sngIDo = -1;
    this.sngID = -1;
    this.sngtnom = -1;
    this.sngtrd = -1;
    this.sngtc = -1;
    this.sngLOSSi = -1;
    this.sngLOSSe = -1;
    this.sngFCAi = -1;
    this.sngFCAe = -1;
    this.sngalpha = -1;
    this.sngrk = -1;
    this.sngY_b = -1;
    this.sngS_stress = -1;
    this.sngE_L = -1;
    this.sngE_c = -1;
    this.sngH_L = 0;
    this.sngs_g = 0;
    this.sngp_H = 0;
    this.sngOD = -1;
    this.sngMAWP = -1;
    this.sngTemp_d = -1;
    this.sngt_sl = 0; //have to be 0 based on line 1169 in Form1.cpp
    this.sngP_d = 0;
    this.sngtmin = -1;
    this.sngtmin_L = 0;
    this.sngr = -1;
    this.cet_temp = -1;
    this.cal_cir_stress = -1;

    CalcTemplate.reset_data();
    this.validationMessage = [];
    this.alertMessage = [];
  }

  public static ReturnMAWPValuesAsHTML(): string {
    let result =
      "this.intUnit_select" +
      this.intUnit_select +
      "</br>" +
      "strD_class: " +
      this.strD_class +
      "</br>" +
      " strD_iclass: " +
      this.strD_iclass +
      "</br>" +
      " strD_result: " +
      this.strD_result +
      "</br>" +
      " strFacility: " +
      this.strFacility +
      "</br>" +
      " strPlant: " +
      this.strPlant +
      "</br>" +
      " strEquipment: " +
      this.strEquipment +
      "</br>" +
      " strAnalyst: " +
      this.strAnalyst +
      "</br>" +
      " strTitle1: " +
      this.strTitle1 +
      "</br>" +
      " buffer: " +
      this.buffer +
      "</br>" +
      " buffer1: " +
      this.buffer1 +
      "</br>" +
      " buffer2: " +
      this.buffer2 +
      "</br>" +
      " intEquip_mawp: " +
      this.intEquip_mawp +
      "</br>" +
      " intEquip: " +
      this.intEquip +
      "</br>" +
      " sngIDo: " +
      this.sngIDo +
      "</br>" +
      " sngID: " +
      this.sngID +
      "</br>" +
      " sngtnom: " +
      this.sngtnom +
      "</br>" +
      " sngtrd: " +
      this.sngtrd +
      "</br>" +
      " sngtc: " +
      this.sngtc +
      "</br>" +
      " cet_temp: " +
      this.cet_temp +
      "</br>" +
      " sngLOSSi: " +
      this.sngLOSSi +
      "</br>" +
      " sngLOSSe: " +
      this.sngLOSSe +
      "</br>" +
      " sngFCAi: " +
      this.sngFCAi +
      "</br>" +
      " sngFCAe: " +
      this.sngFCAe +
      "</br>" +
      " sngalpha: " +
      this.sngalpha +
      "</br>" +
      " sngrk: " +
      this.sngrk +
      "</br>" +
      " sngY_b: " +
      this.sngY_b +
      "</br>" +
      " sngS_stress: " +
      this.sngS_stress +
      "</br>" +
      " sngE_L: " +
      this.sngE_L +
      "</br>" +
      " sngE_c: " +
      this.sngE_c +
      "</br>" +
      " sngH_L: " +
      this.sngH_L +
      "</br>" +
      " sngs_g: " +
      this.sngs_g +
      "</br>" +
      " sngp_H: " +
      this.sngp_H +
      "</br>" +
      " sngOD: " +
      this.sngOD +
      "</br>" +
      " sngMAWP: " +
      this.sngMAWP +
      "</br>" +
      " sngTemp_d: " +
      this.sngTemp_d +
      "</br>" +
      " sngt_sl: " +
      this.sngt_sl +
      "</br>" +
      " sngP_d: " +
      this.sngP_d +
      "</br>" +
      " strDesign_T: " +
      this.strDesign_T +
      "</br>" +
      " sngP_d: " +
      this.sngP_d +
      "</br>" +
      " sngFCA: " +
      this.sngFCA +
      "</br>" +
      " sngtmin: " +
      this.sngtmin +
      "</br>" +
      " sngtmin_L: " +
      this.sngtmin_L +
      "</br>" +
      " sngr: " +
      this.sngr +
      "</br>" +
      " cal_cir_stress: " +
      this.cal_cir_stress;

    return result;
  }

  public static MAWP_calc(): string {
    // this.intUnit_select - unit selection: 0-US Customary, 1-SI
    let M: number;
    let aa: number;

    // Calculate static head (sngp_H), except for piping and tanks
    if (this.intEquip < 5) {
      // not piping and tanks
      if (this.intUnit_select == 0) {
        // US customary unit
        this.sngp_H = this.sngH_L * (62.4 / 144.0) * this.sngs_g;
      } else {
        // SI unit
        this.sngp_H = this.sngH_L * 0.00980665 * this.sngs_g;
      }
    }

    // Calculate current measured thickness (sngtrd), future corroded thickness (sngtc) and general future corrosion allowance (sngFCA)
    if (this.intEquip != 6) {
      // other than tanks
      this.sngtrd = this.sngtnom - this.sngLOSSi - this.sngLOSSe;
      this.sngtc = this.sngtrd - this.sngFCAi - this.sngFCAe;
      this.sngFCA = this.sngFCAi + this.sngFCAe; // FCA = FCAi + FCAe is for the component and is different than FCAml
    }

    switch (this.intEquip) {
      case 0: // cylinder
        this.sngOD =
          this.sngIDo +
          2.0 * this.sngtnom -
          2.0 * (this.sngLOSSe + this.sngFCAe);
        this.sngID = this.sngIDo + 2.0 * (this.sngLOSSi + this.sngFCAi);
        this.sngtmin =
          ((this.sngP_d + this.sngp_H) * (this.sngID / 2.0)) /
          (this.sngS_stress * this.sngE_L - 0.6 * (this.sngP_d + this.sngp_H));
        this.sngMAWP =
          (this.sngS_stress * this.sngE_L * this.sngtc) /
          (this.sngID / 2.0 + 0.6 * this.sngtc);
        break;

      case 1: // cone
        this.sngOD =
          this.sngIDo +
          2.0 * this.sngtnom -
          2.0 * (this.sngLOSSe + this.sngFCAe);
        this.sngID = this.sngIDo + 2.0 * (this.sngLOSSi + this.sngFCAi);
        aa = (this.sngalpha / 360.0) * this.PI; //half apex angle
        this.sngtmin =
          ((this.sngP_d + this.sngp_H) * this.sngID) /
          (2.0 *
            Math.cos(aa) *
            (this.sngS_stress * this.sngE_L -
              0.6 * (this.sngP_d + this.sngp_H)));
        this.sngMAWP =
          (2.0 * this.sngS_stress * this.sngE_L * this.sngtc * Math.cos(aa)) /
          (this.sngID + 1.2 * this.sngtc * Math.cos(aa));
        break;
      case 2: // sphere
        this.sngOD =
          this.sngIDo +
          2.0 * this.sngtnom -
          2.0 * (this.sngLOSSe + this.sngFCAe);
        this.sngID = this.sngIDo + 2.0 * (this.sngLOSSi + this.sngFCAi);
        this.sngtmin =
          ((this.sngP_d + this.sngp_H) * (this.sngID / 2.0)) /
          (2.0 * this.sngS_stress * this.sngE_L -
            0.2 * (this.sngP_d + this.sngp_H));
        this.sngMAWP =
          (2.0 * this.sngS_stress * this.sngE_L * this.sngtc) /
          (this.sngID / 2.0 + 0.2 * this.sngtc);
        break;
      case 3: // ASME 2-to-1 head
        this.sngID =
          0.9005 * (this.sngIDo + 2.0 * (this.sngLOSSi + this.sngFCAi));
        this.sngtmin =
          ((this.sngP_d + this.sngp_H) * this.sngID) /
          (2.0 * this.sngS_stress * this.sngE_L -
            0.2 * (this.sngP_d + this.sngp_H));
        this.sngMAWP =
          (2.0 * this.sngS_stress * this.sngE_L * this.sngtc) /
          (this.sngID + 0.2 * this.sngtc);
        break;
      case 4: // F&D head
        this.sngID = 2.0 * (this.sngIDo + this.sngLOSSi + this.sngFCAi);
        M = 0.25 * (3.0 + Math.sqrt(this.sngID / 2.0 / this.sngrk));
        this.sngtmin =
          ((this.sngP_d + this.sngp_H) * (this.sngID / 2.0) * M) /
          (2.0 * this.sngS_stress * this.sngE_L -
            0.2 * (this.sngP_d + this.sngp_H));
        this.sngMAWP =
          (2.0 * this.sngS_stress * this.sngE_L * this.sngtc) /
          ((M * this.sngID) / 2.0 + 0.2 * this.sngtc);
        break;
      case 5: // B31 piping
        this.sngOD = this.sngIDo - 2.0 * (this.sngLOSSe + this.sngFCAe);
        this.sngID =
          this.sngIDo -
          2.0 * this.sngtnom +
          2.0 * (this.sngLOSSi + this.sngFCAi);
        this.sngtmin =
          (this.sngP_d * this.sngOD) /
          (2.0 * (this.sngS_stress * this.sngE_L + this.sngP_d * this.sngY_b));
        this.sngMAWP =
          (2.0 * this.sngS_stress * this.sngE_L * this.sngtc) /
          (this.sngOD - 2.0 * this.sngY_b * this.sngtc);
        break;
      case 6: // tanks, no calculations
        break;
    }
    // added by shameer 5/22/2023 -start
    if (
      this.intUnit_select == 0 &&
      this.strD_class == "DentGouge" &&
      this.intEquip == 0
    ) {
      // for dent - gouge module and for cylinder selection and works for both uscustomry and SI units.
      let sigma_c1: number,
        ph1: number,
        R: number,
        t1: number,
        trd1: number,
        fca1: number;

      ph1 = (this.sngH_L / 2.31) * this.sngs_g;
      R = (this.sngIDo + 2 * this.sngLOSSi + 2 * this.sngFCAi) / 2;
      trd1 = this.sngtnom - this.sngLOSSi - this.sngLOSSe;
      fca1 = this.sngFCAi + this.sngFCAe;
      t1 = trd1 - fca1;
      sigma_c1 = ((this.sngP_d + ph1) / (1000 * this.sngE_L)) * (R / t1 + 0.6);
      this.cal_cir_stress = sigma_c1;
    }

    if (
      this.intUnit_select == 0 &&
      this.strD_class == "DentGouge" &&
      this.intEquip == 5
    ) {
      // for dent - gouge module and for for B31.3 Piping and works for both uscustomry and SI units.
      let sigma_c2: number, t2: number, trd2: number, fca2: number, ma: number;

      trd2 = this.sngtnom - this.sngLOSSi - this.sngLOSSe;
      fca2 = this.sngFCAi + this.sngFCAe;
      t2 = trd2 - fca2;
      ma = 0;
      sigma_c2 =
        (this.sngP_d / (1000 * this.sngE_L)) *
        (this.sngIDo / (2 * (t2 - ma)) - this.sngY_b);
      this.cal_cir_stress = sigma_c2;
    }
    if (
      this.intUnit_select == 1 &&
      this.strD_class == "DentGouge" &&
      this.intEquip == 0
    ) {
      // for dent - gouge module and for cylinder selection and works for both uscustomry and SI units.
      let sigma_c1: number,
        ph1: number,
        R: number,
        t1: number,
        trd1: number,
        fca1: number;

      ph1 = 0.0098 * this.sngH_L * this.sngs_g;
      R = (this.sngIDo + 2 * this.sngLOSSi + 2 * this.sngFCAi) / 2;
      trd1 = this.sngtnom - this.sngLOSSi - this.sngLOSSe;
      fca1 = this.sngFCAi + this.sngFCAe;
      t1 = trd1 - fca1;
      sigma_c1 = ((this.sngP_d + ph1) / this.sngE_L) * (R / t1 + 0.6);
      this.cal_cir_stress = sigma_c1;
    }

    if (
      this.intUnit_select == 1 &&
      this.strD_class == "DentGouge" &&
      this.intEquip == 5
    ) {
      // for dent - gouge module and for for B31.3 Piping and works for both uscustomry and SI units.
      let sigma_c2: number, t2: number, trd2: number, fca2: number, ma: number;

      trd2 = this.sngtnom - this.sngLOSSi - this.sngLOSSe;
      fca2 = this.sngFCAi + this.sngFCAe;
      t2 = trd2 - fca2;
      ma = 0;
      sigma_c2 =
        (this.sngP_d / this.sngE_L) *
        (this.sngIDo / (2 * (t2 - ma)) - this.sngY_b);
      this.cal_cir_stress = sigma_c2;
    }

    return Component_MAWP.mawpReturnText();
  }

  private static mawpReturnText() {
    let result = "";

    switch (this.intUnit_select) {
      case 0: // US Customary
        result +=
          "Current measured thickness, trd = tnom - LOSSi - LOSSe = " +
          this.sngtrd.toFixed(3) +
          " inch<br />";
        result +=
          "Future corrosion allowance, FCA = FCAi + FCAe = " +
          this.sngFCA.toFixed(3) +
          " inch<br />";
        result +=
          "Future corroded thickness, tc = trd - FCA = " +
          this.sngtc.toFixed(3) +
          " inch<br />";
        // Carlos Acero - 07/24/24
        // Hide below result if component type selected = 2:1 Elliptical Head
        // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7736452
        //same requirement extended for F & D Head added by Shameer
        if ((Component_MAWP.intEquip !== 3)&&(Component_MAWP.intEquip !== 4)) {
          result +=
            "Inside diameter corrected for LOSSi and FCAi, D = " +
            this.sngID.toFixed(3) +
            " inch<br />";
        }
        result +=
          "Minimum required thickness, tmin = " +
          this.sngtmin.toFixed(3) +
          " inch<br />";
        result +=
          "Maximum Allowable Working Pressure based on tc, MAWP = " +
          this.sngMAWP.toFixed(1) +
          " psi<br />";
        if (this.intEquip < 5) {
          // no static head output for B31.3 piping
          if (this.sngp_H <= 0.0) {
            result +=
              "Zero static (liquid) head. No static head adjustment to MAWP.<br />";
          } else {
            result +=
              "Static (liquid) head = " +
              this.sngp_H.toFixed(1) +
              " psi <br />Adjusted MAWP = MAWP - static head = " +
              (this.sngMAWP - this.sngp_H).toFixed(1) +
              " psi<br />";
          }
        }
        break;
      case 1:
        result +=
          "Current measured thickness, trd = tnom - LOSSi - LOSSe = " +
          this.sngtrd.toFixed(1) +
          " mm<br />";
        result +=
          "Future corrosion allowance, FCA = FCAi + FCAe = " +
          this.sngFCA.toFixed(1) +
          " mm<br />";
        result +=
          "Future corroded thickness, tc = trd - FCA = " +
          this.sngtc.toFixed(1) +
          " mm<br />";
        // Carlos Acero - 07/24/24
        // Hide below result if component type selected = 2:1 Elliptical Head
        // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7736452
         //same requirement extended for F & D Head added by Shameer
        if ((Component_MAWP.intEquip !== 3)&&(Component_MAWP.intEquip !== 4)) {
          result +=
            "Inside diameter corrected for LOSSi and FCAi, D = " +
            this.sngID.toFixed(1) +
            " mm<br />";
        }
        result +=
          "Minimum required thickness, tmin = " +
          this.sngtmin.toFixed(1) +
          " mm<br />";
        result +=
          "Maximum Allowable Working Pressure based on tc, MAWP = " +
          this.sngMAWP.toFixed(2) +
          " MPa<br />";
        if (this.intEquip < 5) {
          // no static head output for B31.3 piping
          if (this.sngp_H <= 0.0) {
            result +=
              "Zero static / liquid head. No static head adjustment to MAWP.<br />";
          } else {
            result +=
              "Static / liquid head = " +
              this.sngp_H.toFixed(2) +
              " MPa <br />Adjusted MAWP = MAWP - static head = " +
              (this.sngMAWP - this.sngp_H).toFixed(2) +
              " MPa<br />";
          }
        }
        break;
    }

    return `<font color="blue">` + result + `</font>`;
  }

  public static calc_Check(inspectionDataFormGroup): boolean {
    this.validationMessage = [];
    this.alertMessage = [];

    let intUnit_select = 0;
    if (this.intUnit_select === 1) {
      intUnit_select = 1;
    }
    let messagesValidationsList: string[] = [];

    let textBox2_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox2_MAWP");
    let textBox3_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox3_MAWP");
    let textBox4_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox4_MAWP");
    let textBox5_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox5_MAWP");
    let textBox6_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox6_MAWP");
    let textBox7_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox7_MAWP");
    let textBox8_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox8_MAWP");
    let textBox9_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox9_MAWP");
    let textBox10_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox10_MAWP");
    let textBox11_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox11_MAWP");
    let textBox12_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox12_MAWP");
    let textBox13_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox13_MAWP");
    let textBox14_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox14_MAWP");
    let textBox15_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox15_MAWP");
    let textBox16_MAWP: FormControl =
      inspectionDataFormGroup.get("textBox16_MAWP");

    let checkFlag = true;
    let checkFlag1 = true; // added by shameer 5/2/2023
    let message1: string[] = [];
    let msg: string;
    let equipmentString: string;

    if (Component_MAWP.intEquip < 0 || Component_MAWP.intEquip > 6) {
      messagesValidationsList.push("Component not set.\n");
      checkFlag = false;
    }

    if (Component_MAWP.intEquip != 6) {
      // non-tanks
      //textBox2_MAWP error handling
      if (textBox2_MAWP.enabled) {
        equipmentString = "Original inside diameter, D - ";
        if (!Utility.isTextADouble(textBox2_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngIDo <= 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than or equal to zero.\n"
          );
          checkFlag = false;
        }
      }

      //textBox3_MAWP error handling
      if (textBox3_MAWP.enabled) {
        equipmentString = "Original nominal thickness, tnom - ";
        if (!Utility.isTextADouble(textBox3_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngtnom <= 0) {
          //modified with new requirement
          messagesValidationsList.push(
            equipmentString + "cannot be less than or equal to zero.\n"
          );
          checkFlag = false;
        } else if (Component_MAWP.sngtnom > Component_MAWP.sngIDo / 4) {
          //modified with new requirement
          messagesValidationsList.push(
            equipmentString + "cannot be greater than D/4 .\n"
          );
          checkFlag = false;
        }
      }
      //textBox4_MAWP error handling
      if (textBox4_MAWP.enabled) {
        equipmentString = "Current uniform metal loss (internal), sngLOSSi - ";
        if (!Utility.isTextADouble(textBox4_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngLOSSi < 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than zero.\n"
          );
          checkFlag = false;
        }
      }
      //textBox5_MAWP error handling
      if (textBox5_MAWP.enabled) {
        equipmentString = "Current uniform metal loss (external), sngLOSSe - ";
        if (!Utility.isTextADouble(textBox5_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngLOSSe < 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than zero.\n"
          );
          checkFlag = false;
        }
      }
      //textBox6_MAWP error handling
      if (textBox6_MAWP.enabled) {
        equipmentString = "Future corrosion allowance (internal), sngFCAi - ";
        if (!Utility.isTextADouble(textBox6_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngFCAi < 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than zero.\n"
          );
        }
      }
      //textBox7_MAWP error handling
      if (textBox7_MAWP.enabled) {
        let equipmentString =
          "Future corrosion allowance (external), sngFCAe - ";
        if (!Utility.isTextADouble(textBox7_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngFCAe < 0) {
          // modified
          messagesValidationsList.push(
            equipmentString + "cannot be less than zero.\n"
          );
          checkFlag = false;
        } else if (
          Component_MAWP.sngLOSSi +
          Component_MAWP.sngLOSSe +
          Component_MAWP.sngFCAi +
          Component_MAWP.sngFCAe >=
          Component_MAWP.sngtnom
        ) {
          // modified
          messagesValidationsList.push(
            "LOSSi + LOSSe + FCAi + FCAe cannot be greater than or equal to tnom.\n"
          );
          checkFlag = false;
        }
      }
      //textbox8_MAWP error handling
      if (textBox8_MAWP.enabled) {
        let equipmentString = "Equipment design pressure, P_d - ";
        if (!Utility.isTextADouble(textBox8_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngP_d <= 0.0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than or equal to zero.\n"
          ); //modified
          checkFlag = false;
        } else if (
          Component_MAWP.sngP_d >
          0.385 * Component_MAWP.sngS_stress * Component_MAWP.sngE_L
        ) {
          messagesValidationsList.push(
            equipmentString + "Cannot be greater than 0.385* S * E_L .\n"
          ); //modified by shameer, Cannot be greater than 0.385* S * E_L
          checkFlag = false;
        }
      }
      //textBox9_MAWP error handling
      if (textBox9_MAWP.enabled) {
        let equipmentString = "Allowable stress, S - ";
        if (!Utility.isTextADouble(textBox9_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngS_stress <= 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than or equal to zero.\n"
          );
          checkFlag = false;
        }
      }
      //textBox10_MAWP error handling
      if (textBox10_MAWP.enabled) {
        let equipmentString = "Longitudinal weld joint efficiency, E_L - ";
        if (!Utility.isTextADouble(textBox10_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngE_L <= 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than or equal to zero.\n"
          ); //modified
          checkFlag = false;
        } else if (Component_MAWP.sngE_L > 1) {
          messagesValidationsList.push(
            equipmentString + "cannot be greater than one.\n"
          ); //modified
          checkFlag = false;
        }
      }
      //textbox11_MAWP error handling
      if (textBox11_MAWP.enabled) {
        let equipmentString = "Circumferential weld joint efficiency, E_C - "; // modified by shameer c to C
        if (!Utility.isTextADouble(textBox11_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngE_c <= 0.0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than or equal to zero.\n"
          ); //modified
          checkFlag = false;
        } else if (Component_MAWP.sngE_c > 1) {
          messagesValidationsList.push(
            equipmentString + "cannot be greater than one.\n"
          ); //modified
          checkFlag = false;
        }
      }
      //textBox12_MAWP error handling
      if (textBox12_MAWP.enabled) {
        let equipmentString = "Apex angle for conical shell, Alpha - ";
        //set statement based on equipment
        if (Component_MAWP.intEquip == 5) {
          equipmentString = "B31.3 Y factor - ";
        } else if (Component_MAWP.intEquip == 4) {
          equipmentString = "Knuckle radius of the head - ";
        }

        let property = "sngalpha";
        let errorMsg = "cannot be less than or equal to zero\n";

        if (Component_MAWP.intEquip == 5) {
          equipmentString = "B31.3 Y factor - ";
          property = "sngY_b";
        } else if (Component_MAWP.intEquip == 4) {
          equipmentString = "Knuckle radius of the head - ";
          property = "sngrk";
        }

        if (
          !Utility.isTextADouble(textBox12_MAWP.value) ||
          Component_MAWP[property] <= 0
        ) {
          messagesValidationsList.push(equipmentString + errorMsg);
          checkFlag = false;
        }
      }
      //textBox13_MAWP error handling
      if (textBox13_MAWP.enabled) {
        let equipmentString = "Supplemental loads thickness, t_sl - ";
        if (!Utility.isTextADouble(textBox13_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngt_sl < 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than zero.\n"
          );
          checkFlag = false;
        }
      }
      //as new requirement, it is not needed but using in different calculations, so assigning 0 and in future when required need to remove this
      if (!textBox13_MAWP.enabled) {
        Component_MAWP.sngt_sl = 0;
      }
      //textBox14_MAWP error handling
      if (textBox14_MAWP.enabled) {
        let equipmentString = "Liquid head height, H_L - ";
        if (!Utility.isTextADouble(textBox14_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngH_L < 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than zero.\n"
          );
          checkFlag = false;
        }
      }
      //textBox15_MAWP error handling
      if (textBox15_MAWP.enabled) {
        let equipmentString = "Specific gravity, s_g - ";
        if (!Utility.isTextADouble(textBox15_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (Component_MAWP.sngs_g <= 0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than or equal to zero.\n"
          );
          checkFlag = false;
        }
      }
      //textBox16_MAWP error handling
      if (textBox16_MAWP.enabled) {
        let equipmentString = "Allowable Remaining Strength Factor, RSFa - ";
        if (!Utility.isTextADouble(textBox16_MAWP.value)) {
          messagesValidationsList.push(equipmentString + msg + "\n");
          checkFlag = false;
        } else if (CalcTemplate.sngRSF_a < 0.0) {
          messagesValidationsList.push(
            equipmentString + "cannot be less than zero.\n"
          );
          checkFlag = false;
        }
      }
    }

    //check if there is an error
    if (!checkFlag) {
      this.validationMessage = !checkFlag ? messagesValidationsList : [];
      return false;
    } else {
      //if there is no error but below new conditions have to be checked, if satisfy then needs to show an alert message but no stop to proceed next screen. shameer 5/1/2023
      // for display alert messages when Cylinder is selected  5/3/2023

      if (textBox2_MAWP.enabled) {
        //Original inside diameter, D
        if (
          Component_MAWP.intUnit_select == 0 &&
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 0 &&
          (6.625 > Component_MAWP.sngIDo + 2 * Component_MAWP.sngtnom ||
            Component_MAWP.sngIDo + 2 * Component_MAWP.sngtnom > 42)
        ) {
          // added by shameer 5/2/2023 for uscustomary,Dent/DentGouge, for cylindedr
          message1.push(
            "- The original outside diameter is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        } else if (
          Component_MAWP.intUnit_select == 1 &&
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 0 &&
          (168 > Component_MAWP.sngIDo + 2 * Component_MAWP.sngtnom ||
            Component_MAWP.sngIDo + 2 * Component_MAWP.sngtnom > 1050)
        ) {
          // added by shameer 4/25/2023 for SI,Dent/DentGouge, for cylindedr
          message1.push(
            "- The original outside diameter is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        }
      }

      if (textBox3_MAWP.enabled) {
        //Original nominal thickness, tnom
        if (
          Component_MAWP.intUnit_select == 0 &&
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 0 &&
          (0.2 >
            Component_MAWP.sngtnom -
            Component_MAWP.sngLOSSi -
            Component_MAWP.sngLOSSe ||
            Component_MAWP.sngtnom -
            Component_MAWP.sngLOSSi -
            Component_MAWP.sngLOSSe >
            0.75)
        ) {
          // added by shameer 4/25/2023 for uscustomary,Dent/DentGouge, for cylindedr
          message1.push(
            "- The current measured thickness is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        } else if (
          Component_MAWP.intUnit_select == 1 &&
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 0 &&
          (5 >
            Component_MAWP.sngtnom -
            Component_MAWP.sngLOSSi -
            Component_MAWP.sngLOSSe ||
            Component_MAWP.sngtnom -
            Component_MAWP.sngLOSSi -
            Component_MAWP.sngLOSSe >
            19)
        ) {
          // added by shameer 4/25/2023 for SI,Dent/DentGouge, for cylindedr
          message1.push(
            "- The current measured thickness is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        }
      }

      if (textBox2_MAWP.enabled) {
        //Original inside diameter, D
        if (
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 0 &&
          (23.6 >
            (Component_MAWP.sngIDo + 2 * Component_MAWP.sngtnom) /
            (Component_MAWP.sngtnom -
              Component_MAWP.sngLOSSi -
              Component_MAWP.sngLOSSe) ||
            (Component_MAWP.sngIDo + 2 * Component_MAWP.sngtnom) /
            (Component_MAWP.sngtnom -
              Component_MAWP.sngLOSSi -
              Component_MAWP.sngLOSSe) >
            80)
        ) {
          // added by shameer 5/1/2023 for US Customary and SI,Dent/DentGouge, for cylindedr
          message1.push(
            "- The original outside diameter to current measured thickness ratio is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        }
      }

      // for display alert messages when B31.3 Piping is selected
      if (textBox2_MAWP.enabled) {
        //Original inside diameter, D
        if (
          Component_MAWP.intUnit_select == 0 &&
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 5 &&
          (6.625 > Component_MAWP.sngIDo || Component_MAWP.sngIDo > 42)
        ) {
          // added by shameer 5/3/2023 for uscustomary,Dent/DentGouge, for B31.3 Piping
          message1.push(
            "- The original outside diameter is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        } else if (
          Component_MAWP.intUnit_select == 1 &&
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 5 &&
          (168 > Component_MAWP.sngIDo || Component_MAWP.sngIDo > 1050)
        ) {
          // added by shameer 5/3/2023 for SI,Dent/DentGouge, for B31.3 Piping
          message1.push(
            "- The original outside diameter is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        }
      }

      if (textBox3_MAWP.enabled) {
        //Original nominal thickness, tnom
        if (
          Component_MAWP.intUnit_select == 0 &&
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 5 &&
          (0.2 >
            Component_MAWP.sngtnom -
            Component_MAWP.sngLOSSi -
            Component_MAWP.sngLOSSe ||
            Component_MAWP.sngtnom -
            Component_MAWP.sngLOSSi -
            Component_MAWP.sngLOSSe >
            0.75)
        ) {
          // added by shameer 5/3/2023 for uscustomary,Dent/DentGouge, for B31.3 Piping
          message1.push(
            "- The current measured thickness is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        } else if (
          Component_MAWP.intUnit_select == 1 &&
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 5 &&
          (5 >
            Component_MAWP.sngtnom -
            Component_MAWP.sngLOSSi -
            Component_MAWP.sngLOSSe ||
            Component_MAWP.sngtnom -
            Component_MAWP.sngLOSSi -
            Component_MAWP.sngLOSSe >
            19)
        ) {
          // added by shameer 5/3/2023 for SI,Dent/DentGouge, for B31.3 Piping
          message1.push(
            "- The current measured thickness is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        }
      }

      if (textBox2_MAWP.enabled) {
        //Original inside diameter, D
        if (
          (Component_MAWP.strD_class == "Dent" ||
            Component_MAWP.strD_class == "DentGouge") &&
          Component_MAWP.intEquip == 5 &&
          (23.6 >
            Component_MAWP.sngIDo /
            (Component_MAWP.sngtnom -
              Component_MAWP.sngLOSSi -
              Component_MAWP.sngLOSSe) ||
            Component_MAWP.sngIDo /
            (Component_MAWP.sngtnom -
              Component_MAWP.sngLOSSi -
              Component_MAWP.sngLOSSe) >
            80)
        ) {
          // added by shameer 5/1/2023 for US Customary and SI,Dent/DentGouge, for cylindedr
          message1.push(
            "- The original outside diameter to current measured thickness ratio is out of range for this assessment module.\n"
          );
          checkFlag1 = false;
        }
      }
    }

    this.alertMessage = !checkFlag1 ? message1 : [];
    return true;
  }

  public static updateMAWPFormControls(MAWPFormGroup): void {
    let comboBox1_MAWP_formControl: FormControl = MAWPFormGroup.get(
      "selectedComponentType"
    );
    let textBox2_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox2_MAWP");
    let textBox3_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox3_MAWP");
    let textBox4_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox4_MAWP");
    let textBox5_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox5_MAWP");
    let textBox6_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox6_MAWP");
    let textBox7_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox7_MAWP");
    let textBox8_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox8_MAWP");
    let textBox9_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox9_MAWP");
    let textBox10_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox10_MAWP");
    let textBox11_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox11_MAWP");
    let textBox12_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox12_MAWP");
    let textBox13_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox13_MAWP");
    let textBox14_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox14_MAWP");
    let textBox15_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox15_MAWP");
    let textBox16_MAWP_formControl: FormControl =
      MAWPFormGroup.get("textBox16_MAWP");

    if ([7, 8].indexOf(Component_MAWP.strD_iclass) !== -1) {
      if (Component_MAWP.intEquip == 0)
        comboBox1_MAWP_formControl.setValue(Component_MAWP.intEquip);
      // intEquip = 1 for B31.3 Piping in Dents/DentGouge assessments and there are only 2 items in the combobox. Therefore, it needs to be set as "5", which is the value in combobox for B31.3 Piping
      else comboBox1_MAWP_formControl.setValue(5);
    } else {
      if (Component_MAWP.intEquip != -1) {
        comboBox1_MAWP_formControl.setValue(Component_MAWP.intEquip);
      }
    }

    if (Component_MAWP.intEquip != 6) {
      if (Component_MAWP.sngIDo != -1)
        textBox2_MAWP_formControl.setValue(Component_MAWP.sngIDo.toString());
    } else {
      if (Component_MAWP.sngID != -1)
        textBox2_MAWP_formControl.setValue(Component_MAWP.sngID.toString());
    }

    if (Component_MAWP.intEquip != 6) {
      if (Component_MAWP.sngtnom != -1)
        textBox3_MAWP_formControl.setValue(Component_MAWP.sngtnom.toString());
    } else {
      if (Component_MAWP.sngtc != -1)
        textBox3_MAWP_formControl.setValue(Component_MAWP.sngtc.toString());
    }

    if (Component_MAWP.intEquip != 6) {
      if (Component_MAWP.sngLOSSi != -1)
        textBox4_MAWP_formControl.setValue(Component_MAWP.sngLOSSi.toString());
    } else {
      if (CalcTemplate.sngMFH != -1)
        textBox4_MAWP_formControl.setValue(CalcTemplate.sngMFH.toString());
    }

    if (Component_MAWP.intEquip != 6) {
      if (Component_MAWP.sngLOSSe != -1)
        textBox5_MAWP_formControl.setValue(Component_MAWP.sngLOSSe.toString());
    } else {
      if (CalcTemplate.sngMFH_d != -1)
        textBox5_MAWP_formControl.setValue(CalcTemplate.sngMFH_d.toString());
    }

    if (Component_MAWP.intEquip != 6) {
      if (Component_MAWP.sngFCAi != -1)
        textBox6_MAWP_formControl.setValue(Component_MAWP.sngFCAi.toString());
    } else {
      if (Component_MAWP.sngE_L != -1)
        textBox6_MAWP_formControl.setValue(Component_MAWP.sngE_L.toString());
    }

    if (Component_MAWP.intEquip != 6) {
      if (Component_MAWP.sngFCAe != -1)
        textBox7_MAWP_formControl.setValue(Component_MAWP.sngFCAe.toString());
    } else {
      if (Component_MAWP.sngE_c != -1)
        textBox7_MAWP_formControl.setValue(Component_MAWP.sngE_c.toString());
    }

    if (Component_MAWP.intEquip != 6) {
      if (Component_MAWP.sngP_d != -1)
        textBox8_MAWP_formControl.setValue(Component_MAWP.sngP_d.toString());
    } else {
      if (Component_MAWP.sngtmin != -1)
        textBox8_MAWP_formControl.setValue(Component_MAWP.sngtmin.toString());
    }

    if (Component_MAWP.intEquip != 6) {
      if (Component_MAWP.sngS_stress != -1)
        textBox9_MAWP_formControl.setValue(
          Component_MAWP.sngS_stress.toString()
        );
    } else {
      if (CalcTemplate.sngRSF_a != -1)
        textBox9_MAWP_formControl.setValue(CalcTemplate.sngRSF_a.toString());
    }

    if (Component_MAWP.sngE_L != -1)
      textBox10_MAWP_formControl.setValue(Component_MAWP.sngE_L.toString());
    if (Component_MAWP.sngE_c != -1)
      textBox11_MAWP_formControl.setValue(Component_MAWP.sngE_c.toString());
    if (Component_MAWP.intEquip == 1 && Component_MAWP.sngalpha != -1)
      textBox12_MAWP_formControl.setValue(Component_MAWP.sngalpha.toString());
    if (Component_MAWP.intEquip == 5 && Component_MAWP.sngY_b != -1)
      textBox12_MAWP_formControl.setValue(Component_MAWP.sngY_b.toString());
    if (Component_MAWP.intEquip == 4 && Component_MAWP.sngrk != -1)
      textBox12_MAWP_formControl.setValue(Component_MAWP.sngrk.toString());
    if (Component_MAWP.sngt_sl != -1)
      textBox13_MAWP_formControl.setValue(
        Component_MAWP.sngt_sl != -1 ? Component_MAWP.sngt_sl.toString() : ""
      );
    if (Component_MAWP.sngH_L != -1)
      textBox14_MAWP_formControl.setValue(Component_MAWP.sngH_L.toString());
    if (Component_MAWP.sngs_g != -1)
      textBox15_MAWP_formControl.setValue(Component_MAWP.sngs_g.toString());
    if (CalcTemplate.sngRSF_a != -1)
      textBox16_MAWP_formControl.setValue(CalcTemplate.sngRSF_a.toString());

    this.changeComponentType(MAWPFormGroup);
  }

  public static changeComponentType(MAWPFormGroup: FormGroup<any>) {
    switch (Component_MAWP.intEquip) {
      case 0:
        MAWPFormGroup.controls.textBox13_MAWP.disable();
        MAWPFormGroup.controls.textBox12_MAWP.disable();
        MAWPFormGroup.controls.textBox13_MAWP.setValue("");
        MAWPFormGroup.controls.textBox12_MAWP.setValue("");
        break;
      case 1:
        MAWPFormGroup.controls.textBox13_MAWP.disable();
        MAWPFormGroup.controls.textBox13_MAWP.setValue("");
        break;
      case 2:
        MAWPFormGroup.controls.textBox13_MAWP.disable();
        MAWPFormGroup.controls.textBox12_MAWP.disable();
        MAWPFormGroup.controls.textBox11_MAWP.disable();
        MAWPFormGroup.controls.textBox13_MAWP.setValue("");
        MAWPFormGroup.controls.textBox12_MAWP.setValue("");
        MAWPFormGroup.controls.textBox11_MAWP.setValue("");
        break;
      case 3:
        MAWPFormGroup.controls.textBox13_MAWP.disable();
        MAWPFormGroup.controls.textBox12_MAWP.disable();
        MAWPFormGroup.controls.textBox11_MAWP.disable();
        MAWPFormGroup.controls.textBox13_MAWP.setValue("");
        MAWPFormGroup.controls.textBox12_MAWP.setValue("");
        MAWPFormGroup.controls.textBox11_MAWP.setValue("");
        break;
      case 4:
        MAWPFormGroup.controls.textBox13_MAWP.disable();
        MAWPFormGroup.controls.textBox11_MAWP.disable();
        MAWPFormGroup.controls.textBox13_MAWP.setValue("");
        MAWPFormGroup.controls.textBox11_MAWP.setValue("");
        break;
      case 5:
        MAWPFormGroup.controls.textBox13_MAWP.disable();
        MAWPFormGroup.controls.textBox14_MAWP.disable();
        MAWPFormGroup.controls.textBox15_MAWP.disable();
        MAWPFormGroup.controls.textBox13_MAWP.setValue("");
        MAWPFormGroup.controls.textBox14_MAWP.setValue("");
        MAWPFormGroup.controls.textBox15_MAWP.setValue("");
        break;
    }

    this.disableFiled(MAWPFormGroup);
  }

  public static disableFiled(mawpForm: FormGroup<any>) {
    const textBox14_MAWP_value = mawpForm.get("textBox14_MAWP").value;

    if (
      typeof textBox14_MAWP_value === "string" &&
      textBox14_MAWP_value === "0"
    ) {
      //Only for import. There is string instead of number...
      mawpForm.get("textBox15_MAWP").disable();
      mawpForm.get("textBox15_MAWP").setValue("");
    }

    if (typeof textBox14_MAWP_value === "number") {
      if (textBox14_MAWP_value == 0) {
        mawpForm.get("textBox15_MAWP").disable();
        mawpForm.get("textBox15_MAWP").setValue("");
      } else {
        mawpForm.get("textBox15_MAWP").enable();
      }
    }
  }
}
