import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { LTA_module } from "../services/lta_module";
import { Dent_Module } from "./dent_module";
import { FormControl } from "@angular/forms";
import { formatDate } from "@angular/common";

export class Laminations_module {
  // Variables for Laminations Level 1 calculations
  public static intBulge: number; // bulging, 0 - No, 1 - Yes
  public static intElevation: number; // at different elevations? 0 - No (same elevation), 1 - Yes (different elevations)
  public static intH2: number; // 0 - No, 1 - Yes
  public static sngLs: number; // sapcing to the nearest lamination
  public static sngLh: number; // Lamination height

  public static intCrackCheck: number; //0 - No, 1 - Yes

  public static validationMessage = [];
  public static laminationsCalcResult = "";

  Laminations_module() {
    Laminations_module.Laminations_initialize();
  }

  public static Laminations_initialize(): void {
    this.intBulge = -1;
    this.intElevation = -1;
    this.intH2 = -1;
    this.sngLs = -1;
    this.sngLh = -1;
    this.intCrackCheck = -1;

    // Carlos Acero - 01/09/24
    // Set Any major structural discontinuities or other laminations infringe on the 2s x 2c box? select to NO as default and hide
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    CalcTemplate.intSCbox = 0;
    //other variables belonging to different modules
    CalcTemplate.sngLmsd = -1;
    LTA_module.sngss = -1;
    LTA_module.sngcc = -1;
    LTA_module.sngtmm = -1;
    Dent_Module.sngLw = -1;

    this.validationMessage = [];
    this.laminationsCalcResult = "";
  }

  public static Laminations_calc(): string {
    // Lamination assessment
    // Function to perform lamination Level 1 calculations
    let aa: number;
    let key1: number,
      key2: number,
      key3: number,
      key4: number,
      key5: number,
      key6: number,
      key7: number,
      key8: number;

    this.laminationsCalcResult = "";

    // Carlos Acero - 01/09/24
    // Set Any major structural discontinuities or other laminations infringe on the 2s x 2c box? select to NO as default and hide
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    CalcTemplate.intSCbox = 0;

    // First criterion -- 2s x 2c box
    // Carlos Acero - 07/16/24
    // Commented below block since CalcTemplate.intSCbox is set as NO by default
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    /*
    if (CalcTemplate.intSCbox === 0) {
      key1 = 0;
      this.laminationsCalcResult +=
        "Lamination meets the 2s x 2c box interaction criterion.<br />";
    } else {
      key1 = 1;
      this.laminationsCalcResult +=
        'Lamination fails the 2s x 2c box interaction criterion. <font color="red">*Fail*</font><br />';
    }
    */
    key1 = 0;

    // Second criterion - check Ls against 2 x tc
    aa = 2.0 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = this.sngLs.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (this.sngLs > aa) {
        key2 = 0;
        this.laminationsCalcResult +=
          "Spacing to the nearest lamination, Ls = " +
          Component_MAWP.buffer +
          " inch > 2 * tc = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      } else {
        key2 = 1;
        if (this.intElevation === 0) {
          // at the same elevation
          this.laminationsCalcResult +=
            "Spacing to the nearest lamination, Ls = " +
            Component_MAWP.buffer +
            " inch <= 2 * tc = " +
            Component_MAWP.buffer1 +
            " inch, they need to be combined!<br />";
        } else {
          // at different elevations
          this.laminationsCalcResult +=
            "Spacing to the nearest lamination, Ls = " +
            Component_MAWP.buffer +
            " inch <= 2 * tc = " +
            Component_MAWP.buffer1 +
            ' inch. <font color="red">Use HIC procedure!</font><br />';
        }
      }
    } else {
      // SI
      Component_MAWP.buffer = this.sngLs.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (this.sngLs > aa) {
        key2 = 0;
        this.laminationsCalcResult +=
          "Spacing to the nearest lamination, Ls = " +
          Component_MAWP.buffer +
          " mm > 2 * tc = " +
          Component_MAWP.buffer1 +
          " mm<br />";
      } else {
        key2 = 1;
        if (this.intElevation === 0) {
          // at the same elevation
          this.laminationsCalcResult +=
            "Spacing to the nearest lamination, Ls = " +
            Component_MAWP.buffer +
            " mm <= 2 * tc = " +
            Component_MAWP.buffer1 +
            " mm, they need to be combined!<br />";
        } else {
          // at different elevations
          this.laminationsCalcResult +=
            "Spacing to the nearest lamination, Ls = " +
            Component_MAWP.buffer +
            " mm <= 2 * tc = " +
            Component_MAWP.buffer1 +
            ' mm. <font color="red">Use HIC procedure!</font><br />';
        }
      }
    }

    // third criterion -- distance to structural discontinuity, Lmsd
    aa = 1.8 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      key3 = 0;
      if (CalcTemplate.sngLmsd >= aa) {
        this.laminationsCalcResult +=
          "Lmsd = " +
          Component_MAWP.buffer +
          " inch >= 1.8 * sqrt(ID * tc) = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      } else {
        key3 = 1;
        this.laminationsCalcResult +=
          "Lmsd = " +
          Component_MAWP.buffer +
          " inch < 1.8 * sqrt(ID * tc) = " +
          Component_MAWP.buffer1 +
          ' inch  <font color="red">*Fail*</font><br />';
      }
    } else {
      // SI
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      key3 = 0;
      if (CalcTemplate.sngLmsd >= aa) {
        this.laminationsCalcResult +=
          "Lmsd = " +
          Component_MAWP.buffer +
          " mm >= 1.8 * sqrt(ID * tc) = " +
          Component_MAWP.buffer1 +
          " mm<br />";
      } else {
        key3 = 1;
        this.laminationsCalcResult +=
          "Lmsd = " +
          Component_MAWP.buffer +
          " mm < 1.8 * sqrt(ID * tc) = " +
          Component_MAWP.buffer1 +
          ' mm <font color="red">*Fail*</font><br />';
      }
    }

    // fourth criterion -- Lh <= 0.09 max[s,c]
    if (LTA_module.sngss >= LTA_module.sngcc) aa = 0.09 * LTA_module.sngss;
    else aa = 0.09 * LTA_module.sngcc;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = this.sngLh.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (this.sngLh <= aa) {
        key4 = 0;
        this.laminationsCalcResult +=
          "Lamination height, Lh = " +
          Component_MAWP.buffer +
          " inch <= 0.09 * max[s, c] = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      } else {
        key4 = 1;
        this.laminationsCalcResult +=
          "Lamination height, Lh = " +
          Component_MAWP.buffer +
          " inch > 0.09 * max[s, c] = " +
          Component_MAWP.buffer1 +
          ' inch. <font color="red">Use crack procedure!</font><br />';
      }
    } else {
      // SI
      Component_MAWP.buffer = this.sngLh.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (this.sngLh <= aa) {
        key4 = 0;
        this.laminationsCalcResult +=
          "Lamination height, Lh = " +
          Component_MAWP.buffer +
          " mm <= 0.09 * max[s, c] = " +
          Component_MAWP.buffer1 +
          " mm<br />";
      } else {
        key4 = 1;
        this.laminationsCalcResult +=
          "Lamination height, Lh = " +
          Component_MAWP.buffer +
          " mm > 0.09 * max[s, c] = " +
          Component_MAWP.buffer1 +
          ' mm. <font color="red">Use crack procedure!</font><br />';
      }
    }

    // fifth criterion -- Lh <= min[tc/3, 0.5"(13mm)]
    aa = Component_MAWP.sngtc / 3.0;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (aa > 0.5) aa = 0.5;
      Component_MAWP.buffer = this.sngLh.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (this.sngLh <= aa) {
        key5 = 0;
        this.laminationsCalcResult +=
          "Lamination height, Lh = " +
          Component_MAWP.buffer +
          " inch  <=  min[tc/3, 0.5 inch] = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      } else {
        key5 = 1;
        this.laminationsCalcResult +=
          "Lamination height, Lh = " +
          Component_MAWP.buffer +
          " inch  >  min[tc/3, 0.5 inch] = " +
          Component_MAWP.buffer1 +
          ' inch. <font color="red">Use crack procedure!</font><br />';
      }
    } else {
      // SI
      if (aa > 13.0) aa = 13.0;
      Component_MAWP.buffer = this.sngLh.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (this.sngLh <= aa) {
        key5 = 0;
        this.laminationsCalcResult +=
          "Lamination height, Lh = " +
          Component_MAWP.buffer +
          " mm  <=  min[tc/3, 13 mm] = " +
          Component_MAWP.buffer1 +
          " mm<br />";
      } else {
        key5 = 1;
        this.laminationsCalcResult +=
          "Lamination height, Lh = " +
          Component_MAWP.buffer +
          " mm  >  min[tc/3, 13 mm] = " +
          Component_MAWP.buffer1 +
          ' mm. <font color="red">Use crack procedure!</font><br />';
      }
    }

    // sixth criterion -- tmm >= 0.1 x tc
    aa = 0.1 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = LTA_module.sngtmm.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (LTA_module.sngtmm >= aa) {
        key6 = 0;
        this.laminationsCalcResult +=
          "tmm = " +
          Component_MAWP.buffer +
          " inch  >=  0.1 * tc = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      } else {
        key6 = 1;
        this.laminationsCalcResult +=
          "tmm = " +
          Component_MAWP.buffer +
          " inch  <  0.1 * tc = " +
          Component_MAWP.buffer1 +
          ' inch ==> surface breaking.  <font color="red">*Fail*</font><br />';
      }
    } else {
      // SI
      Component_MAWP.buffer = LTA_module.sngtmm.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (LTA_module.sngtmm >= aa) {
        key6 = 0;
        this.laminationsCalcResult +=
          "tmm = " +
          Component_MAWP.buffer +
          " mm  >=  0.1 * tc = " +
          Component_MAWP.buffer1 +
          " mm<br />";
      } else {
        key6 = 1;
        this.laminationsCalcResult +=
          "tmm = " +
          Component_MAWP.buffer +
          " mm  <  0.1 * tc = " +
          Component_MAWP.buffer1 +
          ' mm ==> surface breaking.  <font color="red">*Fail*</font><br />';
      }
    }

    // seventh criterion -- Lw >= max [2 x tc, 1 inch (25mm)]
    aa = 2.0 * Component_MAWP.sngtc;

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (aa < 1.0) aa = 1.0;
      Component_MAWP.buffer = Dent_Module.sngLw.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (Dent_Module.sngLw >= aa) {
        key7 = 0;
        this.laminationsCalcResult +=
          "Spacing to the nearest weld joint, Lw = " +
          Component_MAWP.buffer +
          " inch  >=  max[2 * tc, 1 inch] = " +
          Component_MAWP.buffer1 +
          " inch<br />";
      } else {
        key7 = 1;
        this.laminationsCalcResult +=
          "Spacing to the nearest weld joint, Lw = " +
          Component_MAWP.buffer +
          " inch  <  max[2 * tc, 1 inch] = " +
          Component_MAWP.buffer1 +
          ' inch  <font color="red">*Fail*</font><br />';
      }
    } else {
      // SI
      if (aa < 25.0) aa = 25.0;
      Component_MAWP.buffer = Dent_Module.sngLw.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (Dent_Module.sngLw >= aa) {
        key7 = 0;
        this.laminationsCalcResult +=
          "Spacing to the nearest weld joint, Lw = " +
          Component_MAWP.buffer +
          " mm  >=  max[2 * tc, 25 mm] = " +
          Component_MAWP.buffer1 +
          " mm<br />";
      } else {
        key7 = 1;
        this.laminationsCalcResult +=
          "Spacing to the nearest weld joint, Lw = " +
          Component_MAWP.buffer +
          " mm  <  max[2 * tc, 25 mm] = " +
          Component_MAWP.buffer1 +
          ' mm  <font color="red">*Fail*</font><br />';
      }
    }

    // eighth criterion -- MAWP >= P_d (equipment MAWP / design pressure)
    if (Component_MAWP.intEquip !== 6) {
      // cylinders, cones, spheres, heads, piping
      CalcTemplate.sngMAWP_r = Component_MAWP.sngMAWP - Component_MAWP.sngp_H; // correct for static head

      if (Component_MAWP.intUnit_select === 0) {
        // US customary
        Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(1);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
      }
      else {
        // SI
        Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(2);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
      }

      if (CalcTemplate.sngMAWP_r >= Component_MAWP.sngP_d) {
        key8 = 0;
        if (Component_MAWP.intUnit_select === 0) {
          // US customary
          this.laminationsCalcResult +=
            "MAWP = " +
            Component_MAWP.buffer +
            " psi >= P_d = " +
            Component_MAWP.buffer1 +
            " psi ==> Equipment can be operated at P_d.<br />";
        } else {
          // SI
          this.laminationsCalcResult +=
            "MAWP = " +
            Component_MAWP.buffer +
            " MPa >= P_d = " +
            Component_MAWP.buffer1 +
            " MPa ==> Equipment can be operated at P_d.<br />";
        }
      } else {
        key8 = 1;
        if (Component_MAWP.intUnit_select === 0) {
          // US customary
          this.laminationsCalcResult +=
            "MAWP = " +
            Component_MAWP.buffer +
            " psi < P_d = " +
            Component_MAWP.buffer1 +
            ' psi <font color="red">*Fail*</font><br />';
        } else {
          // SI
          this.laminationsCalcResult +=
            "MAWP = " +
            Component_MAWP.buffer +
            " MPa < P_d = " +
            Component_MAWP.buffer1 +
            ' MPa <font color="red">*Fail*</font><br />';
        }
      }
    } else {
      // tanks
      Component_MAWP.buffer = CalcTemplate.sngMFH.toFixed(2);
      Component_MAWP.buffer1 = CalcTemplate.sngMFH_d.toFixed(2);
      if (CalcTemplate.sngMFH >= CalcTemplate.sngMFH_d) {
        key8 = 0;
        if (Component_MAWP.intUnit_select === 0) {
          // US Customary unit
          this.laminationsCalcResult +=
            "MFH = " +
            Component_MAWP.buffer +
            " ft >= MFH_d = " +
            Component_MAWP.buffer1 +
            " ft ==> Equipment can be operated at MFH_d.<br />";
        } else {
          // SI unit
          this.laminationsCalcResult +=
            "MFH = " +
            Component_MAWP.buffer +
            " m >= MFH_d = " +
            Component_MAWP.buffer1 +
            " m ==> Equipment can be operated at MFH_d.<br />";
        }
      } else {
        key8 = 1;
        if (Component_MAWP.intUnit_select === 0) {
          // US Customary unit
          this.laminationsCalcResult +=
            "MFH = " +
            Component_MAWP.buffer +
            " ft < MFH_d = " +
            Component_MAWP.buffer1 +
            ' ft <font color="red">*Fail*</font><br />';
        } else {
          // SI unit
          this.laminationsCalcResult +=
            "MFH = " +
            Component_MAWP.buffer +
            " m < MFH_d = " +
            Component_MAWP.buffer1 +
            ' m <font color="red">*Fail*</font><br />';
        }
      }
    } // end criterion #8

    if (
      key1 === 0 &&
      key2 === 0 &&
      key3 === 0 &&
      key4 === 0 &&
      key5 === 0 &&
      key6 === 0 &&
      key7 === 0 &&
      key8 === 0
    ) {
      Component_MAWP.strD_result = "Pass";
      this.laminationsCalcResult += "<br />";
      this.laminationsCalcResult += `<font color="blue">Lamination passed Level 1 assessment.</font><br />`;
    } else {
      Component_MAWP.strD_result = "Fail";
      this.laminationsCalcResult += "<br />";
      this.laminationsCalcResult += `<font color="red">Lamination failed Level 1 assessment. <br /> Consider performing a Level 2 or Level 3 assessment.</font> <br />`;
    }

    return this.laminationsCalcResult;
  }

  public static check_Laminations_Data(inspectionDataFormGroup): boolean {
    let passFlag: boolean = true;
    let messagesValidationsList: string[] = [];

    let textBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");
    let textBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");
    let textBox3_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox3_DI");
    let textBox4_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox4_DI");
    let textBox5_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox5_DI");
    let textBox6_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox6_DI_snggr");
    let textBox7_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox7_DI_sngHf");
    let comboBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox1_DI");
    let comboBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox2_DI");

    const validComboBoxValues = ["No", "Yes"];
    if (comboBox2_DIformControl?.enabled) {
      if (!validComboBoxValues.includes(comboBox2_DIformControl.value)) {
        passFlag = false;
        messagesValidationsList.push(
          "Answer the multiple laminations at different elevations in through-thickness direction question."
        );
      }
    }

    if (comboBox1_DIformControl?.enabled) {
      if (!validComboBoxValues.includes(comboBox1_DIformControl.value)) {
        passFlag = false;
        messagesValidationsList.push(
          "Answer the major structural discontinuities or other laminations question."
        );
      }
    }

    // textBox1_DI error handling
    if (textBox1_DIformControl?.enabled) {
      if (textBox1_DIformControl.invalid) {
        if (
          textBox1_DIformControl.value === null ||
          textBox1_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Minimum measured thickness including FCA as appropriate, tmm - not set"
          );
          passFlag = false;
        }
      } else if (LTA_module.sngtmm <= 0.0) {
        messagesValidationsList.push(
          "Minimum measured thickness including FCA as appropriate, tmm - cannot be less or equal than 0."
        );
        passFlag = false;
      } else if (
        LTA_module.sngtmm - Component_MAWP.sngFCA >
        Component_MAWP.sngtrd - Component_MAWP.sngFCA
      ) {
        messagesValidationsList.push(
          "Minimum measured thickness including FCA, cannot be greater than the future corroded thickenss, tc."
        );
        passFlag = false;
      }
    }

    // textBox2_DI error handling
    if (textBox2_DIformControl?.enabled) {
      if (textBox2_DIformControl.invalid) {
        if (
          textBox2_DIformControl.value === null ||
          textBox2_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Lamination dimension in the long. direction, s - not set"
          );
          passFlag = false;
        } else if (LTA_module.sngss < 0.0) {
          messagesValidationsList.push(
            "Lamination dimension in the long. direction, s - cannot be less than 0."
          );
          passFlag = false;
        }
      }
    }

    //   // textBox3_DI error handling
    if (textBox3_DIformControl?.enabled) {
      if (textBox3_DIformControl.invalid) {
        if (
          textBox3_DIformControl.value === null ||
          textBox3_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Lamination dimension in the circ. direction, c - not set"
          );
          passFlag = false;
        } else if (LTA_module.sngcc < 0.0) {
          messagesValidationsList.push(
            "Lamination dimension in the circ. direction, c - cannot be less than 0."
          );
          passFlag = false;
        }
      }
    }

    //   // textBox4_DI error handling
    if (textBox4_DIformControl?.enabled) {
      if (textBox4_DIformControl.invalid) {
        if (
          textBox4_DIformControl.value === null ||
          textBox4_DIformControl.value === ""
        ) {
          messagesValidationsList.push("Lamination height, Lh - not set");
          passFlag = false;
        } else if (this.sngLh < 0.0) {
          messagesValidationsList.push(
            "Lamination height, Lh - cannot be less than 0."
          );
          passFlag = false;
        }
      } else if (this.sngLh >= Component_MAWP.sngtrd) {
        messagesValidationsList.push(
          "Lamination height, Lh - cannot be greater than or equal to current measured thickness, trd."
        );
        passFlag = false;
      }
    }

    //   // textBox5_DI error handling
    if (textBox5_DIformControl?.enabled) {
      if (textBox5_DIformControl.invalid) {
        if (
          textBox5_DIformControl.value === null ||
          textBox5_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Edge-to-edge spacing between laminations, Ls - not set"
          );
          passFlag = false;
        } else if (Laminations_module.sngLs < 0.0) {
          messagesValidationsList.push(
            "Edge-to-edge spacing between laminations, Ls - cannot be less than 0."
          );
          passFlag = false;
        }
      }
    }

    //   // textBox6_DI error handling
    if (textBox6_DIformControl?.enabled) {
      if (textBox6_DIformControl.invalid) {
        if (
          textBox6_DIformControl.value === null ||
          textBox6_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Lamination spacing to the nearest weld joint, Lw - not set"
          );
          passFlag = false;
        } else if (Dent_Module.sngLw < 0.0) {
          messagesValidationsList.push(
            "Lamination spacing to the nearest weld joint, Lw - cannot be less than 0."
          );
          passFlag = false;
        }
      }
    }

    //   // textBox7_DI error handling
    if (textBox7_DIformControl?.enabled) {
      if (textBox7_DIformControl.invalid) {
        if (
          textBox7_DIformControl.value === null ||
          textBox7_DIformControl.value === ""
        ) {
          messagesValidationsList.push(
            "Distance from the edge of the lamination to the nearest major structural discontinuity, Lmsd - not set"
          );
          passFlag = false;
        } else if (CalcTemplate.sngLmsd < 0.0) {
          messagesValidationsList.push(
            "Distance from the edge of the lamination to the nearest major structural discontinuity, Lmsd - cannot be less than 0."
          );
          passFlag = false;
        }
      }
    }

    this.validationMessage = !passFlag ? messagesValidationsList : [];

    return passFlag;
  }

  public static ExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      Laminations_module: { ...Laminations_module },
      LTA_module: { ...LTA_module },
      Dent_Module: { ...Dent_Module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static ImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();

    // Carlos Acero - 07/09/24
    // Set Any major structural discontinuities or other laminations infringe on the 2s x 2c box? select to NO as default and hide
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    jsonDataResult.CalcTemplate.intSCbox = 0;

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(LTA_module, jsonDataResult.LTA_module);
    Object.assign(Laminations_module, jsonDataResult.Laminations_module);
    Object.assign(Dent_Module, jsonDataResult.Dent_Module);
  }

  public static updateRemainingInspectionDataFormControls(
    inspectionDataFormGroup
  ): void {
    let textBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox1_DI");
    let textBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox2_DI");
    let textBox3_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox3_DI");
    let textBox4_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox4_DI");
    let textBox5_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox5_DI");
    let textBox6_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox6_DI_snggr");
    let textBox7_DIformControl: FormControl =
      inspectionDataFormGroup.get("textBox7_DI_sngHf");
    let comboBox1_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox1_DI");
    let comboBox2_DIformControl: FormControl =
      inspectionDataFormGroup.get("comboBox2_DI");

    let comboBoxValues = ["No", "Yes"];

    if (Laminations_module.intElevation != -1) {
      comboBox2_DIformControl.setValue(
        comboBoxValues[Laminations_module.intElevation]
      );
    }

    if (CalcTemplate.intSCbox != -1) {
      comboBox1_DIformControl.setValue(comboBoxValues[CalcTemplate.intSCbox]);
    }

    if (LTA_module.sngtmm != -1)
      textBox1_DIformControl.setValue(LTA_module.sngtmm.toString());
    if (LTA_module.sngss != -1)
      textBox2_DIformControl.setValue(LTA_module.sngss.toString());
    if (LTA_module.sngcc != -1)
      textBox3_DIformControl.setValue(LTA_module.sngcc.toString());
    if (Laminations_module.sngLh != -1)
      textBox4_DIformControl.setValue(Laminations_module.sngLh.toString());
    if (Laminations_module.sngLs != -1)
      textBox5_DIformControl.setValue(Laminations_module.sngLs.toString());
    if (Dent_Module.sngLw != -1)
      textBox6_DIformControl.setValue(Dent_Module.sngLw.toString());
    textBox6_DIformControl.enable();
    if (CalcTemplate.sngLmsd != -1)
      textBox7_DIformControl.setValue(CalcTemplate.sngLmsd.toString());
  }

  public static Laminations_report(): string {
    let report: string = "";

    const format = "dd/MM/yyyy";
    const locale = "en-US";
    const dt1 = new Date();
    const reportDate = formatDate(dt1, format, locale);

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 13</center>";
    report += "<center>Assessment of Laminations</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 1</br></br></br>";

    const sRefinery: string = "Facility: ";
    const sPlant: string = "Plant: ";
    const sEquipment: string = "Equipment: ";
    const sAnalyst: string = "Analyst: ";

    report += sRefinery + Component_MAWP.strFacility + "</br>";
    report += sPlant + Component_MAWP.strPlant + "</br>";
    report += sEquipment + Component_MAWP.strEquipment + "</br>";
    report += sAnalyst + Component_MAWP.strAnalyst + "</br></br></br>";

    report += "Applicability and Limitations :</br></br>";

    report += `<ol class="appAndLimitList">    
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. The design temperature is less than or equal to the temperature limit in Table 4.1.</li>  
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria), or is in cyclic service and adjacent laminations are in the same plane and there is no indication of through-thickness cracking.</li>  
        <li>Type A components subject to internal pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness.  Examples are:
          <ol type="a">  
            <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
            <li>Spherical pressure vessels and storage spheres</li>  
            <li>Spherical, elliptical and torispherical formed heads</li>  
            <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
            <li>Cylindrical atmospheric storage tank shell courses</li>  
          </ol>  
        </li>  
        <li>Laminations should be evaluated even when the lamination is located within the region of the specified corrosion/erosion allowance.</li>  
      </ol>  
    </br></br>`;

    report += "Component Information :</br></br>";

    if (Component_MAWP.intEquip === 0) {
      report += "Component type = Cylinder<br>";
    } else if (Component_MAWP.intEquip === 1) {
      report += "Component type = Cone<br>";
    } else if (Component_MAWP.intEquip === 2) {
      report += "Component type = Sphere<br>";
    } else if (Component_MAWP.intEquip === 3) {
      report += "Component type = Elliptical Head<br>";
    } else if (Component_MAWP.intEquip === 4) {
      report += "Component type = F&D Head<br>";
    } else if (Component_MAWP.intEquip === 5) {
      report += "Component type = B31.3 Piping<br>";
    } else {
      report += "Component type = Tank<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (Component_MAWP.intEquip !== 6) {
        report += `Design pressure or vessel MAWP (psi) = ${Component_MAWP.sngP_d.toFixed(
          1
        )}<br>`;
      } else {
        report += `Design Maximum Fill Height (ft) = ${CalcTemplate.sngMFH_d.toFixed(
          2
        )}<br>`;
      }
      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-F) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += `Design temperature (deg-F) = N/A<br>`;
      }
      report += `Inside diameter corrected for FCA as applicable, D (inch) = ${Component_MAWP.sngID.toFixed(
        3
      )}<br>`;
      if (Component_MAWP.intEquip !== 6) {
        // non-tanks
        report += `Current measured nominal (uniform) thickness, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
          3
        )}<br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = ${Component_MAWP.sngFCA.toFixed(
          3
        )}<br>`;
      } else {
        report += `Current measured nominal (uniform) thickness, trd (inch) = N/A<br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = N/A<br>`;
      }
      report += `Future corroded thickness, tc (inch) = ${Component_MAWP.sngtc.toFixed(
        3
      )}<br>`;
      if (Component_MAWP.intEquip !== 6) {
        report += `Minimum required thickness, tmin (inch) = ${Component_MAWP.sngtmin.toFixed(
          3
        )}<br>`;
      } else {
        report += `Maximum Fill Height based on tc, MFH (ft) = ${CalcTemplate.sngMFH.toFixed(
          2
        )}<br><br>`;
      }
    } else {
      // SI
      if (Component_MAWP.intEquip !== 6) {
        report += `Design pressure or vessel MAWP (MPa) = ${Component_MAWP.sngP_d.toFixed(
          2
        )}<br>`;
      } else {
        report += `Design Maximum Fill Height (m) = ${CalcTemplate.sngMFH_d.toFixed(
          2
        )}<br>`;
      }
      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-C) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += `Design temperature (deg-C) = N/A<br>`;
      }
      report += `Inside diameter corrected for FCA as applicable, D (mm) = ${Component_MAWP.sngID.toFixed(
        1
      )}<br>`;
      if (Component_MAWP.intEquip !== 6) {
        // non-tanks
        report += `Current measured nominal (uniform) thickness, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
          1
        )}<br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = ${Component_MAWP.sngFCA.toFixed(
          1
        )}<br>`;
      } else {
        report += `Current measured nominal (uniform) thickness, trd (mm) = N/A<br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = N/A<br>`;
      }
      report += `Future corroded thickness, tc (mm) = ${Component_MAWP.sngtc.toFixed(
        1
      )}<br>`;
      if (Component_MAWP.intEquip !== 6) {
        report += `Minimum required thickness, tmin (mm) = ${Component_MAWP.sngtmin.toFixed(
          1
        )}<br>`;
      } else {
        report += `Maximum Fill Height based on tc, MFH (m) = ${CalcTemplate.sngMFH.toFixed(
          2
        )}<br><br>`;
      }
    }

    report += '<div class="pageBreak"></div>';

    // SECOND PAGE

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 13</center>";
    report += "<center>Assessment of Laminations</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 2</br></br></br>";

    if (Component_MAWP.intEquip !== 6) {
      if (Component_MAWP.intUnit_select === 0) {
        report += `MAWP of the component based on tc, MAWP (psi) = ${Component_MAWP.sngMAWP.toFixed(
          1
        )}<br>`;
      } else {
        report += `MAWP of the component based on tc, MAWP (MPa) = ${Component_MAWP.sngMAWP.toFixed(
          2
        )}<br>`;
      }
    }

    report += `Longitudinal weld joint efficiency, E_L = ${Component_MAWP.sngE_L.toFixed(
      2
    )}<br>`;

    if (Component_MAWP.intEquip <= 1 || Component_MAWP.intEquip >= 5) {
      report += `Circumferential weld joint efficiency, E_C = ${Component_MAWP.sngE_c.toFixed(
        2
      )}<br>`;
    }

    report += `Allowable remaining strength factor, RSFa = ${CalcTemplate.sngRSF_a.toFixed(
      2
    )}<br><br>`;

    report += `Lamination Information :<br><br>`;

    if (this.intElevation === 0) {
      report += `Multiple laminations at different elevations in through-thickness direction? = No<br>`;
    } else {
      report += `Multiple laminations at different elevations in through-thickness direction? = Yes<br>`;
    }

    if (CalcTemplate.intSCbox === 0) {
      report += `Major structural discontinuities or other thinned areas infringe on the 2s X 2c box = No<br>`;
    } else {
      report += `Major structural discontinuities or other thinned areas infringe on the 2s X 2c box = Yes<br>`;
    }

    if (Component_MAWP.intUnit_select === 0) {
      report += `Minimum measured thickness including FCA as appropriate, tmm (inch) = ${LTA_module.sngtmm.toFixed(
        3
      )}<br>`;
      report += `Lamination dimension in the long. direction, s (inch) = ${LTA_module.sngss.toFixed(
        3
      )}<br>`;
      report += `Lamination dimension in the circ. direction, c (inch) = ${LTA_module.sngcc.toFixed(
        3
      )}<br>`;
      report += `Lamination height, Lh (inch) = ${Laminations_module.sngLh.toFixed(
        3
      )}<br>`;
      report += `Edge-to-edge spacing between laminations, Ls (inch) = ${Laminations_module.sngLs.toFixed(
        3
      )}<br>`;
      report += `Lamination spacing to the nearest weld joint, Lw (inch) = ${Dent_Module.sngLw.toFixed(
        3
      )}<br>`;
      report += `Distance to structural discontinuity, Lmsd (inch) = ${CalcTemplate.sngLmsd.toFixed(
        3
      )}<br><br>`;
    } else {
      report += `Minimum measured thickness including FCA as appropriate, tmm (mm) = ${LTA_module.sngtmm.toFixed(
        1
      )}<br>`;
      report += `Lamination dimension in the long. direction, s (mm) = ${LTA_module.sngss.toFixed(
        1
      )}<br>`;
      report += `Lamination dimension in the circ. direction, c (mm) = ${LTA_module.sngcc.toFixed(
        1
      )}<br>`;
      report += `Lamination height, Lh (mm) = ${Laminations_module.sngLh.toFixed(
        1
      )}<br>`;
      report += `Edge-to-edge spacing between laminations, Ls (mm) = ${Laminations_module.sngLs.toFixed(
        1
      )}<br>`;
      report += `Lamination spacing to the nearest weld joint, Lw (mm) = ${Dent_Module.sngLw.toFixed(
        1
      )}<br>`;
      report += `Distance to structural discontinuity, Lmsd (mm) = ${CalcTemplate.sngLmsd.toFixed(
        1
      )}<br><br>`;
    }

    report += "Assessment Results :<br><br>";
    report +=
      "All the conditions in the Applicability and Limitations section are met.<br><br>";

    report += this.laminationsCalcResult;

    return report;
  }
}
