import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { Component_MAWP } from "../../../services/component_mawp";
import { Subject } from "rxjs";
import { LTA_module } from "../../../services/lta_module";
import { Form1 } from "../../../services/form1";
import { Level1Data } from "../../../services/level1Data";
import { Pitting_module } from "../../../services/pitting_module";
import { MatStepper } from "@angular/material/stepper";
import { FormControlsEventProxyService } from "../../../services/formcontrols_eventproxy_service";

@Component({
  selector: "pitting-corrosion",
  templateUrl: "pitting-corrosion.component.html",
})
export class PittingCorrosionComponent implements OnInit {
  @ViewChild("stepper") private myStepper: MatStepper;

  inspectionDataCalculate: Subject<boolean> = new Subject();
  public level1Data = Level1Data;

  applicability: string;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;

  summary: string;
  inspectionDataResult: string;
  result: string;

  firstFormGroup = this._formBuilder.group({
    applicabilityCheckbox: ["", Validators.requiredTrue],
  });
  secondFormGroup = this._formBuilder.group({});
  isLinear = true;

  constructor(

    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private formControlsEventProxyService: FormControlsEventProxyService
  ) {
    this.path = activatedRoute.snapshot["_routerState"].url;

    this.PittingCorrosion_Assesment_Setup();
  }

  goToComponent() {
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsDirty({
      onlySelf: true,
    });
    this.firstFormGroup.controls["applicabilityCheckbox"].markAsTouched({
      onlySelf: true,
    });

    this.goNextStep();
  }

  getApplicabilityCheckbox_formControl(): FormControl {
    return this.firstFormGroup.get("applicabilityCheckbox") as FormControl;
  }

  ngOnInit(): void {
    this.summary = "";
    this.result = "";
    this.applicability = `  
        <ol>  
          <li>The original design criteria were in accordance with a recognized code or standard.</li>  
          <li>The component is not operating in the creep range. See the Help Menu for temperature limit used to define the creep range.</li>
          <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment.</li>
          <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
          <li>Type A components subject to internal pressure or external pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness. Examples are:  
            <ol type="a">  
              <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
              <li>Spherical pressure vessels and storage spheres</li>  
              <li>Spherical, elliptical and torispherical formed heads</li>  
              <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
              <li>Cylindrical atmospheric storage tank shell courses</li>  
            </ol>  
          </li>  
          <li>The pitting damage is arrested.</li>
          <li>The pitting damage is located on only one surface (ID or OD) of the component.</li>
          <li>The pitting damage is composed of many pits; individual pits or isolated pairs of pits should be evaluated using the LTA assessment procedure.</li>
        </ol>  
      `;
    localStorage.setItem('calculationStep', '0'); //this is zero step (Applicability)

    this.formControlsEventProxyService.getPitting_ApplicabilityEventSubject().subscribe((v) => {
      this.updateApplicabilityCheckboxToChecked();
    });
  }

  updateApplicabilityCheckboxToChecked() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    applicabilityCheckbox.setValue(true);
  }

  goNextStep() {
    let applicabilityCheckbox = this.getApplicabilityCheckbox_formControl();
    if (applicabilityCheckbox.value === true)
      localStorage.setItem('calculationStep', (this.myStepper.selectedIndex + 1).toString());

    this.myStepper.next();
  }

  goPreviousStep() {
    localStorage.setItem('calculationStep', (this.myStepper.selectedIndex - 1).toString());
    this.myStepper.previous();
  }

  PittingCorrosion_Assesment_Setup() {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    Pitting_module.Pitting_initialize();

    Component_MAWP.strD_class = "Pitting";
    Component_MAWP.strD_iclass = 4;
    Component_MAWP.intUnit_select =
      parseInt(localStorage.getItem("unitType")) || 0;
    Form1.prev_strD_class = "Pitting";
  }

  handleSummaryData(data: any) {
    this.summary = data;
  }

  handleInspectionData(data: any) {
    this.inspectionDataResult = data;
  }

  calculateResult(): void {
    this.inspectionDataCalculate.next(true);
  }
}
