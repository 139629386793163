import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { LTA_module } from "./lta_module";

export class GougeModule {
  // Assuming the following static properties based on the C++ code
  public static intDressed: number; // gouge dressed; 1-yes, 2-no
  public static intCVN_data: number; // material toughness data available; 1-yes, 2-no
  public static sngCVN_value: number; // toughness value, used when intCVN_data = 1
  public static intASME_curve: number; // ASME Exemption curve number, used when intCVN_data = 2
  public static sngCVN_temp: number; // 30 ft-lb toughness temperature
  public static sngSMYS: number; // specified minimum yield strength, in ksi
  public static sngT_op: number; // minimum operating temperature

  public static validationMessage = [];
  public static warningMessage = [];
  public static validationModalMessage = [];

  public static ReturnGougeValuesAsHTML(): string {
    let result =
      "intDressed: " +
      GougeModule.intDressed +
      "</br>" +
      "intCVN_data: " +
      GougeModule.intCVN_data +
      "</br>" +
      "sngCVN_value: " +
      GougeModule.sngCVN_value +
      "</br>" +
      "intASME_curve: " +
      GougeModule.intASME_curve +
      "</br>" +
      "sngCVN_temp: " +
      GougeModule.sngCVN_temp +
      "</br>" +
      "sngSMYS: " +
      GougeModule.sngSMYS;

    return result;
  }

  public static Gouge_initialize(): void {
    // initialize all the variables
    GougeModule.intDressed = -1; // gouge dressed; 1-yes, 2-no
    GougeModule.intCVN_data = -1; // material toughness data available; 1-yes, 2-no
    GougeModule.sngCVN_value = -1; // toughness value, used when intCVN_data = 1
    GougeModule.intASME_curve = -1; // ASME Exemption curve number, used when intCVN_data = 2
    // 0-Curve A, 1-Curve B, 2-Curve C, 3-Curve D
    GougeModule.sngSMYS = -1; // specified minimum yield strength, in ksi
    GougeModule.sngT_op = -1; // minimum operating temperature
    // GougeModule.sngCVN_temp is intentionally left uninitialized, as in the original C++ code.

    this.validationMessage = [];
    this.warningMessage = [];
    this.validationModalMessage = [];
    CalcTemplate.reset_data();
  }

  public static Get_temp(): void {
    // find the 30 ft-lb toughness temperature
    let smy: number, tt: number;

    smy = GougeModule.sngSMYS; // added as needed to get input value, while doing calculation
    smy += Component_MAWP.intUnit_select === 0 ? 10 : 69;

    let baseValuesUS = [114, 76, 38, 12];
    let baseValuesSI = [45.6, 24.4, 3.3, -11.1];

    let intASME: number = Number(GougeModule.intASME_curve);
    switch (intASME) {
      case 0:
      case 1:
      case 2:
      case 3:
        if (Component_MAWP.intUnit_select == 0)
          tt = baseValuesUS[intASME] + this.calculateTT_US(smy);
         else
          tt = baseValuesSI[intASME] + this.calculateTT_SI(smy);
        break;
    }

    GougeModule.sngCVN_temp = tt; // added by shameer
  }

  public static calculateTT_US(smy: number): number {
    return ((61 + smy / 2.83 - 73.8 / 5.86) / 4) * Math.log((30 - (1.84 + 0.738 * Math.pow(smy / 66.7, 2))) / (73.8 - 30));
  }

  public static calculateTT_SI(smy: number): number {
      return ((34 + smy / 35.1 - 100 / 14.3) / 4) * Math.log((40 - (2.5 + Math.pow(smy / 460, 2))) / (100 - 40));
  }

  public static check_Gouge_Data(gougeFormGroup): boolean {
    this.validationMessage = [];
    this.warningMessage = [];
    this.validationModalMessage = [];
    let msgNotSet = "not set.";

    if (GougeModule.intDressed === 1) {
      GougeModule.intCVN_data = -1;
    }

    let equipmentString: string;
    let passFlag: boolean = true;

    if (
      GougeModule.intCVN_data === 1 &&
      gougeFormGroup.controls["textBox1_Gouge"].enabled
    ) {
      equipmentString = "Toughness value, CVN - ";
      if (!gougeFormGroup.controls["textBox1_Gouge"].valid) {
        this.validationMessage.push(equipmentString + msgNotSet + "\n");
        passFlag = false;
      } else if (GougeModule.sngCVN_value < 0.0) {
        this.validationMessage.push(
          equipmentString + "cannot be less than 0.\n"
        );
        passFlag = false;
      }
    }

    if (
      GougeModule.intCVN_data === 2 &&
      (GougeModule.intASME_curve < 0 || GougeModule.intASME_curve > 3)
    ) {
      equipmentString = "Exemption Curve, intASME - ";
      this.validationMessage.push(equipmentString + "needs to be set.\n");
      passFlag = false;
    }

    if (
      GougeModule.intCVN_data === 2 &&
      gougeFormGroup.controls["textBox2_Gouge"].enabled
    ) {
      equipmentString = "Yield strength, SMYS - ";
      if (!gougeFormGroup.controls["textBox2_Gouge"].valid) {
        this.validationMessage.push(equipmentString + msgNotSet + "\n");
        passFlag = false;
      } else if (GougeModule.sngSMYS < 0) {
        this.validationMessage.push(
          equipmentString + "cannot be less than 0.\n"
        );
        passFlag = false;
      } else if (
        Component_MAWP.intUnit_select === 0 &&
        (GougeModule.sngSMYS < 30 || GougeModule.sngSMYS > 80)
      ) {
        this.validationMessage.push(
          "Specified minimum yield strength is out of range on \nTable 12.2- Minimum Temperature, Where the Expected Value of the Charpy Impact Energy (CVN) is Above 40 Joules or 30 ft-lbs\n"
        );
        passFlag = false;
      } else if (
        Component_MAWP.intUnit_select === 1 &&
        (GougeModule.sngSMYS < 200 || GougeModule.sngSMYS > 560)
      ) {
        this.validationMessage.push(
          "Specified minimum yield strength is out of range on \nTable 12.2- Minimum Temperature, Where the Expected Value of the Charpy Impact Energy (CVN) is Above 40 Joules or 30 ft-lbs\n"
        );
        passFlag = false;
      }
    }

    if (
      GougeModule.intCVN_data === 2 &&
      gougeFormGroup.controls["textBox3_Gouge"].enabled
    ) {
      equipmentString = "Minimum operating temperature, T_op - ";
      if (!gougeFormGroup.controls["textBox3_Gouge"].valid) {
        this.validationMessage.push(equipmentString + msgNotSet + "\n");
        passFlag = false;
      } else if (GougeModule.sngT_op < -200.0) {
        this.validationMessage.push(
          equipmentString + "cannot be less than -200.\n"
        );
        passFlag = false;
      }
    }

    if (!passFlag) {
      return passFlag;
    }

    //results
    if (GougeModule.intDressed == 2 && GougeModule.intCVN_data == 1) {
      if (Component_MAWP.intUnit_select == 0) {
        if (GougeModule.sngCVN_value >= 30) {
          let message = `CVN value = ${GougeModule.sngCVN_value} ft-lbs >= 30 ft-lbs. Proceed to the next step.`;
          this.warningMessage.push(message);
        } else {
          let message = `CVN value = ${GougeModule.sngCVN_value} ft-lbs < 30 ft-lbs.  Assessment aborted and gouge failed the Level 1 assessment!`;
          this.validationModalMessage.push(message);

          passFlag = false;
        }
      } else {
        if (GougeModule.sngCVN_value >= 40) {
          let message = `CVN value = ${GougeModule.sngCVN_value} Joules >= 40 Joules.  Proceed to the next step.`;
          this.warningMessage.push(message);
        } else {
          let message = `CVN value = ${GougeModule.sngCVN_value} Joules < 40 Joules.  Assessment aborted and gouge failed the Level 1 assessment!`;
          this.validationModalMessage.push(message);
          passFlag = false;
        }
      }
      return passFlag;
    }

    if (GougeModule.intDressed == 2 && GougeModule.intCVN_data == 2) {
      GougeModule.Get_temp();
      if (GougeModule.sngT_op >= GougeModule.sngCVN_temp) {
        if (Component_MAWP.intUnit_select == 0) {
          let message = `Minimum Operating Temperature, T_op of ${GougeModule.sngT_op.toFixed(
            1
          )} deg-F is greater than ${GougeModule.sngCVN_temp.toFixed(
            1
          )} deg-F, temperature at which the material can be expected to have a CVN impact energy greater than 40 Joules (30 ft-lbs).`;
          this.warningMessage.push(message);
        } else {
          let message = `Minimum Operating Temperature, T_op of ${GougeModule.sngT_op.toFixed(
            1
          )} deg-C is greater than ${GougeModule.sngCVN_temp.toFixed(
            1
          )} deg-C, temperature at which the material can be expected to have a CVN impact energy greater than 40 Joules (30 ft-lbs).`;
          this.warningMessage.push(message);
        }
      } else {
        if (Component_MAWP.intUnit_select == 0) {
          let message = `Minimum Operating Temperature, T_op of ${GougeModule.sngT_op.toFixed(
            1
          )} deg-F is lower than ${GougeModule.sngCVN_temp.toFixed(
            1
          )} deg-F, temperature at which the material can be expected to have a CVN impact energy greater than 40 Joules (30 ft-lbs).`;
          this.validationModalMessage.push(message);
          this.validationModalMessage.push("Gouge failed the Level 1 assessment!");

          passFlag = false;
        } else {
          let message = `Minimum Operating Temperature, T_op of ${GougeModule.sngT_op.toFixed(
            2
          )} deg-C is lower than ${GougeModule.sngCVN_temp.toFixed(
            1
          )} deg-C, temperature at which the material can be expected to have a CVN impact energy greater than 40 Joules (30 ft-lbs).`;
          this.validationModalMessage.push(message);
          this.validationModalMessage.push("Gouge failed the Level 1 assessment!");
          passFlag = false;
        }
      }
    }

    return passFlag;
  }

  public static ExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      LTA_module: { ...LTA_module },
      GougeModule: { ...GougeModule },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static ImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    GougeModule.Gouge_initialize();

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(LTA_module, jsonDataResult.LTA_module);
    Object.assign(GougeModule, jsonDataResult.GougeModule);
  }
}
