import { Component, OnInit, Renderer2, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from '@cvx/nextpage';
import { Component_MAWP } from "../../../../services/component_mawp";
import { Pitting_module } from "../../../../services/pitting_module";
import { CalcTemplate } from "../../../../services/calctemplate";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormControlsEventProxyService } from 'projects/fitnessforservice/src/services/formcontrols_eventproxy_service';

@Component({
  selector: 'pitting-information',
  templateUrl: 'pitting-information.component.html'
})

export class PittingInformationComponent implements OnInit {
  pittingInformationForm: FormGroup;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  path: string;
  intUnit: number;

  @Input() inspectionDataCalcCallValidationsInPitting: Subject<boolean>;
  @Output() updateValidationsStatus = new EventEmitter<{
    messagesValidationsList: string[],
    displayWarningModal: boolean
  }>();

  private charts = new Array<string>('Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8', 'More than Grade 8');

  constructor(private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    private fb: FormBuilder,
    private formControlsEventProxyService: FormControlsEventProxyService) {
    this.path = activatedRoute.snapshot['_routerState'].url;
  }

  ngOnInit(): void {
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0;
    Component_MAWP.intUnit_select = this.intUnit;

    this.pittingInformationForm = this.fb.group({
      maximumPitDepth: ["", Validators.required],
      maximumPitDiameter: ["", Validators.required],
      damageLocation: ["", Validators.required],
      pittingChart: ["", Validators.required]
    });

    this.inspectionDataCalcCallValidationsInPitting.subscribe((v) => {
      this.onSubmitPittingInformationForm();
    });

    this.formControlsEventProxyService.getPittingInformationEventSubject().subscribe((v) => {
      this.updateFormControlsAfterImport();
    });
  }

  get maximumPitDepth() {
    return this.pittingInformationForm.get('maximumPitDepth');
  }

  get maximumPitDiameter() {
    return this.pittingInformationForm.get('maximumPitDiameter');
  }

  get damageLocation() {
    return this.pittingInformationForm.get('damageLocation');
  }

  get pittingChart() {
    return this.pittingInformationForm.get('pittingChart');
  }

  onSubmitPittingInformationForm() {
    // Do form validation
    let messagesValidationsList: string[] = [];
    this.pittingInformationForm.markAllAsTouched(); // imitate the state of form-controls as if they were touched
    let passFlag: boolean = this.validatePittingInformationForm(messagesValidationsList);

    if (passFlag) {
      let displayWarningModal: boolean = false;
      if (Pitting_module.sngdia_max > Component_MAWP.sngtnom) {
        displayWarningModal = true;
      }

      return this.updateValidationsStatus.emit({
        messagesValidationsList: [],
        displayWarningModal
      });
    }
    else
      return this.updateValidationsStatus.emit({
        messagesValidationsList,
        displayWarningModal: false
      });
  }

  validatePittingInformationForm(messagesValidationsList: string[]): boolean {
    let passFlag: boolean = true;

    const checkMaximumPitDepth = () => {
      if (!this.maximumPitDepth?.enabled) return;
      if (this.maximumPitDepth.invalid && (this.maximumPitDepth.value === null || this.maximumPitDepth.value === "")) {
        messagesValidationsList.push("Maximum pit depth, w_max - not set");
        passFlag = false;
      } else if (Pitting_module.sngw_max <= 0.0) {
        messagesValidationsList.push("Maximum pit depth, w_max  cannot be less than or equal to 0.");
        passFlag = false;
      } else if (Pitting_module.sngw_max >= Component_MAWP.sngtc) {
        messagesValidationsList.push("Maximum pit depth, w_max  cannot be greater than or equal to the future corroded thickness, tc.");
        passFlag = false;
      }
    }
    
    const checkMaximumPitDiameter = () => {
      if (!this.maximumPitDiameter?.enabled) return;
      if (this.maximumPitDiameter.invalid && (this.maximumPitDiameter.value === null || this.maximumPitDiameter.value === "")) {
        messagesValidationsList.push("Maximum pit diameter, dia_max - not set");
        passFlag = false;
      } else if (Pitting_module.sngdia_max <= 0.0) {
        messagesValidationsList.push("Maximum pit diameter, dia_max  cannot be less than or equal to 0.");
        passFlag = false;
      }
    }
    
    const checkDamageLocation = () => {
      if (!this.damageLocation?.enabled) return;
      if (this.damageLocation.value !== "0" && this.damageLocation.value !== "1") {
        passFlag = false;
        messagesValidationsList.push("Pitting and corrosion damage location - needs to be selected.");
      }
    }
    
    const checkPittingChart = () => {
      if (!this.pittingChart?.enabled) return;
      const validChartsValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];
      if (!validChartsValues.includes(this.pittingChart.value)) {
        passFlag = false;
        messagesValidationsList.push("Matching Pitting Chart - needs to be selected.");
      }
    }
    
    checkMaximumPitDepth();
    checkMaximumPitDiameter();
    checkDamageLocation();
    checkPittingChart();

    return passFlag;
  }

  updateFormControlsAfterImport() {
    Pitting_module.updatePittingInformationFormControls(this.pittingInformationForm);

    if (Pitting_module.intChart_no !== 1) this.setPittingImageSrc();
  }

  setPittingImageSrc() {
    let chartImage_no = Pitting_module.intChart_no + 1;
    let imagePath = '/assets/images/Pchart' + chartImage_no + '.jpg';
    let imageElement = this.el.nativeElement.querySelector('#pitting_image');
    this.renderer.setAttribute(imageElement, 'src', imagePath);
  }

  onChangeTextBox1_DI(e: { target: { value: string; }; }) {
    if (Component_MAWP.strD_iclass) {
        Pitting_module.sngw_max = parseFloat(e.target.value);
    }
  }

  onChangeTextBox2_DI(e: { target: { value: string; }; }) {
    if (e.target.value && Component_MAWP.strD_iclass) {
          Pitting_module.sngdia_max = parseFloat(e.target.value);
      }
  }

  onChangeComboBox1_DI(e: { target: { value: string; }; }) {
    if (Component_MAWP.strD_iclass) {
        Pitting_module.intSide = parseFloat(e.target.value);
    }else{
        let structuralDiscountinuities = e.target.value;
      if (structuralDiscountinuities == "No") {
        CalcTemplate.intSCbox = 0;
      } else {
        CalcTemplate.intSCbox = 1;
      }
    }
  }

  onChangeComboBox2_DI(e: { target: { value: string; }; }) {
    if (Component_MAWP.strD_iclass) {
        Pitting_module.intChart_no = parseFloat(e.target.value);
        this.setPittingImageSrc();
    }
  }
}