import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ComponentEventService } from '../../services/component_event.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styles: [
    'h2 { font-family: GothamBold, Arial, sans-serif; margin: 16px 15px; }',
    '.help_dialog_close_btn { background: #0B2D71; color: #FFFFFF; margin: 8px 7px 0 0; }'
  ]
})
export class HelpComponent implements OnInit {
  links: any[] = [];
  dialogMode: boolean = false;

  constructor(private http: HttpClient,
              public compService: ComponentEventService
  ) { }

  ngOnInit(): void {
    this.dialogMode = this.compService.isHelpDialogModeActiveValue;
  }
}
