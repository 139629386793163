import { AbstractControl } from "@angular/forms";

export class Utility {
  public static PI: number = 3.1415926535;
  public static CrackXAxisConvertCToF: number = 1.8;
  public static CrackYAxisConvertInchToMm: number = 25.4;


  // Function to convert num to string while preserving the decimal place while
  // converting the decimal to string.
  // @param num - The number to convert.
  // @param decimal_place - The number of decimal places to preserve.
  // @returns The converted string.
  // /
  // public convertDecimalToString(num: number, decimal_place: number): string;
  public static convertDecimalToString(
    num: number,
    decimal_place: number
  ): string {
    let temp_f1 =
      Math.floor(Math.pow(10.0, decimal_place) * num + 0.5) /
      Math.pow(10.0, decimal_place);
    let ss = new String(temp_f1);
    let s = ss.toString();
    let dotIndex = s.indexOf(".");

    if (dotIndex === -1) {
      s += ".";
      for (let i = 1; i <= decimal_place; i++) {
        s += "0";
      }
      return s;
    } else {
      let size = dotIndex + decimal_place + 1;
      if (size > s.length) {
        return s.substr(0, s.length);
      } else {
        return s.substr(0, dotIndex + decimal_place + 1);
      }
    }
  }

  // Function round num based on designated decimal_place. This is take into
  // account of the rounding to be more precise based on the needs for accuracy
  // @param num - The number to round.
  // @param decimal_place - The decimal place to round at.
  // @returns The rounded number.
  // /
  public static roundDouble(num: number, decimal_place: number): number {
    return (
      Math.floor(Math.pow(10.0, decimal_place) * num + 0.5) /
      Math.pow(10.0, decimal_place)
    );
  }

  public static equalToZeroValidator(c: AbstractControl): { [key: string]: boolean } | null {
    return (c.value === 0) ? { "ValueEqualToZero": true } : null;
  }

  public static isTextADouble(text: string): boolean {
    let value = parseFloat(text);
    return !isNaN(value);
  }

  public static getActualUnitName(): string {
    let unitId = parseInt(localStorage.getItem('unitType')) || 0;
    return unitId == 0 ? '(US Customary)' : '(SI)';
  }

  public static decimalFilter(event: any, numberOfDecimalsLimit: number = 10) {
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!this.decimalValidationIsValid(input, numberOfDecimalsLimit)) {
      event.preventDefault();
    }
  }

  public static onPaste(event: ClipboardEvent, numberOfDecimalsLimit: number) {
    return this.decimalValidationIsValid(event.clipboardData.getData('text'), numberOfDecimalsLimit);
  }

  private static decimalValidationIsValid(inputText, numberOfDecimalsLimit) {
    const reg = /^-?\d*(\.\d{0,})?$/;
    let input = inputText;
    let numberOfDecimals = 0;
    if (input.indexOf('.') != -1) {
      numberOfDecimals = input.length - (input.indexOf('.') + 1);
    }

    return reg.test(input) && numberOfDecimals <= numberOfDecimalsLimit;
  }
}
