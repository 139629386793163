import { AfterViewInit, Component, Input } from "@angular/core";
import { Chart, registerables } from "chart.js";
import { Component_MAWP } from "projects/fitnessforservice/src/services/component_mawp";
import { Crack_Module } from "projects/fitnessforservice/src/services/crack_module";
import { Utility } from "projects/fitnessforservice/src/services/utility";
import { Subject } from "rxjs";

Chart.register(...registerables);

@Component({
  selector: "crack-chart",
  templateUrl: "crack-chart.component.html",
})
export class CrackChartComponent implements AfterViewInit {
  @Input() updateChartData: Subject<boolean>;
  resultPass: boolean = false;
  ctx: any;

  chart!: Chart;

  resultDotChartColorBlue = "blue";
  resultDotChartColorRed = "red";

  resultLineChartBorderColor = "rgba(46, 178, 0, 0.8)";
  resultLineChartBackgroundColor = "rgba(46, 178, 0, 0.6)";

  resultTitleColorPass = "blue";
  resultTitleColorFail = "red";

  ngAfterViewInit(): void {
    const ctx = document.getElementById("myChart") as HTMLCanvasElement;
    this.chart = new Chart(ctx, {
      data: {
        datasets: [
          {
            type: "line",
            pointRadius: 0,
            tension: 0.3,
            label: "Acceptable Zone",
            data: Crack_Module.Crack_Chart_Series0(),
            borderColor: this.resultLineChartBorderColor,
            backgroundColor: this.resultLineChartBackgroundColor,
            fill: true,
            order: 2,
          },
          {
            type: "scatter",
            label: "Assessment Point",
            data: Crack_Module.Crack_Chart_Series1(),
            borderColor: this.resultDotChartColorRed,
            backgroundColor: this.resultDotChartColorRed,
            order: 1,
          },
        ],
      },
      options: {
        animation: {
          onComplete: function () {
            Crack_Module.chartBase64ImageUrl = this.toBase64Image();
          }
        },
        scales: {
          x: {
            display: true,
            type: "linear",
            position: "bottom",
            title: {
              display: true,
              text: Component_MAWP.intUnit_select === 0 ? "(T - Tref + 100), °F" : "(T - Tref + 56), °C",
            },
            min: 0,
            max: Component_MAWP.intUnit_select === 0 ? 200 : 110,
            ticks: {
              stepSize: Component_MAWP.intUnit_select === 0 ? 20 : 10,
            },
          },
          y: {
            type: "linear",
            min: 0,
            max: Component_MAWP.intUnit_select === 0 ? 8 : 225,
            ticks: {
              stepSize: Component_MAWP.intUnit_select === 0 ? 1 : 25,
            },
            beginAtZero: true,
            title: {
              display: true,
              text: Component_MAWP.intUnit_select === 0 ? "Flaw length - 2c, in" : "Flaw length - 2c, mm",
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              title: () => (''),
              label: function (context) {
                let pointXValue = Crack_Module.TptActual.toFixed(1);
                let pointYValue = Component_MAWP.intUnit_select === 0 ? Crack_Module.sngC_crack.toFixed(3) : Crack_Module.sngC_crack.toFixed(1);
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += '(' + pointXValue + ', ' + pointYValue + ')';
                return label;
              }
            }
          },
          title: {
            font: {
              size: 20
            },
            display: true,
            text: Crack_Module.chart2LabelResult,
            color: this.resultTitleColorFail,
          },
        },
      },
    });

    Chart.defaults.font.size = 16;

    this.updateChart();

    this.updateChartData.subscribe((result) => {
      this.resultPass = result;
      this.updateChart();
    });
  }

  public updateChart() {
    this.chart.data.datasets[0].data = Crack_Module.Crack_Chart_Series0();
    this.chart.data.datasets[1].data = Crack_Module.Crack_Chart_Series1();
    this.chart.data.datasets[1].borderColor = this.resultPass
      ? this.resultDotChartColorBlue
      : this.resultDotChartColorRed;
    this.chart.data.datasets[1].backgroundColor = this.resultPass
      ? this.resultDotChartColorBlue
      : this.resultDotChartColorRed;
    this.chart.options.plugins.title.color = Crack_Module.chart2LabelResultColorRed
      ? this.resultTitleColorFail
      : this.resultTitleColorPass;
    this.chart.options.plugins.title.text = Crack_Module.chart2LabelResult;
    this.chart.update();
  }
}
