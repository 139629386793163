import { AfterViewInit, Component, Input } from "@angular/core";
import { Chart, registerables } from "chart.js";
import { Dent_Module } from "projects/fitnessforservice/src/services/dent_module";
import { Subject } from "rxjs";

Chart.register(...registerables);

@Component({
  selector: "dent-chart",
  templateUrl: "dent-chart.component.html",
})
export class DentChartComponent implements AfterViewInit {
  @Input() updateChartData: Subject<boolean>;
  resultPass: boolean = false;
  ctx: any;

  chart!: Chart;

  resultDotChartColorBlue = "blue";
  resultDotChartColorRed = "red";

  resultLineChartBorderColor = "rgba(46, 178, 0, 0.8)";
  resultLineChartBackgroundColor = "rgba(46, 178, 0, 0.6)";

  resultTitleColorPass = "blue";
  resultTitleColorFail = "red";

  ngAfterViewInit(): void {
    const ctx = document.getElementById("myChart") as HTMLCanvasElement;
    this.chart = new Chart(ctx, {
      data: {
        datasets: [
          {
            type: "line",
            label: "Acceptable Zone",
            data: [], // Array to hold data points
            pointRadius: 0,
            tension: 0,
            borderColor: this.resultLineChartBorderColor,
            backgroundColor: this.resultLineChartBackgroundColor,
            fill: true,
            order: 2,
          },
          {
            type: "scatter",
            label: "Assessment Point",
            data: [],
            borderColor: this.resultDotChartColorRed,
            backgroundColor: this.resultDotChartColorRed,
            order: 1,
          },
        ],
      },
      options: {
        animation: {
          onComplete: function () {
            Dent_Module.chartBase64ImageUrl = this.toBase64Image();
          },
        },
        scales: {
          x: {
            display: true,
            type: "linear",
            position: "bottom",
            min: 0,
            max: 0.05,
            title: {
              display: true,
              text: "d_dp/OD",
            },
            ticks: {
              stepSize: 0.01,
            },
          },
          y: {
            type: "linear",
            ticks: {
              stepSize: 0.1,
            },
            min: 0,
            max: 0.5,
            beginAtZero: true,
            title: {
              display: true,
              text: "d_g/tc",
            },
          },
        },
        plugins: {
          title: {
            font: {
              size: 20,
            },
            display: true,
            text: "", // Title text based on conditions
          },
        },
      },
    });

    Chart.defaults.font.size = 16;

    this.updateChart();

    this.updateChartData.subscribe((result) => {
      Dent_Module.dentGougeUpdateChart(this.chart);
      this.resultPass = result;
      this.updateChart();
    });
  }

  public updateChart() {
    if (this.resultPass) {
      this.chart.data.datasets[1].borderColor = this.resultDotChartColorBlue;
      this.chart.data.datasets[1].backgroundColor = this.resultDotChartColorBlue;
      this.chart.options.plugins.title.color = this.resultTitleColorPass;
    } else {
      this.chart.data.datasets[1].borderColor = this.resultDotChartColorRed;
      this.chart.data.datasets[1].backgroundColor = this.resultDotChartColorRed;
      this.chart.options.plugins.title.color = this.resultTitleColorFail;
    }
    this.chart.update();
  }
}
