import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from '@cvx/nextpage';

@Component({
  selector: "left-menu",
  templateUrl: "left-menu.component.html",
})
export class LeftMenuComponent {
  @Input() showImportExport: boolean = true;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;

  constructor(private NP: NextPageService, private activatedRoute: ActivatedRoute) { }
}