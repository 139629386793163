import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
} from "@cvx/nextpage";
import { Component_MAWP } from "../../services/component_mawp";
import { LTA_module } from "../../services/lta_module";
import { LTAP_module } from "../../services/ltap_module";
import { Laminations_module } from "../../services/laminations_module";
import { CalcTemplate } from "../../services/calctemplate";
import { BehaviorSubject, Subject } from "rxjs";
import { Pitting_module } from "../../services/pitting_module";
import { GML_module } from "../../services/gml_module";
import { BlisterHIC_module } from "../../services/blisterHIC_module";
import { Dent_Module } from "../../services/dent_module";
import { Crack_Module } from "../../services/crack_module";
import { Utility } from "../../services/utility";
import { WarningModalComponent } from "../warning-modal/warning-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { ComponentInformationComponent } from "./component-information/component-information.component";
import { FormControlsEventProxyService } from "../../services/formcontrols_eventproxy_service";

@Component({
  selector: "inspection-data",
  templateUrl: "inspection-data.component.html",
})
export class InspectionDataComponent implements OnInit, AfterViewInit {
  @Input() MAWP_result = "";
  @Input() inspectionDataCalc: Subject<boolean>;
  @Output() inspectionDataResult: EventEmitter<any> = new EventEmitter();
  @Output("nextStep") nextStep: EventEmitter<any> = new EventEmitter();
  componentInformationValidation: Subject<boolean> = new Subject<boolean>();

  @ViewChild(ComponentInformationComponent)
  childComponentInformationComponent: ComponentInformationComponent;

  inspectionDataCalculateCallValidationsInPitting: Subject<boolean> =
    new Subject();

  public validation_list = [];
  public validation_list_ComponentInformation = [];

  // below 4 properties are only used/changed in LMLProfileData assesment flow
  @Input() lmlProfileData_intnum_t_pt: number;
  @Output() lmlProfileData_intnum_t_ptChange = new EventEmitter<number>();
  @Input() lmlProfileData_sngGrid_spacing: number;
  @Output() lmlProfileData_sngGrid_spacingChange = new EventEmitter<number>();
  @Input() lmlProfileData_disable_intnum_t_pt: Subject<boolean>;

  inspectionDataFormGroup: FormGroup;
  public component_mawp = Component_MAWP;
  public utility_service = Utility;

  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;

  // Declare two input values and one output value
  intUnit_select: number = parseInt(localStorage.getItem("unitType")) || 0;
  comboBox1_DI_index: number = 1; //base-metal default

  summary: string;
  result: string;
  intUnit: number;

  div_for_comboBox2_DI_Equip_Hyd_visible: boolean;
  div_for_comboBox3_DI_Equip_Hyd_visible: boolean;
  requirementsForLaminationApproved: boolean;

  comboBox2_DI_Enabled: boolean = true;
  textBox4_DI_Enabled: boolean = true;

  basicValidationLabel: string =
    "Following inputs have invalid information.";
  pittingDisplayWarningModal: boolean;

  private radioButton_DI: FormControl = new FormControl({
    value: "radioButton1_DI_enabled",
    disabled: false,
  });

  getComboBox1_DI_Equip_Hyd_formControl(): FormControl {
    return this.inspectionDataFormGroup.get(
      "comboBox1_DI_Equip_Hyd"
    ) as FormControl;
  }

  getComboBox2_DI_Equip_Hyd_formControl(): FormControl {
    return this.inspectionDataFormGroup.get(
      "comboBox2_DI_Equip_Hyd"
    ) as FormControl;
  }

  getComboBox3_DI_Equip_Hyd_formControl(): FormControl {
    return this.inspectionDataFormGroup.get(
      "comboBox3_DI_Equip_Hyd"
    ) as FormControl;
  }

  getTextBox1_DI_formControl(): FormControl {
    return this.inspectionDataFormGroup.get("textBox1_DI") as FormControl;
  }

  getComboBox1_DI_formControl(): FormControl {
    return this.inspectionDataFormGroup.get("comboBox1_DI") as FormControl;
  }

  getTextBox1_DI_EquipInfo_formControl(): FormControl {
    return this.inspectionDataFormGroup.get(
      "textBox1_DI_EquipInfo"
    ) as FormControl;
  }

  fieldSetVisible(): boolean {
    return (
      Component_MAWP.strD_class != "Lamination" ||
      this.requirementsForLaminationApproved
    );
  }

  componentData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  updateComponentData(grayedOut: boolean) {
    this.componentData.next(grayedOut);
  }

  constructor(
    private NP: NextPageService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private formControlsEventProxyService: FormControlsEventProxyService
  ) {
    this.div_for_comboBox2_DI_Equip_Hyd_visible = false;
    this.div_for_comboBox3_DI_Equip_Hyd_visible = false;
    this.requirementsForLaminationApproved = false;

    switch (Component_MAWP.strD_iclass) {
      case 0: //GML
        this.inspectionDataFormGroup = this._formBuilder.group({
          textBox1_DI: ["", Validators.required],
          textBox2_DI: ["", Validators.required],
          textBox3_DI: ["", Validators.required],
          textBox4_DI: ["", Validators.required],
          textBox5_DI: ["", Validators.required],
          textBox6_DI_snggr: ["", Validators.required],
          radioButton_DI: this.radioButton_DI,
        });
        this.inspectionDataFormGroup.controls.textBox6_DI_snggr.disable();
        break;
      case 5: //BlisterHIC
        this.inspectionDataFormGroup = this._formBuilder.group({
          comboBox1_DI: ["No", Validators.required],
          textBox1_DI: ["", Validators.required],
          textBox2_DI: ["", Validators.required],
          textBox3_DI: ["", Validators.required],
          textBox4_DI: [
            { value: "", disabled: !this.textBox4_DI_Enabled },
            Validators.required,
          ],
          textBox5_DI: ["", Validators.required],
          textBox6_DI_snggr: ["", Validators.required],
          textBox7_DI: ["", Validators.required],
          comboBox2_DI_BlisterHIC: ["", Validators.required],
          comboBox3_DI: ["", Validators.required],
        });
        break;
      case 6: //Crack
        this.inspectionDataFormGroup = this._formBuilder.group({
          textBox1_DI: ["", Validators.required],
          textBox2_DI: ["", Validators.required],
          textBox3_DI: ["", Validators.required],
          comboBox1_DI: ["", Validators.required],
          comboBox2_DI: [
            { value: "", disabled: !this.comboBox2_DI_Enabled },
            Validators.required,
          ],
          textBox4_DI: [
            { value: "", disabled: !this.textBox4_DI_Enabled },
            Validators.required,
          ],
          comboBox3_DI: ["", Validators.required],
        });
        break;
      case 7: // Dent
        this.inspectionDataFormGroup = this._formBuilder.group({
          comboBox1_DI: ["", Validators.required],
          textBox1_DI: ["", Validators.required],
          textBox1_DI_EquipInfo: ["", Validators.required],
          textBox2_DI: ["", Validators.required],
          textBox3_DI: ["", Validators.required],
          textBox4_DI: [
            { value: "", disabled: !this.textBox4_DI_Enabled },
            Validators.required,
          ],
          textBox5_DI: ["", Validators.required],
        });
        break;
      case 8: // DentGouge
        this.inspectionDataFormGroup = this._formBuilder.group({
          comboBox1_DI: ["", Validators.required],
          textBox1_DI: ["", Validators.required],
          textBox2_DI: ["", Validators.required],
          textBox3_DI: [
            { value: "", disabled: true },
            Validators.required,
          ],
          textBox4_DI: [
            { value: "", disabled: !this.textBox4_DI_Enabled },
            Validators.required,
          ],
          textBox5_DI: ["", Validators.required],
          textBox6_DI_snggr: ["", Validators.required],
          textBox7_DI_sngHf: ["", Validators.required],
          textBox8_DI: ["", Validators.required],
          textBox9_DI: ["", Validators.required],
          textBox1_DI_EquipInfo: [
            { value: "", disabled: true },
            Validators.required,
          ],
          radioButton_DI: this.radioButton_DI,
        });
        let textBox1_DI_EquipInfo_formControl = this.getTextBox1_DI_EquipInfo_formControl();
        textBox1_DI_EquipInfo_formControl.disable();
        break;
      case 9: //Laminations
        this.inspectionDataFormGroup = this._formBuilder.group({
          comboBox1_DI_Equip_Hyd: ["", Validators.required],
          comboBox2_DI_Equip_Hyd: ["", Validators.required],
          comboBox3_DI_Equip_Hyd: ["", Validators.required],
          comboBox1_DI: ["No", Validators.required],
          comboBox2_DI: ["", Validators.required],
          textBox1_DI: ["", Validators.required],
          textBox2_DI: ["", Validators.required],
          textBox3_DI: ["", Validators.required],
          textBox4_DI: [
            { value: "", disabled: !this.textBox4_DI_Enabled },
            Validators.required,
          ],
          textBox5_DI: ["", Validators.required],
          textBox6_DI_snggr: ["", Validators.required],
          textBox7_DI_sngHf: ["", Validators.required],
          radioButton_DI: this.radioButton_DI,
        });
        break;
      default:
        this.inspectionDataFormGroup = this._formBuilder.group({
          comboBox1_DI: ["", Validators.required],
          textBox1_DI: ["", Validators.required],
          textBox2_DI: ["", Validators.required],
          textBox3_DI: ["", Validators.required],
          textBox4_DI: [
            { value: "", disabled: !this.textBox4_DI_Enabled },
            Validators.required,
          ],
          textBox5_DI: ["", Validators.required],
          textBox6_DI_snggr: ["", Validators.required],
          radioButton_DI: this.radioButton_DI,
        });
        this.inspectionDataFormGroup.controls.textBox6_DI_snggr.disable();
        break;
    }
  }

  ngOnInit(): void {
    this.setDefaults();
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0; // 0 - US, 1 - SI
    Component_MAWP.intUnit_select = this.intUnit;
    this.summary = "";
    this.result = "";
    this.inspectionDataCalc.subscribe((v) => {
      this.onPressPictureBox2_DI();
    });

    if (Component_MAWP.strD_iclass === 2) {
      this.lmlProfileData_disable_intnum_t_pt.subscribe((v) => {
        this.disable_lmlProfileData_intnum_t_pt();
      });
    }
    this.comboBox2_DI_Enabled = true;
    this.textBox4_DI_Enabled = true;

    if (Component_MAWP.strD_iclass !== 4)
      // Pitting has its own form controls in Pitting information component
      this.formControlsEventProxyService
        .getInspectionDataEventSubject()
        .subscribe((v) => {
          this.updateFormControlsAfterImport();
        });
  }

  ngAfterViewInit() {
    if ([8].includes(Component_MAWP.strD_iclass)) {
      // 6,
      this.inspectionDataFormGroup.addControl(
        "childComponentInformationForm",
        this.childComponentInformationComponent.componentInformationFormGroup
      );
      this.childComponentInformationComponent.componentInformationFormGroup.setParent(
        this.inspectionDataFormGroup
      );
    }
  }

  setDefaults() {
    LTA_module.intLTA_type = 0;
    switch (Component_MAWP.strD_iclass) {
      case 5: // BListerHIC
        // do nothing in BListerHIC
        break;
      case 6: // Crack
        this.onChangeComboBox2_DI("Parallel");
        break;
      case 7: // Dents
        // do nothing in Dents, Dents doesnt have ComboBox2 and RadioButton1
        break;
      case 8:
        this.onChangeRadioButton1_DI("radioButton1_DI_enabled");
        this.inspectionDataFormGroup.get("textBox6_DI_snggr")["enable"]();
        Dent_Module.intd_key = 0;
        break;
      case 9: // Laminations
        this.onChangeRadioButton1_DI("radioButton1_DI_enabled");
        break;
      default:
        this.onChangeRadioButton1_DI("radioButton1_DI_enabled");
        this.onChangeComboBox2_DI("Yes");
        break;
    }
  }

  onInput_lmlProfileData_intnum_t_pt(event: any) {
    if (Component_MAWP.strD_iclass === 2) {
      this.lmlProfileData_intnum_t_pt = event.target.value;
      this.lmlProfileData_intnum_t_ptChange.emit(
        this.lmlProfileData_intnum_t_pt
      );
    }
  }

  onInput_lmlProfileData_sngGrid_spacing(event: any) {
    if (Component_MAWP.strD_iclass === 2) {
      this.lmlProfileData_sngGrid_spacing = event.target.value;
      let textBox1_DI_formControl: FormControl =
        this.getTextBox1_DI_formControl();
      if (this.lmlProfileData_sngGrid_spacing.toString() === "0") {
        textBox1_DI_formControl.setValue("");
        textBox1_DI_formControl.disable(); // disable lmlProfileData_intnum_t_pt when gridSpacing is 0
      } else if (!textBox1_DI_formControl.enabled) {
        textBox1_DI_formControl.setValue("");
        textBox1_DI_formControl.enable();
      }

      this.lmlProfileData_sngGrid_spacingChange.emit(
        this.lmlProfileData_sngGrid_spacing
      );
    }
  }

  DentInPressurizedCondition(): boolean {
    if ([7, 8].indexOf(Component_MAWP.strD_iclass) !== -1) {
      let comboBox1_DIformControl = this.getComboBox1_DI_formControl();

      if (comboBox1_DIformControl.value === "Yes") return true;
      else if (comboBox1_DIformControl.value === "No") return false;

      return true;
    }
  }

  disable_lmlProfileData_intnum_t_pt() {
    let textBox1_DI_formControl: FormControl =
      this.getTextBox1_DI_formControl();
    textBox1_DI_formControl.disable();
  }

  onUpdateValidationsStatus(validationsStatusData: any) {
    this.validation_list = validationsStatusData.messagesValidationsList;

    if (validationsStatusData.displayWarningModal)
      this.pittingDisplayWarningModal = true;
    else this.pittingDisplayWarningModal = false;
  }

  openPittingWarningModal(): void {
    let pittingWarningMessage: string[] = [];
    pittingWarningMessage.push(
      "Pitting Corrosion is defined as localized regions of metal loss charecterized by a pit diameter on the order of the plate thickness or less and a pit depth that is less than the plate thickness."
    );
    pittingWarningMessage.push(
      "Pitting Corrosion Level 1 assessment is limited to widespread pitting."
    );

    const dialogRef = this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: { warningMessage: pittingWarningMessage, caption: "Alert", captionCenter: true, cancelButtonShow: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // "OK"
        Pitting_module.Pit_RSF_calc();
        this.result = Pitting_module.Pit_calc();
        this.nextStep.emit();
        this.inspectionDataResult.emit(this.result);
      } // back
    });
  }

  updateFormControlsAfterImport() {
    switch (Component_MAWP.strD_iclass) {
      case 0:
        GML_module.updateInspectionDataFormControls(
          this.inspectionDataFormGroup
        );
        break;
      case 1:
      case 2:
      case 3:
        LTA_module.updateInspectionDataFormControls(
          this.inspectionDataFormGroup
        );
        break;
      case 5:
        BlisterHIC_module.updateInspectionDataFormControls(this.inspectionDataFormGroup);
        break;
      case 6:
        Crack_Module.updateInspectionDataFormControls(this.inspectionDataFormGroup);
        break;
      case 7:
        Dent_Module.updateInspectionDataFormControlsForDent(this.inspectionDataFormGroup);
        break;
      case 8:
        Dent_Module.updateInspectionDataFormControlsForDentGouge(this.inspectionDataFormGroup);
        break;
      case 9:
        this.updateFormControlsForLaminations();
        break;
      default: //for other calculations
        break;
    }

    this.validation_list = [];
  }

  updateFormControlsForLaminations(): void {
    let comboBox1_DI_Equip_Hyd_formControl =
      this.getComboBox1_DI_Equip_Hyd_formControl();
    let comboBox2_DI_Equip_Hyd_formControl =
      this.getComboBox2_DI_Equip_Hyd_formControl();
    let comboBox3_DI_Equip_Hyd_formControl =
      this.getComboBox3_DI_Equip_Hyd_formControl();

    if (Laminations_module.intBulge != -1) {
      if (Laminations_module.intBulge == 0) {
        comboBox1_DI_Equip_Hyd_formControl.patchValue("No");
        this.div_for_comboBox2_DI_Equip_Hyd_visible = true;
      } else if (Laminations_module.intBulge == 1) {
        comboBox1_DI_Equip_Hyd_formControl.setValue("Yes");
        this.div_for_comboBox2_DI_Equip_Hyd_visible = false;
        this.div_for_comboBox3_DI_Equip_Hyd_visible = false;
        this.requirementsForLaminationApproved = false;
      }
    }

    if (Laminations_module.intH2 != -1 && !Laminations_module.intBulge) {
      if (Laminations_module.intH2 == 0) {
        comboBox2_DI_Equip_Hyd_formControl.setValue("No");
        this.div_for_comboBox3_DI_Equip_Hyd_visible = true;
      } else if (Laminations_module.intH2 == 1) {
        comboBox2_DI_Equip_Hyd_formControl?.setValue("Yes");
        this.div_for_comboBox3_DI_Equip_Hyd_visible = false;
        this.requirementsForLaminationApproved = false;
      }
    }

    if (
      Laminations_module.intCrackCheck != -1 &&
      !Laminations_module.intH2 &&
      !Laminations_module.intBulge
    ) {
      if (Laminations_module.intCrackCheck == 0) {
        comboBox3_DI_Equip_Hyd_formControl.setValue("No");
        this.requirementsForLaminationApproved = true;
      } else if (Laminations_module.intCrackCheck == 1) {
        comboBox3_DI_Equip_Hyd_formControl?.setValue("Yes");
        this.requirementsForLaminationApproved = false;
      }
    }

    Laminations_module.updateRemainingInspectionDataFormControls(
      this.inspectionDataFormGroup
    );
  }

  onChangeRadioButton1_DI(typeOfMetalLoss: string) {
    let textBox6_DI_snggrEnable;
    if (typeOfMetalLoss == "radioButton1_DI_enabled") {
      LTA_module.intLTA_type = 0;
      textBox6_DI_snggrEnable = false;
      switch (Component_MAWP.strD_iclass) {
        case 0:
          GML_module.intanalysis_type = 0;
          break;
        case 1:
        case 2:
          //local-thin-area-lta
          LTA_module.intLTA_type = 0;
          textBox6_DI_snggrEnable = false;
          break;
        case 3:
          textBox6_DI_snggrEnable = true;
          break;
        case 5:
          Component_MAWP.strD_class = "Blister";
          textBox6_DI_snggrEnable = true;
          break;
      }
    } else {
      LTA_module.intLTA_type = 1;
      textBox6_DI_snggrEnable = true;
      switch (Component_MAWP.strD_iclass) {
        case 0:
          GML_module.intanalysis_type = 1;
          break;
        case 1:
        case 2:
          // Groove
          LTA_module.intLTA_type = 1;
          textBox6_DI_snggrEnable = true;
          break;
        case 3:
          textBox6_DI_snggrEnable = true;
          break;
        case 5:
          Component_MAWP.strD_class = "HIC";
          textBox6_DI_snggrEnable = true;
          break;
      }
    }
    this.inspectionDataFormGroup
      .get("textBox6_DI_snggr")
    [textBox6_DI_snggrEnable ? "enable" : "disable"]();
  }

  onChangeComboBox1_DI(comboBox1Value: string) {
    let textBox1_DI_formControl = this.getTextBox1_DI_formControl();
    switch (Component_MAWP.strD_iclass) {
      case 4: // Pitting
        break;
      case 6: //Crack
        Crack_Module.intcrack_location = Number(comboBox1Value);
        this.comboBox1_DI_index = Number(comboBox1Value);
        break;
      case 7: // Dent    
        let textBox1_DI_EquipInfo_formControl =
          this.getTextBox1_DI_EquipInfo_formControl();
        if (comboBox1Value == "No") {
          Dent_Module.intd_key = 0;
          textBox1_DI_formControl.setValue("");
          textBox1_DI_EquipInfo_formControl.enable();
        } else if (comboBox1Value == "Yes") {
          Dent_Module.intd_key = 1;
          textBox1_DI_formControl.setValue("");
          textBox1_DI_EquipInfo_formControl.disable();
        }
        break;
      case 8: // DentGouge
        if (comboBox1Value == "No") {
          Dent_Module.intd_key = 0;
          this.updateComponentData(false);
          textBox1_DI_formControl.setValue("");
        } else if (comboBox1Value == "Yes") {
          Dent_Module.intd_key = 1;
          this.updateComponentData(true);
          textBox1_DI_formControl.setValue("");
        }
        break;
      default: //for other calculations
        let structuralDiscountinuities = comboBox1Value;
        if (structuralDiscountinuities == "No") {
          CalcTemplate.intSCbox = 0;
        } else {
          CalcTemplate.intSCbox = 1;
        }
        break;
    }
  }

  onChangeTextBox1_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 0: // GML
          GML_module.sngtam = parseFloat(e.target.value);
          break;
        case 3: // Gouge
        case 9: // Laminations
        case 1: // LTA
          LTA_module.sngtmm = parseFloat(e.target.value);
          break;
        case 2: // LTA Profile
          if (e.target.value.toString().includes(".")) {
            let textBox1_DI_formControl: FormControl =
              this.getTextBox1_DI_formControl();
            textBox1_DI_formControl.setValue("");
            return;
          }
          LTAP_module.intnum_t_pt = parseFloat(e.target.value);
          break;
        case 5: //BlisterHIC
          LTA_module.sngss = parseFloat(e.target.value);
          break;
        case 6: //Crack
          Crack_Module.sngA_crack = parseFloat(e.target.value);
          break;
        case 7: //Dents
        case 8: // DentGouge
          if (Dent_Module.intd_key === 0)
            Dent_Module.sngd_d0 = parseFloat(e.target.value);
          else if (Dent_Module.intd_key === 1)
            Dent_Module.sngd_dp = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeTextBox2_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 0:
          LTA_module.sngtmm = parseFloat(e.target.value);
          break;
        case 5: // BLister HI
          LTA_module.sngcc = parseFloat(e.target.value);
          break;
        case 6: // Crack
          Crack_Module.sngC_crack = parseFloat(e.target.value);
          break;
        case 7: // Dents
          Dent_Module.sngLw = parseFloat(e.target.value);
          break;
        case 8: // DentGouge
          Dent_Module.sngd_g = parseFloat(e.target.value);
          break;
        case 9: // Laminations
          LTA_module.sngss = parseFloat(e.target.value);
          break;
        default:
          CalcTemplate.sngFCAml = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeTextBox3_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 0: //GML
          CalcTemplate.sngFCAml = parseFloat(e.target.value);
          break;
        case 1: // LTA
          LTA_module.sngss = parseFloat(e.target.value);
          break;
        case 2: // LTA Profile
          LTAP_module.sngGrid_spacing = parseFloat(e.target.value);
          break;
        case 3: //Gouge
          LTA_module.sngss = parseFloat(e.target.value);
          break;
        case 4: //Pitting
          CalcTemplate.sngH_f = parseFloat(e.target.value);
          break;
        case 5: // Blister HIC
          BlisterHIC_module.sngtmm_ID = parseFloat(e.target.value);
          LTA_module.sngtmm = parseFloat(e.target.value);
          break;
        case 6: //Crack
          Dent_Module.sngLw = parseFloat(e.target.value);
          break;
        case 7: // Dents
          CalcTemplate.sngLmsd = parseFloat(e.target.value);
          break;
        case 8: // DentGouge
          LTA_module.sngtmm = parseFloat(e.target.value);
          break;
        case 9: //Laminations
          LTA_module.sngcc = parseFloat(e.target.value);
          break;
      }
    }
  }

  textBox4_DI_Enabling(enabled: boolean) {
    this.textBox4_DI_Enabled = enabled;
    this.inspectionDataFormGroup.controls["textBox4_DI"].enable();
  }

  onChangeTextBox4_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 1: // LTA
          LTA_module.sngcc = parseFloat(e.target.value);
          break;
        case 2: // LTAP
          LTA_module.sngcc = parseFloat(e.target.value);
          break;
        case 3: // Gouge
          LTA_module.sngcc = parseFloat(e.target.value);
          break;
        case 5: // Blister HIC
          BlisterHIC_module.sngBp = parseFloat(e.target.value);
          BlisterHIC_module.sngtmm_OD = parseFloat(e.target.value);
          break;
        case 6: // Crack
          CalcTemplate.sngLmsd = parseFloat(e.target.value);
          break;
        case 7: // Dents
          Dent_Module.sngLd = parseFloat(e.target.value);
          break;
        case 8: // DentGouge
          CalcTemplate.sngFCAml = parseFloat(e.target.value);
          break;
        case 9: // Laminations
          Laminations_module.sngLh = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeTextBox5_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 1: // LTA
          CalcTemplate.sngLmsd = parseFloat(e.target.value);
          break;
        case 2: //LTAP
          CalcTemplate.sngLmsd = parseFloat(e.target.value);
          break;
        case 3: //Gouge
          CalcTemplate.sngLmsd = parseFloat(e.target.value);
          break;
        case 5: //BLister & HIC
          BlisterHIC_module.sngLb = parseFloat(e.target.value);
          break;
        case 7: // Dents
          Dent_Module.sngrd = parseFloat(e.target.value);
          break;
        case 8: // DentGouge
          Dent_Module.sngLw = parseFloat(e.target.value);
          break;
        case 9: //Laminations
          Laminations_module.sngLs = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeTextBox6_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 1: //LTA
          LTA_module.snggr = parseFloat(e.target.value);
          break;
        case 2: //LTAP
          LTA_module.snggr = parseFloat(e.target.value);
          break;
        case 3: //Gouge
          LTA_module.snggr = parseFloat(e.target.value);
          break;
        case 5: //BListerHIC
          Dent_Module.sngLw = parseFloat(e.target.value);
          break;
        case 8: //DentGouge
          CalcTemplate.sngLmsd = parseFloat(e.target.value);
          break;
        case 9: //Laminations
          Dent_Module.sngLw = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeTextBox7_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 1: //LTA
          CalcTemplate.sngH_f = parseFloat(e.target.value);
          break;
        case 2: //LTAP
          CalcTemplate.sngH_f = parseFloat(e.target.value);
          break;
        case 3: //Gouge
          CalcTemplate.sngH_f = parseFloat(e.target.value);
          break;
        case 5: //BlisterHIC
          CalcTemplate.sngLmsd = parseFloat(e.target.value);
          break;
        case 8: //DentGouge
          Dent_Module.sngLd = parseFloat(e.target.value);
          break;
        case 9: //Laminations
          CalcTemplate.sngLmsd = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeTextBox8_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 8: //DentGouge
          Dent_Module.sngrd1 = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeTextBox9_DI(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 8: //DentGouge
          Dent_Module.sngrd = parseFloat(e.target.value);
          break;
      }
    }
  }

  onChangeComboBox1_DI_Equip_Hyd(e: { target: { value: string } }) {
    {
      if (e.target.value == "No") {
        Laminations_module.intBulge = 0;
        this.div_for_comboBox2_DI_Equip_Hyd_visible = true;
      } else {
        if (e.target.value == "Yes") {
          let comboBox2_DI_Equip_Hyd_formControl =
            this.getComboBox2_DI_Equip_Hyd_formControl();
          comboBox2_DI_Equip_Hyd_formControl.setValue("");
          let comboBox3_DI_Equip_Hyd_formControl =
            this.getComboBox3_DI_Equip_Hyd_formControl();
          comboBox3_DI_Equip_Hyd_formControl.setValue("");
          Laminations_module.intBulge = 1;
          this.openGenericErrorModal(
            "Use Blisters assessment procedure to assess the lamination.  Current assessment aborted.",
            "Failed Level 1 Assessment"
          );
        }

        this.div_for_comboBox2_DI_Equip_Hyd_visible = false;
        this.div_for_comboBox3_DI_Equip_Hyd_visible = false;
        this.requirementsForLaminationApproved = false;
      }
    }
  }

  onChangeComboBox2_DI_Equip_Hyd(e: { target: { value: string } }) {
    {
      if (e.target.value == "No") {
        Laminations_module.intH2 = 0;
        this.div_for_comboBox3_DI_Equip_Hyd_visible = true;
      } else {
        if (e.target.value == "Yes") {
          let comboBox3_DI_Equip_Hyd_formControl =
            this.getComboBox3_DI_Equip_Hyd_formControl();
          comboBox3_DI_Equip_Hyd_formControl.setValue("");
          Laminations_module.intH2 = 1;
          this.openGenericErrorModal(
            "Use HIC Damage assessment procedure to assess the lamination.  Current assessment aborted.",
            "Failed Level 1 Assessment"
          );
        }

        this.div_for_comboBox3_DI_Equip_Hyd_visible = false;
        this.requirementsForLaminationApproved = false;
      }
    }
  }

  onChangeComboBox3_DI_Equip_Hyd(e: { target: { value: string } }) {
    {
      if (e.target.value == "No") {
        Laminations_module.intCrackCheck = 0;
        this.requirementsForLaminationApproved = true;
        this.inspectionDataFormGroup.get("textBox6_DI_snggr")["enable"]();
      } else {
        if (e.target.value == "Yes") {
          Laminations_module.intCrackCheck = 1;
          this.openGenericErrorModal(
            "Use Crack-Like Indications assessment procedure to assess the lamination.  Current assessment aborted.",
            "Failed Level 1 Assessment"
          );
        }

        this.requirementsForLaminationApproved = false;
      }
    }
  }

  comboBox2_DI_Enabling(enabled: boolean) {
    this.comboBox2_DI_Enabled = enabled;
  }

  onChangeComboBox2_DI(comboBox2Value: string) {
    switch (Component_MAWP.strD_iclass) {
      case 5: // BListerHIC
        if (comboBox2Value === "No") {
          BlisterHIC_module.intPcrack = 0;
          BlisterHIC_module.intH2 = 0;
        } else if (comboBox2Value === "Yes") {
          BlisterHIC_module.intPcrack = 1;
          BlisterHIC_module.intH2 = 1;
        }
        break;
      case 6: // Crack
        if (comboBox2Value === "Parallel") Crack_Module.intcrack_orien = 0;
        else if (comboBox2Value === "Perpendicular")
          Crack_Module.intcrack_orien = 1;
        break;
      case 8: //DentGouge
        if (comboBox2Value === "yes") {
          this.comboBox2_DI_Enabling(false);
        } else {
          this.comboBox2_DI_Enabling(true);
        }
        break;
      case 9: // Laminations
        if (comboBox2Value === "No") Laminations_module.intElevation = 0;
        else Laminations_module.intElevation = 1;
        break;
    }
  }

  onChangeComboBox3_DI(comboBox3Value: string) {
    switch (Component_MAWP.strD_iclass) {
      case 5:
        if (comboBox3Value === "No") BlisterHIC_module.intVent = 0;
        else if (comboBox3Value === "Yes") BlisterHIC_module.intVent = 1;
        break;
      case 6:
        if (comboBox3Value === "No") Crack_Module.intsize_accuracy = 0;
        else if (comboBox3Value === "Yes") Crack_Module.intsize_accuracy = 1;
        break;
    }
  }

  onChangeTextBox1_DI_EquipInfo(e) {
    if (Utility.isTextADouble(e.target.value)) {
      switch (Component_MAWP.strD_iclass) {
        case 7:
          Dent_Module.sngP_op = parseFloat(e.target.value);
          break;
      }
    }
  }

  equipHydInLaminationsCorrect(): boolean {
    let comboBox1_DI_Equip_Hyd_formControl =
      this.getComboBox1_DI_Equip_Hyd_formControl();
    let comboBox2_DI_Equip_Hyd_formControl =
      this.getComboBox2_DI_Equip_Hyd_formControl();
    let comboBox3_DI_Equip_Hyd_formControl =
      this.getComboBox3_DI_Equip_Hyd_formControl();

    if (comboBox1_DI_Equip_Hyd_formControl.value == "") {
      this.openGenericErrorModal(
        "Please select an answer for the surface bulging question.",
        "Failed Level 1 Assessment"
      );
      return false;
    } else if (comboBox1_DI_Equip_Hyd_formControl.value == "Yes") {
      this.openGenericErrorModal(
        "Use Blisters assessment procedure to assess the lamination. Current assessment aborted.",
        "Failed Level 1 Assessment"
      );
      return false;
    } else if (comboBox2_DI_Equip_Hyd_formControl.value == "") {
      this.openGenericErrorModal(
        "Please select an answer for the hydrogen changing question.",
        "Failed Level 1 Assessment"
      );
      return false;
    } else if (comboBox2_DI_Equip_Hyd_formControl.value == "Yes") {
      this.openGenericErrorModal(
        "Use HIC Damage assessment procedure to assess the lamination.  Current assessment aborted.",
        "Failed Level 1 Assessment"
      );
      return false;
    } else if (comboBox3_DI_Equip_Hyd_formControl.value == "") {
      this.openGenericErrorModal(
        "Use Crack-Like Indications assessment procedures to assess the lamination.",
        "Failed Level 1 Assessment"
      );
      return false;
    } else if (comboBox3_DI_Equip_Hyd_formControl.value == "Yes") {
      this.openGenericErrorModal(
        "Use Crack-Like Indications assessment procedures to assess the lamination.  Current assessment aborted.",
        "Failed Level 1 Assessment"
      );
      return false;
    }

    return true;
  }

  onPressPictureBox2_DI() {
    Object.keys(this.inspectionDataFormGroup.controls).forEach((key) => {
      this.inspectionDataFormGroup.get(key).markAsDirty();
    });

    let resultFromValidation = false;

    switch (Component_MAWP.strD_iclass) {
      case 0: //GML
        this.onPressPictureBox2_DI_GML();
        break;
      case 1: // LTA
        this.onPressPictureBox2_DI_LTA();
        break;
      case 2: // LTA Profile
        this.onPressPictureBox2_DI_LTA_Profile();
        break;
      case 3: //Gouge
        this.onPressPictureBox2_DI_Gouge();
        break;
      case 4: // Pitting
        this.onPressPictureBox2_DI_Pitting();
        break;
      case 5: //BlisterHIC
        this.onPressPictureBox2_DI_BlisterHIC();
        break;
      case 6: //Crack
        this.onPressPictureBox2_DI_CrackLike();
        break;
      case 7: // Dent
        this.onPressPictureBox2_DI_Dent();
        break;
      case 8: // DentGouge
        this.onPressPictureBox2_DI_DentGouge();
        break;
      case 9: //Laminations
        this.onPressPictureBox2_DI_Laminations();
        break;
    }

    this.inspectionDataResult.emit(this.result);
  }

  onPressPictureBox2_DI_GML() {
    let resultFromValidation = GML_module.check_GML_Data(
      this.inspectionDataFormGroup
    );

    this.validation_list = GML_module.validationMessage;
    if (!resultFromValidation) {
      return;
    }

    this.result = GML_module.GML_calc();
    this.nextStep.emit();
  }

  onPressPictureBox2_DI_LTA() {
    let resultFromValidation = LTA_module.check_LTA_Data(
      this.inspectionDataFormGroup
    );
    this.validation_list = LTA_module.validationMessage;
    if (!resultFromValidation) {
      return;
    }

    this.result = LTA_module.LTA_calc();
    this.nextStep.emit();
  }

  onPressPictureBox2_DI_LTA_Profile() {
    let resultFromValidation = LTA_module.check_LTA_Data(
      this.inspectionDataFormGroup
    );
    this.validation_list = LTA_module.validationMessage;
    if (!resultFromValidation) {
      return;
    }
    this.nextStep.emit();
  }

  onPressPictureBox2_DI_Gouge() {
    let resultFromValidation = LTA_module.check_LTA_Data(
      this.inspectionDataFormGroup
    );

    this.validation_list = LTA_module.validationMessage;
    if (!resultFromValidation) {
      return;
    }

    this.result = LTA_module.LTA_calc();
    this.nextStep.emit();
  }

  onPressPictureBox2_DI_Pitting() {
    this.inspectionDataCalculateCallValidationsInPitting.next(true); // call validations logic in pitting-information component
    if (this.validation_list.length != 0) return;

    if (this.pittingDisplayWarningModal) {
      this.openPittingWarningModal();
    } else {
      Pitting_module.Pit_RSF_calc();
      this.result = Pitting_module.Pit_calc();
      this.nextStep.emit();
    }
  }

  onPressPictureBox2_DI_BlisterHIC() {
    let resultFromValidation = BlisterHIC_module.check_BlisterHIC_Data(
      this.inspectionDataFormGroup
    );
    this.validation_list = BlisterHIC_module.validationMessageBlisterHIC;

    if (!resultFromValidation) {
      return;
    }

    if (Component_MAWP.strD_class == "Blister") {
      this.result = BlisterHIC_module.Blister_calc();
    } else if (Component_MAWP.strD_class == "HIC") {
      this.result = BlisterHIC_module.HIC_calc();
    }

    this.nextStep.emit();
  }

  onPressPictureBox2_DI_CrackLike() {
    let resultFromValidation = Crack_Module.check_NewCrack_Data1(
      this.inspectionDataFormGroup
    );
    this.validation_list = Crack_Module.validationMessageCrack;
    this.validation_list_ComponentInformation =
      Crack_Module.validationMessageComponentInformation;

    if (!resultFromValidation) {
      return;
    }

    //logic from Form1.cpp -> pictureBox_RA_Click
    switch (Component_MAWP.intEquip) {
      case 2: //Sphere
        Component_MAWP.intEquip = 1;
        break;
      case 5://B31.3 Piping
        Component_MAWP.intEquip = 0; // Added as new requirement and used for the graph calculations, value should be same as Cylinder - suggested by Alfonso
        Component_MAWP.sngp_H = -1;  // when user slected "B31.3 Piping" in Crack-Like Indications then Liquid  headHeight -1 should be considered
        break;
      default:
        break;
    }

    this.result = Crack_Module.Crack_calc();

    if (Crack_Module.crackAlertMessageFix.length != 0) {
      this.openCrackAlertModal();
    } else {
      this.nextStep.emit();
    }
  }

  onPressPictureBox2_DI_Dent() {
    let resultFromValidation = Dent_Module.check_Dent_Data(
      this.inspectionDataFormGroup
    );
    this.validation_list = Dent_Module.validationMessage;
    if (!resultFromValidation) {
      return;
    }
    this.result = Dent_Module.Dent_calc();
    this.nextStep.emit();
  }

  onPressPictureBox2_DI_DentGouge() {
    this.validation_list = [];
    this.validation_list_ComponentInformation = [];
    this.componentInformationValidation.next(true);
    let resultFromValidation = Dent_Module.check_Dent_Gouge_Data(
      this.inspectionDataFormGroup
    );
    this.validation_list = Dent_Module.validationMessage;
    this.validation_list_ComponentInformation = Dent_Module.validationMessageComponentInformation;

    if (!resultFromValidation) {
      return;
    }
    this.result = Dent_Module.Dent_Gouge_calc();
    this.nextStep.emit();
  }

  onPressPictureBox2_DI_Laminations() {
    if (!this.equipHydInLaminationsCorrect()) return;

    let resultFromValidation = Laminations_module.check_Laminations_Data(
      this.inspectionDataFormGroup
    );
    this.validation_list = Laminations_module.validationMessage;
    if (!resultFromValidation) {
      return;
    }
    this.result = Laminations_module.Laminations_calc();
    this.nextStep.emit();
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.inspectionDataFormGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  private openCrackAlertModal(): void {
    const dialogRef = this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: {
        warningMessage: Crack_Module.crackAlertMessageFix,
        caption: "Alert",
        captionCenter: true,
        cancelButtonShow: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.nextStep.emit();
    });
  }

  private openGenericErrorModal(warningMessage: string, caption: string): void {
    this.dialog.open(WarningModalComponent, {
      width: "600px",
      data: {
        warningMessage: [warningMessage],
        caption: caption,
        captionCenter: true,
        cancelButtonShow: false,
      },
    });
  }
}
