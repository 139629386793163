import { Component, Inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PopoutService } from "projects/fitnessforservice/src/services/popout.service";
import { Component_MAWP } from "projects/fitnessforservice/src/services/component_mawp";

@Component({
  selector: "print-report-modal",
  templateUrl: "print-report-modal.component.html",
  styleUrls: ["./print-report-modal.component.css"],
})
export class PrintReportModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {},
    private _formBuilder: FormBuilder,
    private popoutService: PopoutService
  ) {}

  printReportModalFormGroup = this._formBuilder.group({
    textBox1: [Component_MAWP.strFacility],
    textBox2: [Component_MAWP.strPlant],
    textBox3: [Component_MAWP.strEquipment],
    textBox4: [Component_MAWP.strAnalyst],
    textBox5: [Component_MAWP.sngP_d],
    textBox6: [
      Component_MAWP.sngTemp_d != -1 ? Component_MAWP.sngTemp_d : null,
    ],
  });

  openReportPopout() {
    Component_MAWP.strFacility =
      this.printReportModalFormGroup.get("textBox1").value;
    Component_MAWP.strPlant =
      this.printReportModalFormGroup.get("textBox2").value;
    Component_MAWP.strEquipment =
      this.printReportModalFormGroup.get("textBox3").value;
    Component_MAWP.strAnalyst =
      this.printReportModalFormGroup.get("textBox4").value;

    Component_MAWP.sngP_d = parseFloat(
      this.printReportModalFormGroup.get("textBox5").value?.toString()
    );
    Component_MAWP.sngTemp_d = parseFloat(
      this.printReportModalFormGroup.get("textBox6").value?.toString()
    );

    this.popoutService.openPopoutModal();
  }
}
