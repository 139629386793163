// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-icon {
  color: orange;
  transform: scale(2);
  margin: 15px;
}

.icon-container {
  height: 50px;
}

.dialog-container {
  display: flex;
}

.warning-text {
  margin: auto;
  color: black;
  list-style-type: none;
}

.dialog-actions {
  float: right;
}

.captionLeft {
  text-align: left;
  font-size: 30px;
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./projects/fitnessforservice/src/app/warning-modal/warning-modal.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd","sourcesContent":[".warning-icon {\n  color: orange;\n  transform: scale(2);\n  margin: 15px;\n}\n\n.icon-container {\n  height: 50px;\n}\n\n.dialog-container {\n  display: flex;\n}\n\n.warning-text {\n  margin: auto;\n  color: black;\n  list-style-type: none;\n}\n\n.dialog-actions {\n  float: right;\n}\n\n.captionLeft {\n  text-align: left;\n  font-size: 30px;\n  margin: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
