import { Component, OnInit } from "@angular/core";
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
  SearchType,
  SliderType,
} from "@cvx/nextpage";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;
  subscription: Subscription;

  constructor(private NP: NextPageService,
  ) {
    this.NP.options.sitename = "Fitness-For-Service Level 1";
  }

  ngOnInit() {
    // Set a default unit system if it's not already set
    if (!localStorage.getItem("unitType")) {
      localStorage.setItem("unitType", "0"); // Default to US
    }
  }
}
