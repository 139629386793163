import { FormControl } from "@angular/forms";
import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { Dent_Module } from "./dent_module";
import { LTA_module } from "./lta_module";
import { formatDate } from "@angular/common";

export class BlisterHIC_module {
  public static intVent: number; // blister vented, 0 - no, 1 - yes
  public static sngDb: number; // blister diameter
  public static sngLb: number; // spacing to nearest blister or HIC
  public static sngBp: number; // blister projection
  public static intPcrack: number; // blister peripheral cracking, 0 - no, 1 - yes
  public static sngtmm_ID: number; // minimum measured thickness form HIC to ID surface
  public static sngtmm_OD: number; // minimum measured thickness form HIC to OD surface
  public static sngW_H: number; // height of the HIC damage zone
  public static intH2: number; //hydrogen charging stopped? 0 - no, 1 - yes

  public static validationMessageBlisterHIC = [];
  public static validationMessageComponentInformation = [];
  public static validationMessageTypeOfDamage = [];
  public static blisterAndHIC_CalcResult: string;

  public static BlisterHIC_initialize(): void {
    //initialize all the variables
    this.intVent = 1; // -1
    this.sngDb = -1;
    this.sngLb = -1;
    this.sngBp = -1;
    this.intPcrack = 1; // -1
    this.sngtmm_ID = -1;
    this.sngtmm_OD = -1;
    this.sngW_H = -1;
    CalcTemplate.intSCbox = 0;

    this.blisterAndHIC_CalcResult = "";

    this.intH2 = 1; // added
    Dent_Module.Dent_initialize(); //added

    this.validationMessageBlisterHIC = [];
    this.validationMessageComponentInformation = [];
    this.validationMessageTypeOfDamage = [];
  }

  public static ReturnBlisterHICValuesAsHTML(): string {
    let result =
      "intVent: " +
      BlisterHIC_module.intVent +
      "</br>" +
      "sngDb: " +
      BlisterHIC_module.sngDb +
      "</br>" +
      "sngLb: " +
      BlisterHIC_module.sngLb +
      "</br>" +
      "sngBp: " +
      BlisterHIC_module.sngBp +
      "</br>" +
      "intPcrack: " +
      BlisterHIC_module.intPcrack +
      "</br>" +
      "sngtmm_ID: " +
      BlisterHIC_module.sngtmm_ID +
      "</br>" +
      "sngtmm_OD: " +
      BlisterHIC_module.sngtmm_OD +
      "</br>" +
      "sngW_H: " +
      BlisterHIC_module.sngW_H +
      "</br>" +
      "intH2: " +
      BlisterHIC_module.intH2;

    return result;
  }

  public static Blister_calc(): string {
    let aa: number;
    let key1: number,
      key2: number,
      key3: number,
      key4: number,
      key5: number,
      key6: number,
      key7: number,
      key8: number,
      key9: number;

    this.blisterAndHIC_CalcResult = ""

    Component_MAWP.sngtc = Component_MAWP.sngtrd - Component_MAWP.sngFCA;

    // Carlos Acero - 07/09/24
    // Set Any other HIC or blister infringe on the 2s X 2c box? select to NO as default and hide
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    CalcTemplate.intSCbox = 0;

    if (LTA_module.sngss >= LTA_module.sngcc) {
      this.sngDb = LTA_module.sngss;
    } else {
      this.sngDb = LTA_module.sngcc;
    }

    // First criterion -- 2s x 2c box
    //CalcTemplate.intSCbox = 0;  //commented by shameer

    // Carlos Acero - 07/16/24
    // Commented below block since CalcTemplate.intSCbox is set as NO by default
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    /*
    if (CalcTemplate.intSCbox === 0) {
      key1 = 0;
      this.blisterAndHIC_CalcResult += "Blister meets the 2s x 2c box interaction criterion. </br>";
    } else {
      key1 = 1;
      this.blisterAndHIC_CalcResult += "Blister fails the 2s x 2c box interaction criterion. <font color=\"red\">*Fail*</font></br>";
    }
    */
    key1 = 0;

    // second criterion -- Lb > 2 x tc
    aa = 2.0 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = this.sngLb.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (this.sngLb > aa) {
        key2 = 0;
        this.blisterAndHIC_CalcResult += `Blister spacing to the nearest HIC or blister, Lb = ${Component_MAWP.buffer} inch  >  2 x tc = ${Component_MAWP.buffer1} inch </br>`;
      } else {
        key2 = 1;
        this.blisterAndHIC_CalcResult += `Blister spacing to the nearest HIC or blister, Lb = ${Component_MAWP.buffer} inch  <=  2 x tc = ${Component_MAWP.buffer1} inch, they need to be combined! <font color="red">*Fail*</font></br>`;
      }
    } else {
      // SI
      Component_MAWP.buffer = this.sngLb.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (this.sngLb > aa) {
        key2 = 0;
        this.blisterAndHIC_CalcResult += `Blister spacing to the nearest HIC or blister, Lb = ${Component_MAWP.buffer} mm  >  2 x tc = ${Component_MAWP.buffer1} mm </br>`;
      } else {
        key2 = 1;
        this.blisterAndHIC_CalcResult += `Blister spacing to the nearest HIC or blister, Lb = ${Component_MAWP.buffer} mm  <=  2 x tc = ${Component_MAWP.buffer1} mm, they need to be combined! <font color="red">*Fail*</font></br>`;
      }
    }

    // third criterion -- blister diameter check
    if (this.intVent === 0) {
      // blister not vented
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        Component_MAWP.buffer = this.sngDb.toFixed(3);
        if (this.sngDb <= 2.0) {
          key3 = 0;
          this.blisterAndHIC_CalcResult += `Blister diameter (max[s, c]) = ${Component_MAWP.buffer} inch  <=  2 inch </br>`;
        } else {
          key3 = 1;
          this.blisterAndHIC_CalcResult += `Blister diameter (max[s, c]) = ${Component_MAWP.buffer} inch  >  2 inch  <font color="red">*Fail*</font> </br>`;
        }
      } else {
        // SI
        Component_MAWP.buffer = this.sngDb.toFixed(1);
        if (this.sngDb <= 50.0) {
          key3 = 0;
          this.blisterAndHIC_CalcResult += `Blister diameter (max[s, c]) = ${Component_MAWP.buffer} mm  <=  50 mm </br>`;
        } else {
          key3 = 1;
          this.blisterAndHIC_CalcResult += `Blister diameter (max[s, c]) = ${Component_MAWP.buffer} mm  >  50 mm  <font color="red">*Fail*</font> </br>`;
        }
      }
    } else {
      aa = 0.6 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
      if (Component_MAWP.intUnit_select === 0) {
        // US Customary
        Component_MAWP.buffer = LTA_module.sngss.toFixed(3);
        Component_MAWP.buffer1 = LTA_module.sngcc.toFixed(3);
        Component_MAWP.buffer2 = aa.toFixed(3);
        if (LTA_module.sngss <= aa && LTA_module.sngcc <= aa) {
          key3 = 0;
          this.blisterAndHIC_CalcResult += `Blister dimensions s = ${Component_MAWP.buffer} inch and c = ${Component_MAWP.buffer1} inch  <=  0.6 * sqrt(ID * tc) = ${Component_MAWP.buffer2} inch </br>`;
        } else {
          key3 = 1;
          this.blisterAndHIC_CalcResult += `Blister dimensions s = ${Component_MAWP.buffer} inch and/or c = ${Component_MAWP.buffer1} inch  >  0.6 * sqrt(ID * tc) = ${Component_MAWP.buffer2} inch  <font color="red">*Fail*</font> </br>`;
        }
      } else {
        // SI
        Component_MAWP.buffer = LTA_module.sngss.toFixed(1);
        Component_MAWP.buffer1 = LTA_module.sngcc.toFixed(1);
        Component_MAWP.buffer2 = aa.toFixed(1);
        if (LTA_module.sngss <= aa && LTA_module.sngcc <= aa) {
          key3 = 0;
          this.blisterAndHIC_CalcResult += `Blister dimensions s = ${Component_MAWP.buffer} mm and c = ${Component_MAWP.buffer1} mm  <=  0.6 * sqrt(ID * tc) = ${Component_MAWP.buffer2} mm </br>`;
        } else {
          key3 = 1;
          this.blisterAndHIC_CalcResult += `Blister dimensions s = ${Component_MAWP.buffer} mm and/or c = ${Component_MAWP.buffer1} mm  >  0.6 * sqrt(ID * tc) = ${Component_MAWP.buffer2} mm  <font color="red">*Fail*</font> </br>`;
        }
      }
    }

    // fourth criterion -- tmm >= 0.5 x tc
    aa = 0.5 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      Component_MAWP.buffer = LTA_module.sngtmm.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (LTA_module.sngtmm >= aa) {
        key4 = 0;
        this.blisterAndHIC_CalcResult += `Minimum measured thickness, tmm = ${Component_MAWP.buffer} inch >= 0.5 * tc = ${Component_MAWP.buffer1} inch </br>`;
      } else {
        key4 = 1;
        this.blisterAndHIC_CalcResult += `Minimum measured thickness, tmm = ${Component_MAWP.buffer} inch < 0.5 * tc = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font> </br>`;
      }
    } else {
      // SI
      Component_MAWP.buffer = LTA_module.sngtmm.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (LTA_module.sngtmm >= aa) {
        key4 = 0;
        this.blisterAndHIC_CalcResult += `Minimum measured thickness, tmm = ${Component_MAWP.buffer} mm >= 0.5 * tc = ${Component_MAWP.buffer1} mm </br>`;
      } else {
        key4 = 1;
        this.blisterAndHIC_CalcResult += `Minimum measured thickness, tmm = ${Component_MAWP.buffer} mm < 0.5 * tc = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font> </br>`;
      }
    }

    // fifth criterion - Bp <= 0.1 x min[s, c]
    if (LTA_module.sngss >= LTA_module.sngcc) aa = 0.1 * LTA_module.sngcc;
    else aa = 0.1 * LTA_module.sngss;
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      Component_MAWP.buffer = this.sngBp.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (this.sngBp <= aa) {
        key5 = 0;
        this.blisterAndHIC_CalcResult += `Blister projection, Bp = ${Component_MAWP.buffer} inch <= 0.1 * min[s, c] = ${Component_MAWP.buffer1} inch </br>`;
      } else {
        key5 = 1;
        this.blisterAndHIC_CalcResult += `Blister projection, Bp = ${Component_MAWP.buffer} inch > 0.1 * min[s, c] = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font> </br>`;
      }
    } else {
      Component_MAWP.buffer = this.sngBp.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (this.sngBp <= aa) {
        key5 = 0;
        this.blisterAndHIC_CalcResult += `Blister projection, Bp = ${Component_MAWP.buffer} mm <= 0.1 * min[s, c] = ${Component_MAWP.buffer1} mm </br>`;
      } else {
        key5 = 1;
        this.blisterAndHIC_CalcResult += `Blister projection, Bp = ${Component_MAWP.buffer} mm > 0.1 * min[s, c] = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font> </br>`;
      }
    }

    // sixth criterion - No periphery cracks directed towards ID or OD surface
    if (this.intPcrack == 0) {
      key6 = 0;
      this.blisterAndHIC_CalcResult += "No periphery crack towards ID or OD. </br>";
    } else {
      key6 = 1;
      this.blisterAndHIC_CalcResult += 'Periphery crack towards ID or OD. <font color="red">*Fail*</font> </br>';
    }

    // seventh criterion - Lw > max [2 x tc, 1” (25mm)]
    aa = 2.0 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      if (aa < 1.0) aa = 1.0;
      Component_MAWP.buffer = Dent_Module.sngLw.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (Dent_Module.sngLw > aa) {
        key7 = 0;
        this.blisterAndHIC_CalcResult += `Spacing to the nearest weld joint, Lw = ${Component_MAWP.buffer} inch > max[2 * tc, 1.0 inch] = ${Component_MAWP.buffer1} inch </br>`;
      } else {
        key7 = 1;
        this.blisterAndHIC_CalcResult += `Spacing to the nearest weld joint, Lw = ${Component_MAWP.buffer} inch <= max[2 * tc, 1.0 inch] = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font> </br>`;
      }
    } else {
      // SI
      if (aa < 25.0) aa = 25.0;
      Component_MAWP.buffer = Dent_Module.sngLw.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (Dent_Module.sngLw > aa) {
        key7 = 0;
        this.blisterAndHIC_CalcResult += `Spacing to the nearest weld joint, Lw = ${Component_MAWP.buffer} mm > max[2 * tc, 25 mm] = ${Component_MAWP.buffer1} mm </br>`;
      } else {
        key7 = 1;
        this.blisterAndHIC_CalcResult += `Spacing to the nearest weld joint, Lw = ${Component_MAWP.buffer} mm <= max[2 * tc, 25 mm] = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font> </br>`;
      }
    }

    // eighth criterion - Lmsd
    aa = 1.8 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (CalcTemplate.sngLmsd >= aa) {
        key8 = 0;
        this.blisterAndHIC_CalcResult += `Lmsd = ${Component_MAWP.buffer} inch  >=  1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch </br>`;
      } else {
        key8 = 1;
        this.blisterAndHIC_CalcResult += `Lmsd = ${Component_MAWP.buffer} inch  <  1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch  <font color="red">*Fail*</font> </br>`;
      }
    } else {
      // SI
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (CalcTemplate.sngLmsd >= aa) {
        key8 = 0;
        this.blisterAndHIC_CalcResult += `Lmsd = ${Component_MAWP.buffer} mm  >=  1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm </br>`;
      } else {
        key8 = 1;
        this.blisterAndHIC_CalcResult += `Lmsd = ${Component_MAWP.buffer} mm  <  1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font> </br>`;
      }
    }

    // ninth criterion -- Calculate MAWP for B31.3 Piping
    if (Component_MAWP.intEquip === 5) {

      if (Component_MAWP.intUnit_select == 0) {
        // US customary
        Component_MAWP.buffer = Component_MAWP.sngMAWP.toFixed(1);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
      } else {
        // SI
        Component_MAWP.buffer = Component_MAWP.sngMAWP.toFixed(2);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
      }

      if (Component_MAWP.sngMAWP >= Component_MAWP.sngP_d) {
        key9 = 0;
        if (Component_MAWP.intUnit_select == 0) {
          // US customary
          this.blisterAndHIC_CalcResult += `MAWP = ${Component_MAWP.buffer} psi >= P_d = ${Component_MAWP.buffer1} psi </br></br>`;
        } else {
          // SI
          this.blisterAndHIC_CalcResult += `MAWP = ${Component_MAWP.buffer} MPa >= P_d = ${Component_MAWP.buffer1} MPa </br></br>`;
        }
      } else {
        key9 = 1;
        if (Component_MAWP.intUnit_select == 0) {
          // US customary
          this.blisterAndHIC_CalcResult += `MAWP = ${Component_MAWP.buffer} psi < P_d = ${Component_MAWP.buffer1} psi <font color="red">*Fail*</font> </br></br>`;
        } else {
          // SI
          this.blisterAndHIC_CalcResult += `MAWP = ${Component_MAWP.buffer} MPa < P_d = ${Component_MAWP.buffer1} MPa <font color="red">*Fail*</font> </br></br>`;
        }
        
        //Validate if other keys has been failed to attach fail message    /* Carlos Acero - 061924 */
        if (this.validateKeysFailure([key1, key2, key3, key4, key5, key6, key7, key8, key9])) {
          this.blisterAndHIC_CalcResult += `<font color="red">Component failed MAWP check. </br>Consider rerating, performing a Level 2, or Level 3 Assessment.</font> </br>`;
        } else {
          this.blisterAndHIC_CalcResult += `<font color="red">Component failed MAWP check. </font> </br>`;
        }
      }
    } // ninth criterion -- Calculate Adjusted MAWP for Cylinder / Sphere / 2:1 Elliptical heads / F&D Head 
    else {
      if ((Component_MAWP.intUnit_select == 0)) { 		// US Customary
        Component_MAWP.buffer = (Component_MAWP.sngMAWP - Component_MAWP.sngp_H).toFixed(1);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
        if ((Component_MAWP.sngMAWP - Component_MAWP.sngp_H) >= Component_MAWP.sngP_d) {  //(Component_MAWP::sngMAWP >= Component_MAWP::sngP_d)
          key9 = 0;
          this.blisterAndHIC_CalcResult += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} psi >= P_d = ${Component_MAWP.buffer1} psi </br></br>`;
        }
        else {
          key9 = 1;
          this.blisterAndHIC_CalcResult += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} psi < P_d = ${Component_MAWP.buffer1} psi <font color="red">*Fail*</font> </br></br>`;
          //Validate if other keys has been failed to attach fail message    /* Carlos Acero - 061924 */
          if (this.validateKeysFailure([key1, key2, key3, key4, key5, key6, key7, key8, key9])) {
            this.blisterAndHIC_CalcResult += `<font color="red">Component failed MAWP check. </br>Consider rerating, performing a Level 2, or Level 3 Assessment.</font> </br>`;
          } else {
            this.blisterAndHIC_CalcResult += `<font color="red">Component failed MAWP check. </font> </br>`;
          }
        }
      } else { 		// SI units
        Component_MAWP.buffer = (Component_MAWP.sngMAWP - Component_MAWP.sngp_H).toFixed(2);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
        if ((Component_MAWP.sngMAWP - Component_MAWP.sngp_H) >= Component_MAWP.sngP_d) {  //(Component_MAWP::sngMAWP >= Component_MAWP::sngP_d)
          key9 = 0;
          this.blisterAndHIC_CalcResult += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} MPa >= P_d = ${Component_MAWP.buffer1} MPa </br></br>`;
        }
        else {
          key9 = 1;
          this.blisterAndHIC_CalcResult += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} MPa < P_d = ${Component_MAWP.buffer1} MPa <font color="red">*Fail*</font> </br></br>`;
          //Validate if other keys has been failed to attach fail message    /* Carlos Acero - 061924 */
          if (this.validateKeysFailure([key1, key2, key3, key4, key5, key6, key7, key8, key9])) {
            this.blisterAndHIC_CalcResult += `<font color="red">Component failed MAWP check. </br>Consider rerating, performing a Level 2, or Level 3 Assessment.</font> </br>`;
          } else {
            this.blisterAndHIC_CalcResult += `<font color="red">Component failed MAWP check. </font> </br>`;
          }
        }
      }
    }

    if (
      key1 === 0 &&
      key2 === 0 &&
      key3 === 0 &&
      key4 === 0 &&
      key5 === 0 &&
      key6 === 0 &&
      key7 === 0 &&
      key8 === 0 &&
      key9 === 0
    ) {
      Component_MAWP.strD_result = "Pass";
      this.blisterAndHIC_CalcResult +=
        '<font color="blue">Blister passed Level 1 assessment.</font> </br>';
    } else {
      Component_MAWP.strD_result = "Fail";
      this.blisterAndHIC_CalcResult +=
        '<font color="red">Blister failed Level 1 assessment. </br>Consider performing a Level 2 or Level 3 assessment.</font> </br>';
    }

    return this.blisterAndHIC_CalcResult;
  }

  public static HIC_calc(): string {
    let result: string = "";
    let aa: number;
    let key1: number,
      key2: number,
      key3: number,
      key4: number,
      key5: number,
      key6: number,
      key7: number,
      key8: number,
      key9: Number;
    Component_MAWP.sngtc = Component_MAWP.sngtrd - Component_MAWP.sngFCA;

    // Carlos Acero - 07/09/24
    // Set Any other HIC or blister infringe on the 2s X 2c box? select to NO as default and hide
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    CalcTemplate.intSCbox = 0;

    // First criterion -- 2s x 2c box
    // Carlos Acero - 07/16/24
    // Commented below block since CalcTemplate.intSCbox is set as NO by default
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    /*
    if (CalcTemplate.intSCbox === 0) {
      key1 = 0;
      result += `HIC meets the 2s x 2c box interaction criterion.</br>`;
    } else {
      key1 = 1;
      result += `HIC fails the 2s x 2c box interaction criterion. <font color="red">*Fail*</font></br>`;
    }
    */
    key1 = 0;

    // second criterion -- Lb >= 8 x tc
    aa = 8.0 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = this.sngLb.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (this.sngLb >= aa) {
        key2 = 0;
        result += `HIC spacing to the nearest HIC or blister, Lh = ${Component_MAWP.buffer} inch  >=  8 * tc = ${Component_MAWP.buffer1} inch</br>`;
      } else {
        key2 = 1;
        result += `HIC spacing to the nearest HIC or blister, Lh = ${Component_MAWP.buffer} inch  <  8 * tc = ${Component_MAWP.buffer1} inch, they need to be combined! <font color="red">*Fail*</font></br>`;
      }
    } else {
      // SI
      Component_MAWP.buffer = this.sngLb.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (this.sngLb >= aa) {
        key2 = 0;
        result += `HIC spacing to the nearest HIC or blister, Lh = ${Component_MAWP.buffer} mm  >=  8 * tc = ${Component_MAWP.buffer1} mm</br>`;
      } else {
        key2 = 1;
        result += `HIC spacing to the nearest HIC or blister, Lh = ${Component_MAWP.buffer} mm  <  8 * tc = ${Component_MAWP.buffer1} mm, they need to be combined! <font color="red">*Fail*</font></br>`;
      }
    }

    // third criterion -- HIC dimensions check
    aa = 0.6 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = LTA_module.sngss.toFixed(3);
      Component_MAWP.buffer1 = LTA_module.sngcc.toFixed(3);
      Component_MAWP.buffer2 = aa.toFixed(3);
      if (LTA_module.sngss <= aa && LTA_module.sngcc <= aa) {
        key3 = 0;
        result += `HIC dimensions s = ${Component_MAWP.buffer} inch and c = ${Component_MAWP.buffer1} inch  <=  0.6 * sqrt(ID * tc) = ${Component_MAWP.buffer2} inch</br>`;
      } else {
        key3 = 1;
        result += `HIC dimensions s = ${Component_MAWP.buffer} inch and/or c = ${Component_MAWP.buffer1} inch  >  0.6 * sqrt(ID * tc) = ${Component_MAWP.buffer2} inch  <font color="red">*Fail*</font></br>`;
      }
    } else {
      // SI
      Component_MAWP.buffer = LTA_module.sngss.toFixed(1);
      Component_MAWP.buffer1 = LTA_module.sngcc.toFixed(1);
      Component_MAWP.buffer2 = aa.toFixed(1);
      if (LTA_module.sngss <= aa && LTA_module.sngcc <= aa) {
        key3 = 0;
        result += `HIC dimensions s = ${Component_MAWP.buffer} mm and c = ${Component_MAWP.buffer1} mm  <=  0.6 * sqrt(ID * tc) = ${Component_MAWP.buffer2} mm</br>`;
      } else {
        key3 = 1;
        result += `HIC dimensions s = ${Component_MAWP.buffer} mm and/or c = ${Component_MAWP.buffer1} mm  >  0.6 * sqrt(ID * tc) = ${Component_MAWP.buffer2} mm  <font color="red">*Fail*</font></br>`;
      }
    }

    this.sngW_H = Component_MAWP.sngtc - this.sngtmm_ID - this.sngtmm_OD;
    aa = Component_MAWP.sngtc / 3.0;
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      if (aa >= 0.5) aa = 0.5;
      Component_MAWP.buffer = this.sngW_H.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (this.sngW_H <= aa) {
        key4 = 0;
        result += `HIC through-thickness extent, W_H = ${Component_MAWP.buffer} inch  <=  min[tc / 3, 0.5 in] = ${Component_MAWP.buffer1} inch</br>`;
      } else {
        key4 = 1;
        result += `HIC through-thickness extent, W_H = ${Component_MAWP.buffer} inch  >  min[tc / 3, 0.5 in] = ${Component_MAWP.buffer1} inch  <font color="red">*Fail*</font></br>`;
      }
    } else {
      // SI
      if (aa >= 13.0) aa = 13.0;
      Component_MAWP.buffer = this.sngW_H.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (this.sngW_H <= aa) {
        key4 = 0;
        result += `HIC through-thickness extent, W_H = ${Component_MAWP.buffer} mm  <=  min[tc / 3, 13 mm] = ${Component_MAWP.buffer1} mm</br>`;
      } else {
        key4 = 1;
        result += `HIC through-thickness extent, W_H = ${Component_MAWP.buffer} mm  >  min[tc / 3, 13 mm] = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font></br>`;
      }
    }

    // fifth criterion -- HIC not surface breaking, tmm_ID >= 0.2 x tc & tmm_OD >= 0.2 x tc
    aa = 0.2 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (this.sngtmm_ID >= aa && this.sngtmm_OD >= aa) {
        key5 = 0;
        result += `HIC damage is not surface-breaking.  Both tmm-ID and tmm-OD  >=  0.2 * tc = ${Component_MAWP.buffer1} inch</br>`;
      } else {
        key5 = 1;
        if (this.sngtmm_ID < aa && this.sngtmm_OD >= aa) {
          Component_MAWP.buffer = this.sngtmm_ID.toFixed(3);
          result += `HIC damage is surface-breaking, tmm-ID = ${Component_MAWP.buffer} inch  <  0.2 * tc = ${Component_MAWP.buffer1} inch  <font color="red">*Fail*</font> </br>`;
        }
        if (this.sngtmm_ID >= aa && this.sngtmm_OD < aa) {
          Component_MAWP.buffer = this.sngtmm_OD.toFixed(3);
          result += `HIC damage is surface-breaking, tmm-OD = ${Component_MAWP.buffer} inch  <  0.2 * tc = ${Component_MAWP.buffer1} inch  <font color="red">*Fail*</font> </br>`;
        }
        if (this.sngtmm_ID < aa && this.sngtmm_OD < aa) {
          result += `HIC damage is surface-breaking, both tmm-ID and tmm-OD  <  0.2 * tc = ${Component_MAWP.buffer1} inch  <font color="red">*Fail*</font> </br>`;
        }
      }
    } else {
      // SI
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (this.sngtmm_ID >= aa && this.sngtmm_OD >= aa) {
        key5 = 0;
        result += `HIC damage is not surface-breaking.  Both tmm-ID and tmm-OD  >=  0.2 * tc = ${Component_MAWP.buffer1} mm</br>`;
      } else {
        key5 = 1;
        if (this.sngtmm_ID < aa && this.sngtmm_OD >= aa) {
          Component_MAWP.buffer = this.sngtmm_ID.toFixed(1);
          result += `HIC damage is surface-breaking, tmm-ID = ${Component_MAWP.buffer} mm  <  0.2 * tc = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font> </br>`;
        }
        if (this.sngtmm_ID >= aa && this.sngtmm_OD < aa) {
          Component_MAWP.buffer = this.sngtmm_OD.toFixed(1);
          result += `HIC damage is surface-breaking, tmm-OD = ${Component_MAWP.buffer} mm  <  0.2 * tc = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font> </br>`;
        }
        if (this.sngtmm_ID < aa && this.sngtmm_OD < aa) {
          result += `HIC damage is surface-breaking, both tmm-ID and tmm-OD  <  0.2 * tc = ${Component_MAWP.buffer1} mm  <font color="red">*Fail*</font> </br>`;
        }
      }
    }

    // sixth criterion - Lw > max [2 x tc, 1” (25mm)]
    aa = 2.0 * Component_MAWP.sngtc;
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      if (aa < 1.0) aa = 1.0;
      Component_MAWP.buffer = Dent_Module.sngLw.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (Dent_Module.sngLw > aa) {
        key6 = 0;
        result += `Spacing to the nearest weld joint, Lw = ${Component_MAWP.buffer} inch  >  max[2 * tc, 1.0 inch] = ${Component_MAWP.buffer1} inch</br>`;
      } else {
        key6 = 1;
        result += `Spacing to the nearest weld joint, Lw = ${Component_MAWP.buffer} inch  <=  max[2 * tc, 1.0 inch] = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font></br>`;
      }
    } else {
      // SI
      if (aa < 25.0) aa = 25.0;
      Component_MAWP.buffer = Dent_Module.sngLw.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (Dent_Module.sngLw > aa) {
        key6 = 0;
        result += `Spacing to the nearest weld joint, Lw = ${Component_MAWP.buffer} mm  >  max[2 * tc, 25 mm] = ${Component_MAWP.buffer1} mm</br>`;
      } else {
        key6 = 1;
        result += `Spacing to the nearest weld joint, Lw = ${Component_MAWP.buffer} mm  <=  max[2 * tc, 25 mm] = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font></br>`;
      }
    }

    // seventh criterion - Lmsd
    aa = 1.8 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (CalcTemplate.sngLmsd >= aa) {
        key7 = 0;
        result += `Lmsd = ${Component_MAWP.buffer} inch  >=  1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch</br>`;
      } else {
        key7 = 1;
        result += `Lmsd = ${Component_MAWP.buffer} inch  <  1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font></br>`;
      }
    } else {
      // SI
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (CalcTemplate.sngLmsd >= aa) {
        key7 = 0;
        result += `Lmsd = ${Component_MAWP.buffer} mm  >=  1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm</br>`;
      } else {
        key7 = 1;
        result += `Lmsd = ${Component_MAWP.buffer} mm  <  1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font></br>`;
      }
    }

    // eighth criterion - hydrogen charging stopped? 0 - no, 1 - yes
    if (this.intH2 === 1) {
      key8 = 0;
      result += `Hydrogen charging is stopped by coating, overlay, or process change.</br>`;
    } else {
      key8 = 1;
      result += `Hydrogen charging continues and this needs to be stopped. <font color="red">*Fail*</font></br>`;
    }

    // ninth criterion -- Calculate MAWP for B31.3 Piping
    if (Component_MAWP.intEquip === 5) {

      if (Component_MAWP.intUnit_select == 0) {
        // US customary
        Component_MAWP.buffer = Component_MAWP.sngMAWP.toFixed(1);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
      } else {
        // SI
        Component_MAWP.buffer = Component_MAWP.sngMAWP.toFixed(2);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
      }

      if (Component_MAWP.sngMAWP >= Component_MAWP.sngP_d) {
        key9 = 0;
        if (Component_MAWP.intUnit_select == 0) {
          // US customary
          result += `MAWP = ${Component_MAWP.buffer} psi >= P_d = ${Component_MAWP.buffer1} psi </br></br>`;
        } else {
          // SI
          result += `MAWP = ${Component_MAWP.buffer} MPa >= P_d = ${Component_MAWP.buffer1} MPa </br></br>`;
        }
      } else {
        key9 = 1;
        if (Component_MAWP.intUnit_select == 0) {
          // US customary
          result += `MAWP = ${Component_MAWP.buffer} psi < P_d = ${Component_MAWP.buffer1} psi <font color="red">*Fail*</font> </br></br>`;
        } else {
          // SI
          result += `MAWP = ${Component_MAWP.buffer} MPa < P_d = ${Component_MAWP.buffer1} MPa <font color="red">*Fail*</font> </br></br>`;
        }

        //Validate if other keys has been failed to attach fail message    /* Carlos Acero - 061924 */
        if (this.validateKeysFailure([key1, key2, key3, key4, key5, key6, key7, key8, key9])) {
          result += `<font color="red">Component failed MAWP check. </br>Consider rerating, performing a Level 2, or Level 3 Assessment.</font> </br>`;
        } else {
          result += `<font color="red">Component failed MAWP check. </font> </br>`;
        }
      }
    } // ninth criterion -- Calculate Adjusted MAWP for Cylinder / Sphere / 2:1 Elliptical heads / F&D Head 
    else {
      if (Component_MAWP.intUnit_select == 0) { 		// US Customary 
        Component_MAWP.buffer = (Component_MAWP.sngMAWP - Component_MAWP.sngp_H).toFixed(1);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
        if ((Component_MAWP.sngMAWP - Component_MAWP.sngp_H) >= Component_MAWP.sngP_d) {  //(Component_MAWP::sngMAWP >= Component_MAWP::sngP_d)
          key9 = 0;
          result += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} psi >= P_d = ${Component_MAWP.buffer1} psi </br></br>`;
        }
        else {
          key9 = 1;
          result += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} psi < P_d = ${Component_MAWP.buffer1} psi <font color="red">*Fail*</font> </br></br>`;
          //Validate if other keys has been failed to attach fail message    /* Carlos Acero - 061924 */
          if (this.validateKeysFailure([key1, key2, key3, key4, key5, key6, key7, key8, key9])) {
            result += `<font color="red">Component failed MAWP check. </br>Consider rerating, performing a Level 2, or Level 3 Assessment.</font> </br>`;
          } else {
            result += `<font color="red">Component failed MAWP check. </font> </br>`;
          }
        }
      } else {  // SI units
        Component_MAWP.buffer = (Component_MAWP.sngMAWP - Component_MAWP.sngp_H).toFixed(2);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
        if ((Component_MAWP.sngMAWP - Component_MAWP.sngp_H) >= Component_MAWP.sngP_d) {  //(Component_MAWP::sngMAWP >= Component_MAWP::sngP_d)
          key9 = 0;
          result += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} MPa >= P_d = ${Component_MAWP.buffer1} MPa </br></br>`;
        }
        else {
          key9 = 1;
          result += `Adjusted Maximum Allowable Working Pressure (MAWP) = ${Component_MAWP.buffer} MPa < P_d = ${Component_MAWP.buffer1} MPa <font color="red">*Fail*</font> </br></br>`;
          //Validate if other keys has been failed to attach fail message    /* Carlos Acero - 061924 */
          if (this.validateKeysFailure([key1, key2, key3, key4, key5, key6, key7, key8, key9])) {
            result += `<font color="red">Component failed MAWP check. </br>Consider rerating, performing a Level 2, or Level 3 Assessment.</font> </br>`;
          } else {
            result += `<font color="red">Component failed MAWP check. </font> </br>`;
          }
        }
      }
    }

    if (
      key1 === 0 &&
      key2 === 0 &&
      key3 === 0 &&
      key4 === 0 &&
      key5 === 0 &&
      key6 === 0 &&
      key7 === 0 &&
      key8 === 0 &&
      key9 === 0
    ) {
      Component_MAWP.strD_result = `Pass`;
      result += `<font color="blue">HIC passed Level 1 assessment.</font></br>`;
    } else {
      Component_MAWP.strD_result = `Fail`;
      result += `<font color="red">HIC failed Level 1 assessment. </br>Consider performing a Level 2 or Level 3 assessment.</font> </br>`;
    }

    return result;
  }

  public static check_BlisterHIC_TypeOfDamage(blistersHIC_formGroup): boolean {
    let passFlag: boolean = true;
    let messagesValidationsList: string[] = [];

    let radioButton_DIformControl: FormControl =
      blistersHIC_formGroup.get("radioButton_DI");

    const validRadioBoxValues = ["radioButton1_DI_enabled", "radioButton2_DI_enabled"];

    if (radioButton_DIformControl?.enabled) {
      if (!validRadioBoxValues.includes(radioButton_DIformControl.value)) {
        passFlag = false;
        messagesValidationsList.push(
          "No type of damage selected."
        );
      }
    }

    this.validationMessageTypeOfDamage = !passFlag ? messagesValidationsList : [];
    return passFlag;
  }


  public static check_BlisterHIC_Data(inspectionDataFormGroup): boolean {
    let pass_flag: boolean = true;

    this.validationMessageBlisterHIC = [];
    this.validationMessageComponentInformation = [];

    let msgNotSet = "not set.";

    if (inspectionDataFormGroup.controls["comboBox1_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["comboBox1_DI"].valid) {
        this.validationMessageBlisterHIC.push(
          "2s x 2c box needs to be selected.\n"
        );
        pass_flag = false;
      }
    }

    if (inspectionDataFormGroup.controls["textBox1_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["textBox1_DI"].valid) {
        this.validationMessageBlisterHIC.push(
          "Longitudinal extent, s -" + msgNotSet + "\n"
        );
        pass_flag = false;
      } else if (LTA_module.sngss < 0.0) {
        this.validationMessageBlisterHIC.push(
          "Longitudinal extent, s -cannot be less than 0.\n"
        );
        pass_flag = false;
      }
    }

    if (inspectionDataFormGroup.controls["textBox2_DI"]?.enabled) {
      if (!inspectionDataFormGroup.controls["textBox2_DI"].valid) {
        this.validationMessageBlisterHIC.push(
          "Circumferential extent, c -" + msgNotSet + "\n"
        );
        pass_flag = false;
      } else if (LTA_module.sngcc < 0.0) {
        this.validationMessageBlisterHIC.push(
          "Circumferential extent, c -cannot be less than 0.\n"
        );
        pass_flag = false;
      }
    }

    if (inspectionDataFormGroup.controls["textBox3_DI"]?.enabled) {
      if (Component_MAWP.strD_class == "Blister") {
        if (!inspectionDataFormGroup.controls["textBox3_DI"].valid) {
          this.validationMessageBlisterHIC.push(
            "Minimum measured thickness including FCA, tmm - " +
            msgNotSet +
            "\n"
          );
          pass_flag = false;
        } else if (LTA_module.sngtmm <= 0.0) {
          this.validationMessageBlisterHIC.push(
            "Minimum measured thickness including FCA, tmm - cannot be less than or equal to 0.\n"
          );
          pass_flag = false;
        }
      } else if (Component_MAWP.strD_class == "HIC") {
        if (!inspectionDataFormGroup.controls["textBox3_DI"].valid) {
          this.validationMessageBlisterHIC.push(
            "Minimum measured thickness including FCA as appropriate, tmm-ID - " +
            msgNotSet +
            "\n"
          );
          pass_flag = false;
        } else if (BlisterHIC_module.sngtmm_ID < 0.0) {
          //modified by shameer
          this.validationMessageBlisterHIC.push(
            "Minimum measured thickness from HIC to ID including FCA cannot be less than 0.\n"
          );
          pass_flag = false;
        }
      }
    }
    if (inspectionDataFormGroup.controls["textBox4_DI"]?.enabled) {
      if (Component_MAWP.strD_class == "Blister") {
        if (!inspectionDataFormGroup.controls["textBox4_DI"].valid) {
          this.validationMessageBlisterHIC.push(
            "Blister Projection, Bp - " + msgNotSet + "\n"
          );
          pass_flag = false;
        } else if (BlisterHIC_module.sngBp < 0.0) {
          this.validationMessageBlisterHIC.push(
            "Blister projection, Bp - cannot be less than 0.\n"
          );
          pass_flag = false;
        } else if (LTA_module.sngtmm >
          (Component_MAWP.sngtrd - Component_MAWP.sngFCA)) {
          this.validationMessageBlisterHIC.push(
            "Minimum measured thickness including FCA as appropriate cannot be greater than the future corroded thickness, tc\n"
          );
          pass_flag = false;
        }
      } else if (Component_MAWP.strD_class == "HIC") {
        if (!inspectionDataFormGroup.controls["textBox4_DI"].valid) {
          this.validationMessageBlisterHIC.push(
            "Minimum measured thickness from HIC to OD surface, tmm-OD - " +
            msgNotSet +
            "\n"
          );
          pass_flag = false;
        } else if (BlisterHIC_module.sngtmm_OD < 0.0) {
          this.validationMessageBlisterHIC.push(
            "Minimum measured thickness from HIC to OD including FCA cannot be less than 0.\n"
          );
          pass_flag = false;
        } else if (
          (BlisterHIC_module.sngtmm_OD + BlisterHIC_module.sngtmm_ID) >
          (Component_MAWP.sngtrd - Component_MAWP.sngFCA)) {
          this.validationMessageBlisterHIC.push(
            "Minimum measured thickness from HIC to OD including FCA as applicable plus minimum measured thickness from HIC to ID including FCA as applicable cannot be greater than future corroded thickness, tc\n"
          );
          pass_flag = false;
        }
      }
    }

    if (inspectionDataFormGroup.controls["textBox5_DI"]?.enabled) {
      if (Component_MAWP.strD_class == "Blister") {
        if (!inspectionDataFormGroup.controls["textBox5_DI"].valid) {
          this.validationMessageBlisterHIC.push(
            "Blister spacing to the nearest blister or HIC, Lb - " +
            msgNotSet +
            "\n"
          );
          pass_flag = false;
        } else if (BlisterHIC_module.sngLb < 0.0) {
          this.validationMessageBlisterHIC.push(
            "Blister spacing to the nearest blister or HIC, Lb - cannot be less than 0.\n"
          );
          pass_flag = false;
        }
      } else if (Component_MAWP.strD_class == "HIC") {
        if (!inspectionDataFormGroup.controls["textBox5_DI"].valid) {
          this.validationMessageBlisterHIC.push(
            "HIC spacing to the nearest HIC or blister, Lh - " +
            msgNotSet +
            "\n"
          );
          pass_flag = false;
        } else if (BlisterHIC_module.sngLb < 0.0) {
          this.validationMessageBlisterHIC.push(
            "HIC spacing to the nearest HIC or blister, Lh - cannot be less than 0.\n"
          );
          pass_flag = false;
        }
      }
    }

    if (inspectionDataFormGroup.controls["textBox6_DI_snggr"]?.enabled) {
      if (Component_MAWP.strD_class == "Blister") {
        if (!inspectionDataFormGroup.controls["textBox6_DI_snggr"].valid) {
          this.validationMessageBlisterHIC.push(
            "Blister spacing to the nearest weld joint, Lw - " +
            msgNotSet +
            "\n"
          );
          pass_flag = false;
        } else if (Dent_Module.sngLw < 0.0) {
          this.validationMessageBlisterHIC.push(
            "Blister spacing to the nearest weld joint, Lw - cannot be less than 0.\n"
          );
          pass_flag = false;
        }
      } else if (Component_MAWP.strD_class == "HIC") {
        if (!inspectionDataFormGroup.controls["textBox6_DI_snggr"].valid) {
          this.validationMessageBlisterHIC.push(
            "HIC spacing to the nearest weld joint, Lw - " + msgNotSet + "\n"
          );
          pass_flag = false;
        } else if (Dent_Module.sngLw < 0.0) {
          this.validationMessageBlisterHIC.push(
            "HIC spacing to the nearest weld joint, Lw - cannot be less than 0.\n"
          );
          pass_flag = false;
        }
      }
    }

    if (inspectionDataFormGroup.controls["textBox7_DI"]?.enabled) {
      if (Component_MAWP.strD_class === "Blister") {
        if (!inspectionDataFormGroup.controls["textBox7_DI"].valid) {
          this.validationMessageBlisterHIC.push(
            "Blister spacing to the nearest major structural discontinuity, Lmsd - " +
            msgNotSet +
            "\n"
          );
          pass_flag = false;
        } else if (CalcTemplate.sngLmsd < 0.0) {
          this.validationMessageBlisterHIC.push(
            "Blister spacing to the nearest major structural discontinuity, Lmsd - cannot be less than 0.\n"
          );
          pass_flag = false;
        }
      } else if (Component_MAWP.strD_class === "HIC") {
        if (!inspectionDataFormGroup.controls["textBox7_DI"].valid) {
          this.validationMessageBlisterHIC.push(
            "HIC spacing to the nearest major structural discontinuity, Lmsd - " +
            msgNotSet +
            "\n"
          );
          pass_flag = false;
        } else if (CalcTemplate.sngLmsd < 0.0) {
          this.validationMessageBlisterHIC.push(
            "HIC spacing to the nearest major structural discontinuity, Lmsd - cannot be less than 0.\n"
          );
          pass_flag = false;
        }
      }
    }

    if (Component_MAWP.strD_class === "Blister") {
      if (!inspectionDataFormGroup.controls["comboBox2_DI_BlisterHIC"].valid) {
        this.validationMessageBlisterHIC.push(
          "Periphery cracks - needs to be selected.\n"
        );
        pass_flag = false;
      }
    } else if (Component_MAWP.strD_class === "HIC") {
      if (!inspectionDataFormGroup.controls["comboBox2_DI_BlisterHIC"].valid) {
        this.validationMessageBlisterHIC.push(
          "Hydrogen charging is stopped - needs to be selected.\n"
        );
        pass_flag = false;
      }
    }

    if (Component_MAWP.strD_class === "Blister") {
      if (!inspectionDataFormGroup.controls["comboBox3_DI"].valid) {
        this.validationMessageBlisterHIC.push(
          "Blister vented - needs to be selected.\n"
        );
        pass_flag = false;
      }
    }

    return pass_flag;
  }

  public static ExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      LTA_module: { ...LTA_module },
      Dent_Module: { ...Dent_Module },
      BlisterHIC_module: { ...BlisterHIC_module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static ImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();
    BlisterHIC_module.BlisterHIC_initialize();

    // Carlos Acero - 07/09/24
    // Set Any other HIC or blister infringe on the 2s X 2c box? select to NO as default and hide
    // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7671147
    jsonDataResult.CalcTemplate.intSCbox = 0;

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(LTA_module, jsonDataResult.LTA_module);
    Object.assign(Dent_Module, jsonDataResult.Dent_Module);
    Object.assign(BlisterHIC_module, jsonDataResult.BlisterHIC_module);
  }

  public static updateInspectionDataFormControls(inspectionDataFormGroup): void {
    const formControls = {
      textBox1_DIformControl: inspectionDataFormGroup.get("textBox1_DI"),
      textBox2_DIformControl: inspectionDataFormGroup.get("textBox2_DI"),
      textBox3_DIformControl: inspectionDataFormGroup.get("textBox3_DI"),
      textBox4_DIformControl: inspectionDataFormGroup.get("textBox4_DI"),
      textBox5_DIformControl: inspectionDataFormGroup.get("textBox5_DI"),
      textBox6_DIformControl: inspectionDataFormGroup.get("textBox6_DI_snggr"),
      textBox7_DIformControl: inspectionDataFormGroup.get("textBox7_DI"),
      comboBox1_DIformControl: inspectionDataFormGroup.get("comboBox1_DI"),
      comboBox2_DIformControl: inspectionDataFormGroup.get("comboBox2_DI_BlisterHIC"),
    };

    const comboBoxValues = ["No", "Yes"];
    const setValueIfNotNegative = (control, value) => {
      if (value !== -1) control.setValue(value.toString());
    };

    setValueIfNotNegative(formControls.comboBox1_DIformControl, comboBoxValues[CalcTemplate.intSCbox]);
    setValueIfNotNegative(formControls.textBox1_DIformControl, LTA_module.sngss);
    setValueIfNotNegative(formControls.textBox2_DIformControl, LTA_module.sngcc);

    if (Component_MAWP.strD_class == "Blister") {
      this.updateBlister(formControls, comboBoxValues, inspectionDataFormGroup);
    } else {
      this.updateHIC(formControls, comboBoxValues);
    }
  }

  public static updateBlister(formControls, comboBoxValues, inspectionDataFormGroup): void {
    const setValueIfNotNegative = (control, value) => {
      if (value !== -1) control.setValue(value.toString());
    };

    setValueIfNotNegative(formControls.textBox3_DIformControl, LTA_module.sngtmm);
    setValueIfNotNegative(formControls.textBox4_DIformControl, BlisterHIC_module.sngBp);
    setValueIfNotNegative(formControls.textBox5_DIformControl, BlisterHIC_module.sngLb);
    setValueIfNotNegative(formControls.textBox6_DIformControl, Dent_Module.sngLw);
    setValueIfNotNegative(formControls.textBox7_DIformControl, CalcTemplate.sngLmsd);

    if (BlisterHIC_module.intPcrack !== -1)
      formControls.comboBox2_DIformControl.setValue(comboBoxValues[BlisterHIC_module.intPcrack]);

    let comboBox3_DIformControl = inspectionDataFormGroup.get("comboBox3_DI");
    if (BlisterHIC_module.intVent !== -1)
      comboBox3_DIformControl.setValue(comboBoxValues[BlisterHIC_module.intVent]);
  }

  public static updateHIC(formControls, comboBoxValues): void {
    const setValueIfNotNegative = (control, value) => {
      if (value !== -1) control.setValue(value.toString());
    };

    setValueIfNotNegative(formControls.textBox3_DIformControl, BlisterHIC_module.sngtmm_ID);
    setValueIfNotNegative(formControls.textBox4_DIformControl, BlisterHIC_module.sngtmm_OD);
    setValueIfNotNegative(formControls.textBox5_DIformControl, BlisterHIC_module.sngLb);
    setValueIfNotNegative(formControls.textBox6_DIformControl, Dent_Module.sngLw);
    setValueIfNotNegative(formControls.textBox7_DIformControl, CalcTemplate.sngLmsd);

    if (BlisterHIC_module.intH2 !== -1)
      formControls.comboBox2_DIformControl.setValue(comboBoxValues[BlisterHIC_module.intH2]);
  }

  public static BlisterHIC_report(): string {
    let report: string = "";

    const format = "dd/MM/yyyy";
    const locale = "en-US";
    const dt1 = new Date();
    const reportDate = formatDate(dt1, format, locale);

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 7</center>";

    if (Component_MAWP.strD_class === "Blister")
      report += "<center>Assessment of Blisters</center></br></br>";
    else
      report += "<center>Assessment of HIC Damage</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 1</br></br></br>";

    const sRefinery: string = "Facility: ";
    const sPlant: string = "Plant: ";
    const sEquipment: string = "Equipment: ";
    const sAnalyst: string = "Analyst: ";

    report += sRefinery + Component_MAWP.strFacility + "</br>";
    report += sPlant + Component_MAWP.strPlant + "</br>";
    report += sEquipment + Component_MAWP.strEquipment + "</br>";
    report += sAnalyst + Component_MAWP.strAnalyst + "</br></br></br>";

    report += "Applicability and Limitations :</br></br>";

    report += `<ol class="appAndLimitList">
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The operating temperature is less than 400°F (204°C) for carbon or low alloy steels, or is below the applicable design curve in API 941, whichever is greater. Damage associated with high temperature hydrogen attack is specifically excluded.</li>  
        <li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment.</li>  
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
        <li>Type A components subject to internal pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness.  Examples are:  
          <ol type="a" class="appAndLimitList">  
            <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
            <li>Spherical pressure vessels and storage spheres</li>  
            <li>Spherical, elliptical and torispherical formed heads</li>  
            <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
            <li>Cylindrical atmospheric storage tank shell courses</li>  
          </ol>  
        </li>  
      </ol>
    </br></br>`;

    report += "Component Information :</br></br>";

    if (Component_MAWP.intEquip === 0) {
      report += "Component type = Cylinder<br>";
    } else if (Component_MAWP.intEquip === 2) {
      report += "Component type = Sphere<br>";
    } else if (Component_MAWP.intEquip === 3) {
      report += "Component type = 2:1 Elliptical Head<br>";
    } else if (Component_MAWP.intEquip === 4) {
      report += "Component type = F & D Head<br>";
    } else if (Component_MAWP.intEquip === 5) {
      report += "Component type = B31.3 Piping<br>";
    }

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (
        !Number.isNaN(Component_MAWP.sngP_d) &&
        Component_MAWP.sngP_d != -1
      )
        report += `Design pressure or vessel MAWP (psi) = ${Component_MAWP.sngP_d.toFixed(
          1
        )}<br>`;
      else
        report += `Design pressure or vessel MAWP (psi) = N/A<br>`;

      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-F) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design temperature (deg-F) = N/A<br>";
      }

      report += `Inside diameter corrected for FCA as applicable, D (inch) = ${Component_MAWP.sngID.toFixed(
        3
      )}<br>`;

      if (Component_MAWP.strD_class === "Blister")
        report += `Current measured uniform thickness away from blister, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
          3
        )}<br>`;
      else
        report += `Current measured uniform thickness away from HIC, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
          3
        )}<br>`;


      report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = ${Component_MAWP.sngFCA.toFixed(
        3
      )}<br>`;

      report += `Future corroded thickness, tc (inch) = ${Component_MAWP.sngtc.toFixed(
        3
      )}<br>`;

      report += `Minimum required thickness, tmin (inch) = ${Component_MAWP.sngtmin.toFixed(
        3
      )}<br>`;

      report += `MAWP of the component based on tc, MAWP (psi) = ${Component_MAWP.sngMAWP.toFixed(
        1
      )}<br>`;

    } else {
      // SI
      if (Component_MAWP.sngP_d != -1)
        report += `Design pressure or vessel MAWP (MPa) = ${Component_MAWP.sngP_d.toFixed(
          2
        )}<br>`;
      else
        report += `Design pressure or vessel MAWP (MPa) = N/A<br>`;

      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      ) {
        report += `Design temperature (deg-C) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}<br>`;
      } else {
        report += "Design temperature (deg-C) = N/A<br>";
      }

      report += `Inside diameter corrected for FCA as applicable, D (mm) = ${Component_MAWP.sngID.toFixed(
        1
      )}<br>`;

      if (Component_MAWP.strD_class === "Blister")
        report += `Current measured uniform thickness away from blister, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
          1
        )}<br>`;
      else
        report += `Current measured uniform thickness away from HIC, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
          1
        )}<br>`;

      report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = ${Component_MAWP.sngFCA.toFixed(
        1
      )}<br>`;

      report += `Future corroded thickness, tc (mm) = ${Component_MAWP.sngtc.toFixed(
        1
      )}<br>`;

      report += `Minimum required thickness, tmin (mm) = ${Component_MAWP.sngtmin.toFixed(
        1
      )}<br>`;

      report += `MAWP of the component based on tc, MAWP (MPa) = ${Component_MAWP.sngMAWP.toFixed(
        2
      )}<br>`;
    }

    report += `Longitudinal weld joint efficiency, E_L = ${Component_MAWP.sngE_L.toFixed(
      2
    )}<br>`;

    if (Component_MAWP.intEquip === 0 || Component_MAWP.intEquip === 5)
      report += `Circumferential weld joint efficiency, E_C = ${Component_MAWP.sngE_c.toFixed(
        2
      )}<br>`;

    report += '<div class="pageBreak"></div>';

    // SECOND PAGE

    report +=
      "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 7</center>";
    if (Component_MAWP.strD_class === "Blister")
      report += "<center>Assessment of Blisters</center></br></br>";
    else
      report += "<center>Assessment of HIC Damage</center></br></br>";

    report += reportDate + "</br>";
    report += "Page: 2</br></br></br>";

    if (Component_MAWP.strD_class === "Blister")
      report += "Blister Information :<br><br>";
    else
      report += "HIC Damage Information :<br><br>";

    if (CalcTemplate.intSCbox === 0)
      report +=
        "Any other HIC or blister infringes on the 2s x 2c box ? = No<br>";
    else
      report +=
        "Any other HIC or blister infringes on the 2s x 2c box ? = Yes<br>";

    if (Component_MAWP.strD_class === "Blister") { // Blisters
      if (Component_MAWP.intUnit_select === 0) { // US Customary
        report += "Longitudinal extent of the blister, s (inch) = " + LTA_module.sngss.toFixed(3) + "<br>";
        report += "Circumferential extent of the blister, c (inch) = " + LTA_module.sngcc.toFixed(3) + "<br>";
        report += "Minimum measured thickness including FCA as appropriate, tmm (inch) = " + LTA_module.sngtmm.toFixed(3) + "<br>";
        report += "Blister projection, Bp (inch) = " + BlisterHIC_module.sngBp.toFixed(3) + "<br>";
        report += "Blister spacing to the nearest HIC or blister, Lb (inch) = " + BlisterHIC_module.sngLb.toFixed(3) + "<br>";
        report += "Blister spacing to the nearest weld joint. Lw (inch) = " + Dent_Module.sngLw.toFixed(3) + "<br>";
        report += "Blister spacing to the nearest major structural discontinuity, Lmsd (inch) = " + CalcTemplate.sngLmsd.toFixed(3) + "<br>";

      } else { // SI
        report += "Longitudinal extent of the blister, s (mm) = " + LTA_module.sngss.toFixed(1) + "<br>";
        report += "Circumferential extent of the blister, c (mm) = " + LTA_module.sngcc.toFixed(1) + "<br>";
        report += "Minimum measured thickness including FCA as appropriate, tmm (mm) = " + LTA_module.sngtmm.toFixed(1) + "<br>";
        report += "Blister projection, Bp (mm) = " + BlisterHIC_module.sngBp.toFixed(1) + "<br>";
        report += "Blister spacing to the nearest HIC or blister, Lb (mm) = " + BlisterHIC_module.sngLb.toFixed(1) + "<br>";
        report += "Blister spacing to the nearest weld joint. Lw (mm) = " + Dent_Module.sngLw.toFixed(1) + "<br>";
        report += "Blister spacing to the nearest major structural discontinuity, Lmsd (mm) =" + CalcTemplate.sngLmsd.toFixed(1) + "<br>";
      }

      if (this.intPcrack === 0)
        report += "Periphery cracks towards ID or OD surface = No<br>";
      else
        report += "Periphery cracks towards ID or OD surface = Yes<br>";

      if (this.intVent === 0)
        report += "Blister vented (crown cracks or vent holes) = No<br><br><br>";
      else
        report += "Blister vented (crown cracks or vent holes) = Yes<br><br><br>";
    } else { // HIC
      if (Component_MAWP.intUnit_select === 0) { // US Customary
        report += "Longitudinal extent of the HIC, s (inch) = " + LTA_module.sngss.toFixed(3) + "<br>";
        report += "Circumferential extent of the HIC, c (inch) = " + LTA_module.sngcc.toFixed(3) + "<br>";
        report += "Minimum measured thickness from HIC to ID including FCA as<br>";
        report += "appropriate, tmm-ID (inch) = " + BlisterHIC_module.sngtmm_ID.toFixed(3) + "<br>";
        report += "Minimum measured thickness from HIC to OD including FCA as<br>";
        report += "appropriate, tmm-OD (inch) = " + BlisterHIC_module.sngtmm_OD.toFixed(3) + "<br>";
        report += "HIC spacing to the nearest HIC or blister, Lh (inch) = " + BlisterHIC_module.sngLb.toFixed(3) + "<br>";
        report += "HIC spacing to the nearest weld joint. Lw (inch) = " + Dent_Module.sngLw.toFixed(3) + "<br>";
        report += "HIC spacing to the nearest major structural discountinuity, Lmsd  (inch) = " + CalcTemplate.sngLmsd.toFixed(3) + "<br>";
      }
      else { // SI
        report += "Longitudinal extent of the HIC, s (mm) = " + LTA_module.sngss.toFixed(1) + "<br>";
        report += "Circumferential extent of the HIC, c (mm) = " + LTA_module.sngcc.toFixed(1) + "<br>";
        report += "Minimum measured thickness from HIC to ID including FCA as<br>";
        report += "appropriate, tmm-ID (mm) = " + BlisterHIC_module.sngtmm_ID.toFixed(1) + "<br>";
        report += "Minimum measured thickness from HIC to OD including FCA as<br>";
        report += "appropriate, tmm-OD (mm) = " + BlisterHIC_module.sngtmm_OD.toFixed(1) + "<br>";
        report += "HIC spacing to the nearest HIC or blister, Lh (mm) = " + BlisterHIC_module.sngLb.toFixed(1) + "<br>";
        report += "HIC spacing to the nearest weld joint. Lw (mm) = " + Dent_Module.sngLw.toFixed(1) + "<br>";
        report += "HIC spacing to the nearest major structural discountinuity, Lmsd  (mm) = " + CalcTemplate.sngLmsd.toFixed(1) + "<br>";
      }

      if (this.intH2 === 0)
        report += "H2 charging is stopped by coating, overlay, or process change? = No<br><br><br>";
      else
        report += "H2 charging is stopped by coating, overlay, or process change? = Yes<br><br><br>";
    }

    report += "Assessment Results :<br><br>";
    report +=
      "All the conditions in the Applicability and Limitations section are met.<br><br>";
    report += this.blisterAndHIC_CalcResult;

    return report;
  }

  //Validate if array of keys has been fail
  //Returning FALSE is someone fails or TRUE if all has been pass
  //Carlos Acero - 061924
  public static validateKeysFailure(keys: number[] | Number[]): boolean {
    return keys.includes(1) ? false : true;
  }
}
