import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { Component_MAWP } from "../../services/component_mawp";
import { BehaviorSubject, Subject } from "rxjs";
import { Crack_Module } from "../../services/crack_module";
import { MatDialog } from "@angular/material/dialog";
import { ComponentInformationComponent } from "../inspection-data/component-information/component-information.component";
import { FormControlsEventProxyService } from "../../services/formcontrols_eventproxy_service";

@Component({
  selector: "additional-component-information",
  templateUrl: "additional-component-information.component.html",
})
export class AdditionalInformationComponent implements OnInit, AfterViewInit {
  @Input() MAWP_result = "";
  @Input() additionalDataCalc: Subject<boolean>;
  @Output("nextStep") nextStep: EventEmitter<any> = new EventEmitter();
  componentInformationValidation: Subject<boolean> = new Subject<boolean>();

  @ViewChild(ComponentInformationComponent)
  childComponentInformationComponent: ComponentInformationComponent;

  public validation_list = [];
  public validation_list_ComponentInformation = [];

  additionalInformationFormGroup: FormGroup;
  public component_mawp = Component_MAWP;

  // Declare two input values and one output value
  intUnit_select: number = parseInt(localStorage.getItem("unitType")) || 0;

  summary: string;
  result: string;
  intUnit: number;

  componentData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  comboBox1_DI_Disable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  updateComponentData() {
    this.componentData.next(!this.componentData.value);
  }

  constructor(private _formBuilder: FormBuilder, private dialog: MatDialog, private formControlsEventProxyService: FormControlsEventProxyService,) {
    this.additionalInformationFormGroup = this._formBuilder.group({});
  }

  ngOnInit(): void {
    this.intUnit = parseInt(localStorage.getItem("unitType")) || 0; // 0 - US, 1 - SI
    Component_MAWP.intUnit_select = this.intUnit;
    this.summary = "";
    this.result = "";
    this.additionalDataCalc.subscribe((v) => {
      this.onPressPictureBox2_DI();
    });
  }

  ngAfterViewInit() {
    if ([6].includes(Component_MAWP.strD_iclass)) {
      this.additionalInformationFormGroup.addControl(
        "childComponentInformationForm",
        this.childComponentInformationComponent.componentInformationFormGroup
      );
      this.childComponentInformationComponent.componentInformationFormGroup.setParent(
        this.additionalInformationFormGroup
      );

      this.formControlsEventProxyService.getCrackLikeIndications_ComponentInformationEvent().subscribe((v) => {
        Crack_Module.updateFormControls(this.additionalInformationFormGroup);
      });
    }
  }

  onPressPictureBox2_DI() {
    Object.keys(this.additionalInformationFormGroup.controls).forEach((key) => {
      this.additionalInformationFormGroup.get(key).markAsDirty();
    });

    let resultFromValidation = false;

    if (Component_MAWP.strD_iclass === 6) {
        this.componentInformationValidation.next(true);

        resultFromValidation = Crack_Module.check_NewCrack_Data(
          this.additionalInformationFormGroup
        );
        this.validation_list = Crack_Module.validationMessageCrack;
        this.validation_list_ComponentInformation =
          Crack_Module.validationMessageComponentInformation;

        if (!resultFromValidation) {
          return;
        }

        this.nextStep.emit();
    }
  }
}
