import { formatDate } from "@angular/common";
import { CalcTemplate } from "./calctemplate";
import { Component_MAWP } from "./component_mawp";
import { LTAP_module } from "./ltap_module";
import { Utility } from "./utility";

export class LTA_module {
  public static intLTA_type: number = -1;
  public static sngtmm: number = -1;
  public static sngss: number = -1;
  public static sngcc: number = -1;
  public static sngRt: number = -1;
  public static sngLamda: number = -1;
  public static snggr: number = -1;

  public static validationMessage = [];
  public static lmlCalcResult = "";

  LTA_module() {
    LTA_module.LTA_initialize();
  }

  public static LTA_initialize(): void {
    //initialize all the variables
    this.intLTA_type = -1;
    this.sngtmm = -1;
    this.sngss = -1;
    this.sngcc = -1;
    this.sngRt = -1;
    this.sngLamda = -1;
    this.snggr = -1;
    Component_MAWP.sngP_d = -1;
    CalcTemplate.reset_data();

    this.validationMessage = [];
    this.lmlCalcResult = "";
  }

  public static ReturnLTAValuesAsHTML(): string {
    let result =
      "intLTA_type: " +
      this.intLTA_type +
      "</br>" +
      "sngtmm: " +
      this.sngtmm +
      "</br>" +
      "sngss: " +
      this.sngss +
      "</br>" +
      "sngcc: " +
      this.sngcc +
      "</br>" +
      "sngRt: " +
      this.sngRt +
      "</br>" +
      "sngLamda: " +
      this.sngLamda +
      "</br>" +
      "snggr: " +
      this.snggr;

    return result;
  }

  public static LTA_calc(): string {
    let aa, Mt: number;
    let key1, key2, key3, key4, key5, key7, key8: number;

    this.sngRt = (this.sngtmm - CalcTemplate.sngFCAml) / Component_MAWP.sngtc; // calculate thickness ratio
    aa = Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (
      Component_MAWP.intEquip === 0 ||
      Component_MAWP.intEquip === 1 ||
      Component_MAWP.intEquip === 5 ||
      Component_MAWP.intEquip === 6
    ) {
      // cylinder, cone, piping, tank
      this.sngLamda = (1.285 * this.sngss) / aa; // calculate shell parameter in longitudinal direction
    } else {
      // sphere and formed heads; pick the larger dimension between s and c
      if (this.sngss >= this.sngcc) {
        this.sngLamda = (1.285 * this.sngss) / aa;
      } else {
        this.sngLamda = (1.285 * this.sngcc) / aa;
      }
    }

    if (
      Component_MAWP.strD_class === "LTA" ||
      Component_MAWP.strD_class === "Gouge"
    ) {
      // calculate Folias factor
      if (
        Component_MAWP.intEquip === 0 ||
        Component_MAWP.intEquip === 1 ||
        Component_MAWP.intEquip === 5 ||
        Component_MAWP.intEquip === 6
      ) {
        // cylinder, cone, piping, tank
        if (this.sngLamda > 20.0) {
          this.sngLamda = 20.0;
        }
        Mt =
          1.001 -
          0.014195 * this.sngLamda +
          0.2909 * Math.pow(this.sngLamda, 2) -
          0.09642 * Math.pow(this.sngLamda, 3) +
          0.02089 * Math.pow(this.sngLamda, 4) -
          0.003054 * Math.pow(this.sngLamda, 5) +
          2.957e-4 * Math.pow(this.sngLamda, 6) -
          1.8462e-5 * Math.pow(this.sngLamda, 7) +
          7.1553e-7 * Math.pow(this.sngLamda, 8) -
          1.5631e-8 * Math.pow(this.sngLamda, 9) +
          1.4656e-10 * Math.pow(this.sngLamda, 10);
      } else {
        // sphere and formed heads
        if (this.sngLamda > (1.285 * Utility.PI * Component_MAWP.sngID) / aa) {
          this.sngLamda = (1.285 * Utility.PI * Component_MAWP.sngID) / aa;
        }
        Mt =
          (1.0005 +
            0.49001 * this.sngLamda +
            0.32409 * Math.pow(this.sngLamda, 2)) /
          (1.0 +
            0.50144 * this.sngLamda -
            0.011067 * Math.pow(this.sngLamda, 2));
      }
      CalcTemplate.sngRSF = this.sngRt / (1.0 - (1.0 - this.sngRt) / Mt); // calculate remaining strength factor
    }

    if (CalcTemplate.sngRSF - CalcTemplate.sngRSF_a < -0.00005) {
      // check the condition RSF >= RSFa
      if (Component_MAWP.intEquip !== 6) {
        // RSF < RSF_a - subtract static head after RSF adjustment to calculate MAWP_r, except for tanks
        CalcTemplate.sngMAWP_r =
          Component_MAWP.sngMAWP *
            (CalcTemplate.sngRSF / CalcTemplate.sngRSF_a) -
          Component_MAWP.sngp_H;
      } else {
        // tanks
        CalcTemplate.sngMFH_r =
          CalcTemplate.sngH_f +
          (CalcTemplate.sngMFH - CalcTemplate.sngH_f) *
            (CalcTemplate.sngRSF / CalcTemplate.sngRSF_a);
      }
    } else {
      if (Component_MAWP.intEquip !== 6) {
        // RSF >= RSF_a - no RSF adjustment, subtract static head to calculate MAWP_r, except for tanks
        CalcTemplate.sngMAWP_r = Component_MAWP.sngMAWP - Component_MAWP.sngp_H;
      } else {
        // tanks
        CalcTemplate.sngMFH_r = CalcTemplate.sngMFH;
      }
    }

    this.lmlCalcResult = "";

    // First criterion -- 2s x 2c box
    if (CalcTemplate.intSCbox === 0) {
      key1 = 0;
      if (Component_MAWP.strD_class === "Gouge") {
        this.lmlCalcResult +=
          "Gouge meets the 2s x 2c box interaction criterion. <br />";
      } else {
        if (this.intLTA_type === 0) {
          this.lmlCalcResult +=
            "LTA meets the 2s x 2c box interaction criterion. <br />";
        } else {
          this.lmlCalcResult +=
            "Groove meets the 2s x 2c box interaction criterion. <br />";
        }
      }
    } else {
      key1 = 1;
      if (Component_MAWP.strD_class === "Gouge") {
        this.lmlCalcResult += `Gouge fails the 2s x 2c box interaction criterion. <font color="red">*Fail*</font> <br />`;
      } else {
        if (this.intLTA_type === 0) {
          this.lmlCalcResult += `LTA fails the 2s x 2c box interaction criterion. <font color="red">*Fail*</font> <br />`;
        } else {
          this.lmlCalcResult += `Groove fails the 2s x 2c box interaction criterion. <font color="red">*Fail*</font> <br />`;
        }
      }
    }

    // Second criterion -- thickness ratio
    const sngRtStr = this.sngRt.toFixed(2).toString();
    if (parseFloat(sngRtStr) - 0.2 > -0.00005) {
      key2 = 0;
      this.lmlCalcResult += `Thickness ratio Rt = ${sngRtStr} >= 0.2 <br />`;
    } else {
      key2 = 1;
      this.lmlCalcResult += `Thickness ratio Rt = ${sngRtStr} < 0.2 <font color="red">*Fail*</font> <br />`;
    }

    // Third criterion -- minimum remaining thickness, tmm - FCAml; 0.05" (1.3mm) for piping, 0.1" (2.5mm) for others
    aa = LTA_module.sngtmm - CalcTemplate.sngFCAml;
    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      aa = Utility.roundDouble(aa, 3); // asked to modify round to 3 decimal places //shameer 4/6/2023
      const aaStr = aa.toFixed(3).toString();
      if (Component_MAWP.intEquip !== 5) {
        // vessels & tanks
        if (aa >= 0.1) {
          key3 = 0;
          this.lmlCalcResult += `tmm - FCAml = ${aaStr} inch >= 0.1 inch <br />`;
        } else {
          key3 = 1;
          this.lmlCalcResult += `tmm - FCAml = ${aaStr} inch < 0.1 inch <font color="red">*Fail*</font> <br />`;
        }
      } else {
        // piping
        if (aa >= 0.05) {
          key3 = 0;
          this.lmlCalcResult += `tmm - FCAml = ${aaStr} inch >= 0.05 inch <br />`;
        } else {
          key3 = 1;
          this.lmlCalcResult += `tmm - FCAml = ${aaStr} inch < 0.05 inch <font color="red">*Fail*</font> <br />`;
        }
      }
    } else {
      // SI
      const aaStr = aa.toFixed(1).toString();
      if (Component_MAWP.intEquip !== 5) {
        // vessels & tanks
        if (aa >= 2.5) {
          key3 = 0;
          this.lmlCalcResult += `tmm - FCAml = ${aaStr} mm >= 2.5 mm <br />`;
        } else {
          key3 = 1;
          this.lmlCalcResult += `tmm - FCAml = ${aaStr} mm < 2.5 mm <font color="red">*Fail*</font> <br />`;
        }
      } else {
        // piping
        if (aa >= 1.3) {
          key3 = 0;
          this.lmlCalcResult += `tmm - FCAml = ${aaStr} mm >= 1.3 mm <br />`;
        } else {
          key3 = 1;
          this.lmlCalcResult += `tmm - FCAml = ${aaStr} mm < 1.3 mm <font color="red">*Fail*</font> <br />`;
        }
      }
    }

    // Fourth criterion -- distance to structural discontinuity, Lmsd
    aa = 1.8 * Math.sqrt(Component_MAWP.sngID * Component_MAWP.sngtc);
    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(3);
      Component_MAWP.buffer1 = aa.toFixed(3);
      if (CalcTemplate.sngLmsd >= aa) {
        key4 = 0;
        this.lmlCalcResult += `Lmsd = ${Component_MAWP.buffer} inch >= 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch <br />`;
      } else {
        key4 = 1;
        this.lmlCalcResult += `Lmsd = ${Component_MAWP.buffer} inch < 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font> <br />`;
      }
    } else {
      // SI
      Component_MAWP.buffer = CalcTemplate.sngLmsd.toFixed(1);
      Component_MAWP.buffer1 = aa.toFixed(1);
      if (CalcTemplate.sngLmsd >= aa) {
        key4 = 0;
        this.lmlCalcResult += `Lmsd = ${Component_MAWP.buffer} mm >= 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm <br />`;
      } else {
        key4 = 1;
        this.lmlCalcResult += `Lmsd = ${Component_MAWP.buffer} mm < 1.8 * sqrt(ID * tc) = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font> <br />`;
      }
    }

    // Fifth criterion -- groove bottom radius
    if (
      (Component_MAWP.strD_class != "Gouge" && this.intLTA_type == 1) ||
      Component_MAWP.strD_class == "Gouge"
    ) {
      aa = 0.5 * (1.0 - this.sngRt) * Component_MAWP.sngtc;
      if (Component_MAWP.intUnit_select == 0) {
        // US Customary
        Component_MAWP.buffer = this.snggr.toFixed(3);
        Component_MAWP.buffer1 = aa.toFixed(3);
        if (this.snggr >= aa) {
          key5 = 0;
          this.lmlCalcResult += `gr = ${Component_MAWP.buffer} inch >= 0.5 * (1 - Rt) * tc = ${Component_MAWP.buffer1} inch <br />`;
        } else {
          key5 = 1;
          this.lmlCalcResult += `gr = ${Component_MAWP.buffer} inch < 0.5 * (1 - Rt) * tc = ${Component_MAWP.buffer1} inch <font color="red">*Fail*</font> <br />`;
        }
      } else {
        // SI
        Component_MAWP.buffer = this.snggr.toFixed(1);
        Component_MAWP.buffer1 = aa.toFixed(1);
        if (this.snggr >= aa) {
          key5 = 0;
          this.lmlCalcResult += `gr = ${Component_MAWP.buffer} mm >= 0.5 * (1 - Rt) * tc = ${Component_MAWP.buffer1} mm <br />`;
        } else {
          key5 = 1;
          this.lmlCalcResult += `gr = ${Component_MAWP.buffer} mm < 0.5 * (1 - Rt) * tc = ${Component_MAWP.buffer1} mm <font color="red">*Fail*</font> <br />`;
        }
      }
    }

    // Sixth criterion -- RSF >= RSFa for long. extent assessment
    Component_MAWP.buffer = CalcTemplate.sngRSF.toFixed(2);
    Component_MAWP.buffer1 = CalcTemplate.sngRSF_a.toFixed(2);
    if (CalcTemplate.sngRSF - CalcTemplate.sngRSF_a >= -0.00005) {
      this.lmlCalcResult += `RSF = ${Component_MAWP.buffer} >= RSFa = ${Component_MAWP.buffer1} ==> No pressure reduction. <br />`;
    } else {
      this.lmlCalcResult += `RSF = ${Component_MAWP.buffer} < RSFa = ${Component_MAWP.buffer1} ==> Pressure reduction may be required. <br />`;
    }

    // Seventh criterion -- Calculate MAWPr
    if (Component_MAWP.intEquip != 6) {
      // cylinders, cones, spheres, heads, piping
      if (Component_MAWP.intUnit_select == 0) {
        // US customary
        Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(1);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(1);
      } else {
        // SI
        Component_MAWP.buffer = CalcTemplate.sngMAWP_r.toFixed(2);
        Component_MAWP.buffer1 = Component_MAWP.sngP_d.toFixed(2);
      }

      if (CalcTemplate.sngMAWP_r >= Component_MAWP.sngP_d) {
        key7 = 0;
        // Carlos Acero
        // Implementing 7712938
        // https://dev.azure.com/chevron/Surface-Platform/_boards/board/t/SE-Application%20Support%20-%20Kanban/Stories/?workitem=7712938
        
        if (Component_MAWP.strD_class == "Gouge") {
          if (
            key1 === 0 &&
            key2 === 0 &&
            key3 === 0 &&
            key4 === 0 &&
            key5 === 0
          ) {
            if (Component_MAWP.intUnit_select == 0) {
              // US customary
              this.lmlCalcResult += `MAWP_r = ${Component_MAWP.buffer} psi >= P_d = ${Component_MAWP.buffer1} psi ==> Equipment can be operated at P_d. <br />`;
            } else {
              // SI
              this.lmlCalcResult += `MAWP_r = ${Component_MAWP.buffer} MPa >= P_d = ${Component_MAWP.buffer1} MPa ==> Equipment can be operated at P_d. <br />`;
            }
          }
        } else {
          if (
            key1 === 0 &&
            key2 === 0 &&
            key3 === 0 &&
            key4 === 0
          ) {
            if (Component_MAWP.intUnit_select == 0) {
              // US customary
              this.lmlCalcResult += `MAWP_r = ${Component_MAWP.buffer} psi >= P_d = ${Component_MAWP.buffer1} psi ==> Equipment can be operated at P_d. <br />`;
            } else {
              // SI
              this.lmlCalcResult += `MAWP_r = ${Component_MAWP.buffer} MPa >= P_d = ${Component_MAWP.buffer1} MPa ==> Equipment can be operated at P_d. <br />`;
            }
          }
        }
      } else {
        key7 = 1;
        if (Component_MAWP.strD_class == "Gouge") {
          if (!(key1 === 1 ||
            key2 === 1 ||
            key3 === 1 ||
            key4 === 1 ||
            key5 === 1)
          ) {
            if (Component_MAWP.intUnit_select == 0) {
              // US customary
              this.lmlCalcResult += `MAWP_r = ${Component_MAWP.buffer} psi < P_d = ${Component_MAWP.buffer1} psi ==> Equipment can only be operated at MAWP_r. <br />`;
            } else {
              // SI
              this.lmlCalcResult += `MAWP_r = ${Component_MAWP.buffer} MPa < P_d = ${Component_MAWP.buffer1} MPa ==> Equipment can only be operated at MAWP_r. <br />`;
            }
          }
        } else {
          if (!(key1 === 1 ||
            key2 === 1 ||
            key3 === 1 ||
            key4 === 1)
          ) {
            if (Component_MAWP.intUnit_select == 0) {
              // US customary
              this.lmlCalcResult += `MAWP_r = ${Component_MAWP.buffer} psi < P_d = ${Component_MAWP.buffer1} psi ==> Equipment can only be operated at MAWP_r. <br />`;
            } else {
              // SI
              this.lmlCalcResult += `MAWP_r = ${Component_MAWP.buffer} MPa < P_d = ${Component_MAWP.buffer1} MPa ==> Equipment can only be operated at MAWP_r. <br />`;
            }
          }
        }
      }
    } else {
      // tanks
      Component_MAWP.buffer = CalcTemplate.sngMFH_r.toFixed(2);
      Component_MAWP.buffer1 = CalcTemplate.sngMFH_d.toFixed(2);
      if (CalcTemplate.sngMFH_r >= CalcTemplate.sngMFH_d) {
        key7 = 0;
        if (Component_MAWP.intUnit_select == 0) {
          // US Customary unit
          this.lmlCalcResult += `MFH_r = ${Component_MAWP.buffer} ft >= MFH_d = ${Component_MAWP.buffer1} ft ==> Equipment can be operated at MFH_d. <br />`;
        } else {
          // SI unit
          this.lmlCalcResult += `MFH_r = ${Component_MAWP.buffer} m >= MFH_d = ${Component_MAWP.buffer1} m ==> Equipment can be operated at MFH_d. <br />`;
        }
      } else {
        key7 = 1;
        if (Component_MAWP.intUnit_select == 0) {
          // US Customary unit
          this.lmlCalcResult += `MFH_r = ${Component_MAWP.buffer} ft < MFH_d = ${Component_MAWP.buffer1} ft ==> Equipment can only be operated at MFH_r. <br />`;
        } else {
          // SI unit
          this.lmlCalcResult += `MFH_r = ${Component_MAWP.buffer} m < MFH_d = ${Component_MAWP.buffer1} m ==> Equipment can only be operated at MFH_r. <br />`;
        }
      }
    } // end criterion #7

    // Eighth criterion -- circumferential extent for cylinders, cones, piping and tanks
    if (
      Component_MAWP.intEquip === 0 ||
      Component_MAWP.intEquip === 1 ||
      Component_MAWP.intEquip === 5 ||
      Component_MAWP.intEquip === 6
    ) {
      aa =
        (2.0 * LTA_module.sngss * Component_MAWP.sngE_c) /
        Component_MAWP.sngE_L;
      if (LTA_module.sngcc <= aa) {
        // 1st criterion => c <= 2s(E_c/E_L)
        key8 = 0;
        if (Component_MAWP.intUnit_select === 0) {
          if (!(key1 === 1 ||
            key2 === 1 ||
            key3 === 1 ||
            key4 === 1)
          ) {
            // US customary
            this.lmlCalcResult += `c = ${LTA_module.sngcc.toFixed(
              3
            )} inch <= 2 x s x (E_c / E_L) = ${aa.toFixed(
              3
            )} inch ==> Circ. extent of the metal loss is acceptable. <br />`;
          }
        } else {
          if (!(key1 === 1 ||
            key2 === 1 ||
            key3 === 1 ||
            key4 === 1)
          ) {
            // SI
            this.lmlCalcResult += `c = ${LTA_module.sngcc.toFixed(
              1
            )} mm <= 2 x s x (E_c / E_L) = ${aa.toFixed(
              1
            )} mm ==> Circ. extent of the metal loss is acceptable. <br />`;
          }
        }
      } else {
        // fail the 1st criterion => c <= 2s(E_c/E_L)
        if (Component_MAWP.intEquip === 6) {
          // storage tanks
          key8 = 1;
          if (Component_MAWP.intUnit_select === 0) {
            if (!(key1 === 1 ||
              key2 === 1 ||
              key3 === 1 ||
              key4 === 1)
            ) {
              // US customary
              this.lmlCalcResult += `c = ${LTA_module.sngcc.toFixed(
                3
              )} inch > 2 x s x (E_c / E_L) = ${aa.toFixed(
                3
              )} inch ==> Circ. extent of the metal loss is unacceptable. <br />`;
            }
          } else {
            if (!(key1 === 1 ||
              key2 === 1 ||
              key3 === 1 ||
              key4 === 1)
            ) {
              // SI
              this.lmlCalcResult += `c = ${LTA_module.sngcc.toFixed(
                1
              )} mm > 2 x s x (E_c / E_L) = ${aa.toFixed(
                1
              )} mm ==> Circ. extent of the metal loss is unacceptable. <br />`;
            }
          }
        } else {
          // cylinders, cones, & piping
          if (CalcTemplate.sngMAWP_r >= Component_MAWP.sngP_d) {
            // pick the smaller of MAWPr and Pd
            aa = Component_MAWP.sngP_d;
          } else {
            aa = CalcTemplate.sngMAWP_r;
          }
          if (Component_MAWP.intEquip === 0) {
            // cylinder
            Component_MAWP.sngtmin_L =
              ((aa + Component_MAWP.sngp_H) * (Component_MAWP.sngID / 2.0)) /
                (2.0 * Component_MAWP.sngS_stress * Component_MAWP.sngE_c +
                  0.4 * (aa + Component_MAWP.sngp_H)) +
              Component_MAWP.sngt_sl;
          } else if (Component_MAWP.intEquip === 1) {
            // cone
            Component_MAWP.sngtmin_L =
              ((aa + Component_MAWP.sngp_H) * Component_MAWP.sngID) /
                (2.0 *
                  Math.cos((Component_MAWP.sngalpha / 360.0) * Math.PI) *
                  (2.0 * Component_MAWP.sngS_stress * Component_MAWP.sngE_c +
                    0.4 * (aa + Component_MAWP.sngp_H))) +
              Component_MAWP.sngt_sl;
          } else if (Component_MAWP.intEquip === 5) {
            // B31.3 piping, no static head
            Component_MAWP.sngtmin_L =
              (aa * Component_MAWP.sngOD) /
                (4.0 *
                  (Component_MAWP.sngS_stress * Component_MAWP.sngE_c +
                    aa * Component_MAWP.sngY_b)) +
              Component_MAWP.sngt_sl;
          }
          aa = LTA_module.sngtmm - CalcTemplate.sngFCAml;
          if (Component_MAWP.sngtmin_L <= aa) {
            key8 = 0;
            if (Component_MAWP.intUnit_select === 0) {
              if (!(key1 === 1 ||
                key2 === 1 ||
                key3 === 1 ||
                key4 === 1)
              ) {
                // US customary
                this.lmlCalcResult +=
                "tmin_L = " +
                Component_MAWP.sngtmin_L.toFixed(3) +
                " inch <= tmm - FCAml = " +
                aa.toFixed(3) +
                " inch ==> Circ. extent of the metal loss is acceptable. <br />";
              }
            } else {
              if (!(key1 === 1 ||
                key2 === 1 ||
                key3 === 1 ||
                key4 === 1)
              ) {
                // SI
                this.lmlCalcResult +=
                "tmin_L = " +
                Component_MAWP.sngtmin_L.toFixed(1) +
                ` mm <= tmm - FCAml = ${aa.toFixed(
                  1
                )} mm ==> Circ. extent of the metal loss is acceptable. <br />`;
              }
            }
          } else {
            key8 = 1;
            CalcTemplate.sngMAWP_rL =
              CalcTemplate.sngMAWP_r * (aa / Component_MAWP.sngtmin_L);
            if (Component_MAWP.intUnit_select === 0) {
              if (!(key1 === 1 ||
                key2 === 1 ||
                key3 === 1 ||
                key4 === 1)
              ) {
                // US customary
                this.lmlCalcResult += `tmin_L = ${Component_MAWP.sngtmin_L.toFixed(
                  3
                )} inch > tmm - FCAml = ${aa.toFixed(
                  3
                )} inch ==> Pressure reduction to ${CalcTemplate.sngMAWP_rL.toFixed(
                  1
                )} psi is needed. <br />`;
              }
            } else {
              if (!(key1 === 1 ||
                key2 === 1 ||
                key3 === 1 ||
                key4 === 1)
              ) {
                // SI
                this.lmlCalcResult += `tmin_L = ${Component_MAWP.sngtmin_L.toFixed(
                  1
                )} mm > tmm - FCAml = ${aa.toFixed(
                  1
                )} mm ==> Pressure reduction to ${CalcTemplate.sngMAWP_rL.toFixed(
                  2
                )} MPa is needed. <br />`;
              }
            }
          }
        }
      }
    } //end criterion #8

    // Final results
    if (
      (Component_MAWP.strD_class === "LTA" ||
        Component_MAWP.strD_class === "LTAP") &&
      this.intLTA_type === 0
    ) {
      // LTA
      if (Component_MAWP.intEquip > 1 && Component_MAWP.intEquip < 5) {
        // spheres and heads
        if (key1 === 0 && key2 === 0 && key3 === 0 && key4 === 0) {
          Component_MAWP.strD_result += "Pass";
          this.lmlCalcResult += " <br />";
          if (key7 === 0) {
            if (Component_MAWP.intUnit_select === 0) {
              // US customary
              this.lmlCalcResult += `<font color="blue">Equipment can be operated at design pressure = ${Component_MAWP.sngP_d.toFixed(
                1
              )} psi. (MAWP_r >= P_d).</font> <br />`;
            } else {
              // SI
              this.lmlCalcResult += `<font color="blue">Equipment can be operated at design pressure = ${Component_MAWP.sngP_d.toFixed(
                2
              )} MPa. (MAWP_r >= P_d).</font> <br />`;
            }
          } else {
            if (!(key1 === 1 ||
              key2 === 1 ||
              key3 === 1 ||
              key4 === 1)
            ) {
              if (Component_MAWP.intUnit_select === 0) {
                // US customary
                this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_r.toFixed(
                  1
                )} psi. (MAWP_r < P_d).</font> <br />`;
              } else {
                // SI
                this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_r.toFixed(
                  2
                )} MPa. (MAWP_r < P_d).</font> <br />`;
              }
            }
          }
          return this.lmlCalcResult;
        } else {
          Component_MAWP.strD_result += "Fail";
          this.lmlCalcResult += " <br />";
          this.lmlCalcResult += `<font color="red">Local Metal Loss failed the Level 1 assessment. <br /> Consider performing a Local Metal Loss (Profile Data) or Level 3 assesment.</font> <br />`;
          return this.lmlCalcResult;
        }
      } else {
        // cylinders, cone, piping & tanks
        if (key1 === 0 && key2 === 0 && key3 === 0 && key4 === 0) {
          Component_MAWP.strD_result += "Pass";
          if (Component_MAWP.intEquip !== 6) {
            // cylinders, cones, and piping
            if (key8 === 0) {
              // no pressure reduction due to circ. extent
              this.lmlCalcResult += " <br />";
              if (key7 === 0) {
                if (Component_MAWP.intUnit_select === 0) {
                  // US customary
                  this.lmlCalcResult += `<font color="blue">Equipment can be operated at design pressure = ${Component_MAWP.sngP_d.toFixed(
                    1
                  )} psi. (MAWP_r >= P_d).</font> <br />`;
                } else {
                  // SI
                  this.lmlCalcResult += `<font color="blue">Equipment can be operated at design pressure = ${Component_MAWP.sngP_d.toFixed(
                    2
                  )} MPa. (MAWP_r >= P_d).</font> <br />`;
                }
              } else {
                if (Component_MAWP.intUnit_select === 0) {
                  if (!(key1 === 1 ||
                    key2 === 1 ||
                    key3 === 1 ||
                    key4 === 1)
                  ) {
                    // US customary
                    this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_r.toFixed(
                      1
                    )} psi. (MAWP_r < P_d).</font> <br />`;
                  } else {
                    // SI
                    this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_r.toFixed(
                      2
                    )} MPa. (MAWP_r < P_d).</font> <br />`;
                  }
                }  
              }
            } else {
              // key8 = 1 ==> further pressure reduction due to circ. extent
              if (Component_MAWP.intUnit_select === 0) {
                if (!(key1 === 1 ||
                  key2 === 1 ||
                  key3 === 1 ||
                  key4 === 1)
                ) {
                  // US customary
                  this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_rL.toFixed(
                    1
                  )} psi based on circumferential extent assessment.</font> <br />`;
                } else {
                  // SI
                  this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_rL.toFixed(
                    2
                  )} MPa. based on circumferential extent assessment.</font> <br />`;
                }
              }  
            }
            return this.lmlCalcResult;
          } else {
            // tanks
            if (key8 === 0) {
              // no pressure reduction based on circ. extent
              this.lmlCalcResult += " <br />";
              if (key7 === 0) {
                if (Component_MAWP.intUnit_select === 0) {
                  // US customary
                  this.lmlCalcResult += `<font color="blue">Equipment can be operated at design MFH = ${CalcTemplate.sngMFH_d.toFixed(
                    2
                  )} ft. (MFH_r >= MFH_d).</font> <br />`;
                } else {
                  // SI
                  this.lmlCalcResult += `<font color="blue">Equipment can be operated at design MFH = ${CalcTemplate.sngMFH_d.toFixed(
                    2
                  )} m. (MFH_r >= MFH_d).</font> <br />`;
                }
              } else {
                if (Component_MAWP.intUnit_select === 0) {
                  // US customary
                  this.lmlCalcResult += `<font color="blue">Equipment can only be operated at MFH_r = ${CalcTemplate.sngMFH_r.toFixed(
                    2
                  )} ft. (MFH_r < MFH_d).</font> <br />`;
                } else {
                  // SI
                  this.lmlCalcResult += `<font color="blue">Equipment can only be operated at MFH_r = ${CalcTemplate.sngMFH_r.toFixed(
                    2
                  )} m. (MFH_r < MFH_d).</font> <br />`;
                }
              }
            } else {
              //key8 = 1 ==> no pressure reduction for tanks
              Component_MAWP.strD_result += "Fail";
              this.lmlCalcResult += " <br />";
              this.lmlCalcResult += `<font color="red">Local Metal Loss failed the Level 1 assessment. <br /> Consider performing a Local Metal Loss (Profile Data) or Level 3 assesment.</font> <br />`;
            }
            return this.lmlCalcResult;
          }
        } else {
          // any of the first 4 criteria is not met
          Component_MAWP.strD_result += "Fail";
          this.lmlCalcResult += " <br />";

          if (Component_MAWP.strD_class === "LTA") {
            this.lmlCalcResult += `<font color="red">Local Metal Loss failed the Level 1 assessment. <br /> Consider performing a Local Metal Loss (Profile Data) or Level 3 assesment.</font> <br />`;
            return this.lmlCalcResult;
          } else if (Component_MAWP.strD_class === "LTAP") {
            this.lmlCalcResult += `<font color="red">Local Metal Loss failed the Level 1 assessment. <br /> Consider performing a Level 3 assessment.</font> <br />`;
            return this.lmlCalcResult;
          }
        }
      }
    }
    if (
      (Component_MAWP.strD_class !== "Gouge" && this.intLTA_type == 1) ||
      Component_MAWP.strD_class == "Gouge"
    ) {
      // groove & gouge
      if (Component_MAWP.intEquip > 1 && Component_MAWP.intEquip < 5) {
        // spheres and heads
        if (key1 == 0 && key2 == 0 && key3 == 0 && key4 == 0 && key5 == 0) {
          Component_MAWP.strD_result += "Pass";
          this.lmlCalcResult += " <br />";
          if (key7 == 0) {
            if (Component_MAWP.intUnit_select == 0) {
              // US customary
              this.lmlCalcResult += `<font color="blue">Equipment can be operated at design pressure = ${Component_MAWP.sngP_d.toFixed(
                1
              )} psi. (MAWP_r >= P_d).</font> <br />`;
            } else {
              // SI
              this.lmlCalcResult += `<font color="blue">Equipment can be operated at design pressure = ${Component_MAWP.sngP_d.toFixed(
                2
              )} MPa. (MAWP_r >= P_d).</font> <br />`;
            }
          } else {
            if (Component_MAWP.intUnit_select == 0) {
              if (!(key1 === 1 ||
                key2 === 1 ||
                key3 === 1 ||
                key4 === 1)
              ) {
                // US customary
                this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_r.toFixed(
                  1
                )} psi. (MAWP_r < P_d).</font> <br />`;
              } else {
                // SI
                this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_r.toFixed(
                  2
                )} MPa. (MAWP_r < P_d).</font> <br />`;
              }
            }
          }
          return this.lmlCalcResult;
        } else {
          Component_MAWP.strD_result += "Fail";
          this.lmlCalcResult += " <br />";
          if (Component_MAWP.strD_class == "Gouge") {
            if (key3 == 1 || key4 == 1 || key5 == 1) {
              this.lmlCalcResult += `<font color="red">The Gouge failed Level 1 assessment. <br /> Consider performing a Level 2 or Level 3 assesment.</font> <br />`; // modified by shameer
            } else {
              this.lmlCalcResult += `<font color="red">The Gouge failed Level 1 assessment. <br /> Consider performing a Local Metal Loss (Profile) assesment.</font> <br />`; // modified by shameer
            }
          } else {
            this.lmlCalcResult += `<font color="red">Groove failed the Level 1 assessment. <br /> Consider performing a Level 3 assesment.</font> <br />`;
          }
          return this.lmlCalcResult;
        }
      } else {
        // cylinders, cone, piping & tanks
        if (key1 == 0 && key2 == 0 && key3 == 0 && key4 == 0 && key5 == 0) {
          // cylinders, cones, piping, & tanks
          Component_MAWP.strD_result += "Pass";
          this.lmlCalcResult += " <br />";
          if (Component_MAWP.intEquip != 6) {
            // cylinders, cones, and piping
            if (key8 == 0) {
              // no pressure reduction due to circ. extent
              if (key7 == 0) {
                if (Component_MAWP.intUnit_select == 0) {
                  // US customary
                  this.lmlCalcResult += `<font color="blue">Equipment can be operated at design pressure = ${Component_MAWP.sngP_d.toFixed(
                    1
                  )} psi. (MAWP_r >= P_d).</font> <br />`;
                } else {
                  // SI
                  this.lmlCalcResult += `<font color="blue">Equipment can be operated at design pressure = ${Component_MAWP.sngP_d.toFixed(
                    2
                  )} MPa. (MAWP_r >= P_d).</font> <br />`;
                }
              } else {
                if (Component_MAWP.intUnit_select == 0) {
                  if (!(key1 === 1 ||
                    key2 === 1 ||
                    key3 === 1 ||
                    key4 === 1)
                  ) {
                    // US customary
                    this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_r.toFixed(
                      1
                    )} psi. (MAWP_r < P_d).</font> <br />`;
                  } else {
                    // SI
                    this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_r.toFixed(
                      2
                    )} MPa. (MAWP_r < P_d).</font> <br />`;
                  }
                }
              }
            } else {
              // key8 = 1 ==> further pressure reduction due to circ. extent
              if (Component_MAWP.intUnit_select == 0) {
                if (!(key1 === 1 ||
                  key2 === 1 ||
                  key3 === 1 ||
                  key4 === 1)
                ) {
                  // US customary
                  this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_rL.toFixed(
                    1
                  )} psi based on circumferential extent assessment.</font> <br />`;
                } else {
                  // SI
                  this.lmlCalcResult += `<font color="orange">Equipment can only be operated at MAWP_r = ${CalcTemplate.sngMAWP_rL.toFixed(
                    2
                  )} MPa. based on circumferential extent assessment.</font> <br />`;
                }
              }
            }
            return this.lmlCalcResult;
          } else {
            // tanks
            if (key8 == 0) {
              // no pressure reduction due to circ. extent
              if (key7 == 0) {
                if (Component_MAWP.intUnit_select == 0) {
                  // US customary
                  this.lmlCalcResult += `<font color="blue">Equipment can be operated at design MFH = ${CalcTemplate.sngMFH_d.toFixed(
                    2
                  )} ft. (MFH_r >= MFH_d).</font> <br />`;
                } else {
                  // SI
                  this.lmlCalcResult += `<font color="blue">Equipment can be operated at design MFH = ${CalcTemplate.sngMFH_d.toFixed(
                    2
                  )} m. (MFH_r >= MFH_d).</font> <br />`;
                }
              } else {
                if (Component_MAWP.intUnit_select == 0) {
                  // US customary
                  this.lmlCalcResult += `<font color="blue">Equipment can only be operated at MFH_r = ${CalcTemplate.sngMFH_r.toFixed(
                    2
                  )} ft. (MFH_r < MFH_d).</font> <br />`;
                } else {
                  // SI
                  this.lmlCalcResult += `<font color="blue">Equipment can only be operated at MFH_r = ${CalcTemplate.sngMFH_r.toFixed(
                    2
                  )} m. (MFH_r < MFH_d).</font> <br />`;
                }
              }
            } else {
              //key8 = 1 ==> no pressure reduction for tanks
              Component_MAWP.strD_result += "Fail";
              if (Component_MAWP.strD_class == "Gouge") {
                if (key3 == 1 || key4 == 1 || key5 == 1) {
                  this.lmlCalcResult += `<font color="red">The Gouge failed Level 1 assessment. <br /> Consider performing a Level 2 or Level 3 assesment.</font> <br />`; // modified by shameer
                } else {
                  this.lmlCalcResult += `<font color="red">The Gouge failed Level 1 assessment. <br /> Consider performing a Local Metal Loss (Profile) assesment.</font> <br />`; // modified by shameer
                }
              } else {
                this.lmlCalcResult += `<font color="red">Groove failed the Level 1 assessment. <br /> Consider performing a Level 3 assesment.</font> <br />`; // modified by shameer
              }
            }
            return this.lmlCalcResult;
          }
        } else {
          // any of the first 5 criteria is not met
          Component_MAWP.strD_result += "Fail";
          this.lmlCalcResult += " <br />";
          if (Component_MAWP.strD_class == "Gouge") {
            if (key3 == 1 || key4 == 1 || key5 == 1) {
              this.lmlCalcResult += `<font color="red">The Gouge failed Level 1 assessment. <br /> Consider performing a Level 2 or Level 3 assesment.</font> <br />`; // added by shameer
            } else {
              this.lmlCalcResult += `<font color="red">The Gouge failed Level 1 assessment. <br /> Consider performing a Local Metal Loss (Profile) assesment.</font> <br />`; // modified by shameer
            }
          } else if (
            Component_MAWP.strD_class == "LTA" ||
            Component_MAWP.strD_class == "LTAP"
          ) {
            this.lmlCalcResult += `<font color="red">Groove failed the Level 1 assessment. <br /> Consider performing a Level 3 assesment.</font> <br />`; // modified by shameer
          }
          return this.lmlCalcResult;
        }
      }
    }

    return this.lmlCalcResult;
  }

  public static check_LTA_Data(inspectionDataFormGroup): boolean {
    let pass_flag: boolean = true;

    this.validationMessage = [];
    let msgNotSet = "not set.";

    if (Component_MAWP.strD_iclass >= 1 && Component_MAWP.strD_iclass <= 3) {
      let equipmentString: string;

      if (CalcTemplate.intSCbox < 0) {
        this.validationMessage.push(
          "Structural discontinuities not selected\n"
        );
        pass_flag = false;
      }

      // textBox1_DI error handling
      if (inspectionDataFormGroup.controls["textBox1_DI"]?.enabled) {
        if (
          Component_MAWP.strD_class == "LTA" ||
          Component_MAWP.strD_class == "Gouge"
        ) {
          equipmentString = "Minimum measured thickness, tmm - ";
          if (!inspectionDataFormGroup.controls["textBox1_DI"].valid) {
            this.validationMessage.push(equipmentString + msgNotSet + "\n");
            pass_flag = false;
          } else {
            // if (
            //   LTA_module.sngtmm <= 0.0 ||
            //   LTA_module.sngtmm > Component_MAWP.sngtnom
            // ) {
            //   this.validationMessage.push(
            //     equipmentString +
            //       "cannot be less than or equal to 0 and it should satisfy the condition: tmm <= tnom\n"
            //   );
            //   pass_flag = false;
            // }

        //     if (Component_MAWP.strD_class == "Gouge") {  //shameer new validation in web
        //       if ( Component_MAWP.sngtrd <= LTA_module.sngtmm ) {
        //        this.validationMessage.push(
        //          equipmentString + "cannot be greater or equal to the current measured thickness.\n"
        //        );
        //        pass_flag = false;
        //      }          
        //  }

         if (Component_MAWP.strD_class == "Gouge" || Component_MAWP.strD_class == "LTA") {  //shameer new validation in web
          if ( LTA_module.sngtmm == 0 ) {
           this.validationMessage.push(
             equipmentString + "cannot be equal to zero.\n"
           );
           pass_flag = false;
         }  else if(LTA_module.sngtmm > Number(Component_MAWP.sngtrd.toFixed(3)))//shameer - data from mawp needs to be rounded before comparing  
          {
            this.validationMessage.push(
              equipmentString + "cannot be greater than the current measured thickness.\n"
            );
            pass_flag = false;
          }      
     }

          }
        } else if (Component_MAWP.strD_class == "LTAP") {
          equipmentString =
            "Number of thickness measurements in the longitudinal direction, LTAP - ";
          if (!inspectionDataFormGroup.controls["textBox1_DI"].valid) {
            this.validationMessage.push(equipmentString + msgNotSet + "\n");
            pass_flag = false;
          } else {
            if (LTAP_module.intnum_t_pt <= 0.0) {
              this.validationMessage.push(
                equipmentString + "cannot be less than or equal to 0\n"
              );
              pass_flag = false;
            }
          }
        } 
      }

      // textBox2_DI error handling
      if (inspectionDataFormGroup.controls["textBox2_DI"]?.enabled) {
        equipmentString = "Future corrosion allowance, FCA - ";
        if (!inspectionDataFormGroup.controls["textBox2_DI"].valid) {
          this.validationMessage.push(equipmentString + msgNotSet + "\n");
          pass_flag = false;
        } else {
          if (CalcTemplate.sngFCAml < 0.0) {
            this.validationMessage.push(
              equipmentString + "cannot be less than 0\n"
            );
            pass_flag = false;
          }
        }
      }

      // textBox3_DI error handling
      // 0 is the variable key to activate variable use for LTAP
      if (inspectionDataFormGroup.controls["textBox3_DI"]?.enabled) {
        if (
          Component_MAWP.strD_class == "LTA" ||
          Component_MAWP.strD_class == "Gouge"
        ) {
          equipmentString = "Length of metal loss in the long. direction, s - ";
          if (!inspectionDataFormGroup.controls["textBox3_DI"].valid) {
            this.validationMessage.push(equipmentString + msgNotSet + "\n");
            pass_flag = false;
          } else {
            if (LTA_module.sngss <= 0.0) {
              this.validationMessage.push(
                equipmentString + "cannot be less than or equal to 0\n"
              );
              pass_flag = false;
            }
          }
        } else if (Component_MAWP.strD_class == "LTAP") {
          equipmentString =
            "Grid spacing for thickness measurements, sngGrid_spacing - ";
          if (!inspectionDataFormGroup.controls["textBox3_DI"].valid) {
            this.validationMessage.push(equipmentString + msgNotSet + "\n");
            pass_flag = false;
          } else {
            if (LTAP_module.sngGrid_spacing < 0.0) {
              this.validationMessage.push(
                equipmentString + "cannot be less than 0\n"
              );
              pass_flag = false;
            }
          }
        }
      }

      // textBox4_DI error handling
      if (inspectionDataFormGroup.controls["textBox4_DI"]?.enabled) {
        equipmentString = "Length of metal loss in the circ. direction, c - ";
        if (!inspectionDataFormGroup.controls["textBox4_DI"].valid) {
          this.validationMessage.push(equipmentString + msgNotSet + "\n");
          pass_flag = false;
        } else {
          if (LTA_module.sngcc <= 0.0) {
            this.validationMessage.push(
              equipmentString + "must be greater than 0\n"
            );
            pass_flag = false;
          }
        }
      }

      // textBox5_DI error handling
      if (inspectionDataFormGroup.controls["textBox5_DI"]?.enabled) {
        equipmentString =
          "Distance from the edge of the metal loss to the nearest structural discontinuity, Lmsd - ";
        if (!inspectionDataFormGroup.controls["textBox5_DI"].valid) {
          this.validationMessage.push(equipmentString + msgNotSet + "\n");
          pass_flag = false;
        } else {
          if (CalcTemplate.sngLmsd < 0.0) {
            this.validationMessage.push(
              equipmentString + "cannot be less than 0\n"
            );
            pass_flag = false;
          }
        }
      }

      // textBox6_DI error handling
      if (inspectionDataFormGroup.controls["textBox6_DI_snggr"]?.enabled) {
        if (
          LTA_module.intLTA_type === 1 ||
          Component_MAWP.strD_class === "Gouge"
        ) {
          equipmentString = "Radius at the base of the groove-like flaw, gr - ";
          if (!inspectionDataFormGroup.controls["textBox6_DI_snggr"].valid) {
            this.validationMessage.push(equipmentString + msgNotSet + "\n");
            pass_flag = false;
          } else {
            if (LTA_module.snggr < 0.0) {
              this.validationMessage.push(
                equipmentString + "cannot be less than 0\n"
              );
              pass_flag = false;
            }
          }
        }
      }

      // textBox7_DI error handling
      if (inspectionDataFormGroup.controls["textBox7_DI"]?.enabled) {
        if (Component_MAWP.intEquip === 6) {
          equipmentString =
            "Distance between the bottom of the flaw and the tank bottom, H_f - ";
          if (!inspectionDataFormGroup.controls["textBox7_DI"].valid) {
            this.validationMessage.push(equipmentString + msgNotSet + "\n");
            pass_flag = false;
          } else {
            if (CalcTemplate.sngH_f < 0.0) {
              this.validationMessage.push(
                equipmentString + "cannot be less than 0\n"
              );
              pass_flag = false;
            }
          }
        }
      }

      if (!pass_flag) {
        return pass_flag;
      }

      if (Component_MAWP.sngtc <= 0.0) {
        // setup messagebox system
        this.validationMessage.push(
          "Check your input! Future corroded thickness, tc = trd - FCA,\n is less than or equal to zero !"
        );
        pass_flag = false;
      }
    }

    return pass_flag;
  }

  public static ExportToJson(): string {
    const dataToExport = {
      Component_MAWP: { ...Component_MAWP },
      CalcTemplate: { ...CalcTemplate },
      LTA_module: { ...LTA_module },
    };

    const jsonData = JSON.stringify(dataToExport);

    return jsonData;
  }

  public static ImportFromJson(jsonDataResult): void {
    Component_MAWP.MAWP_initialize();
    LTA_module.LTA_initialize();

    Object.assign(Component_MAWP, jsonDataResult.Component_MAWP);
    Object.assign(CalcTemplate, jsonDataResult.CalcTemplate);
    Object.assign(LTA_module, jsonDataResult.LTA_module);
  }

  public static updateInspectionDataFormControls(
    inspectionDataFormGroup
  ): void {
    const formControls = this.getFormControls(inspectionDataFormGroup);
    this.updateRadioButtonAndTextBox6(formControls);
    this.updateTextBox2(formControls);
    this.updateComboBox1(formControls);
    this.updateTextBox1And3(formControls);
    this.updateTextBox4(formControls);
    this.updateTextBox5(formControls);
    this.updateTextBox6(formControls);
  }

  private static getFormControls(inspectionDataFormGroup) {
    return {
      textBox1_DIformControl: inspectionDataFormGroup.get("textBox1_DI"),
      textBox2_DIformControl: inspectionDataFormGroup.get("textBox2_DI"),
      textBox3_DIformControl: inspectionDataFormGroup.get("textBox3_DI"),
      textBox4_DIformControl: inspectionDataFormGroup.get("textBox4_DI"),
      textBox5_DIformControl: inspectionDataFormGroup.get("textBox5_DI"),
      textBox6_DIformControl: inspectionDataFormGroup.get("textBox6_DI_snggr"),
      comboBox1_DIformControl: inspectionDataFormGroup.get("comboBox1_DI"),
      radioButton_DIformControl: inspectionDataFormGroup.get("radioButton_DI"),
    };
  }

  private static updateRadioButtonAndTextBox6(formControls): void {
    if (Component_MAWP.strD_iclass !== 3 && LTA_module.intLTA_type !== -1) {
      if (LTA_module.intLTA_type === 0) {
        formControls.radioButton_DIformControl.setValue(
          "radioButton1_DI_enabled"
        );
        formControls.textBox6_DIformControl.disable();
      } else if (LTA_module.intLTA_type === 1) {
        formControls.radioButton_DIformControl.setValue(
          "radioButton2_DI_enabled"
        );
        formControls.textBox6_DIformControl.enable();
      }
    }
  }

  private static updateTextBox2(formControls): void {
    if (CalcTemplate.sngFCAml !== -1)
      formControls.textBox2_DIformControl.setValue(
        CalcTemplate.sngFCAml.toString()
      );
  }

  private static updateComboBox1(formControls): void {
    let comboBoxValues = ["No", "Yes"];
    if (CalcTemplate.intSCbox !== -1) {
      formControls.comboBox1_DIformControl.setValue(
        comboBoxValues[CalcTemplate.intSCbox]
      );
    }
  }

  private static updateTextBox1And3(formControls): void {
    if (Component_MAWP.strD_iclass === 2) {
      this.updateForLTAP(formControls);
    } else {
      this.updateForLTAAndGouge(formControls);
    }
  }

  private static updateForLTAP(formControls): void {
    if (LTAP_module.intnum_t_pt !== -1)
      formControls.textBox1_DIformControl.setValue(
        LTAP_module.intnum_t_pt.toString()
      );
    if (LTAP_module.sngGrid_spacing != -1) {
      formControls.textBox3_DIformControl.setValue(
        LTAP_module.sngGrid_spacing.toString()
      );
      if (LTAP_module.sngGrid_spacing === 0) {
        formControls.textBox1_DIformControl.setValue("");
        formControls.textBox1_DIformControl.disable();
      }
    }
  }

  private static updateForLTAAndGouge(formControls): void {
    if (LTA_module.sngtmm != -1)
      formControls.textBox1_DIformControl.setValue(
        LTA_module.sngtmm.toString()
      );
    if (LTA_module.sngss != -1)
      formControls.textBox3_DIformControl.setValue(LTA_module.sngss.toString());
  }

  private static updateTextBox4(formControls): void {
    if (LTA_module.sngcc !== -1)
      formControls.textBox4_DIformControl.setValue(LTA_module.sngcc.toString());
  }

  private static updateTextBox5(formControls): void {
    if (CalcTemplate.sngLmsd !== -1)
      formControls.textBox5_DIformControl.setValue(
        CalcTemplate.sngLmsd.toString()
      );
  }

  private static updateTextBox6(formControls): void {
    if (LTA_module.intLTA_type === 1 || Component_MAWP.strD_iclass === 3) {
      if (LTA_module.snggr !== 1)
        formControls.textBox6_DIformControl.setValue(
          LTA_module.snggr.toString()
        );
    }
  }

  public static LTA_report(): string {
    let report: string = "";

    const format = "dd/MM/yyyy";
    const locale = "en-US";
    const dt1 = new Date();
    const reportDate = formatDate(dt1, format, locale);

    report += this.LTA_report_subpart_1(reportDate);
    report += this.LTA_report_subpart_2();
    report += this.LTA_report_subpart_3();

    // SECOND PAGE

    report += this.LTA_report_subpart_4(reportDate);

    report += `Allowable remaining strength factor, RSFa = ${CalcTemplate.sngRSF_a.toFixed(
      2
    )}`;
    report += "<br><br><br>"; // Added <br> for HTML formatting

    report += "Metal Loss Information :<br><br>";

    if (CalcTemplate.intSCbox == 0)
      report +=
        "Major structural discontinuities or other thinned areas infringe on the 2s X 2c box = No</br>";
    else
      report +=
        "Major structural discontinuities or other thinned areas infringe on the 2s X 2c box = Yes</br>";

    if (Component_MAWP.intUnit_select == 0) {
      // US Customary
      report += `Minimum measured thickness, tmm (inch) = ${LTA_module.sngtmm.toFixed(
        3
      )}</br>`;
      report += `Future corrosion allowance inside the damage area, FCAml (inch) = ${CalcTemplate.sngFCAml.toFixed(
        3
      )}</br>`;
      report += `Length of the metal loss in the long. direction, s (inch) = ${LTA_module.sngss.toFixed(
        3
      )}</br>`;
      report += `Length of the metal loss in the circ. direction, c (inch) = ${LTA_module.sngcc.toFixed(
        3
      )}</br>`;
      report += `Distance to structural discontinuity, Lmsd (inch) = ${CalcTemplate.sngLmsd.toFixed(
        3
      )}</br>`;
    } else {
      // SI
      report += `Minimum measured thickness, tmm (mm) = ${LTA_module.sngtmm.toFixed(
        1
      )}</br>`;
      report += `Future corrosion allowance inside the damage area, FCAml (mm) = ${CalcTemplate.sngFCAml.toFixed(
        1
      )}</br>`;
      report += `Length of the metal loss in the long. direction, s (mm) = ${LTA_module.sngss.toFixed(
        1
      )}</br>`;
      report += `Length of the metal loss in the circ. direction, c (mm) = ${LTA_module.sngcc.toFixed(
        1
      )}</br>`;
      report += `Distance to structural discontinuity, Lmsd (mm) = ${CalcTemplate.sngLmsd.toFixed(
        1
      )}</br>`;
    }

    if (
      Component_MAWP.strD_class == "Gouge" ||
      (Component_MAWP.strD_class != "Gouge" && LTA_module.intLTA_type == 1)
    ) {
      if (Component_MAWP.intUnit_select == 0) {
        // US Customary
        report += `Radius at the base of the groove-like flaw, gr (inch) = ${LTA_module.snggr.toFixed(
          4
        )}`;
      } else {
        report += `Radius at the base of the groove-like flaw, gr (mm) = ${LTA_module.snggr.toFixed(
          2
        )}`;
      }
      report += "<br><br>";
    } else {
      report += "<br><br>";
    }

    report += "Assessment Results :<br><br>";
    report +=
      "All the conditions in the Applicability and Limitations section are met.<br><br>";

    report += this.lmlCalcResult;

    return report;
  }

  public static LTA_report_subpart_1(reportDate: string): string {
    let report: string = "";

    if (Component_MAWP.strD_class === "Gouge") {
      report +=
        "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 12</center>";
      report += "<center>Assessment of Gouges</center></br></br>";
    } else {
      report +=
        "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 5</center>";
      if (Component_MAWP.strD_class === "LTA") {
        report += "<center>Assessment of Local Metal Loss</center></br></br>";
      } else {
        report +=
          "<center>Assessment of Local Metal Loss (Profile)</center></br></br>";
      }
    }

    report += reportDate + "</br>";
    report += "Page: 1</br></br></br>";

    const sRefinery: string = "Facility: ";
    const sPlant: string = "Plant: ";
    const sEquipment: string = "Equipment: ";
    const sAnalyst: string = "Analyst: ";

    report += sRefinery + Component_MAWP.strFacility + "</br>";
    report += sPlant + Component_MAWP.strPlant + "</br>";
    report += sEquipment + Component_MAWP.strEquipment + "</br>";
    report += sAnalyst + Component_MAWP.strAnalyst + "</br></br></br>";

    report += "Applicability and Limitations :</br></br>";

    report += `  
      <ol class="appAndLimitList">  
        <li>The original design criteria were in accordance with a recognized code or standard.</li>  
        <li>The component is not operating in the creep range. The design temperature is less than or equal to the temperature limit in Table 4.1.</li>`;

    if (Component_MAWP.strD_class === "Gouge") {
      report +=
        "<li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment. Note that cold work may be produced by the deformation. This cold worked area may be more susceptible to some forms of mechanical and environmental cracking.</li>";
    } else {
      report +=
        "<li>The material is considered to have sufficient material toughness and the component is not subject to embrittlement during operation due to temperature and/or process environment.</li>";
    }

    report += `
        <li>The component is not in cyclic service (i.e., less than 150 cycles in past and future planned operation or satisfying the cyclic service screening criteria).</li>  
        <li>Type A components subject to internal pressure or external pressure. Type A components have a design equation that specifically relates pressure and/or other loads to a required wall thickness. Examples are:  
          <ol type="a" class="appAndLimitList">  
            <li>Pressure vessel cylindrical and conical shell sections satisfying the dimensions criteria</li>  
            <li>Spherical pressure vessels and storage spheres</li>  
            <li>Spherical, elliptical and torispherical formed heads</li>  
            <li>Straight sections of piping systems and elbows or pipe bends that do not have structural attachments satisfying the size and temperature criteria</li>  
            <li>Cylindrical atmospheric storage tank shell courses</li>  
          </ol>  
        </li>  
      </ol>  
      </br></br></br>`;

    report += "Component Information :</br></br>";

    if (Component_MAWP.intEquip === 0)
      report += "Component type = Cylinder</br>";
    else if (Component_MAWP.intEquip === 1)
      report += "Component type = Cone</br>";
    else if (Component_MAWP.intEquip === 2)
      report += "Component type = Sphere</br>";
    else if (Component_MAWP.intEquip === 3)
      report += "Component type = Elliptical Head</br>";
    else if (Component_MAWP.intEquip === 4)
      report += "Component type = F&D Head</br>";
    else if (Component_MAWP.intEquip === 5)
      report += "Component type = B31.3 Piping</br>";
    else report += "Component type = Tank</br>";

    return report;
  }

  public static LTA_report_subpart_2(): string {
    let report: string = "";

    if (Component_MAWP.intUnit_select === 0) {
      // US Customary
      if (Component_MAWP.intEquip !== 6)
        report += `Design pressure or vessel MAWP (psi) = ${Component_MAWP.sngP_d.toFixed(
          1
        )}</br>`;
      else
        report += `Design Maximum Fill Height (ft) = ${CalcTemplate.sngMFH_d.toFixed(
          2
        )}</br>`;
      if (
        !Number.isNaN(Component_MAWP.sngTemp_d) &&
        Component_MAWP.sngTemp_d !== -1
      )
        report += `Design temperature (deg-F) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}</br>`;
      else report += `Design temperature (deg-F) = N/A</br>`;
      report += `Inside diameter corrected for FCA as applicable, D (inch) = ${Component_MAWP.sngID.toFixed(
        3
      )}</br>`;
      if (Component_MAWP.intEquip !== 6) {
        // non-tanks
        report += `Current measured nominal (uniform) thickness, trd (inch) = ${Component_MAWP.sngtrd.toFixed(
          3
        )}</br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = ${Component_MAWP.sngFCA.toFixed(
          3
        )}</br>`;
      } else {
        report += `Current measured nominal (uniform) thickness, trd (inch) = N/A</br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (inch) = N/A</br>`;
      }
      report += `Future corroded thickness, tc (inch) = ${Component_MAWP.sngtc.toFixed(
        3
      )}</br>`;
      if (Component_MAWP.intEquip !== 6) {
        report += `Minimum required thickness, tmin (inch) = ${Component_MAWP.sngtmin.toFixed(
          3
        )}</br>`;
        report += `MAWP of the component based on tc, MAWP (psi) = ${Component_MAWP.sngMAWP.toFixed(
          1
        )}</br>`;
      } else {
        report += `Maximum Fill Height based on tc, MFH (ft) = ${CalcTemplate.sngMFH.toFixed(
          2
        )}</br>`;
      }
    }

    return report;
  }

  public static LTA_report_subpart_3(): string {
    let report: string = "";

    if (Component_MAWP.intUnit_select !== 0) {
      // SI
      if (Component_MAWP.intEquip !== 6)
        report += `Design pressure or vessel MAWP (MPa) = ${Component_MAWP.sngP_d.toFixed(
          1
        )}</br>`;
      else
        report += `Design Maximum Fill Height (m) = ${CalcTemplate.sngMFH_d.toFixed(
          2
        )}</br>`;
      if (Component_MAWP.sngTemp_d !== -1)
        report += `Design temperature (deg-C) = ${Component_MAWP.sngTemp_d.toFixed(
          2
        )}</br>`;
      else report += `Design temperature (deg-C) = N/A`;
      report += `Inside diameter corrected for FCA as applicable, D (mm) = ${Component_MAWP.sngID.toFixed(
        1
      )}</br>`;
      if (Component_MAWP.intEquip !== 6) {
        // non-tanks
        report += `Current measured nominal (uniform) thickness, trd (mm) = ${Component_MAWP.sngtrd.toFixed(
          1
        )}</br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = ${Component_MAWP.sngFCA.toFixed(
          1
        )}</br>`;
      } else {
        report += `Current measured nominal (uniform) thickness, trd (mm) = N/A</br>`;
        report += `Future corrosion allowance, FCA = FCAi + FCAe (mm) = N/A</br>`;
      }
      report += `Future corroded thickness, tc (mm) = ${Component_MAWP.sngtc.toFixed(
        1
      )}</br>`;
      if (Component_MAWP.intEquip !== 6) {
        report += `Minimum required thickness, tmin (mm) = ${Component_MAWP.sngtmin.toFixed(
          1
        )}</br>`;
        report += `MAWP of the component based on tc, MAWP (MPa) = ${Component_MAWP.sngMAWP.toFixed(
          1
        )}</br>`;
      } else {
        report += `Maximum Fill Height based on tc, MFH (m) = ${CalcTemplate.sngMFH.toFixed(
          2
        )}</br>`;
      }
    }

    report += '<div class="pageBreak"></div>';

    return report;
  }

  public static LTA_report_subpart_4(reportDate: string): string {
    let report: string = "";

    if (Component_MAWP.strD_class === "Gouge") {
      report +=
        "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 12</center>";
      report += "<center>Assessment of Gouges</center></br></br>";
    } else {
      report +=
        "</br></br><center>API 579-1/ASME FFS-1 Standard 2021 Edition, Part 5</center>";
      if (Component_MAWP.strD_class === "LTA") {
        report += "<center>Assessment of Local Metal Loss</center></br></br>";
      } else {
        report +=
          "<center>Assessment of Local Metal Loss (Profile)</center></br></br>";
      }
    }

    report += reportDate + "</br>";
    report += "Page: 2</br></br></br>";

    report += `Longitudinal weld joint efficiency, E_L = ${Component_MAWP.sngE_L.toFixed(
      2
    )}</br>`;

    if (Component_MAWP.intEquip <= 1 || Component_MAWP.intEquip >= 5) {
      report += `Circumferential weld joint efficiency, E_C = ${Component_MAWP.sngE_c.toFixed(
        2
      )}</br>`; // modified by shameer c to C
    }

    return report;
  }
}
