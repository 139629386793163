import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NextPageService } from '@cvx/nextpage';
import { Subscription, lastValueFrom } from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';
import { environment } from '../../environments/environment';

@Component({
    selector: 'home',
    templateUrl: 'home.component.html',
    styleUrls: ["home.component.css"]
})

export class HomeComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    isInGroup: boolean = true;

    constructor(private NP: NextPageService,
        private activatedRoute: ActivatedRoute,
        private calService: CalAngularService) {
        localStorage.setItem('calculationStep', '9999'); //set localStorage's calculationStep to a random value outside the step range to gray out the export button and save button
    }

    ngOnInit(): void {
        this.validateUserInGroup();
    }

    private async validateUserInGroup(): Promise<void> {
        let isSigned = await lastValueFrom(this.calService.isUserSignedIn());
        if (isSigned) {
            this.subscription = this.calService.isInGroup(environment.groupName).subscribe({
                next: (isInGroup: boolean) => {
                    this.isInGroup = isInGroup;
                },
                error(err) {
                    console.log(err);
                },
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}